// https://gist.github.com/tomasznguyen/ac28b0b3207c08a81687b657a2ce86d6
import React, { createContext, useContext } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const GoogleMapsContext = createContext({ isLoaded: false });

export const GoogleMapsProvider = ({ children, ...loadScriptOptions }) => {
  const { isLoaded, loadError } = useJsApiLoader(loadScriptOptions);

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);

export const withGoogleMaps = (Component) =>
  function GoogleMapsConnectFunction (componentProps) {
    return (
      <GoogleMapsContext.Consumer>
        {({ isLoaded, loadError }) => (
          <Component
            googleMapsIsLoaded={isLoaded}
            googleMapsLoadError={loadError}
            {...componentProps}
          />
        )}
      </GoogleMapsContext.Consumer>
    );
  };
