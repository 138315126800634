import { apiAxios } from 'src/util';

export const createInvitation = async (data) => {
  const res = await apiAxios.post('invitations', data);
  return res.data;
};

export const getAcceptedInvitationGuids = async (loanGuid) => {
  const res = await apiAxios.get(`invitations/${loanGuid}/accepted`);
  return res.data;
};

export const sendReferralLinkInvitation = async (data) => {
  const res = await apiAxios.post('invitations/referral', data);
  return res.data;
};

export const getAllReferralLinkInvitationsByLoanOfficerId = async (
  loanOfficerId,
) => {
  const res = await apiAxios.get(`invitations/referral/${loanOfficerId}`);
  return res.data;
};
