import { apiAxios } from 'src/util';

const QUERY_LIMIT = 10;

export const fetchNotifications =
  ({ includeDismissed = false, cursor = null } = {}) =>
    async (dispatch) => {
      const cursorKey = `notifications-${includeDismissed}`;
      if (!cursor) {
        dispatch({
          type: 'reset_cursor',
          data: {
            key: cursorKey,
          },
        });
      }
      const res = await apiAxios.get('notifications', {
        params: {
          includeDismissed,
          limit: QUERY_LIMIT,
          cursor: cursor || null,
        },
      });
      const nextCursor = res.data.metadata?.next ?? null;
      const results = res.data.results ?? [];
      const data = getParsedNotifications(results);
      if (data.length) {
        dispatch({
          type: 'set_notifications',
          data,
        });
      }
      dispatch({
        type: 'set_next_cursor',
        data: {
          key: cursorKey,
          nextCursor,
          selfCursor: cursor,
          resultIds: results.map((x) => x.id),
        },
      });
      return data;
    };

export const dismissNotification =
  (notificationId, dismissed = true) =>
    async (dispatch) => {
      const res = await apiAxios.patch(
      `notifications/${notificationId}?notifySocket=false`,
      {
        dismissed,
        unseen: false,
      },
      );
      const data = res.data;
      if (data.metadata) data.metadata = JSON.parse(data.metadata);
      dispatch({
        type: 'update_notification',
        data,
      });
    };

export const dismissAllNotifications = () => async (dispatch, getState) => {
  const notifications = getState().notifications;
  dispatch({
    type: 'set_notifications',
    data: notifications.map((x) => ({
      id: x.id,
      dismissed: true,
      unseen: false,
    })),
  });
  await apiAxios.patch('notifications/dismiss-all?notifySocket=false');
};

export const markAllNotificationsAsSeen = () => async (dispatch, getState) => {
  const notifications = getState().notifications;
  const ids = getFilteredIds(notifications.filter((x) => x.unseen));
  if (!ids.length) return;
  dispatch({
    type: 'set_notifications',
    data: notifications.map((x) => ({
      id: x.id,
      unseen: false,
    })),
  });
  await apiAxios.patch(
    `notifications/batch?ids=${ids.join(',')}&notifySocket=false`,
    {
      unseen: false,
    },
  );
};

export const removeNotification = (notificationId) => async (dispatch) => {
  await apiAxios.delete(`notifications/${notificationId}?notifySocket=false`);
  dispatch({
    type: 'remove_notification',
    data: notificationId,
  });
};

export const removeAllNotifications = () => async (dispatch) => {
  await apiAxios.delete('notifications?notifySocket=false');
  dispatch({
    type: 'remove_all_notifications',
  });
};

export const getParsedNotifications = (items) => {
  return items.map((x) => {
    if (x.metadata) x.metadata = JSON.parse(x.metadata);
    return x;
  });
};

const getFilteredIds = (items) => {
  return items
    .map((x) => {
      return x.id;
    })
    .filter((x) => x);
};
