import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Icon from '@mdi/react';
import { mdiInbox } from '@mdi/js';

/**
 * Empty list placeholder
 * @param {{
 *   icon: import('react').ReactNode,
 *   text: string,
 *   iconSize: number,
 *   textSize: number,
 *   className?: string,
 *   children?: import('react').ReactElement
 * }} props
 */
const Empty = ({
  icon,
  text,
  className,
  children,
  iconSize,
  textSize,
  style,
}) => {
  const iconClone = React.cloneElement(icon, {
    style: { height: iconSize, width: iconSize },
  });

  return (
    <div
      className={clsx(
        styles.emptyComponentContainer,
        className,
        'empty-component-container flex-container flex-center flex',
      )}
      style={style}
    >
      {iconSize > 0 && iconClone}
      {textSize > 0 && (
        <div
          className={styles.text}
          style={{
            fontSize: textSize,
            margin: `${Math.floor(textSize / 2.5)}px 0`,
          }}
        >
          {text}
        </div>
      )}
      {children}
    </div>
  );
};

Empty.defaultProps = {
  icon: <Icon path={mdiInbox} />,
  text: 'No data',
  iconSize: 130,
  textSize: 24,
};

Empty.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  iconSize: PropTypes.number,
  textSize: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Empty;
