import { useEffect } from 'react';
import { useAuth0 } from 'src/applicationproviders';

export const Logout = () => {
  const { logout } = useAuth0();
  useEffect(() => {
    (async () => {
      await logout();
    })();
  }, [logout]);
  return null;
};
