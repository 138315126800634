/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  dismissAllNotifications,
  fetchNotifications,
  markAllNotificationsAsSeen,
  getParsedNotifications,
} from 'src/actions';
import { Button, Paper, Fade, LinearProgress } from '@material-ui/core';
import './index.scss';
import { withRouter, Link } from 'react-router-dom';
import ErrorBoundary from '../../errorboundary';
import { createPortal } from 'react-dom';
import NotificationsList from 'src/components/loanOfficer/NotificationsPopover/NotificationsList';
import { useLockBodyScroll } from 'src/hooks';
import clsx from 'clsx';
import { useWebsocket } from 'src/hooks/signalr/use-websocket';
import { useNotifications } from 'src/hooks/use-notifications';

const EMPTY_HEIGHT = 120;
const HEADER_FOOTER_HEIGHT = 52 + 49;

const Container = ({ open, onClose }) => {
  useLockBodyScroll();
  const dispatch = useDispatch();
  const [height, setHeight] = useState(EMPTY_HEIGHT);
  const [loading, setLoading] = useState(false);
  const notifications = useNotifications({ includeDismissed: false });
  const { ready, on } = useWebsocket();

  useEffect(() => {
    let onCreate, onUpdate, onDelete, onDeleteAll;
    if (ready) {
      onCreate = on('notifications.create', (data) => {
        dispatch({
          type: 'set_notifications',
          data: getParsedNotifications([JSON.parse(data)]),
        });
      });
      onUpdate = on('notifications.update', (data) => {
        dispatch({
          type: 'set_notifications',
          data: getParsedNotifications(JSON.parse(data)),
        });
      });
      onDelete = on('notifications.delete', (data) => {
        dispatch({
          type: 'remove_notification',
          data,
        });
      });
      onDeleteAll = on('notifications.all.delete', () => {
        dispatch({ type: 'remove_all_notifications' });
      });
    }
    return () => {
      if (ready) {
        onCreate.unsubscribe();
        onUpdate.unsubscribe();
        onDelete.unsubscribe();
        onDeleteAll.unsubscribe();
      }
    };
  }, [ready]);

  useEffect(() => {
    dispatch(fetchNotifications({ includeDismissed: false }));
  }, []);

  useEffect(() => {
    if (open) {
      (async () => {
        setLoading(true);
        await dispatch(fetchNotifications({ includeDismissed: false }));
        setLoading(false);
      })();
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (open && notifications.length) {
      if (notifications.some((x) => x.unseen)) {
        setTimeout(() => {
          dispatch(markAllNotificationsAsSeen());
        }, 2000);
      }
    }
  }, [dispatch, open, notifications]);

  useEffect(() => {
    if (open) {
      setHeight(EMPTY_HEIGHT);
    }
  }, [open]);

  const handleDismissAll = async () => {
    await dispatch(dismissAllNotifications());
    setHeight(EMPTY_HEIGHT);
    onClose();
  };

  return (
    <div
      className={clsx('notifications-backing', open && 'active')}
      onClick={onClose}
    >
      <Fade in={open} timeout={200} mountOnEnter unmountOnExit>
        <Paper
          className='notifications flex-container flex-vertical'
          elevation={4}
          onClick={(e) => e.stopPropagation()}
          style={{ height: height + HEADER_FOOTER_HEIGHT }}
        >
          {loading && <LinearProgress className='progress' variant='query' />}
          <header className='notifications-header flex-container'>
            <h2 className='flex'>Notifications</h2>
            {notifications.length > 0 && (
              <Button color='primary' size='small' onClick={handleDismissAll}>
                Mark all as read
              </Button>
            )}
          </header>
          <NotificationsList
            onClose={onClose}
            onHeightChanged={(height) => setHeight(height)}
          />
          <footer className='notifications-footer'>
            <Button
              component={Link}
              to='/notifications'
              color='primary'
              onClick={onClose}
            >
              Manage notifications
            </Button>
          </footer>
        </Paper>
      </Fade>
    </div>
  );
};

const Notifications = (props) =>
  createPortal(
    <ErrorBoundary>
      <Container {...props} />
    </ErrorBoundary>,
    document.getElementById('root'),
  );

export default withRouter(Notifications);
