export enum ApplyPlanCodeResponseStatus {
  Success = 'SUCCESS',
  Confict = 'CONFLICT',
  SuccessWithConflict = 'SUCCESS WITH CONFLICT',
}

export interface EncompassApplyPlanCodeResponse {
  status: ApplyPlanCodeResponseStatus;
  conflicts: EncompassApplyPlanCodeResponseConflict[];
}

export interface EncompassApplyPlanCodeResponseConflict {
  description: string;
  planFieldId: string;
  planValue: string;
  encompassFieldId: string;
  encompassValue: string;
}
