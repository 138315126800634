import { apiAxios } from 'src/util';
import { format } from 'date-fns';
import axios from 'axios';
const now = null; // we want to get full range now
export const fetchReportingDashboardWidgetFilters = () => async (dispatch) => {
  const res = await apiAxios.get('/reporting/filters');
  dispatch({
    type: 'set_reporting_dashboard_template_filters',
    data: res.data,
  });
};

export const fetchReportingDashboardWidgetTemplateFilterData =
  (
    filterId,
    { from = now, to = now, prevFrom, prevTo } = {},
    abortController,
  ) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'set_reporting_dashboard_template_filter_data_loading',
          data: {
            filterId,
            loading: true,
          },
        });
        const params = {
          from: from ? format(from, 'yyyy-MM-dd') : null,
          to: to ? format(to, 'yyyy-MM-dd') : null,
        };
        if (prevFrom) params.prevFrom = format(prevFrom, 'yyyy-MM-dd');
        if (prevTo) params.prevTo = format(prevTo, 'yyyy-MM-dd');
        const res = await apiAxios.get(`/reporting/filters/${filterId}/data`, {
          params,
          signal: abortController?.signal,
        });
        dispatch({
          type: 'set_reporting_dashboard_template_filter_data',
          data: {
            filterId,
            ...res.data,
          },
        });
      } catch (e) {
        if (!axios.isCancel(e)) {
          dispatch({
            type: 'set_reporting_dashboard_template_filter_data',
            data: {
              filterId,
              error:
              e.response?.data?.messages?.[0] ?? 'Could not fetch report data',
            },
          });
        }
        throw e;
      } finally {
        dispatch({
          type: 'set_reporting_dashboard_template_filter_data_loading',
          data: {
            filterId,
            loading: false,
          },
        });
      }
    };

export const registerReportingFilter = (
  filterId,
  needsPreviousDateRange = false,
) => ({
  type: 'add_active_reporting_dashboard_filter',
  data: {
    filterId: filterId?.toString(),
    needsPreviousDateRange,
  },
});

export const unregisterReportingFilter = (
  filterId,
  needsPreviousDateRange = false,
) => ({
  type: 'remove_active_reporting_dashboard_filter',
  data: {
    filterId: filterId?.toString(),
    needsPreviousDateRange,
  },
});

export const createReportingFilter = (data) => async (dispatch) => {
  const res = await apiAxios.post('/reporting/filters', data);
  dispatch({
    type: 'add_reporting_dashboard_filter',
    data: res.data,
  });
};

export const updateReportingFilter = (filterId, data) => async (dispatch) => {
  const res = await apiAxios.patch(`/reporting/filters/${filterId}`, data);
  dispatch({
    type: 'update_reporting_dashboard_filter',
    data: res.data,
  });
};

export const deleteReportingFilter = (filterId) => async (dispatch) => {
  await apiAxios.delete(`/reporting/filters/${filterId}`);
  dispatch({
    type: 'remove_reporting_dashboard_filter',
    data: filterId,
  });
};

export const downloadReportingDashboardReport =
  (filterId, filterName, { from, to }) =>
    async () => {
      if (!from || !to) {
        throw new Error('missing from or to');
      }
      const res = await apiAxios.get(`reporting/filters/${filterId}/export`, {
        params: {
          from: format(from, 'yyyy-MM-dd'),
          to: format(to, 'yyyy-MM-dd'),
        },
      });
      const fileName = `report_${filterName
        .replace(/[^\w\s]+/g, '')
        .replace(/\s{2,}/g, ' ')}_${format(from, 'M-d-yyyy')}_${format(
          to,
          'M-d-yyyy',
        )}.csv`;
      const elem = document.createElement('a');
      const blob = new File([res.data], fileName, {
        type: 'text/csv',
      });
      elem.href = URL.createObjectURL(blob);
      elem.setAttribute('download', fileName);
      elem.click();
      elem.remove();
    };
