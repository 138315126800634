import { combineReducers } from 'redux';
import encompassReducer from './encompassReducer';
import notifications from './notifications';
import cinchTasks from './cinchTasks';
import loanFields from './loanFields';
import loanStorage from './loanStorage';
import messages from './messages';
import loanOfficer from './loanOfficer';
import loanFolders from './loanFolders';
import loanOfficers from 'src/components/admin/store/loanOfficers/reducer';
import addresses from 'src/components/compliance/store/addresses/reducer';
import webhookSubscriptions from 'src/components/admin/store/webhooks/reducer';
import autoDisclosureQueries from 'src/components/admin/store/disclosurequeries/reducer';
import userRoles from 'src/components/admin/store/roles/reducer';
import termsheetDrafts from './termsheetDrafts';
import serviceStatus from './serviceStatus';
import disclosureReports from 'src/components/loanOfficer/specialreports/store/disclosureReports/reducer';
import loans from './loan';
import locks from './locks';
import workState from './workState';
import loanAssociates from './loanAssociates';
import efolder from './efolder';
import paginationCursors from './paginationCursors';
import env from 'src/env';
import appSettings from './appSettings';
import pipelineFields from './pipelineFields';
import titleInvoiceFees from './titleInvoiceFees';
import {
  reportingDashboards,
  reportingDashboardTemplates,
  reportingDashboardTemplateFilters,
  reportingDashboardTemplateFilterData,
  reportingDashboardActiveFilters,
  reportingSchedules,
} from 'src/components/loanOfficer/reporting/store/reducers/index';
import departments from './departments';

const isDev = process.env.NODE_ENV === 'development';
const selectedEnv = isDev
  ? env.getConfig(
    localStorage.getItem('crinch.development.environment') || 'test',
  )
  : env;
const defaultState = {
  functionServer: selectedEnv.FUNCTION_URL,
  apiServer: selectedEnv.API_URL,
  clientID: selectedEnv.CLIENT_ID,
  activeEnvironment: selectedEnv.DEPLOY_ENV,
  httpError: '',
  httpErrorObj: null,
};

const appReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'set_app_error':
      return {
        ...state,
        httpError: payload.errString,
        httpErrorObj: payload.errObj,
      };
    default:
      return state;
  }
};

export default combineReducers({
  app: appReducer,
  loan: encompassReducer,
  loans,
  locks,
  workState,
  notifications,
  cinchTasks,
  loanFields,
  loanStorage,
  messages,
  loanOfficer,
  loanOfficers,
  addresses,
  webhookSubscriptions,
  termsheetDrafts,
  serviceStatus,
  pipelineFields,
  titleInvoiceFees,
  loanFolders,
  disclosureReports,
  autoDisclosureQueries,
  userRoles,
  loanAssociates,
  efolder,
  paginationCursors,
  appSettings,
  reportingDashboards,
  reportingDashboardTemplates,
  reportingDashboardTemplateFilters,
  reportingDashboardTemplateFilterData,
  reportingDashboardActiveFilters,
  reportingSchedules,
  departments,
});
