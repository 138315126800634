const defaultState = {
  'Loan.DateFileOpened': {
    fieldId: 'Loan.DateFileOpened',
    name: 'Start Date',
    columnType: 3,
    isDateValue: true,
  },
  'Loan.LoanNumber': {
    fieldId: 'Loan.LoanNumber',
    name: 'Loan Number',
    columnType: 1,
  },
  'Loan.LoanFolder': {
    fieldId: 'Loan.LoanFolder',
    name: 'Loan Folder',
    columnType: 1,
  },
  'Loan.LastModified': {
    fieldId: 'Loan.LastModified',
    name: 'Last Updated',
    columnType: 1,
    isDateValue: true,
  },
};

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_pipeline_canonicalfields': {
      return {
        ...state,
        ...data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
