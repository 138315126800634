import { mapToCustomLoanFormat } from 'src/actions/loan';
import { apiAxios } from 'src/util';

export const changeTargetLoanProgram =
  (loanGuid: string, targetLoanProgram: string, targetLoanType?: string) =>
    async (dispatch) => {
      const res = await apiAxios.put(
      `loan-migration/loans/${loanGuid}/loan-program`,
      {
        targetLoanProgram,
        targetLoanType: targetLoanType ?? undefined,
      },
      );

      const { loan, resourceLock } = res.data;
      if (loan) {
        dispatch({
          type: 'set_loan',
          data: mapToCustomLoanFormat(loan),
        });
      }
      if (resourceLock) {
        dispatch({
          type: 'set_lock',
          data: { isLockedByOther: false, loanGuid, ...resourceLock },
        });
      }
    };
