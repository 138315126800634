import {
  ADD_CINCH_TASKS,
  GET_CINCH_TASKS,
  SET_CINCH_TASKS,
  UPDATE_CINCH_TASK,
  SET_CINCH_TASKS_WORKING,
  REMOVE_CINCH_TASK,
} from 'src/actions/types';

const defaultState = {
  data: [],
  isWorking: false,
  error: null,
};

const reduce = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_CINCH_TASKS_WORKING:
      return {
        ...state,
        isWorking: payload,
      };
    case GET_CINCH_TASKS:
      return {
        ...state,
        isWorking: true,
        data: [],
      };
    case SET_CINCH_TASKS:
      return {
        ...state,
        isWorking: false,
        data: payload || [],
      };
    case ADD_CINCH_TASKS: {
      const data = [...state.data, ...payload];
      return {
        ...state,
        isWorking: false,
        data: data,
      };
    }
    case UPDATE_CINCH_TASK: {
      const matchIndex = state.data.findIndex(x => x.id === payload.id);
      const data = [...state.data];
      data.splice(matchIndex, 1, {
        ...data[matchIndex],
        ...payload,
      });
      return {
        ...state,
        isWorking: false,
        data,
      };
    }
    case REMOVE_CINCH_TASK: {
      const matchIndex = state.data.findIndex(x => x.id === payload);
      const data = [...state.data];
      data.splice(matchIndex, 1);
      return {
        ...state,
        isWorking: false,
        data,
      };
    }
    default:
      return state;
  }
};

export default reduce;
