export interface EncompassAUSTrackingLog {
  UnderwritingRiskAssessType: UnderwritingRiskAssessType,
}

export enum UnderwritingRiskAssessType {
  ManualUnderwriting = 'ManualUnderwriting',
  DU = 'DU',
  EarlyCheck = 'EarlyCheck',
  LP = 'LP',
  LQA = 'LQA',
  Other = 'Other'
}
