// For Declarations
import React from 'react';
import { YesNoInput } from '@fmm/react-encompass-inputs';
import { FormControlLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';
import styles from './index.module.scss';
import clsx from 'clsx';

export const ConnectedYesNoEmptyInputGroup = React.forwardRef(
  ({ name, rules, required, label, options, id, ...props }, ref) => {
    const { id: contextId } = useAutosavingFormContext();
    return (
      <Controller
        name={name}
        rules={{
          required,
          ...rules,
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <FormControlLabel
              id={id ?? contextId ? `${contextId}.${name}` : name}
              control={
                <YesNoInput
                  className={clsx(styles.yesNoEmptyGroup)}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  options={options}
                  allowEmpty
                />
              }
              label={label}
              disabled={props.disabled}
              ref={ref}
            />
          );
        }}
      />
    );
  },
);
ConnectedYesNoEmptyInputGroup.defaultProps = {
  options: [
    {
      value: true,
      text: 'Yes',
    },
    {
      value: false,
      text: 'No',
    },
  ],
};
ConnectedYesNoEmptyInputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  value: PropTypes.bool,
};
