export const defaultState = [];

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'admin_set_user_roles': {
      return data;
    }
    default:
      return state;
  }
};

export default reducer;
