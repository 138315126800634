import React from 'react';
import PropTypes from 'prop-types';
import { DateTimeInput } from '@fmm/react-encompass-inputs';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';
import { Controller } from 'react-hook-form';

export const ConnectedDateTimeInput = React.forwardRef(
  ({ name, rules, required, id, ...props }, ref) => {
    const { id: contextId } = useAutosavingFormContext();

    return (
      <Controller
        name={name}
        rules={{
          required,
          ...rules,
        }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error },
        }) => (
          <DateTimeInput
            id={id ?? contextId ? `${contextId}.${name}` : name}
            variant='outlined'
            size='small'
            fullWidth
            {...props}
            name={name}
            value={value || ''}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            ref={ref}
            error={invalid}
            helperText={error?.message}
            required={Boolean(required || rules?.required)}
          />
        )}
      />
    );
  },
);

export default ConnectedDateTimeInput;

ConnectedDateTimeInput.displayName = 'ConnectedDateTimeInput';

ConnectedDateTimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
};
