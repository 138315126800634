import { apiAxios } from 'src/util';

export const getFraudGuardData = async (loanGuid) => {
  const res = await apiAxios.get(`/services/fraud/${loanGuid}/getfraudresult`);
  return res.data;
};

export const runFraudGuardAsync = async (loanGuid) => {
  const res = await apiAxios.get(`/services/fraud/${loanGuid}/order`);
  return res.data;
};

export const clearConditions = async (request) => {
  const res = await apiAxios.post('/services/fraud/clearconditions', request);
  return res.data;
};

export const fetchFraudGuardConditions = async () => {
  const res = await apiAxios.get('/services/fraud/getallconditions');
  return res.data;
};

export const updateFraudGuardConditionSolution = async (code, solution) => {
  const res = await apiAxios.patch('/services/fraud/updateconditionsolution', {
    fraudConditionCode: code,
    uwConditionsProperties: solution,
  });
  return res.data;
};

export const downloadFraudGuardReportAsync = async (loanGuid) => {
  const res = await apiAxios.post(
    `/services/fraud/${loanGuid}/downloadpdfstring`,
    {
      responseType: 'text',
    },
  );
  return res.data;
};

export const uploadFraudGuardReportToEncompass = async (loanGuid) => {
  const res = await apiAxios.post(
    `/services/fraud/${loanGuid}/attachtoefolder`,
  );
  return res.data;
};
