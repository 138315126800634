export const defaultState = [];

const sortFees = (fees) => {
  return fees.sort(
    (fee1, fee2) =>
      fee1.sectionDescription.localeCompare(fee2.sectionDescription) ||
      fee1.feeName.localeCompare(fee2.feeName),
  );
};

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_title_fees': {
      return sortFees(data);
    }
    case 'add_title_fee': {
      return sortFees([...state, data]);
    }
    case 'update_title_fee': {
      return sortFees([...state.filter((f) => f.id !== data.id), data]);
    }
    case 'delete_title_fee': {
      return [...state.filter((f) => f.id !== data)];
    }
    default:
      return state;
  }
};

export default reducer;
