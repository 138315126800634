import { deepFreeze } from 'src/util';

const initialState = Object.freeze({});

/**
 * @param {{ [loanGuid: string]: { loanGuid: string } }} state
 * @param {{ type: string, data: { loanGuid: string } }} action
 */
const reduce = function (state = initialState, { type, data }) {
  switch (type) {
    case 'loan_set_error': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          error: data.error,
        },
      });
    }
    case 'loan_start_work': {
      const tasks = state[data.loanGuid]?.tasks ?? [];
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          working: (state[data.loanGuid]?.working || 0) + 1,
          tasks: [...tasks, data.task],
          error: data.error ?? state[data.loanGuid]?.error ?? null,
        },
      });
    }
    case 'loan_stop_work': {
      const tasks =
        state[data.loanGuid]?.tasks?.length > 0
          ? [...(state[data.loanGuid]?.tasks ?? null)]
          : [];
      const index = tasks.indexOf(data.task);
      if (index > -1) {
        tasks.splice(index, 1);
      }
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          working: Math.max(state[data.loanGuid].working - 1, 0),
          tasks: tasks,
          error: data.error ?? state[data.loanGuid]?.error ?? null,
        },
      });
    }
    default:
      return state;
  }
};

export default reduce;
