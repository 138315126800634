import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { LoadingIcon } from '../Loading';

const texts = {
  confirm: {
    cancel: 'Cancel',
    submit: 'Continue',
  },
  alert: {
    submit: 'OK',
  },
};

const ConfirmationDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  onClick,
  reverse,
  buttons,
}) => {
  const [working, setWorking] = useState(false);
  const text = {
    ...texts[variant],
    ...buttons,
  };
  const submitProps =
    text.cancel && !reverse
      ? {
        color: 'primary',
        variant: 'contained',
      }
      : null;
  const cancelProps = reverse
    ? {
      color: 'primary',
      variant: 'contained',
    }
    : null;

  const handleSubmit = useCallback(
    async (e) => {
      try {
        setWorking(true);
        if (onClick?.submit) await onClick.submit(e);
        onSubmit(e);
      } finally {
        setWorking(false);
      }
    },
    [onClick, onSubmit],
  );

  const handleClose = useCallback(() => {
    if (working) return;
    if (variant === 'confirm' || variant === 'info') {
      onClose();
    }
  }, [onClose, variant, working]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      {open && (
        <>
          {title && <DialogTitle>{title}</DialogTitle>}

          {description && <DialogContent>{description}</DialogContent>}
          <DialogActions>
            {text.cancel && (
              <Button
                {...cancelProps}
                onClick={onClose}
                autoFocus
                disabled={working}
              >
                {text.cancel}
              </Button>
            )}
            {text.submit && (
              <Button
                {...submitProps}
                onClick={handleSubmit}
                startIcon={working ? <LoadingIcon /> : null}
                disabled={working}
              >
                {text.submit}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  open: false,
  onSubmit: () => null,
  onClose: () => null,
  variant: 'confirm',
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['confirm', 'alert', 'info']),
  buttons: PropTypes.shape({
    submit: PropTypes.string,
    cancel: PropTypes.string,
  }),
  onClick: PropTypes.shape({
    submit: PropTypes.func,
  }),
};

export default ConfirmationDialog;
