const config = {
  name: 'test',
  hostnames: ['cinchtest.fmm.com', 'cinchtest.azurefd.net', 'crinch.fmm.com'],
  values: {
    FUNCTION_URL: 'https://func-cinch-serverside-test.azurewebsites.net/api/',
    API_URL: 'https://app-cinch-test.azurewebsites.net/api/',
    AUTH_DOMAIN: 'logintest.fmm.com',
    CLIENT_ID: 'sMZyVF6fTqPwyTJqGXmLGXzsppByYifV',
    AI_INSTRUMENTATION_KEY: '8e60ab53-730f-477c-b154-f3aa67606265',
    DEPLOY_ENV: 'test',
    BORROWER_URL: 'https://cinchborrowertest.fmm.com',
    LOGROCKET_APPID: 'fmm/cinch-dev',
  },
};
export default config;
