import { Button } from '@material-ui/core';
import { mdiAlert } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPublicApplicationConfig } from 'src/actions';
import Empty from 'src/components/baseComponents/Empty';
import Loading from 'src/components/baseComponents/Loading';

export const CinchAppConfigContext = React.createContext(null);

export const CinchAppConfigProvider = ({ children, ready }) => {
  const dispatch = useDispatch();
  const [config, setConfig] = useState(null);
  const [error, setError] = useState(null);
  const [tries, setTries] = useState(0);

  useEffect(() => {
    if (!ready) return;
    (async () => {
      try {
        setError(null);
        const config = await fetchPublicApplicationConfig();
        setConfig(config);
      } catch (e) {
        setError(
          e.response?.data?.messages?.[0] ??
            'Could not load app configuration.',
        );
      }
    })();
  }, [dispatch, ready, tries]);

  return (
    <CinchAppConfigContext.Provider value={config}>
      {config ? (
        children
      ) : error ? (
        <Empty text={error} icon={<Icon path={mdiAlert} />}>
          <Button onClick={() => setTries(tries + 1)} variant='outlined'>
            Try Again
          </Button>
        </Empty>
      ) : (
        <Loading label='Loading configuration' dots />
      )}
    </CinchAppConfigContext.Provider>
  );
};
