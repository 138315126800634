import axios from 'axios';
import { LOAN_FIELD_CHANGE } from './types';
const axiosLogicApps = axios.create({
  transformRequest: (data, headers) => {
    // remove the default auth header meant for Cinch API
    delete headers.common.Authorization;
    // return the data or POST will not send a body
    return data;
  },
});

export const updateSubjectCountyFromZip = (zip, fieldId) => (dispatch) => {
  // GetCountyFromZip logic app
  // Should only update the Subject Property's county if Subject Zip code is modified
  if (fieldId === '15') {
    axiosLogicApps
      .post(
        'https://prod-05.eastus.logic.azure.com:443/workflows/a8dc0b7991124a43b997e6a089f75cda/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bY6TlRByCeGo6PVshJFHMHsccDrI8dpzEjxXqh6K9Vg',
        zip,
      )
      .then((res) => {
        dispatch({
          type: LOAN_FIELD_CHANGE,
          fieldId: 13,
          value: res.data,
        });
      })
      .catch((error) => {
        console.log('county update error', error);
      });
  }
};

export const getSofrValue = () => () => {
  return axiosLogicApps.get(
    'https://prod-66.eastus.logic.azure.com:443/workflows/389fddfb72aa4dc2be7ac03aadf5bbeb/triggers/request/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Frequest%2Frun&sv=1.0&sig=ehGcS2Zj3Jc_2cN--lPd344ntGMMzMvfIdp1GrwyhPE',
  );
};
