import { UPDATE_FLOOD_FIELDS } from 'src/actions/types';
import { BorrowerType } from 'src/types';
import {
  byIdArrayReducer,
  deepFreeze,
  deleteDeepById,
  mergeArray,
  mergeDeepWith,
} from 'src/util';

const defaultState = Object.freeze({});

/**
 * @param {{ [id: string]: { id: string } }} state
 * @param {{ type: string, data: { id: string } }} action
 */
// eslint-disable-next-line max-statements, complexity
const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_loan': {
      return deepFreeze({
        ...state,
        [data.id]: data,
      });
    }
    case 'update_loan': {
      // this might need to do a fancy deep merge, include the custom field reducer section too in this
      return deepFreeze({
        ...state,
        [data.id]: data,
      });
    }
    case 'update_loan_custom_fields': {
      const existing = state[data.id];

      const customFields = {
        ...existing.customFields,
        ...data.customFields,
      };

      return deepFreeze({
        ...state,
        [data.id]: {
          ...existing,
          customFields,
        },
      });
    }
    case 'remove_loan': {
      const newState = {
        ...state,
      };
      delete newState[data.id];
      return deepFreeze(newState);
    }

    case 'add_loan_application': {
      const { loanGuid, application } = data;
      const loan = state[loanGuid];
      if (!loan) return state;
      return deepFreeze({
        ...state,
        [loanGuid]: mergeDeepWith(loan, {
          applications: [application],
        }),
      });
    }

    case 'remove_loan_application': {
      const { loanGuid, applicationId } = data;
      const loan = state[loanGuid];
      if (!loan) return state;
      return deepFreeze({
        ...state,
        [loanGuid]: deleteDeepById(loan, {
          applications: [
            {
              id: applicationId,
            },
          ],
        }),
      });
    }

    case 'add_loan_application_entitycollection_item': {
      const { loanGuid, applicationId, entityKey, item } = data;
      const loan = state[loanGuid];
      if (!loan) return state;
      return deepFreeze({
        ...state,
        [loanGuid]: mergeDeepWithApplication(loan, {
          applicationId,
          entityKey,
          item,
        }),
      });
    }
    case 'update_loan_application_entitycollection_item': {
      const { loanGuid, applicationId, entityKey, entityId, item } = data;
      const loan = state[loanGuid];
      if (!loan) return state;
      return deepFreeze({
        ...state,
        [loanGuid]: mergeDeepWithApplication(loan, {
          applicationId,
          entityKey,
          item: {
            id: entityId,
            ...item,
          },
        }),
      });
    }
    case 'delete_loan_application_entitycollection_item': {
      const { loanGuid, applicationId, entityKey, entityId } = data;
      const loan = state[loanGuid];
      if (!loan) return state;
      return deepFreeze({
        ...state,
        [loanGuid]: deleteDeepFromApplication(loan, {
          applicationId,
          entityKey,
          id: entityId,
        }),
      });
    }

    case 'add_loan_borrower_entitycollection_item': {
      const { loanGuid, applicationId, borrowerType, entityKey, item } = data;
      const loan = state[loanGuid];
      if (!loan) return state;
      return deepFreeze({
        ...state,
        [loanGuid]: mergeDeepWithBorrower(loan, {
          applicationId,
          borrowerType,
          entityKey,
          item,
        }),
      });
    }
    case 'update_loan_borrower_entitycollection_item': {
      const {
        loanGuid,
        applicationId,
        borrowerType,
        entityKey,
        entityId,
        item,
      } = data;
      const loan = state[loanGuid];
      if (!loan) return state;
      return deepFreeze({
        ...state,
        [loanGuid]: mergeDeepWithBorrower(loan, {
          applicationId,
          borrowerType,
          entityKey,
          item: {
            id: entityId,
            ...item,
          },
        }),
      });
    }
    case 'delete_loan_borrower_entitycollection_item': {
      const { loanGuid, applicationId, borrowerType, entityKey, entityId } =
        data;
      const loan = state[loanGuid];
      if (!loan) return state;
      return deepFreeze({
        ...state,
        [loanGuid]: deleteDeepFromBorrower(loan, {
          applicationId,
          borrowerType,
          entityKey,
          id: entityId,
        }),
      });
    }

    case UPDATE_FLOOD_FIELDS: {
      const { loanGuid, floodServiceOrderStatus, floodServiceOrderId } = data;
      const loan = state[loanGuid];
      if (!loan) return state;

      return deepFreeze({
        ...state,
        [loanGuid]: {
          ...loan,
          customFieldsMapped: {
            ...loan.customFieldsMapped,
            flood: {
              floodServiceOrderId,
              floodServiceOrderStatus,
            },
          },
        },
      });
    }

    default:
      return state;
  }
};

const mergeDeepWithApplication = (loan, { applicationId, entityKey, item }) => {
  return mergeDeepWith(
    loan,
    {
      applications: [
        {
          id: applicationId,
          [entityKey]: [item],
        },
      ],
    },
    mergeArray,
    byIdArrayReducer,
  );
};

const mergeDeepWithBorrower = (
  loan,
  { applicationId, borrowerType, entityKey, item },
) => {
  const borrowerKey =
    borrowerType === BorrowerType.CoBorrower ? 'coBorrower' : 'borrower';
  return mergeDeepWith(
    loan,
    {
      applications: [
        {
          id: applicationId,
          [borrowerKey]: {
            [entityKey]: [item],
          },
        },
      ],
    },
    mergeArray,
    byIdArrayReducer,
  );
};

const deleteDeepFromApplication = (loan, { applicationId, entityKey, id }) => {
  return deleteDeepById(loan, {
    applications: [
      {
        id: applicationId,
        [entityKey]: [
          {
            id,
          },
        ],
      },
    ],
  });
};

const deleteDeepFromBorrower = (
  loan,
  { applicationId, borrowerType, entityKey, id },
) => {
  const borrowerKey =
    borrowerType === BorrowerType.CoBorrower ? 'coBorrower' : 'borrower';
  return deleteDeepById(loan, {
    applications: [
      {
        id: applicationId,
        [borrowerKey]: {
          [entityKey]: [
            {
              id,
            },
          ],
        },
      },
    ],
  });
};

export default reduce;
