import { useMemo } from 'react';
import { useLoanApplicationById } from 'src/hooks/use-loan-application';

const emptyArray = [];
export const useOrderCreditReportRequirements = (loanGuid, applicationGuid) => {
  const application = useLoanApplicationById(loanGuid, applicationGuid);

  return useMemo(() => {
    if (!loanGuid || !applicationGuid || !application) {
      return emptyArray;
    }
    const borrower = application.borrower;
    const coBorrower = application.coBorrower;
    const results = [
      {
        section: 'Borrower Info',
        missing: [
          {
            name: 'Borrower SSN',
            value: borrower?.taxIdentificationIdentifier,
            location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationGuid}`,
            inputName: 'borrower.taxIdentificationIdentifier',
          },
          {
            name: 'Borrower First Name',
            value: borrower?.firstName,
            location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationGuid}`,
            inputName: 'borrower.firstName',
          },
          {
            name: 'Borrower Last Name',
            value: borrower?.lastName,
            location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationGuid}`,
            inputName: 'borrower.lastName',
          },
          {
            name: 'Borrower Birth Date',
            value: borrower?.birthDate,
            location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationGuid}`,
            inputName: 'borrower.birthDate',
          },
        ].filter((x) => !x.value),
      },
      {
        section: 'Borrower Residence',
        missing: [
          {
            name: 'Borrower Address Street',
            value: borrower?.residences?.[0]?.urla2020StreetAddress,
            location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationGuid}`,
            inputName: 'borrower.urla2020StreetAddress',
          },
          {
            name: 'Borrower Address State',
            value: borrower?.residences?.[0]?.addressState,
            location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationGuid}`,
            inputName: 'borrower.addressState',
          },
          {
            name: 'Borrower Address Postal Code',
            value: borrower?.residences?.[0]?.addressPostalCode,
            location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationGuid}`,
            inputName: 'borrower.addressPostalCode',
          },
          {
            name: 'Borrower Address City',
            value: borrower?.residences?.[0]?.addressCity,
            location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationGuid}`,
            inputName: 'borrower.addressCity',
          },
        ].filter((x) => !x.value),
      },
    ];

    if (coBorrower?.fullName) {
      results.push(
        {
          section: 'Co-Borrower Info',
          missing: [
            {
              name: 'Co-Borrower SSN',
              value: coBorrower?.taxIdentificationIdentifier,
              location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationGuid}`,
              inputName: 'coBorrower.taxIdentificationIdentifier',
            },
            {
              name: 'Co-Borrower First Name',
              value: coBorrower?.firstName,
              location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationGuid}`,
              inputName: 'coBorrower.firstName',
            },
            {
              name: 'Co-Borrower Last Name',
              value: coBorrower?.lastName,
              location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationGuid}`,
              inputName: 'coBorrower.lastName',
            },
            {
              name: 'Co-Borrower Birth Date',
              value: coBorrower?.birthDate,
              location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationGuid}`,
              inputName: 'coBorrower.birthDate',
            },
          ].filter((x) => !x.value),
        },
        {
          section: 'Co-Borrower Residence',
          missing: [
            {
              name: 'Co-Borrower Address Street',
              value: coBorrower?.residences?.[0]?.urla2020StreetAddress,
              location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationGuid}`,
              inputName: 'coBorrower.urla2020StreetAddress',
            },
            {
              name: 'Co-Borrower Address State',
              value: coBorrower?.residences?.[0]?.addressState,
              location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationGuid}`,
              inputName: 'coBorrower.addressState',
            },
            {
              name: 'Co-Borrower Address Postal Code',
              value: coBorrower?.residences?.[0]?.addressPostalCode,
              location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationGuid}`,
              inputName: 'coBorrower.addressPostalCode',
            },
            {
              name: 'Co-Borrower Address City',
              value: coBorrower?.residences?.[0]?.addressCity,
              location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationGuid}`,
              inputName: 'coBorrower.addressCity',
            },
          ].filter((x) => !x.value),
        },
      );
    }

    return results;
  }, [application, applicationGuid, loanGuid]);
};
