import { getLoanAssociates } from 'src/actions';
import { apiAxios } from 'src/util';

export const createLoProfile = (data) => async (dispatch) => {
  const res = await apiAxios.post('loan-officers', data);
  dispatch({
    type: 'admin_add_loan_officer',
    data: res.data,
  });
};

export const updateLoProfile = (id, data) => async (dispatch) => {
  const res = await apiAxios.patch(`loan-officers/${id}`, data);
  dispatch({
    type: 'admin_update_loan_officer',
    data: res.data,
  });
};

export const deleteLoProfile = (id) => async (dispatch) => {
  await apiAxios.delete(`loan-officers/${id}`);
  dispatch({
    type: 'admin_remove_loan_officer',
    data: id,
  });
};

/**
 * @param {{ view?: 'minimal' | 'basic' | 'entity' | 'full' }} params
 */
export const fetchLoanOfficers =
  ({ view } = { view: null }) =>
    async (dispatch) => {
      const res = await apiAxios.get('loan-officers', {
        params: {
          view,
        },
      });
      dispatch({
        type: 'admin_set_loan_officers',
        data: res.data,
      });
    };

export const fetchLoanOfficer =
  (id, { licenses, personas } = { licenses: false, personas: false }) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.get(`loan-officers/${id}`, {
          params: {
            licenses: licenses || false,
            personas: personas || false,
          },
        });
        dispatch({
          type: 'admin_set_loan_officers',
          data: [res.data],
        });
      } catch (err) {
        console.warn(err);
      }
    };

export const getLoResources = (id) => async (dispatch) => {
  const res = await apiAxios.get(`loan-officers/${id}/resources`);
  dispatch({
    type: 'admin_update_loan_officer',
    data: {
      id,
      resources: res.data,
    },
  });
};

/**
 * @param {string} loanGuid Loan guid
 * @param {{
 *   from: number,
 *   to: number
 * }} LoIds Loan officer ids to reassign from and to
 */
export const reassignLoanToLo =
  (loanGuid, { from, to }) =>
    async (dispatch) => {
      await apiAxios.post(`loans/${loanGuid}/reassign?loanOfficerId=${to}`);
      dispatch({
        type: 'admin_unassign_loan_from_loan_officer',
        data: {
          loanGuid,
          loanOfficerId: from,
        },
      });
      dispatch(getLoanAssociates(loanGuid));
      dispatch(
        fetchLoanOfficer(to, {
          licenses: true,
        }),
      );
    };

export const adminReassignLoanToLo =
  (loanGuid, { from, to }) =>
    async (dispatch) => {
      await apiAxios.post(`loans/${loanGuid}/adminreassign?loanOfficerId=${to}`);
      dispatch({
        type: 'admin_unassign_loan_from_loan_officer',
        data: {
          loanGuid,
          loanOfficerId: from,
        },
      });
    };

export const reassignLoanProcessor =
  (loanGuid, { from, to }) =>
    async (dispatch) => {
      await apiAxios.post(`loans/${loanGuid}/reassign-lp?loanOfficerId=${to}`);
      dispatch({
        type: 'admin_unassign_loan_from_loan_officer',
        data: {
          loanGuid,
          loanOfficerId: from,
        },
      });
    };

export const fetchEligibleLoanOfficers = async () => {
  try {
    const res = await apiAxios.get('loan-officers/eligible');
    return res.data;
  } catch (err) {
    console.warn(err);
  }
};

export const fetchEligibleLoanProcessors = async () => {
  try {
    const res = await apiAxios.get('loan-officers/eligible/loan-processors');
    return res.data;
  } catch (err) {
    console.warn(err);
  }
};

export const finishMilestone = async (loanGuid, milestoneName) => {
  await apiAxios.post(`milestones/${loanGuid}?milestoneName=${milestoneName}`);
};

export const unFinishMilestone = async (loanGuid, milestoneName) => {
  await apiAxios.post(
    `milestones/${loanGuid}/unfinish?milestoneName=${milestoneName}`,
  );
};
