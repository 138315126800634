import { UPDATE_LOANSTORAGE } from 'src/actions/types';

const defaultState = {};

const reduce = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_LOANSTORAGE: {
      const newState = { ...state };
      for (const item of payload) {
        newState[item.location] = item;
      }
      return newState;
    }
    default:
      return state;
  }
};

export default reduce;
