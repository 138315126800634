/* AUS / Desktop Underwriting / Loan Product */

import { apiAxios } from 'src/util';

export const getAusTrackingLogs = (loanGuid, applicationId) => async () => {
  const payload = { loanGuid: loanGuid, applicationGuid: applicationId };
  try {
    const res = await apiAxios.post('aus/logs', payload);
    return res.data;
  } catch (err) {
    console.warn(err);
    return null;
  }
};

export const orderDesktopUnderWriting = async (loanGuid, applicationId) => {
  const payload = { loanGuid: loanGuid, applicationGuid: applicationId };
  const res = await apiAxios.post('aus/order/du', payload);
  return res.data;
};
export const orderLoanProduct = async (loanGuid, applicationId) => {
  const payload = { loanGuid: loanGuid, applicationGuid: applicationId };
  const res = await apiAxios.post('aus/order/lp', payload);
  return res.data;
};

export const getAusSummaryComparison = async (
  loanGuid,
  applicationIndex,
  duAttachmentId,
  lpAttachmentId,
) => {
  const payload = {
    loanGuid: loanGuid,
    applicationIndex: applicationIndex,
    duAttachmentId: duAttachmentId,
    lpAttachmentId: lpAttachmentId,
  };
  const res = await apiAxios.post('aus/comparison', payload);
  return res.data;
};
