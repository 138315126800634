import { apiAxios } from 'src/util';

export const generateFNFChecklist = (loanGuid, data) => async () => {
  const res = await apiAxios.post(`fnf/${loanGuid}/checklist`, data);
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${loanGuid}_FNF-Checklist.csv`); // or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const generateFNFExport =
  (loanGuid, data, loanNumber, borrowerLastname) => async () => {
    try {
      const res = await apiAxios({
        method: 'post',
        url: `fnf/${loanGuid}/export/${data}`,
        responseType: 'arraybuffer',
      });
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${data} ${loanNumber} - ${borrowerLastname}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        return 'Error Failed to download file';
      }
    } catch (error) {
      return 'Error occurred while downloading file: ' + error;
    }
  };

export const sendPrequalEmail = async (loanGuid, data) => {
  try {
    const res = await apiAxios.post(`fnf/${loanGuid}/prequal`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export const sendExceptionRequest = async (loanGuid, data) => {
  try {
    const res = await apiAxios.post(`fnf/${loanGuid}/exception`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export const sendGoodFaithReceivedEmail = async (loanGuid, data) => {
  try {
    const res = await apiAxios.post(`fnf/${loanGuid}/good-faith-email`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export const runErnstForFixAndFLip = async (loanGuid) => {
  const res = await apiAxios.post(`LoanFee/loan/${loanGuid}/fixandflip`);
  return res;
};
