import { useLoan } from 'src/hooks';
import { useLoanApplicationById } from 'src/hooks/use-loan-application';

export const useAusRequirements = (loanGuid: string, applicationId = '') => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  const loan = useLoan(loanGuid);

  const borrower = application?.borrower;
  const coBorrower = application?.coBorrower;
  const results = [
    {
      section: 'Loan Info',
      missing: [
        {
          name: 'Loan Amount',
          value: loan?.borrowerRequestedLoanAmount,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'borrowerRequestedLoanAmount',
        },
        {
          name: 'Interest Rate',
          value: loan?.requestedInterestRatePercent,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'requestedInterestRatePercent',
        },
        {
          name: 'Number of Units',
          value: loan?.property?.financedNumberOfUnits,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'property.financedNumberOfUnits',
        },
        {
          name: 'Loan Purpose',
          value: loan?.property?.loanPurposeType,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'property.loanPurposeType',
        },
        {
          name: 'Property Type',
          value: loan?.loanProductData?.gsePropertyType,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'property.gsePropertyType',
        },
        {
          name: 'Subject Property Address',
          value: loan?.property?.streetAddress,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'property.addressLineText',
        },
        {
          name: 'Subject Property County',
          value: loan?.property?.county,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'property.county',
        },
        {
          name: 'Subject Property City',
          value: loan?.property?.city,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'property.city',
        },
        {
          name: 'Subject Property State',
          value: loan?.property?.state,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'property.state',
        },
        {
          name: 'Subject Property Postal Code',
          value: loan?.property?.postalCode,
          location: `/loans/${loan?.id}/forms/loan-info`,
          inputName: 'property.postalCode',
        },
      ].filter((x) => !x.value),
    },
    {
      section: 'Borrower Info',
      missing: [
        {
          name: 'Borrower SSN',
          value: borrower?.taxIdentificationIdentifier,
          location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationId}`,
          inputName: 'borrower.taxIdentificationIdentifier',
        },
        {
          name: 'Borrower First Name',
          value: borrower?.firstName,
          location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationId}`,
          inputName: 'borrower.firstName',
        },
        {
          name: 'Borrower Last Name',
          value: borrower?.lastName,
          location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationId}`,
          inputName: 'borrower.lastName',
        },
        {
          name: 'Borrower Birth Date',
          value: borrower?.birthDate,
          location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationId}`,
          inputName: 'borrower.birthDate',
        },
      ].filter((x) => !x.value),
    },
  ];
  const checks = [
    {
      name: 'Borrower Address Street',
      value: borrower?.residences?.[0]?.urla2020StreetAddress,
      location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
      inputName: 'borrower.urla2020StreetAddress',
    },
    {
      name: 'Borrower Address State',
      value: borrower?.residences?.[0]?.addressState,
      location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
      inputName: 'borrower.addressState',
    },
    {
      name: 'Borrower Address Postal Code',
      value: borrower?.residences?.[0]?.addressPostalCode,
      location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
      inputName: 'borrower.addressPostalCode',
    },
    {
      name: 'Borrower Address City',
      value: borrower?.residences?.[0]?.addressCity,
      location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
      inputName: 'borrower.addressCity',
    },
    {
      name: 'Borrower Residency Currently Owned',
      value: borrower?.residences?.[0]?.residencyBasisType,
      location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
      inputName: 'borrower.residencyBasisType',
    },
  ];
  const months = borrower?.residences?.reduce(
    (a, x) => a + (x.durationTermMonths || 0),
    0,
  );
  const years = borrower?.residences?.reduce(
    (a, x) => a + (x.durationTermYears || 0),
    0,
  );
  if (months === undefined || Math.floor(months / 12) + Number(years) < 2) {
    checks.push({
      name: 'At least 2 years of borrower residency are required',
      value: undefined,
      location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
      inputName: 'borrower.durationTermYears',
    });
  }
  results.push({
    section: 'Borrower Residence',
    missing: checks.filter((x) => !x.value),
  });

  if (coBorrower?.fullName) {
    results.push(
      {
        section: 'Co-Borrower Info',
        missing: [
          {
            name: 'Co-Borrower SSN',
            value: coBorrower?.taxIdentificationIdentifier,
            location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationId}`,
            inputName: 'coBorrower.taxIdentificationIdentifier',
          },
          {
            name: 'Co-Borrower First Name',
            value: coBorrower?.firstName,
            location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationId}`,
            inputName: 'coBorrower.firstName',
          },
          {
            name: 'Co-Borrower Last Name',
            value: coBorrower?.lastName,
            location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationId}`,
            inputName: 'coBorrower.lastName',
          },
          {
            name: 'Co-Borrower Birth Date',
            value: coBorrower?.birthDate,
            location: `/loans/${loanGuid}/forms/borrower-info/information/applications/${applicationId}`,
            inputName: 'coBorrower.birthDate',
          },
        ].filter((x) => !x.value),
      },
      {
        section: 'Co-Borrower Residence',
        missing: [
          {
            name: 'Co-Borrower address street',
            value: coBorrower?.residences?.[0]?.urla2020StreetAddress,
            location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
            inputName: 'coBorrower.urla2020StreetAddress',
          },
          {
            name: 'Co-Borrower Address State',
            value: coBorrower?.residences?.[0]?.addressState,
            location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
            inputName: 'coBorrower.addressState',
          },
          {
            name: 'Co-Borrower Address Postal Code',
            value: coBorrower?.residences?.[0]?.addressPostalCode,
            location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
            inputName: 'coBorrower.addressPostalCode',
          },
          {
            name: 'Co-Borrower Address City',
            value: coBorrower?.residences?.[0]?.addressCity,
            location: `/loans/${loanGuid}/forms/borrower-info/residences/applications/${applicationId}`,
            inputName: 'coBorrower.addressCity',
          },
        ].filter((x) => !x.value),
      },
    );
  }

  return results;
};
