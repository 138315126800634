/* eslint-disable @typescript-eslint/explicit-function-return-type */
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import env from 'src/env';
import { GoogleMapsProvider } from 'src/applicationproviders';
import { BrowserStorageProvider } from 'src/hooks/use-browser-storage';
import store from 'src/store';
import { QueryParamProvider } from 'use-query-params';
import App from './App';
import history from './history';
import { ConfirmationProvider, ErrorHandlerProvider } from './hooks';
import './index.scss';
import { sanitizeRequest, sanitizeResponse } from 'src/util/networksanitizer';
import { CanonicalFieldFetcherProvider } from 'src/applicationproviders/CanonicalFieldFetcherProvider';
import { apiAxios } from 'src/util';
import { Auth0Provider } from 'src/applicationproviders';
import { MuiThemeProvider } from 'src/applicationproviders/MuiThemeProvider';
import { HelmetProvider } from 'react-helmet-async';

if (env.LOGROCKET_APPID) {
  LogRocket.init(env.LOGROCKET_APPID, {
    network: {
      requestSanitizer: (request) => {
        request = sanitizeRequest(request);
        if (request?.url?.toLowerCase()?.indexOf('visualstudio') !== -1) {
          request = null;
        }
        return request;
      },
      responseSanitizer: sanitizeResponse,
    },
  });
  setupLogRocketReact(LogRocket);
  LogRocket.getSessionURL((sessionURL) => {
    apiAxios.defaults.headers.common['X-LogRocket-URL'] = sessionURL;
  });
}

console.log(
  `Build #: %c${process.env.REACT_APP_BUILD_NUMBER ?? 'LOCAL'}`,
  'color: #ffffff; font-weight: bold; background: #00acc4; border-radius: 13px; padding: 2px 10px;',
);

const mapsApiKey = 'AIzaSyBfm1-zWTPuNPLYWXWbRQPgs-tyguqkEiE';
const mapsLibraries = ['places'];

const elem = document.getElementById('root');
const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserStorageProvider>
        <Auth0Provider>
          <MuiThemeProvider>
            <CssBaseline />
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <ErrorHandlerProvider>
                <ConfirmationProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <GoogleMapsProvider
                      googleMapsApiKey={mapsApiKey}
                      language='en'
                      libraries={mapsLibraries}
                    >
                      <Router history={history}>
                        <QueryParamProvider ReactRouterRoute={Route}>
                          <CanonicalFieldFetcherProvider>
                            <HelmetProvider>
                              <Component />
                            </HelmetProvider>
                          </CanonicalFieldFetcherProvider>
                        </QueryParamProvider>
                      </Router>
                    </GoogleMapsProvider>
                  </MuiPickersUtilsProvider>
                </ConfirmationProvider>
              </ErrorHandlerProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </Auth0Provider>
      </BrowserStorageProvider>
    </Provider>,
    elem,
  );
};

if (elem) render(App);

if (process.env.NODE_ENV === 'development' && module.hot && elem) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const nextApp = require('./App').default;
    console.clear();
    render(nextApp);
  });
  module.hot.accept('./reducers', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const reducers = require('./reducers').default;
    store.replaceReducer(reducers());
  });
}
