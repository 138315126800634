export const getFileMimeType = (fileName: string): string | null => {
  if (!fileName?.length) return null;

  const ext: string = fileName.split('.').pop()?.toLowerCase() ?? '';

  return (
    {
      txt: 'text/plain',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      pdf: 'application/pdf',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      tif: 'image/tiff',
    }[ext] || 'application/octet-stream'
  );
};
