/* eslint-disable no-unused-vars */
import { BorrowerType } from 'src/types';

export enum OtherAssetType {
  Annuity = 'Annuity',
  Automobile = 'Automobile',
  Boat = 'Boat',
  BorrowerPrimaryHome = 'BorrowerPrimaryHome',
  BridgeLoanNotDeposited = 'BridgeLoanNotDeposited',
  CashOnHand = 'CashOnHand',
  EarnestMoney = 'EarnestMoney',
  EmployerAssistedHousing = 'EmployerAssistedHousing',
  LeasePurchaseFund = 'LeasePurchaseFund',
  LotEquity = 'LotEquity',
  NetWorthOfBusinessOwned = 'NetWorthOfBusinessOwned',
  PendingNetSaleProceedsFromRealEstateAssets = 'PendingNetSaleProceedsFromRealEstateAssets',
  ProceedsFromSaleOfNonRealEstateAsset = 'ProceedsFromSaleOfNonRealEstateAsset',
  ProceedsFromSecuredLoan = 'ProceedsFromSecuredLoan',
  ProceedsFromUnsecuredLoan = 'ProceedsFromUnsecuredLoan',
  LeasePurchaseCredit = 'LeasePurchaseCredit',
  Other = 'Other',
  RecreationalVehicle = 'RecreationalVehicle',
  RelocationFunds = 'RelocationFunds',
  SavingsBond = 'SavingsBond',
  SeverancePackage = 'SeverancePackage',
  SweatEquity = 'SweatEquity',
  TradeEquityFromPropertySwap = 'TradeEquityFromPropertySwap',
}

export interface EncompassOtherAsset {
  id?: string;
  assetType?: OtherAssetType;
  cashOrMarketValue?: number;
  borrowerType?: BorrowerType;
}
