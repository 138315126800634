import { apiAxios } from 'src/util';

export const getEncompassBusinessContacts = async (filter) => {
  const res = await apiAxios.post('businessContacts/query', filter);
  return res.data;
};

export const getSingleEncompassBusinessContact = async (id) => {
  const res = await apiAxios.get(`businessContacts/id/${id}`);
  return res.data;
};
