import {
  EncompassApplication,
  EncompassClosingCost,
  EncompassCustomField,
  EncompassCustomFieldsMapped,
  EncompassFhaVaLoan,
  EncompassLoanContact,
  EncompassLoanFee,
  EncompassLoanProductData,
  EncompassProperty,
  EncompassRateLock,
  EncompassRegulationZ,
  EncompassTql,
  EncompassUldd,
  EncompassVaLoanData,
} from 'src/types';
import { EncompassMilestone } from 'src/types/encompass/loan/EncompassMilestone';
import { PropertyUsageType } from 'src/types/encompass/loan/EncompassReoProperty';
import { EncompassClosingDocument } from '.';

export enum LoanType {
  Conventional = 'Conventional',
  VA = 'VA',
  FHA = 'FHA',
  Other = 'Other',
  USDARHS = 'FarmersHomeAdministration',
  HELOC = 'HELOC',
}

export enum LoanChannel {
  BankedRetail = 'Banked - Retail',
  BankedWholesale = 'Banked - Wholesale',
  Correspondent = 'Correspondent',
  Brokered = 'Brokered',
}

export interface EncompassLoan {
  id: string;
  applications: EncompassApplication[];
  property?: EncompassProperty;
  customFieldsMapped?: EncompassCustomFieldsMapped;
  customFields?: Record<string, string>;
  purchasePriceAmount?: number; // 136
  borrowerRequestedLoanAmount?: number; // 1109
  titleHolderName1?: string; // 31
  loanNumber?: string; // 364
  ltv?: number; // 353 aka Loan to Value
  combinedLtv?: number; // 976
  requestedInterestRatePercent?: number; // 3
  loanProductData?: EncompassLoanProductData;
  fees?: EncompassLoanFee[];
  closingCost?: EncompassClosingCost;
  fhaVaLoan?: EncompassFhaVaLoan;
  contacts?: EncompassLoanContact[];
  regulationZ?: EncompassRegulationZ;
  rateLock?: EncompassRateLock;
  uldd?: EncompassUldd;
  vaLoanData?: EncompassVaLoanData;
  baseLoanAmount?: number;
  loanAmortizationTermMonths?: number;
  miAndFundingFeeFinancedAmount?: number;
  principalAndInterestMonthlyPaymentAmount?: number;
  mortgageType?: LoanType;
  agencyCaseIdentifier?: string;
  lotAcquiredDate?: string;
  propertyAppraisedValueAmount?: number;
  propertyEstimatedValueAmount?: number;
  channel?: LoanChannel;
  maturityDate?: string;
  loanProgramName?: string;
  tql?: EncompassTql;
  closingDocument?: EncompassClosingDocument;
  fieldLockData?: string[];
  occupancy?: PropertyUsageType;
  milestoneCurrentName?: string;
  milestones?: EncompassMilestone[];
  loanCreationDate?: string;
  originationDate?: string | number | Date;
  milestoneCompletedDate?: string | Date;
}

export interface EncompassLoanPatch
  extends Omit<EncompassLoan, 'customFields'> {
  customFields?: EncompassCustomField[];
}
