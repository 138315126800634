export const defaultState = [];

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'admin_set_loan_officers': {
      const loanOfficers = [...state];
      for (const loanOfficer of data) {
        const matchIndex = state.findIndex(
          (x) => x.id.toString() === loanOfficer.id.toString(),
        );
        if (matchIndex > -1) {
          loanOfficers.splice(matchIndex, 1, {
            ...loanOfficers[matchIndex],
            ...loanOfficer,
          });
        } else {
          loanOfficers.push(loanOfficer);
        }
      }
      return loanOfficers.sort(
        (a, b) => a.firstName?.localeCompare(b.firstName) || 0,
      );
    }
    case 'admin_update_loan_officer': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.id.toString(),
      );
      const loanOfficers = [...state];

      loanOfficers.splice(matchIndex, 1, {
        ...loanOfficers[matchIndex],
        ...data,
      });
      return loanOfficers;
    }
    case 'admin_add_loan_officer': {
      const loanOfficers = [...state, data];
      return loanOfficers;
    }
    case 'admin_remove_loan_officer': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.toString(),
      );
      const loanOfficers = [...state];
      loanOfficers.splice(matchIndex, 1);
      return loanOfficers;
    }
    case 'admin_unassign_loan_from_loan_officer': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.loanOfficerId.toString(),
      );
      const loanOfficers = [...state];
      // resources are not fetched or not needed(?) when reassigning on a loan by loan basis
      const existingResources = loanOfficers?.[matchIndex].resources ?? [];
      const resources = [...existingResources];

      const updatedResources = resources.filter((res) => {
        if (res.resourceType === 'Borrower' && res.loanGuid === data.loanGuid) {
          return false;
        }
        if (res.resourceType === 'Loan' && res.resourceId === data.loanGuid) {
          return false;
        }
        return true;
      });

      loanOfficers.splice(matchIndex, 1, {
        ...loanOfficers[matchIndex],
        resources: updatedResources,
      });
      return loanOfficers;
    }
    case 'update_loan_officer_addresses': {
      const loanOfficers = [...state];
      delete data.address.id;
      for (let i = 0; i < loanOfficers.length; ++i) {
        const lo = loanOfficers[i];
        if (lo.addressId === data.addressId) {
          loanOfficers.splice(i, 1, {
            ...lo,
            address: data.address,
          });
        }
      }
      return loanOfficers;
    }
    default:
      return state;
  }
};

export default reducer;
