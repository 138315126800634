import { useLoan } from 'src/hooks';
import { RequiredItemLocation } from 'src/types/FormValidation';

export const useFloodRequirements = (loanGuid: string) => {
  const loan = useLoan(loanGuid);
  const items: RequiredItemLocation[] = [
    {
      name: 'Subject Property Address',
      value: loan?.property?.streetAddress,
      location: `/loans/${loan?.id}/forms/loan-info`,
      inputName: 'property.addressLineText',
    },
    {
      name: 'Subject Property County',
      value: loan?.property?.county,
      location: `/loans/${loan?.id}/forms/loan-info`,
      inputName: 'property.county',
    },
    {
      name: 'Subject Property City',
      value: loan?.property?.city,
      location: `/loans/${loan?.id}/forms/loan-info`,
      inputName: 'property.city',
    },
    {
      name: 'Subject Property State',
      value: loan?.property?.state,
      location: `/loans/${loan?.id}/forms/loan-info`,
      inputName: 'property.state',
    },
    {
      name: 'Subject Property Postal Code',
      value: loan?.property?.postalCode,
      location: `/loans/${loan?.id}/forms/loan-info`,
      inputName: 'property.postalCode',
    },
  ];

  return items.filter((x) => !x.value);
};
