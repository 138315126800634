import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { ConnectedSelectInput } from 'src/components/ConnectedInputs';
import styles from '../index.module.scss';

export const Minutes = ({ disabled }) => {
  const minutes = useWatch({
    name: 'minutes',
  });
  const tooltip = useMemo(() => getDisplayValue(minutes), [minutes]);

  return (
    <Tooltip title={tooltip} arrow placement='top'>
      <ConnectedSelectInput
        multiple
        displayEmpty
        name='minutes'
        options={options}
        disabled={disabled}
        fullWidth={false}
        className={clsx(styles.input, styles.minutesInput)}
        renderValue={getDisplayValue}
      />
    </Tooltip>
  );
};

const getDisplayValue = (values) =>
  values.length ? values.sort((a, b) => a - b).join(', ') : 'Every minute';

const options = new Array(60).fill(0).map((_, i) => ({
  text: `${i.toString().padStart(2, '0')}`,
  value: i,
}));
