import React from 'react';
import PropTypes from 'prop-types';
import { IntegerInput } from '@fmm/react-encompass-inputs';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';
import { Controller } from 'react-hook-form';

export const ConnectedNumberInput = React.forwardRef(
  (
    { name, rules, decimalLimit, required, id, allowNullOrEmpty, ...props },
    ref,
  ) => {
    const { id: contextId } = useAutosavingFormContext();

    return (
      <Controller
        name={name}
        rules={{
          required,
          ...rules,
        }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error },
        }) => (
          <IntegerInput
            id={id ?? contextId ? `${contextId}.${name}` : name}
            variant='outlined'
            size='small'
            fullWidth
            {...props}
            decimalLimit={decimalLimit}
            name={name}
            value={value ?? ''}
            onChange={(e) => {
              let value = e.target.value;
              if (allowNullOrEmpty && (value === null || value === '')) {
                // let's us reset field instead of setting to 0
                onChange(value);
              } else if (e.target.value !== '') value = +value;
              onChange(value);
            }}
            onBlur={onBlur}
            ref={ref}
            error={invalid}
            helperText={error?.message}
            required={Boolean(required || rules?.required)}
          />
        )}
      />
    );
  },
);

export default ConnectedNumberInput;
ConnectedNumberInput.displayName = 'ConnectedNumberInput';
ConnectedNumberInput.defaultProps = {
  decimalLimit: 2,
};
ConnectedNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  decimalLimit: PropTypes.number,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  allowNullOrEmpty: PropTypes.bool,
};
