import { apiAxios } from 'src/util';

export const fetchWebhookSubscriptions = () => async (dispatch) => {
  const res = await apiAxios.get('webhooks/subscriptions');
  dispatch({
    type: 'admin_set_webhook_subscriptions',
    data: res.data,
  });
};

export const fetchWebhookSubscriptionEvents =
  (subscriptionId) => async (dispatch) => {
    const res = await apiAxios.get(
      `webhooks/subscriptions/${subscriptionId}/events`,
    );
    dispatch({
      type: 'admin_update_webhook_subscription',
      data: {
        id: subscriptionId,
        eventHistory: res.data,
      },
    });
  };

export const createDefaultWebhookSubscriptions = () => async (dispatch) => {
  await apiAxios.post('webhooks/subscriptions/defaults');
  await dispatch(fetchWebhookSubscriptions());
};

export const removeWebhookSubscription =
  (subscriptionId) => async (dispatch) => {
    await apiAxios.delete(`webhooks/subscriptions/${subscriptionId}`);
    dispatch({
      type: 'admin_delete_webhook_subscription',
      data: subscriptionId,
    });
  };
