import { apiAxios } from 'src/util';

export const getAllUserRoles = () => async (dispatch) => {
  const res = await apiAxios.get('roles');
  dispatch({
    type: 'admin_set_user_roles',
    data: res.data,
  });
};

export const getRolesForUser = async (userId) => {
  const res = await apiAxios.get(`loan-officers/${userId}/roles`);
  return res.data;
};

export const updateRolesForUser = async (userId, roleIds) => {
  await apiAxios.patch(`loan-officers/${userId}/roles`, roleIds);
};
