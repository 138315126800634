import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { fetchLoanPipelineCanonicalFields } from 'src/actions';
import { useLoanPipelineCanonicalFields } from 'src/hooks';

const CanonicalFieldFetcherContext = createContext({});

export const CanonicalFieldFetcherProvider = ({ children }) => {
  const [requested, setRequested] = useState([]);
  const [fetching, setFetching] = useState([]);
  const dispatch = useDispatch();
  const canonicalFields = useLoanPipelineCanonicalFields();
  const requestedJson = JSON.stringify(requested);

  useEffect(() => {
    (async () => {
      const requestedFields = JSON.parse(requestedJson);
      const pendingFields = requestedFields.filter(
        (field) => !fetching.includes(field),
      );
      const missingFields = pendingFields.filter(
        (field) => !canonicalFields[field],
      );

      if (requestedFields.length) {
        // clear requested fields for next run
        setRequested((current) =>
          current.filter((field) => !requestedFields.includes(field)),
        );
      }
      if (missingFields.length) {
        // add missing fields to fetching so they are not requested again
        // then fetch the actual data
        setFetching((current) => current.concat(missingFields));
        await dispatch(fetchLoanPipelineCanonicalFields(missingFields));
        setFetching((current) =>
          current.filter((field) => !missingFields.includes(field)),
        );
      }
    })();
  }, [canonicalFields, requestedJson, dispatch, fetching]);

  const fetch = useCallback(
    (fields) => {
      if (!fields?.length) return;

      const missing = fields.filter((field) => !canonicalFields[field]);
      if (!missing.length) return;

      setRequested((current) => [...new Set(current.concat(missing))]);
    },
    [canonicalFields],
  );

  const value = useMemo(
    () => ({
      fetchCanonicalFields: fetch,
    }),
    [fetch],
  );

  return (
    <CanonicalFieldFetcherContext.Provider value={value}>
      {children}
    </CanonicalFieldFetcherContext.Provider>
  );
};

/**
 * @returns {{ fetchCanonicalFields: (fields: string[]) => void }}
 */
export const useCanonicalFieldFetcherContext = () =>
  useContext(CanonicalFieldFetcherContext);
