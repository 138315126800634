import { BorrowerType } from 'src/types';

export enum OtherIncomeSourceDescription {
  AccessoryUnitIncome = 'AccessoryUnitIncome',
  Alimony = 'Alimony',
  AutomobileAllowance = 'AutomobileAllowance',
  BoarderIncome = 'BoarderIncome',
  CapitalGains = 'CapitalGains',
  ChildSupport = 'ChildSupport',
  DefinedContributionPlan = 'DefinedContributionPlan',
  Disability = 'Disability',
  DividendsInterest = 'DividendsInterest',
  EmploymentRelatedAccount = 'EmploymentRelatedAccount',
  FosterCare = 'FosterCare',
  HousingAllowance = 'HousingAllowance',
  HousingChoiceVoucherProgram = 'HousingChoiceVoucherProgram',
  MortgageCreditCertificate = 'MortgageCreditCertificate',
  MortgageDifferential = 'MortgageDifferential',
  NonBorrowerHouseholdIncome = 'NonBorrowerHouseholdIncome',
  NotesReceivableInstallment = 'NotesReceivableInstallment',
  Other = 'Other',
  Pension = 'Pension',
  PublicAssistance = 'PublicAssistance',
  Royalties = 'Royalties',
  SeparateMaintenance = 'SeparateMaintenance',
  TemporaryLeave = 'TemporaryLeave',
  TipIncome = 'TipIncome',
  Trust = 'Trust',
  Unemployment = 'Unemployment',
  VABenefitsNonEducational = 'VABenefitsNonEducational',
}

export interface EncompassOtherIncomeSource {
  id: string;
  owner?: BorrowerType;
  description?: OtherIncomeSourceDescription;
  monthlyAmount?: number;
  otherDescription?: string;
}
