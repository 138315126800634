import { Button, FormControl, FormHelperText } from '@material-ui/core';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiUpload } from '@mdi/js';
import styles from './index.module.scss';
import clsx from 'clsx';

export const FileInput = React.forwardRef(
  (
    {
      name,
      required,
      label,
      buttonText,
      value,
      onChange,
      accept,
      allowEmptyFiles = false,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef();

    return (
      <FormControl className={styles.container} ref={ref}>
        <label htmlFor={`file-input-${name}`}>
          <Button
            startIcon={<Icon path={mdiUpload} />}
            className={styles.button}
            variant='outlined'
            color='secondary'
            onClick={() => inputRef.current.click()}
          >
            {buttonText ?? `${value ? 'Change' : 'Select'} file`}
          </Button>
          <span className={clsx(styles.fileName, value && styles.selected)}>
            {value?.name ?? label}
          </span>
          <input
            {...props}
            ref={inputRef}
            id={`file-input-${name}`}
            className={styles.hiddenInput}
            name={name}
            type='file'
            onChange={onChange}
            required={required}
            accept={accept}
          />
        </label>
        {value?.size === 0 && !allowEmptyFiles && (
          <FormHelperText error>File must not be empty.</FormHelperText>
        )}
      </FormControl>
    );
  },
);

FileInput.defaultProps = {
  onChange: () => null,
  label: 'No file selected',
};

FileInput.displayName = 'FileInput';

FileInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }),
  onChange: PropTypes.func,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
};
