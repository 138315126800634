export enum TaxFormTranscriptType {
  OtherTranscriptDescription = 'OtherTranscriptDescription',
  ReturnTranscriptIndicator = 'ReturnTranscriptIndicator',
  AccountTranscriptIndicator = 'AccountTranscriptIndicator',
  RecordOfAccountIndicator = 'RecordOfAccountIndicator',
  VerificationOfNonfilingIndicator = 'VerificationOfNonfilingIndicator',
}

export enum TaxType {
  Individual = 'Individual',
  Business = 'Business',
}

export enum TaxFormType {
  IRS1065 = 'IRS1065',
  IRS1099 = 'IRS1099',
  IRS1040 = 'IRS1040',
  IRS1120 = 'IRS1120',
  IRS1120S = 'IRS1120S',
  IRSW2 = 'IRSW2',
}
