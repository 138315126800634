export const defaultState = {};

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'update_service_statuses': {
      const newState = { ...state };
      for (const k in data) {
        newState[k] = {
          ...state[k],
          ...data[k],
        };
      }
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
