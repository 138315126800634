import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { ConnectedSelectInput } from 'src/components/ConnectedInputs';
import styles from '../index.module.scss';

export const MonthDays = ({ disabled }) => {
  const monthDays = useWatch({
    name: 'monthDays',
  });
  const tooltip = useMemo(() => getDisplayValue(monthDays), [monthDays]);

  return (
    <Tooltip title={tooltip} arrow placement='top'>
      <ConnectedSelectInput
        multiple
        displayEmpty
        name='monthDays'
        options={options}
        disabled={disabled}
        fullWidth={false}
        className={clsx(styles.input, styles.monthDaysInput)}
        renderValue={getDisplayValue}
      />
    </Tooltip>
  );
};

const getDisplayValue = (values) =>
  values.length
    ? values.sort((a, b) => a - b).join(', ')
    : 'Every day of the month';

const options = new Array(31).fill(0).map((_, i) => ({
  text: `${i + 1}`,
  value: i + 1,
}));
