import { FloodServiceRequest } from 'src/types';
import { apiAxios } from 'src/util';
import { UPDATE_FLOOD_FIELDS } from './types';

export const orderFloodService =
  (loanGuid, productData: FloodServiceRequest) => async (dispatch) => {
    const res = await apiAxios.post(
      `services/flood/loans/${loanGuid}`,
      productData,
    );
    dispatch({
      type: UPDATE_FLOOD_FIELDS,
      data: {
        floodServiceOrderStatus: res?.data?.orderStatus,
        floodServiceOrderId: res?.data?.orderId,
      },
    });
    return res.data;
  };

export const getFloodReportStatus = (loanGuid) => async (dispatch) => {
  const res = await apiAxios.get(`services/flood/loans/${loanGuid}`);
  dispatch({
    type: UPDATE_FLOOD_FIELDS,
    data: {
      loanGuid,
      floodServiceOrderStatus: res?.data?.orderStatus,
      floodServiceOrderId: res?.data?.orderId,
    },
  });
  return res.data;
};
