import { useUserRoles } from 'src/hooks';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ROLE_NAMES } from 'src/util';

/**
 * Shows <c>children</c> if user has any of the roles in <c>roles</c>,
 * Otherwise shows <c>fallback</c>
 * @param {{
 *   roles: (typeof ROLE_NAMES[keyof ROLE_NAMES])[],
 *   children: import('react').ReactNode,
 *   fallback?: import('react').ReactNode,
 * }} props
 */
const HasRole = ({ roles, children, fallback }) => {
  if (!roles || !roles.length) {
    throw new Error("HasRole is missing prop 'roles'");
  }
  const userRoles = useUserRoles();
  const hasAccess = useMemo(() => {
    return userRoles.some((role) => roles.includes(role));
  }, [roles, userRoles]);

  // show Developer sections if on local env
  const hasLocalDeveloper =
    process.env.NODE_ENV === 'development' &&
    roles.includes(ROLE_NAMES.DEVELOPER);

  if (hasAccess || hasLocalDeveloper) return children;
  return fallback;
};

HasRole.defaultProps = {
  children: null,
  fallback: null,
};

HasRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ROLE_NAMES)))
    .isRequired,
  children: PropTypes.any,
  fallback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
};

export default HasRole;
