import { apiAxios } from 'src/util';

export const fetchAutoDisclosurePipelineQueries = () => async (dispatch) => {
  const res = await apiAxios.get('settings/pipeline-queries');
  dispatch({
    type: 'admin_set_pipeline_queries',
    data: res.data,
  });
};

export const fetchAutoDisclosurePipelineQuery = (id) => async (dispatch) => {
  const res = await apiAxios.get(`settings/pipeline-queries/${id}`);
  dispatch({
    type: 'admin_update_pipeline_query',
    data: res.data,
  });
  return res.data;
};

export const createAutoDisclosurePipelineQuery = (data) => async (dispatch) => {
  const res = await apiAxios.post('settings/pipeline-queries', data);
  dispatch({
    type: 'admin_add_pipeline_query',
    data: res.data,
  });
  return res.data;
};

export const updateAutoDisclosurePipelineQuery =
  (queryId, data) => async (dispatch) => {
    const res = await apiAxios.patch(
      `settings/pipeline-queries/${queryId}`,
      data,
    );
    dispatch({
      type: 'admin_update_pipeline_query',
      data: res.data,
    });
    return res.data;
  };

export const removeAutoDisclosurePipelineQuery =
  (queryId) => async (dispatch) => {
    await apiAxios.delete(`settings/pipeline-queries/${queryId}`);
    dispatch({
      type: 'admin_remove_pipeline_query',
      data: queryId,
    });
  };

export const deployAutoDisclosurePipelineQuery =
  (queryId) => async (dispatch) => {
    const res = await apiAxios.post(
      `settings/pipeline-queries/${queryId}/deploy`,
    );
    dispatch({
      type: 'admin_deploy_pipeline_query',
      data: res.data,
    });
    return res.data;
  };
