import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { CheckboxInput } from 'src/components/baseComponents/Inputs';
import { AuditRuleMessages } from 'src/components/loanOfficer/NotificationsManager/MaventAuditResults/ResultsDialog/AuditRuleMessages';
import { PlanCodeConflicts } from 'src/components/loanOfficer/NotificationsManager/MaventAuditResults/ResultsDialog/PlanCodeConflicts';
import { CinchLongProcessStepMetadata } from 'src/types';
import styles from './index.module.scss';

interface MaventAuditResultsDialogProps {
  data: CinchLongProcessStepMetadata;
  open: boolean;
  onClose: () => void;
}

export const MaventAuditResultsDialog: FC<MaventAuditResultsDialogProps> = ({
  data,
  open,
  onClose,
}) => {
  const [hideOk, setHideOk] = useState(true);
  const { type: dataType, data: auditResult } = data;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      className={styles.dialog}
    >
      <DialogContent className={styles.content}>
        {dataType === 'MaventLoanAudit' &&
          'complianceReviewResult' in auditResult && (
          <section>
            <div className={clsx(styles.header, 'flex-container')}>
              <div className='flex'>
                <h2>Compliance review results</h2>
                <h3>Status: {auditResult.complianceReviewResult.message}</h3>
              </div>
              <div>
                <CheckboxInput
                  label='Show only items that need attention'
                  checked={hideOk}
                  onChange={(e, c) => setHideOk(c)}
                />
              </div>
            </div>
            <AuditRuleMessages
              messages={auditResult.complianceReviewResult.auditRuleMessages}
              hideOk={hideOk}
            />
          </section>
        )}
        {dataType === 'PlancodeResponse' && 'conflicts' in auditResult && (
          <section>
            <div className={clsx(styles.header, 'flex-container')}>
              <div className='flex'>
                <h2>Plan code results</h2>
                <h3>Status: {auditResult.status}</h3>
              </div>
            </div>
            <p>Some fields did not contain the expected values:</p>
            <PlanCodeConflicts messages={auditResult.conflicts} />
          </section>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
