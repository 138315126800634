export const defaultState = [];

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'admin_set_webhook_subscriptions': {
      const subscriptions = [...state];
      for (const subscription of data) {
        const matchIndex = state.findIndex(
          (x) => x.id.toString() === subscription.id.toString(),
        );
        if (matchIndex > -1) {
          subscriptions.splice(matchIndex, 1, {
            ...subscriptions[matchIndex],
            ...subscription,
          });
        } else {
          subscriptions.push(subscription);
        }
      }
      return subscriptions.sort(
        (a, b) => a.firstName?.localeCompare(b.firstName) || 0,
      );
    }
    case 'admin_update_webhook_subscription': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.id.toString(),
      );
      const subscriptions = [...state];

      subscriptions.splice(matchIndex, 1, {
        ...subscriptions[matchIndex],
        ...data,
      });
      return subscriptions;
    }
    case 'admin_delete_webhook_subscription': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.toString(),
      );
      const subscriptions = [...state];

      subscriptions.splice(matchIndex, 1);
      return subscriptions;
    }
    default:
      return state;
  }
};

export default reducer;
