/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import FMLoader from 'src/components/baseComponents/FMLoader';
import { SlowSuspense } from 'src/components/baseComponents/SlowSuspense';

const FifteenUnitsDeny = lazy(() => import('./FifteenUnitsDeny'));
const DenyDeal = lazy(() => import('./DenyDeal'));
const IdleTimeout = lazy(() => import('./IdleTimeout'));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function Modal (props) {
  const [forceBtnClick, setForceBtnClick] = React.useState(false);
  const modalMap = {
    // loan officer side
    FifteenUnitsDeny: FifteenUnitsDeny,
    DenyDeal: DenyDeal,
    IdleTimeout: IdleTimeout,
  };

  React.useEffect(() => {
    // force users to click on an option instead of clicking off or hitting ESC
    if (props.modal === 'IdleTimeout') {
      setForceBtnClick(true);
    }
  }, []);

  const ModalContent = modalMap[props.modal];

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={(e, reason) => {
        if (
          (reason === 'backdropClick' || reason === 'escapeKeyDown') &&
          forceBtnClick
        ) {
          return;
        }
        props.toggle(e);
      }}
      maxWidth='sm'
      fullWidth
    >
      <SlowSuspense fallback={<FMLoader backgroundColor='#ffffff' />}>
        <ModalContent {...props}>
          {({ title, content, actions }) => {
            return (
              <>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{content}</DialogContent>
                <DialogActions>{actions}</DialogActions>
              </>
            );
          }}
        </ModalContent>
      </SlowSuspense>
    </Dialog>
  );
}
export default Modal;
