import {
  useLoan,
  useLoanCustomFieldsMapped,
  useLoanTypeFlags,
} from 'src/hooks';
import { LoanType } from 'src/types';
import { useMemo } from 'react';

export const useAppraisalWarning = (loanGuid: string): string | null => {
  const loan = useLoan(loanGuid);
  const fields = useLoanCustomFieldsMapped(loanGuid);
  const loanTypeFlag = useLoanTypeFlags(loanGuid);

  const appraisalOrderedDate = fields?.appraisal?.orderedDate;
  const agencyCaseIdentifier = loan?.agencyCaseIdentifier;
  const mortgageType = loan?.mortgageType;
  const borrowerItp = loan?.regulationZ?.borrowerIntendToContinueDate;
  const loanEstimateStatus =
    loan?.customFieldsMapped?.targetLoanProgram?.nqm?.loanEstimateStatus;

  return useMemo(() => {
    if (
      loanEstimateStatus !== 'NO LE' &&
      !loanTypeFlag.isFnf &&
      !loanTypeFlag.isCommercial &&
      !loanTypeFlag.isMultifamilyCommercial &&
      !borrowerItp
    ) {
      return 'You cannot order an appraisal until you have received the Intent To Proceed from the borrower.';
    }
    if (!agencyCaseIdentifier && mortgageType === LoanType.FHA) {
      return 'You cannot order an appraisal on an FHA loan before you have an FHA CASE #';
    } else if (appraisalOrderedDate) {
      return `You already ordered an appraisal on ${appraisalOrderedDate}`;
    }
    return null;
  }, [
    appraisalOrderedDate,
    agencyCaseIdentifier,
    mortgageType,
    borrowerItp,
    loanEstimateStatus,
    loanTypeFlag,
  ]);
};
