import { apiAxios, STEP_FRIENDLY_IDS } from 'src/util';

/**
 * @param {string} guid
 * @param {number[]} pairIndexes
 * @param {object[]} borrowerPairs
 * @param {string} loanNumber
 * @param {string} residentialOrCommercial
 * @param {string} borrowerName
 * @param {string} addressState
 * @param {string} loanType
 * @param {{ ernst?: boolean, disclosureFieldCheckFlag?:boolean, maventQueueFlag?: boolean }} options
 * @returns
 */
export const addToMaventQueue =
  (
    guid,
    borrowerPairs,
    pairIndexes,
    loanNumber,
    borrowerName,
    addressState,
    loanType,
    disclosureType,
    residentialOrCommercial,
  ) =>
  // eslint-disable-next-line max-statements
    async (dispatch, getState) => {
      try {
      // create long process
        let res = await createMaventLongProcess(
          guid,
          borrowerPairs,
          loanNumber,
          borrowerName,
          disclosureType,
        );

        const process = res.data;
        const notificationId = process.id;
        const state = getState();

        // create disclosureOrder request body
        const disclosureOrder = {
          loanGuid: guid,
          applicationIndexes: pairIndexes,
          borrowerPairs,
          loanNumber,
          notificationId,
          borrowerName,
          loEmail: state.loanOfficer.email,
          residentialOrCommercial,
          state: addressState,
          loanType,
          disclosureType: getDisclosureTypeParam(disclosureType),
        };

        // Add to mavent queue
        res = await queue({
          ...disclosureOrder,
          longProcessId: disclosureOrder.notificationId,
          currentStepId: process.longProcessSteps[0].id,
        });
      } catch (e) {
        console.warn(e);
      }
    };

const queue = async ({
  loanGuid,
  applicationIndexes,
  loanNumber,
  residentialOrCommercial,
  loEmail,
  longProcessId,
  state,
  disclosureType,
  currentStepId,
}) => {
  return await apiAxios.post(`disclosures/mavent/${loanGuid}/queue`, {
    applicationIndexes,
    loanNumber,
    residentialOrCommercial,
    loEmail,
    state,
    longProcessId,
    currentStepId,
    disclosureType,
  });
};

const getDisclosureTypeParam = (disclosureType) => {
  switch (disclosureType) {
    case 'COC':
      return 'ChangeOfCircumstance';
    case 'Initial':
      return 'Initial';
    default:
      return disclosureType;
  }
};

const createMaventLongProcess = async (
  guid,
  borrowerPairs,
  loanNumber,
  borrowerName,
  disclosureType,
) => {
  const res = await apiAxios.post('longprocesses', {
    pName: `${
      disclosureType === 'Commercial'
        ? disclosureType
        : `Residential ${disclosureType}`
    } Disclosures <small>${borrowerName} - #${loanNumber} - Direct Mavent Queue</small>`,
    pStatus: 'Running',
    description: '',
    longProcessSteps: [
      {
        title: 'Adding to Mavent queue',
        friendlyId: STEP_FRIENDLY_IDS.MAVENTQUEUE,
      },
      {
        title: 'Performing compliance review',
        friendlyId: STEP_FRIENDLY_IDS.COMPLIANCECHECK,
      },
      {
        title: 'Sending disclosures for e-signature',
        steps: borrowerPairs.map((pair, i) => ({
          title: `${pair.borrower?.fullNameWithSuffix || `Borrower ${i + 1}`}`,
          applicationIndex: i,
        })),
        friendlyId: STEP_FRIENDLY_IDS.SENDINGSIGNNOW,
      },
    ],
    category: 'Disclosure',
    loanGuid: guid,
  });

  return res;
};
