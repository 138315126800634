import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';

/**
 * only supports setting initial value Y/N/true/false - onChange will always use an actual boolean
 */
export const ConnectedCheckboxInput = React.forwardRef(
  ({ name, rules, required, label, id, ...props }, ref) => {
    const { id: contextId } = useAutosavingFormContext();

    return (
      <Controller
        name={name}
        rules={{
          required,
          ...rules,
        }}
        render={({ field: { value, onChange, onBlur } }) => {
          const isYN = ['Y', 'N', ''].includes(value);
          const checked = isYN ? value === 'Y' : Boolean(value);
          return (
            <FormControlLabel
              id={id ?? contextId ? `${contextId}.${name}` : name}
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    const value = e.target.checked;
                    onChange(value);
                  }}
                  {...props}
                  name={name}
                  onBlur={onBlur}
                  ref={ref}
                  required={Boolean(required)}
                />
              }
              label={label}
              disabled={props.disabled}
            />
          );
        }}
      />
    );
  },
);

ConnectedCheckboxInput.displayName = 'ConnectedCheckboxInput';

ConnectedCheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
};
