import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useUserRoles } from 'src/hooks';
import { useCurrentLoanOfficer } from 'src/hooks/use-loan-officer';
import { Department, RootStore } from 'src/types';
import { ROLE_NAMES } from 'src/util';

const emptyArray = [];

export const useCurrentLoanOfficerDepartments = () => {
  const current = useCurrentLoanOfficer();
  return current?.departments ?? emptyArray;
};

export const useDepartments = (): Department[] => {
  const departments = useSelector<RootStore, Department[]>(
    (store) => store.departments,
  );
  return useMemo(() => {
    return departments.map<Department>((dep) => ({
      ...dep,
      parentDepartmentId: dep.parentDepartmentId ?? null,
    }));
  }, [departments]);
};

export const useDepartment = (
  departmentId: string | number,
): Department | null => {
  const deps = useDepartments();
  if (!departmentId) return null;
  return (
    deps.find((dep) => dep.id.toString() === departmentId.toString()) ?? null
  );
};

export const useIsDepartmentManager = (): boolean => {
  const roles = useUserRoles();
  const userDepartments = useCurrentLoanOfficerDepartments();

  return useMemo(() => {
    return (
      roles.includes(ROLE_NAMES.ADMINISTRATOR) ||
      userDepartments.some((dep) => dep.isManager)
    );
  }, [userDepartments, roles]);
};
