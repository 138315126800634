import { useCurrentLoanOfficer } from 'src/hooks';
import { ROLE_NAMES } from 'src/util';

const emptyArray = [];

export const useUserRoles = () => {
  const loanOfficer = useCurrentLoanOfficer();
  return loanOfficer.roles ?? emptyArray;
};

export const useIsAdmin = () => {
  const roles = useUserRoles();
  return roles.includes(ROLE_NAMES.ADMINISTRATOR);
};

export const useHasRole = (
  roles: typeof ROLE_NAMES[keyof typeof ROLE_NAMES][],
) => {
  const userRoles = useUserRoles();
  return roles.some((role) => userRoles.includes(role));
};
