import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchEligibleLoanOfficers, fetchLoanOfficers } from 'src/actions';
import Loading from 'src/components/baseComponents/Loading';
import { useCurrentLoanOfficer, useLoanOfficers } from 'src/hooks';
import PropTypes from 'prop-types';

const emptyArray = [];
/*
  TODO
    Potentially separate cinch users and loan officers into two different components to avoid confusion
    may require near identical components, but they are different calls at the root anyways.
*/
export const CinchUserPicker = ({
  onChange,
  includeSelf,
  includeEncompassDuplicates,
  value,
  label = 'Select loan officer',
  autoFocus,
  required,
  helperText,
  error,
  excludeLoanOfficerIds = emptyArray,
  excludeIneligibleLoanOfficers = false,
  requireAuthorization = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loanOfficers = useLoanOfficers();
  const currentLoanOfficer = useCurrentLoanOfficer();
  const [inputValue, setInputValue] = useState('');
  const [eligibleLoanOfficers, setEligibleLoanOfficers] = useState([]);

  const loanOfficersFiltered = useMemo(() => {
    if (excludeIneligibleLoanOfficers) {
      if (requireAuthorization) {
        // filter officers they shouldn't have access to
      }
      return eligibleLoanOfficers
        .filter(
          (x) =>
            (includeSelf || x.id !== currentLoanOfficer.id) &&
            (includeEncompassDuplicates ||
              x.encompassUser !== currentLoanOfficer.encompassUser) &&
            !excludeLoanOfficerIds.includes(x.id),
        )
        .map((x) => ({
          ...x,
          firstLetter: x.name[0]?.toUpperCase(),
        }));
    }
    return loanOfficers
      .filter(
        (x) =>
          (includeSelf || x.id !== currentLoanOfficer.id) &&
          (includeEncompassDuplicates ||
            x.encompassUser !== currentLoanOfficer.encompassUser) &&
          !excludeLoanOfficerIds.includes(x.id),
      )
      .map((x) => ({
        ...x,
        firstLetter: x.name[0]?.toUpperCase(),
      }));
  }, [
    loanOfficers,
    includeSelf,
    currentLoanOfficer.id,
    currentLoanOfficer.encompassUser,
    includeEncompassDuplicates,
    excludeLoanOfficerIds,
    eligibleLoanOfficers,
    excludeIneligibleLoanOfficers,
    requireAuthorization,
  ]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (excludeIneligibleLoanOfficers) {
        setEligibleLoanOfficers(await fetchEligibleLoanOfficers());
      } else {
        await dispatch(fetchLoanOfficers({ view: 'basic' }));
      }
      setLoading(false);
    })();
  }, [dispatch, excludeIneligibleLoanOfficers]);

  return (
    <div
      className='flex flex-container flex-vertical'
      style={{ width: '100%', marginBottom: 4 }}
    >
      {loading ? (
        <Loading label='Fetching loan officers...' />
      ) : (
        <Autocomplete
          className='flex'
          {...props}
          value={value}
          onChange={(e, value) => onChange(e, value || null)}
          inputValue={inputValue}
          onInputChange={(_, value) => setInputValue(value)}
          getOptionLabel={(opt) => opt.name || ''}
          groupBy={(option) => option.firstLetter}
          options={loanOfficersFiltered}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant='outlined'
              size='small'
              autoFocus={autoFocus}
              required={required}
              helperText={helperText}
              error={error}
            />
          )}
        />
      )}
    </div>
  );
};

CinchUserPicker.defaultProps = {
  onChange: () => null,
};

CinchUserPicker.propTypes = {
  includeEncompassDuplicates: PropTypes.bool,
  includeSelf: PropTypes.bool,
  excludeLoanOfficerIds: PropTypes.arrayOf(PropTypes.number),
};
