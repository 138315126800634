import { apiAxios } from 'src/util';

export const createAppraisalFee = async (data) => {
  const res = await apiAxios.post('appraisal-fees', data);
  return res.data;
};

export const updateAppraisalFee = async (id, data, notifyAppraisalDesk) => {
  const res = await apiAxios.patch(`appraisal-fees/${id}`, data, {
    params: {
      notifyAppraisalDesk,
    },
  });
  return res.data;
};

export const deleteAppraisalFee = async (id) => {
  await apiAxios.delete(`appraisal-fees/${id}`);
  return id;
};
export const fetchAppraisalFees = async () => {
  const res = await apiAxios.get('appraisal-fees');
  return res.data;
};
