import { HubConnectionState } from '@microsoft/signalr';
import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createConnection } from 'src/hooks/signalr/connection';

/**
 * @type {React.Context<{ connection: import('@microsoft/signalr').HubConnection, status: HubConnectionState }>}
 */
export const WebsocketContext = createContext(null);

/**
 * @param {{
 *   hub: string,
 *   options: import('@microsoft/signalr').IHttpConnectionOptions
 * }} props
 * @returns
 */
const WebsocketProvider = ({ children, hub, options, ready }) => {
  /**
   * @type {import('react').MutableRefObject<HubConnection>}
   */
  const connectionRef = useRef(null);
  const [status, setStatus] = useState(HubConnectionState.Disconnected);

  useEffect(() => {
    if (ready && !connectionRef.current) {
      connectionRef.current = createConnection(hub, options);
      connectionRef.current.onclose((e) => {
        setStatus(HubConnectionState.Disconnected);
        console.log(
          '%c[websocket]%c Disconnected.',
          'color:pink',
          'color:auto',
          e.toString(),
        );
      });
      connectionRef.current.onreconnecting((e) => {
        setStatus(HubConnectionState.Reconnecting);
        console.log(
          '%c[websocket]%c Connection lost. Reconnecting...',
          'color:pink',
          'color:auto',
          e.toString(),
        );
      });
      connectionRef.current.onreconnected(() => {
        setStatus(HubConnectionState.Connected);
        console.log('%c[websocket]%c Reconnected.', 'color:pink', 'color:auto');
      });

      (async () => {
        try {
          await connectionRef.current.start();
          console.assert(
            connectionRef.current.state === HubConnectionState.Connected,
          );
          console.log(
            '%c[websocket]%c Connection established!',
            'color:pink',
            'color:auto',
          );
        } catch (e) {
          console.log(
            '%c[websocket]%c Connection failed.',
            'color:pink',
            'color:auto',
          );
          console.warn('[SignalR]', e);
        } finally {
          setStatus(connectionRef.current.state);
        }
      })();
    }
    return () => {
      if (connectionRef.current) {
        connectionRef.current.stop().then(() => {
          console.assert(
            connectionRef.current.state === HubConnectionState.Disconnected,
          );
          console.log(
            '%c[websocket]%c Connection closed (component unmounted).',
            'color:pink',
            'color:auto',
          );
        });
      }
    };
  }, [hub, options, ready]);

  const value = useMemo(
    () => ({
      status,
      connection: connectionRef.current,
    }),
    [status],
  );

  return (
    <WebsocketContext.Provider value={value}>
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketProvider;
