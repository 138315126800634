import { apiAxios } from 'src/util';

export const createReportingDashboardWidgetTemplate =
  (data) => async (dispatch) => {
    const res = await apiAxios.post('/reporting/widgets', data);

    dispatch({
      type: 'add_reporting_dashboard_template',
      data: res.data,
    });

    return res.data;
  };

export const fetchReportingDashboardWidgetTemplates =
  () => async (dispatch) => {
    const res = await apiAxios.get('/reporting/widgets');
    dispatch({
      type: 'set_reporting_dashboard_templates',
      data: res.data,
    });
  };
export const updateReportingDashboardWidgetTemplate =
  (templateId, body) => async (dispatch) => {
    const res = await apiAxios.patch(`/reporting/widgets/${templateId}`, body);

    dispatch({
      type: 'update_reporting_dashboard_template',
      data: res.data,
    });
  };

export const deleteReportingDashboardWidgetTemplate =
  (templateId) => async (dispatch) => {
    await apiAxios.delete(`/reporting/widgets/${templateId}`);

    dispatch({
      type: 'remove_reporting_dashboard_template',
      data: templateId,
    });
  };
