import axios from 'axios';
import { apiAxios, DEFAULT_PROFILE_PASSWORD } from 'src/util';
import {
  SET_LOAN_OFFICER,
  UPDATE_LOAN_OFFICER_PIPELINE_COLUMNS,
} from './types';

export const testCredentials = async ({
  userId,
  password,
  type,
  serviceProvider,
  loId,
}) => {
  const body = {
    userId,
    password,
    serviceProvider,
  };

  let url = `loan-officers/test-credentials/${type}`;
  if (loId) {
    url += `?loId=${loId}`;
  }
  const res = await apiAxios.post(url, body);
  return res.data;
};

export const getLoInvites = ({ loId, borrowerEmail, loName, referralUrl }) => {
  return axios.post('GetLOInvites?loId=' + loId, {
    borrowerEmail,
    referralUrl,
    loName,
    loId,
  });
};

export const getLoggedInUser =
  ({ personas, licenses } = {}) =>
    async (dispatch) => {
      const res = await apiAxios.get('loan-officers/me', {
        params: {
          personas: personas || false,
          licenses: licenses || false,
        },
      });
      dispatch({
        type: SET_LOAN_OFFICER,
        payload: res.data,
      });
      dispatch({
        type: 'admin_set_loan_officers',
        data: [res.data],
      });
      return res;
    };

export const updateLoggedInUser = (data) => async (dispatch, getState) => {
  const existing = getState().loanOfficer;
  let endpoint = 'loan-officers/me';
  if (existing.needsSetup) {
    endpoint += '?licenses=true&personas=true';
  }

  //Make sure the default password is not sent to the server
  if (data.encompassPassword === DEFAULT_PROFILE_PASSWORD) {
    delete data.encompassPassword;
  }

  if (data.creditPassword === DEFAULT_PROFILE_PASSWORD) {
    delete data.creditPassword;
  }

  if (data.accountChekPassword === DEFAULT_PROFILE_PASSWORD) {
    delete data.accountChekPassword;
  }

  const res = await apiAxios.patch(endpoint, data);
  dispatch({
    type: SET_LOAN_OFFICER,
    payload: res.data,
  });
  dispatch({
    type: 'admin_update_loan_officer',
    data: res.data,
  });
  return res;
};

export const updateLoggedInUserMetadata = (data) => async (dispatch) => {
  const res = await apiAxios.patch('loan-officers/me/metadata', data);
  dispatch({
    type: SET_LOAN_OFFICER,
    payload: {
      metadata: res.data,
    },
  });
};

export const updateLoggedInUserPipelineTableColumnConfig =
  (tableId, columns) => async (dispatch, getState) => {
    const originalColumns = getState().loanOfficer.pipelineConfigs?.find(
      (c) => c.tableId === tableId,
    )?.columns;
    const mappedColumns = columns.map((c) => ({
      fieldId: c.fieldId,
      width:
        c.width ?? originalColumns?.find((o) => o.fieldId === c.fieldId)?.width,
    }));
    const res = await apiAxios.patch(
      `loan-officers/me/pipelineConfig/${tableId}`,
      {
        columns: mappedColumns,
      },
    );

    dispatch({
      type: UPDATE_LOAN_OFFICER_PIPELINE_COLUMNS,
      payload: res.data,
    });
  };
