const config = {
  name: 'development',
  hostnames: ['localhost'],
  values: {
    FUNCTION_URL: 'http://localhost:7071/api/',
    API_URL: 'http://localhost/api/',
    AUTH_DOMAIN: 'logintest.fmm.com',
    CLIENT_ID: 'sMZyVF6fTqPwyTJqGXmLGXzsppByYifV',
    AI_INSTRUMENTATION_KEY: '509ad2af-52fd-4f42-9166-3f73c308d9cc',
    DEPLOY_ENV: 'development',
    BORROWER_URL: 'http://localhost:3001',
    LOGROCKET_APPID: null,
  },
};
export default config;
