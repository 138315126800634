import {
  CommercialExceptionApproval,
  CommercialExceptionRequest,
} from 'src/types/CommercialExceptionRequest';
import { apiAxios } from 'src/util';

export const requestCommercialException = async (
  data: CommercialExceptionRequest,
) => {
  await apiAxios.post('commercial-exceptions/request', data);
};

export const approveCommercialException = async (
  data: CommercialExceptionApproval,
) => {
  const res = await apiAxios.get(
    `commercial-exceptions/approve/${data.loanId}/${data.userEmail}/${false}`,
  );

  return res.data;
};
