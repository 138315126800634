import { apiAxios } from 'src/util';

export const getUnreadMessagesForLoan = loanGuid => async dispatch => {
  const res = await apiAxios.get(`loans/${loanGuid}/messages/unread`);

  dispatch({
    type: 'set_messages',
    data: res.data,
  });
};

export const getMessagesForTask = taskId => async dispatch => {
  const res = await apiAxios.get(`tasks/${taskId}/messages`);

  dispatch({
    type: 'set_messages',
    data: res.data,
  });
};

export const updateMessage = (messageId, data) => async dispatch => {
  const res = await apiAxios.patch(`messages/${messageId}`, data);
  dispatch({
    type: 'update_message',
    data: res.data,
  });
};

export const sendTaskMessage = (taskId, data) => async dispatch => {
  const res = await apiAxios.post(`tasks/${taskId}/messages`, data);
  dispatch({
    type: 'add_message',
    data: res.data,
  });

  // load any new messages that may have been sent
  if (res.data.loanGuid) {
    await dispatch(getUnreadMessagesForLoan(res.data.loanGuid));
  }
};

export const deleteMessage = messageId => async dispatch => {
  try {
    await apiAxios.delete(`messages/${messageId}`);
    dispatch({
      type: 'remove_message',
      data: messageId,
    });
  } catch (e) {
    return e;
  }
};

export const updateMessageReadStatus = (
  messageIds,
  status,
) => async dispatch => {
  const data = {
    ids: messageIds,
    isRead: status,
  };
  const res = await apiAxios.patch('messages/read', data);
  // wait a little bit so user has a chance to see the unread indicator
  setTimeout(() => {
    dispatch({
      type: 'set_messages',
      data: res.data.ids.map(id => ({
        id,
        isRead: res.data.isRead,
      })),
    });
  }, 4000);
};
