import React, { useMemo } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { useUserRoles } from 'src/hooks';
import { CINCH_NOI_FLAG, CINCH_ACCOUNTING_FLAG, ROLE_NAMES } from 'src/util';
import { ActiveLoanTab } from 'src/components/baseComponents/Header/Tabs/ActiveLoanTab';
import styles from '../index.module.scss';
import { ExternalLinksTab } from 'src/components/baseComponents/Header/Tabs/ExternalLinksTab';
import { useAppConfiguration } from '@fmm/react-app-configuration';
import {
  mdiAccountMultiple,
  mdiChartBar,
  mdiClockAlertOutline,
  mdiCog,
  mdiViewArray,
  mdiViewList,
} from '@mdi/js';

export const NavTabs = () => {
  const location = useLocation();
  const navItems = useNavItems();

  return (
    <Tabs
      className={styles.navButtons}
      value={false}
      TabIndicatorProps={{
        className: styles.activeTabIndicator,
      }}
    >
      {!location.pathname.startsWith('/admin') && <ActiveLoanTab />}
      {navItems.map(({ text, link, exact }) => {
        return (
          <Tab
            key={link}
            component={NavLink}
            to={link}
            exact={exact}
            className={styles.navButton}
            activeClassName={styles.navButtonActive}
            label={text}
          />
        );
      })}
      {!location.pathname.startsWith('/admin') && <ExternalLinksTab />}
    </Tabs>
  );
};

export const useNavItems = () => {
  const location = useLocation();
  const userRoles = useUserRoles();
  const { features } = useAppConfiguration() ?? {};

  return useMemo(() => {
    let items = defaultNavItems;
    if (location.pathname.startsWith('/admin')) {
      items = adminNavItems;
    }
    return items.filter(({ roles, featureFlag }) => {
      if (featureFlag && !features[featureFlag]) return false;
      if (!roles?.length) return true;
      return roles.some((role) => userRoles.includes(role));
    });
  }, [location.pathname, userRoles, features]);
};

const defaultNavItems = [
  {
    text: 'Pipeline',
    link: '/dashboard',
    icon: mdiViewList,
  },
  {
    text: 'Reporting',
    link: '/reporting',
    icon: mdiChartBar,
    roles: [ROLE_NAMES.FMM],
  },
  /*{
    text: 'Reports',
    link: '/reports',
  },*/
  {
    text: 'Appraisal loans',
    link: '/appraisalloans',
    icon: mdiViewArray,
    exact: true,
    roles: [
      ROLE_NAMES.FMM,
      ROLE_NAMES.ADMINISTRATOR,
      ROLE_NAMES.APPRAISALMANAGER,
    ],
  },
  {
    text: 'NOI',
    link: '/noticeofincompletion',
    icon: mdiClockAlertOutline,
    featureFlag: CINCH_NOI_FLAG,
    roles: [ROLE_NAMES.FMM],
  },
  {
    text: 'Accounting',
    link: '/accounting',
    icon: mdiClockAlertOutline,
    featureFlag: CINCH_ACCOUNTING_FLAG,
    roles: [ROLE_NAMES.FMM, ROLE_NAMES.ADMINISTRATOR, ROLE_NAMES.ACCOUNTING],
  },
];

export const adminNavItems = [
  {
    text: 'Users',
    link: '/admin/users',
    icon: mdiAccountMultiple,
    roles: [ROLE_NAMES.ADMINISTRATOR],
  },
  {
    text: 'Settings',
    link: '/admin/settings',
    icon: mdiCog,
    roles: [
      ROLE_NAMES.ADMINISTRATOR,
      ROLE_NAMES.ACCOUNTING,
      ROLE_NAMES.RATESMANAGER,
      ROLE_NAMES.APPRAISALMANAGER,
      ROLE_NAMES.DISCLOSUREDESKMANAGER,
    ],
  },
];
