import { AggregateDatesData } from 'src/types';
import { apiAxios } from 'src/util';

export const fetchEscrowAggregateData = async (): Promise<
  AggregateDatesData[]
> => {
  const res = await apiAxios.get('/aggregate-dates');
  return res.data;
};

export const addEscrowAggregateData = async (
  data: AggregateDatesData,
): Promise<AggregateDatesData> => {
  const res = await apiAxios.post('/aggregate-dates', data);
  return res.data;
};

export const updateEscrowAggregateData = async (
  data: AggregateDatesData,
): Promise<AggregateDatesData> => {
  const res = await apiAxios.patch('/aggregate-dates', data);
  return res.data;
};

export const deleteEscrowAggregateData = async (data: AggregateDatesData) => {
  const res = await apiAxios.delete('/aggregate-dates', { data: data });
  return res.data;
};
