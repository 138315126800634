import { compareDesc, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCursors } from 'src/hooks';
import { RootStore, CinchNotification } from 'src/types';

const emptyArray = [];

export const useNotifications = ({
  includeDismissed = false,
}: {
  includeDismissed: boolean;
}) => {
  const notifications = useSelector<RootStore, CinchNotification[]>(
    (store) => store.notifications,
  );
  const cursorData = useCursors(`notifications-${includeDismissed}`);

  return useMemo(() => {
    if (!cursorData) return emptyArray;
    const resultIds = new Set(
      cursorData.reduce((a, x) => a.concat(x.items), [] as (number | string)[]),
    );
    // @ts-ignore
    const maxId = Math.max(...resultIds);
    const recentNotifications = notifications.filter((x) => x.id > maxId);
    const previousNotifications = notifications.filter((x) =>
      resultIds.has(x.id),
    );
    return recentNotifications
      .concat(previousNotifications)
      .filter((x) => includeDismissed || !x.dismissed)
      .sort((a, b) =>
        compareDesc(parseISO(a.createdAt), parseISO(b.createdAt)),
      );
  }, [cursorData, includeDismissed, notifications]);
};
