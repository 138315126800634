import { apiAxios } from 'src/util';

export const queryPipeline =
  (
    { loanIds, filter, sortOrder, fields },
    { start, limit, validateFields, checkEncompassCredentials } = {},
  ) =>
    async (dispatch) => {
      const res = await dispatch(
        queryPipelineWithPaginationInfo(
          {
            loanIds,
            filter,
            sortOrder,
            fields,
          },
          {
            start,
            limit,
            validateFields,
            checkEncompassCredentials,
          },
        ),
      );

      return res.data;
    };

export const queryPipelineWithPaginationInfo =
  (
    { loanIds, filter, sortOrder, fields },
    {
      start,
      limit,
      validateFields,
      includeLockInfo,
      checkEncompassCredentials,
    } = {},
  ) =>
    async () => {
      const res = await apiAxios.post(
        'loanPipeline',
        {
          loanIds,
          filter,
          sortOrder,
          fields,
        },
        {
          params: {
            start,
            limit,
            validateFields,
            include: includeLockInfo ? 'LockInfo' : undefined,
            checkEncompassCredentials,
          },
        },
      );
      return {
        data: res.data,
        count: +res.headers['x-total-count'],
      };
    };
