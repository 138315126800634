import Decimal from 'decimal.js';
import { useEffect, useState, useMemo } from 'react';

export const useMultiFamilyLoanCalculationsLtvc = (formMethods) => {
  const { watch, setValue } = formMethods;
  const isConstruction = watch('loandata.isConstruction');
  const purchasePriceOrEstimatedValue = watch(
    'loandata.purchasePriceEstimatedValue',
  );
  const loanType = watch('loandata.loanType');
  const loanAmount = watch('loandata.loanAmount');
  const loanTerm = watch('loandata.loanTerm');
  const ltcValue = watch('loandata.construction.ltc');
  const ltcLocked = watch('loandata.construction.ltcLocked');
  const ltvValue = watch('loandata.ltv');
  const ltvLocked = watch('loandata.ltvLocked');
  const estimatedValue = watch('loandata.purchase.estimatedValue');
  const arv = watch('loandata.construction.arv');
  const totalProjectCosts = watch('calculated.totalProjectCosts');

  // Used to prevent circular updating. LoanAmount updates ltc/ltv, and ltc/ltv updates LoanAmount
  const [trackLtc, setTrackLtc] = useState();
  const [trackLtv, setTrackLtv] = useState();

  const parseNumber = (value) => {
    return Number.isFinite(parseFloat(value)) ? parseFloat(value) : 0;
  };

  const baseLtc = useMemo(() => {
    return parseNumber(totalProjectCosts);
  }, [totalProjectCosts]);

  const baseLtv = useMemo(() => {
    if (isConstruction === 'Yes') {
      return parseFloat(arv ?? 0);
    } else if (
      loanType === 'Refinance' ||
      (loanType === 'Purchase' && loanTerm === 'Bridge')
    ) {
      return parseFloat(purchasePriceOrEstimatedValue ?? 0);
    } else {
      return Math.min(
        parseFloat(purchasePriceOrEstimatedValue ?? 0),
        parseFloat(estimatedValue ?? 0),
      );
    }
  }, [
    isConstruction,
    loanType,
    loanTerm,
    purchasePriceOrEstimatedValue,
    estimatedValue,
    arv,
  ]);

  const calculateLtvc = (baseLtvc, loanAmount) => {
    if (baseLtvc === 0 || loanAmount === 0) {
      return 0;
    }
    return new Decimal(loanAmount)
      .div(new Decimal(baseLtvc))
      .times(100)
      .toFixed(3);
  };

  const calculateLoanAmountFromLtvc = (baseLtvc, ltvc) => {
    if (baseLtvc === 0 || ltvc === 0) {
      return 0;
    }
    return new Decimal(baseLtvc).div(100).times(new Decimal(ltvc)).toFixed(3);
  };

  useEffect(() => {
    if (Number.isFinite(parseFloat(loanAmount))) {
      const ltv = calculateLtvc(baseLtv, loanAmount);
      setTrackLtv(ltv);
      setValue('loandata.ltv', ltv);
    }

    if (isConstruction === 'Yes') {
      if (Number.isFinite(parseFloat(loanAmount))) {
        const ltc = calculateLtvc(baseLtc, loanAmount);
        setValue('loandata.construction.ltc', ltc);
        setTrackLtc(ltc);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseLtv, baseLtc, loanAmount]);

  useEffect(() => {
    if (
      ltvLocked === false &&
      Number.isFinite(parseFloat(trackLtv)) &&
      Number.isFinite(parseFloat(ltvValue)) &&
      parseFloat(trackLtv) !== parseFloat(ltvValue)
    ) {
      const newValue = calculateLoanAmountFromLtvc(baseLtv, ltvValue);
      setValue('loandata.loanAmount', newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltvValue]);

  useEffect(() => {
    if (
      ltcLocked === false &&
      isConstruction === 'Yes' &&
      Number.isFinite(parseFloat(trackLtc)) &&
      Number.isFinite(parseFloat(ltcValue)) &&
      parseFloat(trackLtc) !== parseFloat(ltcValue)
    ) {
      const newValue = calculateLoanAmountFromLtvc(baseLtc, ltcValue);
      setValue('loandata.loanAmount', newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltcValue]);
};
