import { apiAxios } from 'src/util';

export const createInvestor = async (data) => {
  const res = await apiAxios.post('investors', data);
  return res.data;
};

export const updateInvestor = async (id, data) => {
  const res = await apiAxios.patch(`investors/${id}`, data);
  return res.data;
};

export const deleteInvestor = async (id) => {
  await apiAxios.delete(`investors/${id}`);
  return id;
};

/**
 * @param {{ view?: 'minimal' | 'basic' | 'entity' | 'full' }} params
 */
export const fetchInvestors = async ({ view } = { view: null }) => {
  const res = await apiAxios.get('investors', {
    params: {
      view,
    },
  });
  return res.data;
};

export const fetchInvestorMers = async ({ view } = { view: null }) => {
  const res = await apiAxios.get('investor-mers', {
    params: {
      view,
    },
  });
  return res.data;
};

export const createInvestorMers = async (data) => {
  const res = await apiAxios.post('investor-mers', data);
  return res.data;
};

export const updateInvestorMers = async (id, data) => {
  const res = await apiAxios.patch(`investor-mers/${id}`, data);
  return res.data;
};

export const deleteInvestorMers = async (id) => {
  await apiAxios.delete(`investor-mers/${id}`);
  return id;
};

export const testMersLoan = async (loanNumber) => {
  const res = await apiAxios.get(`investor-mers/test/${loanNumber}`);
  return res.data;
};
