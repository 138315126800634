import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import Empty from 'src/components/baseComponents/Empty';
import {
  EncompassLoanAuditResultRuleMessage,
  LoanAuditMessageSeverity,
} from 'src/types';
import styles from './index.module.scss';

interface AuditRuleMessagesProps {
  messages: EncompassLoanAuditResultRuleMessage[];
  hideOk: boolean;
}
interface AuditRuleMessageProps {
  message: EncompassLoanAuditResultRuleMessage;
}

const severityRating = {
  Required: 0,
  Recommended: 1,
};

export const AuditRuleMessages: FC<AuditRuleMessagesProps> = ({
  messages,
  hideOk,
}) => {
  const sortedMessages = useMemo(() => {
    const result = messages
      .sort((a, b) => {
        return (
          (a.severity ? severityRating[a.severity] : 100) -
          (b.severity ? severityRating[b.severity] : 100)
        );
      })
      .filter((m) => {
        if (hideOk && !m.severity) return false;

        if (
          m.severity === LoanAuditMessageSeverity.Recommended &&
          m.description.includes(
            'The Disclosed Projected Payments Table contains',
          )
        ) {
          const firstLParen = m.description.indexOf('(');
          const firstRParen = m.description.indexOf(')', firstLParen);

          const nextLParen = m.description.indexOf('(', firstRParen);
          const nextRParen = m.description.indexOf(')', nextLParen);

          if (
            firstLParen !== -1 &&
            firstRParen !== -1 &&
            nextLParen !== -1 &&
            nextRParen !== -1
          ) {
            const left = +m.description.substring(firstLParen + 1, firstRParen);
            const right = +m.description.substring(nextLParen + 1, nextRParen);
            if (left === right) return !hideOk;
          }
        }
        return true;
      });
    return result;
  }, [hideOk, messages]);

  return sortedMessages.length > 0 ? (
    <ul className={styles.messagesList}>
      {sortedMessages.map((m, i) => (
        <AuditRuleMessage message={m} key={`${i}-${m.description}`} />
      ))}
    </ul>
  ) : (
    <Empty text='There are no compliance results that require changes.' />
  );
};

const AuditRuleMessage: FC<AuditRuleMessageProps> = ({ message }) => {
  return (
    <li className={clsx(styles.message, 'flex-container')}>
      <div className={clsx(styles.descriptionContainer, 'flex')}>
        <span className={styles.description}>{message.description}</span>
      </div>
      <div>
        <Chip
          className={clsx(styles.severity, {
            [styles.ok]: !message.severity,
            [styles.recommended]:
              message.severity === LoanAuditMessageSeverity.Recommended,
            [styles.required]:
              message.severity === LoanAuditMessageSeverity.Required,
          })}
          label={message.severity ?? 'OK'}
          size='small'
        />
      </div>
    </li>
  );
};
