export enum AddressUnitDesignatorType {
  Apartment = 'Apartment',
  Basement = 'Basement',
  Building = 'Building',
  Condo = 'Condo',
  Department = 'Department',
  Floor = 'Floor',
  Front = 'Front',
  Hangar = 'Hangar',
  Key = 'Key',
  Lobby = 'Lobby',
  Lot = 'Lot',
  Lower = 'Lower',
  Office = 'Office',
  Penthouse = 'Penthouse',
  Pier = 'Pier',
  Rear = 'Rear',
  Room = 'Room',
  Side = 'Side',
  Space = 'Space',
  Stop = 'Stop',
  Suite = 'Suite',
  Trailer = 'Trailer',
  Unit = 'Unit',
  Upper = 'Upper',
}

export enum GseTitleMannerHeldDescription {
  CommunityProperty = 'Community property',
  JointTenants = 'Joint tenants',
  SingleMan = 'Single man',
  SingleWoman = 'Single woman',
  MarriedMan = 'Married man',
  MarriedWoman = 'Married woman',
  TenantsInCommon = 'Tenants in common',
  TenancyByEntirety = 'Tenancy by entirety',
  ToBeDecidedInEscrow = 'To be decided in escrow',
  UnmarriedMan = 'Unmarried man',
  UnmarriedWoman = 'Unmarried woman',
  SpousesMarriedToEachOther = 'Spouses Married to Each Other',
  Other = 'Other',
}

export enum PropertyRightsType {
  FeeSimple = 'FeeSimple',
  Leasehold = 'Leasehold',
}

export enum LoanPurposeType {
  ConstructionToPermanent = 'ConstructionToPermanent',
  NoCashOutRefinance = 'NoCash-Out Refinance',
  Purchase = 'Purchase',
  ConstructionOnly = 'ConstructionOnly',
  CashOutRefinance = 'Cash-Out Refinance',
  Other = 'Other',
}

export interface EncompassProperty {
  streetAddress?: string; // 11
  streetAddress2?: string; // 3893
  city?: string; // 12
  county?: string;
  state?: string; // 14
  propertyUsageType?: string;
  addressUnitDesignatorType?: AddressUnitDesignatorType;
  financedNumberOfUnits?: number;
  postalCode?: string; // 15
  loanPurposeType?: LoanPurposeType; // 19
  constructionImprovementCostsAmount?: number; // 23
  gseTitleMannerHeldDescription?: GseTitleMannerHeldDescription;
  propertyRightsType?: PropertyRightsType;
  propertyLeaseholdExpirationDate?: string;
  propertyMixedUsageIndicator?: boolean;
  addressLineText?: string;
}
