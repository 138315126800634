import { apiAxios } from 'src/util';

export const orderLOE = async (data) => {
  const res = await apiAxios.post('/services/loe', data);
  return res;
};

export const downloadLOE = async (loanGuid, docid) => {
  const res = await apiAxios.get(`/services/loe/${docid}/download/${loanGuid}`);
  return res.data;
};
