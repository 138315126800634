import { isAfter, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LoanPipelineTableConfig } from 'src/types/LoanPipelineConfig';
import {
  CinchLoanOfficerAddress,
  EncompassLoanAssociate,
  EncompassLoanOfficer,
  RootStore,
} from '../types';

const emptyArray = [];
const emptyObject = {};

export const useLoanOfficers = (): EncompassLoanOfficer[] => {
  return useSelector<RootStore, EncompassLoanOfficer[]>(
    (store) => store.loanOfficers,
  );
};

export const useLoanOfficer = (id: number): EncompassLoanOfficer | null => {
  const loanOfficers = useLoanOfficers();
  if (!id) {
    return null;
  } else {
    return loanOfficers.find((x) => x.id.toString() === id.toString()) ?? null;
  }
};

export const useCurrentLoanOfficer = () => {
  return useSelector<RootStore, EncompassLoanOfficer>(
    (store) => store.loanOfficer,
  );
};

export const useCurrentLoanOfficerSettings = () => {
  const current = useCurrentLoanOfficer();
  return current?.metadata ?? emptyObject;
};

export const useLoanAssociates = (
  loanGuid: string,
): EncompassLoanAssociate[] => {
  const associates = useSelector<
    RootStore,
    Record<string, EncompassLoanAssociate[]>
  >((store) => store.loanAssociates);
  if (!loanGuid) return emptyArray;
  return associates[loanGuid] ?? emptyArray;
};

export const useLoanOfficerAssociateForLoan = (loanGuid) => {
  const loanAssociates = useLoanAssociates(loanGuid);
  return useMemo(
    () => loanAssociates.find((x) => x.roleName === 'Loan Officer'),
    [loanAssociates],
  );
};

export const useLoanOfficerForLoan = (loanGuid) => {
  const loanOfficers = useLoanOfficers();
  const loanOfficerAssociateForLoan = useLoanOfficerAssociateForLoan(loanGuid);
  return useMemo(
    () =>
      loanOfficers.find(
        (x) => x.id === loanOfficerAssociateForLoan?.loanOfficerId,
      ),
    [loanOfficerAssociateForLoan, loanOfficers],
  );
};

export const useLoanOfficerForLoanValidLicenses = (loanGuid: string) => {
  const loanOfficerForLoan = useLoanOfficerForLoan(loanGuid);
  return useMemo(
    () =>
      (loanOfficerForLoan?.licenses ?? []).filter(
        (lic) =>
          lic.enabled !== false &&
          (lic.expirationDate
            ? isAfter(parseISO(lic.expirationDate), new Date())
            : true),
      ),
    [loanOfficerForLoan?.licenses],
  );
};

export const useCurrentLoanOfficerValidLicenses = () => {
  const loanOfficer = useCurrentLoanOfficer();
  return useMemo(
    () =>
      (loanOfficer?.licenses ?? []).filter(
        (lic) =>
          lic.enabled !== false &&
          (lic.expirationDate
            ? isAfter(parseISO(lic.expirationDate), new Date())
            : true),
      ),
    [loanOfficer?.licenses],
  );
};

export const useIsCurrentLoanOfficerAssignedThisLoan = (loanGuid: string) => {
  const currentLoanOfficer = useCurrentLoanOfficer();
  const loanOfficer = useLoanOfficerForLoan(loanGuid);
  return currentLoanOfficer.id === loanOfficer?.id ? true : false;
};

export const useAddresses = (): CinchLoanOfficerAddress[] => {
  return useSelector<RootStore, CinchLoanOfficerAddress[]>(
    (store) => store.addresses,
  );
};

/**
 * @param {number} id
 */
export const useAddress = (id) => {
  const addresses = useAddresses();
  if (!id) return null;
  else return addresses.find((x) => x.id.toString() === id.toString());
};

export const useUserPipelineTableConfig = (
  tableId: string,
): LoanPipelineTableConfig | null => {
  const user = useCurrentLoanOfficer();
  return useMemo(() => {
    return user.pipelineConfigs?.find((x) => x.tableId === tableId) ?? null;
  }, [tableId, user.pipelineConfigs]);
};

export const useUserPipelineTableConfigFieldIds = (
  tableId: string,
): string[] | null => {
  const config = useUserPipelineTableConfig(tableId);
  return useMemo(() => {
    return config?.columns?.map((c) => c.fieldId) ?? null;
  }, [config?.columns]);
};
