const defaultState = [];

const reportingDashboardTemplatesReducer = (
  state = defaultState,
  { type, data },
) => {
  switch (type) {
    case 'set_reporting_dashboard_templates': {
      return data;
    }
    case 'add_reporting_dashboard_template': {
      return [...state, data];
    }
    case 'update_reporting_dashboard_template': {
      const newState = [...state];
      const matchIndex = newState.findIndex(
        (x) => x.id.toString() === data.id.toString(),
      );
      newState.splice(matchIndex, 1, {
        ...newState[matchIndex],
        ...data,
      });
      return newState;
    }
    case 'remove_reporting_dashboard_template': {
      const newState = [...state];
      const matchIndex = newState.findIndex(
        (x) => x.id.toString() === data.toString(),
      );
      newState.splice(matchIndex, 1);
      return newState;
    }
    default:
      return state;
  }
};

export default reportingDashboardTemplatesReducer;
