import { ListItemText, Menu, MenuItem, Tab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCinchSettingExternalLinks } from 'src/actions';
import { useAppSetting, useCurrentLoanOfficer } from 'src/hooks';
import { CINCHSETTING_EXTERNAL_LINKS } from 'src/util';
import HasRole from 'src/components/baseComponents/HasRole';
import { ROLE_NAMES } from 'src/util';
import styles from '../index.module.scss';

export const ExternalLinksTab = () => {
  const dispatch = useDispatch();
  const user = useCurrentLoanOfficer();
  const loanOfficerId = user?.id;
  const linksSetting = useAppSetting(CINCHSETTING_EXTERNAL_LINKS);
  const links = linksSetting?.value ?? [];
  const [menuRef, setMenuRef] = useState(null);

  useEffect(() => {
    if (loanOfficerId) {
      dispatch(getCinchSettingExternalLinks());
    }
  }, [dispatch, loanOfficerId]);

  if (!links.length || !user) {
    return null;
  }

  return (
    <HasRole roles={[ROLE_NAMES.FMM]}>
      <Tab
        className={styles.navButton}
        label='External'
        onClick={(e) => setMenuRef(e.currentTarget)}
      />
      <Menu
        anchorEl={menuRef}
        open={Boolean(menuRef)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        onClose={() => setMenuRef(null)}
        PaperProps={{
          className: styles.navMenu,
        }}
      >
        {links.map(({ name, url }) => (
          <MenuItem
            key={url}
            component='a'
            href={url}
            onClick={() => setMenuRef(null)}
            target='_blank'
            rel='noopener noreferrer'
          >
            <ListItemText>{name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </HasRole>
  );
};
