/* eslint-disable react-hooks/exhaustive-deps */
import { AppConfigurationProvider } from '@fmm/react-app-configuration';
import { LogLevel } from '@microsoft/signalr';
import Axios from 'axios';
import LogRocket from 'logrocket';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { setApplicationError } from 'src/actions';
import 'src/App.css';
import { CinchAppConfigProvider, useAuth0 } from 'src/applicationproviders';
import { AuthenticationGate } from 'src/components/baseComponents/AuthenticationGate';
import FullPageErrorBoundary from 'src/components/baseComponents/FullPageErrorBoundary';
import { Header } from 'src/components/baseComponents/Header';
import Loading from 'src/components/baseComponents/Loading';
import NotFound from 'src/components/baseComponents/NotFound';
import { SlowSuspense } from 'src/components/baseComponents/SlowSuspense';
import Modal from 'src/components/loanOfficer/Modals/Modal';
import LoLogin from 'src/components/login';
import MultifamilyPortal from 'src/components/MultifamilyPortal';
import env from 'src/env';
import history from 'src/history';
import { useCurrentLoanOfficer } from 'src/hooks';
import WebsocketProvider from 'src/hooks/signalr/WebsocketProvider';
import { useConfirmation } from 'src/hooks/use-confirmation';
import { ai } from 'src/TelemetryService';
import {
  apiAxios,
  CINCH_COC_AUTO_DISCLOSURES_FLAG,
  CINCH_COPY_LOAN_FLAG,
  CINCH_DU_LP_SERVICE_FLAG,
  CINCH_EXCLUDED_FIELDS,
  CINCH_INITIAL_DISCLOSURES_FLAG,
  CINCH_NQM_PRICER_FLAG,
  CINCH_NOI_FLAG,
  globalAxiosAbortController,
  CINCH_NEW_PREAPPROVAL,
  CINCH_CUSTOM_EMAIL_SIGNATURE,
  CINCH_SUMMARY_AND_TOOLS,
  CINCH_FRAUDGUARD,
  CINCH_ACCOUNTING_FLAG,
  CINCH_OPTIMALBLUE_SERVICE_FLAG,
  CINCH_STATEMENT_OF_DENIAL_FLAG,
  CINCH_SIGNNOW_SERVICE_FLAG,
  CINCH_CONSENT_SERVICE_FLAG,
  CINCH_USE_ECONSENT_FLAG,
  CINCH_FNF_RUN_ERNST,
  CINCH_CLOSING_DISCLOSURES_FLAG,
  CINCH_COPY_DOCUMENTS,
} from 'src/util';
import CommercialExceptionApproval from './components/loanOfficer/CommercialExceptionApproval';
import { useInputFocusOnNavigation } from './hooks/use-focusonnavigation';
import { Logout } from 'src/components/login/logout';
import EllieMaeStatusBanner from 'src/components/baseComponents/Header/EllieMaeStatusBanner';
import { Helmet } from 'react-helmet-async';

const LOBase = lazy(() => import('src/components/loanOfficer'));
const AdminBase = lazy(() => import('src/components/admin'));
const ComplianceBase = lazy(() => import('src/components/compliance'));
const ServerPicker = lazy(() =>
  import('src/components/development/ServerPicker'),
);
const EFolderPopOut = lazy(() =>
  import('src/components/loanOfficer/Loan/EFolder/PopOut'),
);

const featureFlags = [
  CINCH_COC_AUTO_DISCLOSURES_FLAG,
  CINCH_INITIAL_DISCLOSURES_FLAG,
  CINCH_COPY_LOAN_FLAG,
  CINCH_NQM_PRICER_FLAG,
  CINCH_EXCLUDED_FIELDS,
  CINCH_DU_LP_SERVICE_FLAG,
  CINCH_NOI_FLAG,
  CINCH_NEW_PREAPPROVAL,
  CINCH_CUSTOM_EMAIL_SIGNATURE,
  CINCH_SUMMARY_AND_TOOLS,
  CINCH_FRAUDGUARD,
  CINCH_ACCOUNTING_FLAG,
  CINCH_OPTIMALBLUE_SERVICE_FLAG,
  CINCH_STATEMENT_OF_DENIAL_FLAG,
  CINCH_SIGNNOW_SERVICE_FLAG,
  CINCH_CONSENT_SERVICE_FLAG,
  CINCH_USE_ECONSENT_FLAG,
  CINCH_FNF_RUN_ERNST,
  CINCH_CLOSING_DISCLOSURES_FLAG,
  CINCH_COPY_DOCUMENTS,
];

ai.initialize({ history: history });

// eslint-disable-next-line max-statements
const App = () => {
  const dispatch = useDispatch();
  const {
    apiServer,
    functionServer,
    httpError,
    httpErrorObj,
    activeEnvironment,
  } = useSelector((store) => store.app);
  const location = useLocation();
  const history = useHistory();
  const [modal, setModal] = useState(null);
  const confirm = useConfirmation();
  const loanOfficer = useCurrentLoanOfficer();
  const [axiosHooksReady, setAxiosHooksReady] = useState({
    api: false,
    errors: false,
  });

  const { activate } = useIdleTimer({
    onPrompt: () => {
      if (authenticated && !modal) {
        toggleModal('IdleTimeout');
      }
    },
    onActive: () => setModal(null),
    debounce: 250,
    promptBeforeIdle: 1000 * 60 * 1,
    timeout: 1000 * 60 * 45, // millseconds * seconds * minutes
    crossTab: true,
  });

  const {
    ready: auth0Ready,
    handleRedirect,
    authenticated,
    getToken,
  } = useAuth0();
  const axiosReady = useMemo(
    () =>
      auth0Ready &&
      Object.keys(axiosHooksReady).length &&
      Object.values(axiosHooksReady).every(Boolean),
    [auth0Ready, axiosHooksReady],
  );

  useInputFocusOnNavigation();

  useEffect(() => {
    (async () => {
      if (location.pathname?.includes('/callback')) {
        const result = await handleRedirect();
        if (result || location.search.includes('error')) {
          history.replace(
            location.search.includes('error')
              ? '/login'
              : result.appState?.targetUrl ?? '/',
          );
        }
      }
    })();
  }, [location.pathname, location.search, handleRedirect]);

  const handleAxiosError = (error) => {
    if (Axios.isCancel(error)) {
      return Promise.reject(error);
    }
    const resp = error.response;
    if (resp ? !resp.status.toString().startsWith('4') : true) {
      dispatch(
        setApplicationError(
          resp
            ? resp.data?.messages?.[0]
            : 'Something went wrong! Please check there are no outages. Also, try to log out and back in as well. Then please refresh and try again.',
          error,
        ),
      );
    }
    return Promise.reject(error);
  };

  useEffect(() => {
    apiAxios.interceptors.request.use((config) => {
      if (
        LogRocket.sessionURL &&
        !LogRocket.sessionURL.includes('LogRocket.init()')
      ) {
        config.headers['X-LogRocket-URL'] = LogRocket.sessionURL;
      }
      if (!config.signal) config.signal = globalAxiosAbortController.signal;
      return config;
    });

    Axios.interceptors.response.use(null, handleAxiosError);
    apiAxios.interceptors.response.use(null, handleAxiosError);
    setAxiosHooksReady((c) => ({ ...c, errors: true }));
    // this works for now, but avoid alert() after this
    window.alert = (str) => {
      return confirm({
        variant: 'alert',
        title: str.toString(),
      });
    };
  }, []);

  useEffect(() => {
    Axios.defaults.baseURL = functionServer;
    apiAxios.defaults.baseURL = apiServer;
    setAxiosHooksReady((c) => ({ ...c, api: true }));
  }, [functionServer, apiServer]);

  useEffect(() => {
    (async () => {
      if (httpError) {
        await confirm({
          variant: 'alert',
          description: (
            <div>
              <p>
                {httpError ||
                  'Something went wrong! Please refresh and try again.'}
              </p>
              {env.DEPLOY_ENV === 'development' && (
                <pre>{JSON.stringify(httpErrorObj, null, 2)}</pre>
              )}
            </div>
          ),
        });
        setApplicationError('', null);
      }
    })();
  }, [httpError]);

  const wsHubOptions = useMemo(
    () => ({
      logMessageContent: process.env.NODE_ENV === 'development',
      logger:
        process.env.NODE_ENV === 'development'
          ? LogLevel.Warning
          : LogLevel.Error,
      accessTokenFactory: getToken,
    }),
    [getToken],
  );

  const toggleModal = (modalName = null) => {
    setModal(modalName);
    if (!modalName) {
      activate();
    }
  };

  return (
    <FullPageErrorBoundary>
      <Helmet
        defaultTitle='Cinch by FM Home Loans'
        titleTemplate='%s - Cinch'
      />
      <Suspense fallback={<Loading />}>
        <AppConfigurationProvider
          env={activeEnvironment}
          options={{
            featureFlags,
          }}
          defaults={env}
          axios={Axios}
          isAxiosAuthenticated={authenticated}
          fallback={<Loading />}
          blocking={false}
        >
          <CinchAppConfigProvider ready={axiosReady}>
            {modal && (
              <Modal modal={modal} open={Boolean(modal)} toggle={toggleModal} />
            )}
            <Switch>
              <Route
                exact
                path='/multifamilyPortal'
                component={MultifamilyPortal}
              />
              <Route exact path='/login' component={LoLogin} />
              <Route exact path='/logout' component={Logout} />
              <Route
                path='/callback'
                render={() => <Loading label='Logging you in' />}
              />
              <Route path={['/pop-out']}>
                <AuthenticationGate>
                  <SlowSuspense fallback={<Loading label='' />}>
                    <Switch>
                      <Route
                        path={[
                          '/pop-out/:loanGuid/efolder/:documentGuid/attachments/:attachmentId?',
                          '/pop-out/:loanGuid/efolder/:documentGuid?',
                        ]}
                        component={EFolderPopOut}
                      />
                    </Switch>
                  </SlowSuspense>
                </AuthenticationGate>
              </Route>

              <Route path={['/admin', '/compliance', '/approval', '/']}>
                <AuthenticationGate>
                  <WebsocketProvider
                    hub={`${apiServer}ws/hub?loanOfficerId=${loanOfficer?.id}`}
                    options={wsHubOptions}
                    ready={Boolean(authenticated && loanOfficer?.id)}
                  >
                    <EllieMaeStatusBanner />
                    <Header />
                    <SlowSuspense fallback={<Loading label='' />}>
                      <Switch>
                        <Route path='/admin' component={AdminBase} />
                        <Route path='/compliance' component={ComplianceBase} />
                        <Route
                          path='/approval/:loanId/:userEmail'
                          component={CommercialExceptionApproval}
                        />
                        <Route path='/' component={LOBase} />
                      </Switch>
                    </SlowSuspense>
                  </WebsocketProvider>
                </AuthenticationGate>
              </Route>
              <Route component={NotFound} />
            </Switch>
          </CinchAppConfigProvider>
          {process.env.NODE_ENV === 'development' && (
            <Suspense fallback=''>
              <ServerPicker />
            </Suspense>
          )}
        </AppConfigurationProvider>
      </Suspense>
    </FullPageErrorBoundary>
  );
};

export default App;
