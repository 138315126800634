/* eslint-disable complexity, max-statements */
import { useEffect, useState } from 'react';
import Decimal from 'decimal.js';

export const useMultiFamilyLoanCalculationsIncome = (formMethods) => {
  const { watch, setValue } = formMethods;
  const totalIncome = watch('totalIncome');
  const totalExpenses = watch('totalExpenses');
  const purchasePrice = watch('loandata.purchasePriceEstimatedValue');
  const arv = watch('loandata.construction.arv');
  const monthlyPayment = watch('loandata.monthlyPayment');
  const loanAmount = watch('loandata.loanAmount');
  const isInterestOnly = watch('loandata.interestOnly');
  const isConstruction = watch('loandata.isConstruction');
  const interestRate = watch('loandata.rate');
  const netIncome = watch('calculated.netOperatingIncome');
  const amortizationTerm = watch('loandata.amortizationTerm');
  const totalProjectCosts = watch('calculated.totalProjectCosts');
  const stabilizedCapRate = watch('calculated.stabilizedCapRate');
  const stabilizedCapRateLocked = watch('calculated.stabilizedCapRateLocked');

  // Used to prevent circular updating. LoanAmount updates DSCR/debtYield, and DSCR/debtYield updates LoanLamount
  const [trackDebtCoverageRatio, setTrackDebtCoverageRatio] = useState();
  const [trackDebtYield, setTrackDebtYield] = useState();

  const parseNumber = (value) => {
    return Number.isFinite(parseFloat(value)) ? parseFloat(value) : 0;
  };

  // Used to prevent circular updating.
  const [trackStabilizedCapRate, setTrackStabilizedCapRate] = useState();

  useEffect(() => {
    if (
      totalIncome === '' ||
      totalIncome === null ||
      totalIncome === undefined
    ) {
      return;
    }

    const annualPayment = parseNumber(monthlyPayment) * 12;
    const netIncome = parseNumber(totalIncome) - parseNumber(totalExpenses);
    const debtCoverageRatio =
      parseNumber(netIncome) / parseNumber(annualPayment);
    const debtYield = parseNumber((netIncome / parseNumber(loanAmount)) * 100);

    if (isConstruction === 'Yes') {
      const capRate = new Decimal(netIncome)
        .div(parseNumber(totalProjectCosts))
        .times(100);
      setValue('calculated.capRate', capRate.toFixed(3));
      const stabilizedCapRate = new Decimal(netIncome)
        .div(parseNumber(arv))
        .times(100);
      setValue('calculated.stabilizedCapRate', stabilizedCapRate.toFixed(3));
      setTrackStabilizedCapRate(stabilizedCapRate.toFixed(3));
    } else {
      const capRate = new Decimal(netIncome)
        .div(parseNumber(purchasePrice))
        .times(100);
      setValue('calculated.capRate', capRate.toFixed(3));
      const capRateDebtIncluded = new Decimal(netIncome - annualPayment)
        .div(parseNumber(purchasePrice))
        .times(100);
      setValue(
        'calculated.capRateDebtIncluded',
        capRateDebtIncluded.toFixed(3),
      );
    }
    setValue('calculated.netOperatingIncome', netIncome.toFixed(0));
    setValue('calculated.debtCoverageRatio', debtCoverageRatio.toFixed(3));
    setTrackDebtCoverageRatio(debtCoverageRatio.toFixed(3));
    setValue('calculated.debtYield', debtYield.toFixed(3));
    setTrackDebtYield(debtYield.toFixed(3));
  }, [
    loanAmount,
    purchasePrice,
    totalProjectCosts,
    totalExpenses,
    totalIncome,
    arv,
    monthlyPayment,
    isConstruction,
    setValue,
  ]);

  const debtYield = watch('calculated.debtYield');
  const debtYieldLocked = watch('calculated.debtYieldLocked');
  useEffect(() => {
    if (
      debtYieldLocked === false &&
      Number.isFinite(parseFloat(trackDebtYield)) &&
      Number.isFinite(parseFloat(debtYield)) &&
      parseNumber(trackDebtYield) !== parseNumber(debtYield)
    ) {
      const newValue = (netIncome * 100) / parseFloat(debtYield);
      if (Number.isFinite(newValue)) {
        setValue('loandata.loanAmount', newValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtYield]);

  const debtCoverageRatio = watch('calculated.debtCoverageRatio');
  const debtCoverageRatioLocked = watch('calculated.debtCoverageRatioLocked');
  useEffect(() => {
    if (
      debtCoverageRatioLocked === false &&
      Number.isFinite(parseFloat(trackDebtCoverageRatio)) &&
      Number.isFinite(parseFloat(debtCoverageRatio)) &&
      parseNumber(trackDebtCoverageRatio) !== parseNumber(debtCoverageRatio)
    ) {
      let newValue = undefined;
      const newMonthlyPayment = parseNumber(netIncome / debtCoverageRatio / 12);
      if (isInterestOnly === 'true') {
        newValue = (newMonthlyPayment / (parseNumber(interestRate) / 12)) * 100;
      } else {
        newValue =
          (newMonthlyPayment *
            (1 -
              Math.pow(1 + interestRate / 100 / 12, amortizationTerm * -1))) /
          (interestRate / 100 / 12);
      }

      if (Number.isFinite(newValue)) {
        setValue('loandata.loanAmount', newValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtCoverageRatio]);

  useEffect(() => {
    if (
      stabilizedCapRateLocked === false &&
      Number.isFinite(parseFloat(trackStabilizedCapRate)) &&
      Number.isFinite(parseFloat(stabilizedCapRate)) &&
      parseFloat(trackStabilizedCapRate) !== parseFloat(stabilizedCapRate)
    ) {
      const newValue = new Decimal(parseNumber(netIncome))
        .div(stabilizedCapRate)
        .times(100);
      setValue('loandata.construction.arv', newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stabilizedCapRate]);
};
