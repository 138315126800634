import { deepFreeze } from 'src/util';

const initialState = Object.freeze({});

/**
 * @param {{ [loanGuid: string]: { documents: any[], attachments: any[] } }} state
 * @param {{ type: string, data: { loanGuid: string, attachments: any[], documents: any[], document: any, attachment: any } }} action
 */
const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case 'set_efolder_documents': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          documents: data.documents,
        },
      });
    }
    case 'add_efolder_document': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          documents: [...(state[data.loanGuid].documents ?? []), data.document],
        },
      });
    }
    case 'set_efolder_attachments': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          attachments: data.attachments,
        },
      });
    }
    case 'add_efolder_attachment': {
      const documents = [...state[data.loanGuid].documents];
      const matchIndex = documents.findIndex((x) => x.id === data.documentGuid);
      const docAttachments = [...(documents[matchIndex].attachments ?? [])];
      docAttachments.push({
        entityId: data.attachment.id,
        isActive: data.attachment.isActive,
        entityType: 'Attachment',
      });
      documents.splice(matchIndex, 1, {
        ...documents[matchIndex],
        attachments: docAttachments,
      });
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          attachments: [...state[data.loanGuid].attachments, data.attachment],
          documents,
        },
      });
    }
    case 'delete_efolder_attachment': {
      const attachments = [...state[data.loanGuid].attachments] ?? [];
      let matchIndex = attachments.findIndex((x) => x.id === data.attachmentId);
      attachments.splice(matchIndex, 1);
      return deepFreeze({
        [data.loanGuid]: {
          ...state[data.loanGuid],
          attachments: attachments,
        },
      });
    }
    case 'assign_efolder_attachment': {
      const documents = [...state[data.loanGuid].documents];
      const attachments = [...state[data.loanGuid].attachments] ?? [];
      let matchIndex = documents.findIndex((x) => x.id === data.documentGuid);
      const documentMatch = documents[matchIndex];
      const docAttachments = [...(documents[matchIndex].attachments ?? [])];
      docAttachments.push({
        entityId: data.attachment.id,
        isActive: data.attachment.isActive,
        entityType: 'Attachment',
      });
      documents.splice(matchIndex, 1, {
        ...documents[matchIndex],
        attachments: docAttachments,
      });

      matchIndex = attachments.findIndex((x) => x.id === data.attachment.id);
      const attachmentsCopy = [...attachments];
      attachmentsCopy.splice(matchIndex, 1, {
        ...attachments[matchIndex],
        assignedTo: {
          entityId: data.documentGuid,
          entityName: documentMatch.title,
          entityType: 'Document',
        },
      });
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          attachments: attachmentsCopy,
          documents,
        },
      });
    }
    case 'set_efolder_attachment_urls': {
      const attachments = [...(state[data.loanGuid]?.attachments ?? [])];
      const matchIndex = attachments.findIndex(
        (x) => x.id === data.attachmentId,
      );

      if (matchIndex > -1) {
        attachments.splice(matchIndex, 1, {
          ...attachments[matchIndex],
          urls: data.urls,
        });
      }

      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          attachments: attachments,
        },
      });
    }
    default:
      return state;
  }
};

export default reducer;
