export enum LoanAuditMessageSeverity {
  Required = 'Required',
  Recommended = 'Recommended',
  Fatal = 'Fatal',
  Warning = 'Warning',
}

export interface EncompassLoanAuditResultRuleMessage {
  description: string;
  fields?: string[];
  severity?: LoanAuditMessageSeverity;
}
