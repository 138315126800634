import React from 'react';
import PropTypes from 'prop-types';
import { DecimalInput } from '@fmm/react-encompass-inputs';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';
import { Controller } from 'react-hook-form';

export const ConnectedCurrencyInput = React.forwardRef(
  ({ name, rules, required, decimalLimit, id, ...props }, ref) => {
    const { id: contextId } = useAutosavingFormContext();

    return (
      <Controller
        name={name}
        rules={{
          required,
          ...rules,
        }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error },
        }) => (
          <DecimalInput
            id={id ?? contextId ? `${contextId}.${name}` : name}
            variant='outlined'
            size='small'
            fullWidth
            prefix='$'
            {...props}
            name={name}
            value={value ?? ''}
            onChange={(e) => {
              let value = e.target.value;
              if (e.target.value !== '') value = +value;
              onChange(value);
            }}
            onBlur={onBlur}
            ref={ref}
            error={invalid}
            helperText={error?.message}
            required={Boolean(required || rules?.required)}
            decimalLimit={decimalLimit}
          />
        )}
      />
    );
  },
);

export default ConnectedCurrencyInput;

ConnectedCurrencyInput.defaultProps = {
  decimalLimit: 2,
};

ConnectedCurrencyInput.displayName = 'ConnectedCurrencyInput';

ConnectedCurrencyInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  decimalLimit: PropTypes.number,
};
