import React, { Component } from 'react';
import './index.scss';

export default class ErrorBoundary extends Component {
  state = {
    error: null,
    info: '',
  };

  static getDerivedStateFromError (error) {
    return {
      error,
    };
  }

  componentDidCatch (error, errorInfo) {
    console.info(error, errorInfo);
    this.setState({
      info: errorInfo,
    });
  }

  render () {
    if (this.state.error) {
      return (
        <div className='error-boundary flex-container flex-center'>
          <h1>{this.state.error.message}</h1>
          <h2>Please send this to the developers.</h2>
          {process.env.NODE_ENV === 'development' && (
            <p>{this.state.info.componentStack.replace(/^\s*\n/, '')}</p>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
