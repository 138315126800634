import {
  ADD_CINCH_TASKS,
  SET_CINCH_TASKS_WORKING,
  GET_CINCH_TASKS_RELEASE,
  SET_CINCH_TASKS_RELEASE,
  SET_CINCH_TASKS_RELEASE_ERROR,
} from '../types';
import { apiAxios } from 'src/util';

export const getCinchTasksRelease = (loanGuid) => async (dispatch) => {
  dispatch({
    type: GET_CINCH_TASKS_RELEASE,
  });
  const res = await apiAxios.get(`loans/${loanGuid}/tasks/release`);
  if (res.data) {
    // set temp ids for selection purposes
    let i = 0;
    let dataWithIds = res.data.map((task) => {
      task.id = i++;
      return task;
    });
    dispatch({
      type: SET_CINCH_TASKS_RELEASE,
      payload: dataWithIds,
    });
    dispatch({
      type: SET_CINCH_TASKS_RELEASE_ERROR,
      payload: null,
    });
  } else {
    dispatch({
      type: SET_CINCH_TASKS_RELEASE,
      payload: [],
    });
    dispatch({
      type: SET_CINCH_TASKS_RELEASE_ERROR,
      payload: true,
    });
  }
};

export const createCinchTasksRelease = (loanGuid, data) => async (dispatch) => {
  dispatch({
    type: SET_CINCH_TASKS_WORKING,
    payload: true,
  });
  // reset temp ids to 0
  let dataWithResetIds = data.map((task) => {
    task.id = 0;
    return task;
  });
  const res = await apiAxios.post(
    `loans/${loanGuid}/tasks/release`,
    dataWithResetIds,
  );

  if (res.data) {
    dispatch({
      type: SET_CINCH_TASKS_RELEASE,
      payload: [],
    });
    dispatch({
      type: ADD_CINCH_TASKS,
      payload: res.data,
    });
    return res.data;
  } else {
    dispatch({
      type: SET_CINCH_TASKS_RELEASE_ERROR,
      payload: true,
    });
  }
};
