import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Auth0Client,
  RedirectLoginOptions,
  RedirectLoginResult,
  User,
} from '@auth0/auth0-spa-js';
import { useSelector } from 'react-redux';
import env from 'src/env';

const Auth0Context = createContext<{
  ready: boolean;
  authenticated: boolean;
  user: User | null;
  login: (options?: RedirectLoginOptions) => Promise<void>;
  handleRedirect: () => Promise<RedirectLoginResult | undefined>;
  getToken: (loginIfNeeded?: boolean) => Promise<string | null>;
  logout: () => Promise<void>;
    }>({
      ready: false,
      authenticated: false,
      user: null,
      login: () => new Promise((resolve) => resolve()),
      handleRedirect: () => new Promise((resolve) => resolve(undefined)),
      getToken: () => new Promise((resolve) => resolve(null)),
      logout: () => new Promise((resolve) => resolve()),
    });

export const Auth0Provider = ({ children }) => {
  const [auth0, setAuth0] = useState<Auth0Client | null>(null);
  const clientId: string =
    useSelector<{ app: { clientID: string } }>((store) => store.app.clientID) ??
    env.clientID;

  useEffect(() => {
    const auth0 = new Auth0Client({
      domain: env.AUTH_DOMAIN,
      client_id: clientId,
      redirect_uri: `${window.location.origin}/callback`,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
    });
    setAuth0(auth0);
  }, [clientId]);

  const tokenRef = useRef<string>();
  const [user, setUser] = useState<User | null>(null);

  const getUser = useCallback(async () => {
    return (await auth0?.getUser()) ?? null;
  }, [auth0]);

  const login = useCallback(
    async (options?: RedirectLoginOptions) => {
      await auth0?.loginWithRedirect(options);
    },
    [auth0],
  );

  const handleRedirect = useCallback(async () => {
    try {
      const result = await auth0?.handleRedirectCallback();
      setUser(await getUser());
      return result;
    } catch (e) {
      console.log(e);
    }
  }, [auth0, getUser]);

  const getToken = useCallback<
    (loginIfNeeded?: boolean) => Promise<string | null>
      >(
      async (loginIfNeeded = true) => {
        if (!auth0) {
          return null;
        }
        let token: string | null = '';
        try {
          const response = await auth0.getTokenSilently({
            detailedResponse: true,
          });
          token = response.id_token;
        } catch (e) {
          if (window.location.pathname !== '/login') {
            console.warn(e);
            if (
              loginIfNeeded &&
            (e.error === 'login_required' || e.error === 'consent_required')
            ) {
              const options = {
                appState: {
                  targetUrl: `${window.location.pathname}${window.location.search}`,
                },
              };
              login(options);
            }
          }
        } finally {
          if (token && tokenRef.current !== token) {
            tokenRef.current = token;
            setUser(await getUser());
          }
        }
        return token ?? null;
      },
      [auth0, getUser, login],
      );

  const logout = useCallback(async () => {
    setUser(null);
    tokenRef.current = undefined;
    await auth0?.logout({
      returnTo: `${window.location.origin}/login`,
    });
  }, [auth0]);

  const contextValue = useMemo(
    () => ({
      ready: Boolean(auth0),
      authenticated: Boolean(auth0 && tokenRef.current && user),
      user,
      login,
      handleRedirect,
      getToken,
      logout,
    }),
    [auth0, getToken, handleRedirect, login, logout, user],
  );

  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  );
};

export const useAuth0 = () => {
  return useContext(Auth0Context);
};

export const withAuth0 = (Component) => (props) => {
  const auth0 = useAuth0();
  return <Component {...props} auth0={auth0} />;
};
