import { EncompassLoan } from 'src/types';
import { CinchLoanType } from 'src/types/CinchLoanType';

export enum EntityView {
  Id = 'id',
  Entity = 'entity',
  Logs = 'logs',
  Full = 'full',
}

export enum CinchLoanTypeFull {
  Residential = 'Residential',
  Commercial = 'Commercial',
  FixAndFlip = 'FixAndFlip',
  MultiFamilyCommercial = 'MultiFamilyCommercial',
}

export const loanTypeToFullLoanTypeMap = {
  [CinchLoanType.Commercial]: CinchLoanTypeFull.Commercial,
  [CinchLoanType.Residential]: CinchLoanTypeFull.Residential,
  [CinchLoanType.FixAndFlip]: CinchLoanTypeFull.FixAndFlip,
  [CinchLoanType.MultiFamilyCommercial]:
    CinchLoanTypeFull.MultiFamilyCommercial,
};

export interface CreateLoanParameters {
  loan: EncompassLoan;
  loanType: CinchLoanTypeFull;
  isBrokered?: boolean;
  targetMilestone?: string;
}

export interface CreateLoanQueryExtended {
  loId: string;
  view: EntityView;
  loanFolder?: string;
  templatePath?: string;
}

export enum ResidentialProgramType {
  Agency = 'Agency',
  Jumbo = 'Jumbo',
  NonQm = 'NonQm',
  FHA = 'FHA',
  VA = 'VA',
  Broker = 'Broker',
  HomeStyle = 'Homestyle',
  Construction = 'Construction',
  JuniorLien = 'Junior Lien',
}

export enum NonQmType {
  Personal = 'Personal',
  Business = 'Business',
  TitleInLLC = 'TitleInLLC',
}

export enum RateType {
  Fixed = 'Fixed',
  ARM = 'AdjustableRate',
}
