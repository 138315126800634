import { deepFreeze } from 'src/util';

const initialState = Object.freeze({});

/**
 * @param {{ [loanGuid: string]: { loanGuid: string } }} state
 * @param {{ type: string, data: { loanGuid: string, associates: any[], } }} action
 */
const reduce = function (state = initialState, { type, data }) {
  switch (type) {
    case 'set_loan_associates': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: data.associates,
      });
    }
    default:
      return state;
  }
};

export default reduce;
