import { CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@react-google-maps/api';
import clsx from 'clsx';
import { get as lodashGet } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { updateGeocodeFromZip } from 'src/actions';
import {
  ConnectedPlainInput,
  ConnectedSelectInput,
  ConnectedZipcodeInput,
} from 'src/components/ConnectedInputs';
import ConnectedNumberInput from 'src/components/ConnectedInputs/ConnectedNumberInput';
import { useGoogleMaps } from 'src/applicationproviders';
import {
  LOAN_PRODUCT_GSE_PROPERTY_TYPE_OPTIONS,
  POSTALCODE_REGEX,
  UNIT_TYPE_OPTIONS,
} from 'src/util';
import countriesOptions from 'src/util/countries.json';
import states from 'src/util/states.json';
import styles from './index.module.scss';

const defaultLabels = {
  address: 'Street Address',
  address2: 'Address Line 2',
  city: 'City',
  state: 'State',
  county: 'County',
  country: 'Country',
  propertyType: 'Property Type',
  zip: 'Zip',
  unitIdentifier: 'Unit Number',
  numUnits: 'Number of Units',
  unitType: 'Unit Type',
};

/**
 * @typedef {'address' | 'address2' | 'city' | 'state' | 'zip' | 'county' | 'country' | 'unitIdentifier' | 'numUnits' | 'unitType' | 'propertyType'} AddressKey
 * @param {{
 *   keys: Record<AddressKey, string>,
 *   required: Record<AddressKey, string | boolean>,
 *   labels: Record<AddressKey, string>,
 *   rows: (AddressKey)[][]
 *   disabled?: boolean,
 *   unitTypeOptions?: { text: string, value: string }[],
 *   propertyTypeOptions?: { text: string, value: string }[],
 *   statesOptions: { text: string, value: string }[],
 *   fullWidth?: boolean,
 *   classes: { inputRow?: string, inputItem?: string }
 * }} props
 */
// eslint-disable-next-line complexity
export const ConnectedAddressInput = ({
  keys,
  required,
  labels,
  max,
  rows,
  disabled,
  unitTypeOptions,
  propertyTypeOptions,
  statesOptions,
  className,
  fullWidth,
  classes,
}) => {
  const { setValue, watch, setError, trigger } = useFormContext();
  const { dirtyFields } = useFormState();
  const { isLoaded } = useGoogleMaps();
  const searchRef = useRef();
  const zip = watch(keys.zip);
  const state = watch(keys.state);
  const county = watch(keys.county);
  const city = watch(keys.city);

  useEffect(() => {
    if (POSTALCODE_REGEX.test(zip) && lodashGet(dirtyFields, keys.zip)) {
      (async () => {
        const data = await updateGeocodeFromZip(zip);
        if (data) {
          if (city !== data.city && data.city !== null && keys.city) {
            setValue(keys.city, data.city, { shouldDirty: true });
          }
          if (state !== data.state && data.state !== null && keys.state) {
            setValue(keys.state, data.state, { shouldDirty: true });
          }
          if (county !== data.county && data.county !== null && keys.county) {
            setValue(keys.county, data.county, { shouldDirty: true });
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip, dirtyFields, setValue]);

  const handleSelect = async () => {
    if (searchRef.current) {
      const place = searchRef.current.getPlace();
      const zipCode = getAddressPart('postal_code', place);
      if (keys.zip && zipCode === '') {
        setError(keys.address, {
          type: 'manual',
          message:
            'Could not determine zipcode of selected address. Please include a street number in your search.',
        });
        return;
      }
      const fields = {
        [keys.address]: getAddressPart('address', place),
      };
      if (keys.city) {
        fields[keys.city] = getAddressPart('city', place);
      }
      if (keys.state) {
        fields[keys.state] = getAddressPart(
          'administrative_area_level_1',
          place,
          true,
        );
      }
      if (keys.zip) {
        fields[keys.zip] = zipCode;
      }
      if (keys.county) {
        fields[keys.county] = getAddressPart(
          'administrative_area_level_2',
          place,
        )
          ?.replace('County', '')
          .trim();
      }
      if (keys.country) {
        fields[keys.country] = getAddressPart('country', place, true);
      }
      for (const [field, value] of Object.entries(fields)) {
        setValue(field, value, { shouldDirty: true });
      }
      trigger(Object.keys(fields));
    }
  };
  /**
   * @type Record<AddressKey, any>
   */
  const inputs = {
    address: (
      <Autocomplete
        onLoad={(ref) => (searchRef.current = ref)}
        onPlaceChanged={() => handleSelect()}
        types={['address']}
      >
        <ConnectedPlainInput
          name={keys.address || 'address'}
          rules={{
            required: required.address,
          }}
          label={labels.address ?? defaultLabels.address}
          inputProps={{
            autoComplete: 'noautocomplete',
          }}
          autoComplete='noautocomplete'
          disabled={disabled}
          max={max?.address}
        />
      </Autocomplete>
    ),
    address2: (
      <ConnectedPlainInput
        name={keys.address2 || 'address2'}
        rules={{
          required: required.address2,
        }}
        label={labels.address2 ?? defaultLabels.address2}
        disabled={disabled}
        max={max?.address2}
      />
    ),
    city: (
      <ConnectedPlainInput
        name={keys.city || 'city'}
        rules={{
          required: required.city,
        }}
        label={labels.city ?? defaultLabels.city}
        disabled={disabled}
        max={max?.city}
      />
    ),
    state: (
      <ConnectedSelectInput
        name={keys.state || 'state'}
        rules={{
          pattern: {
            value: new RegExp(
              `^(${statesOptions.map((x) => x.value).join('|')})$`,
              'i',
            ),
            message:
              'The loan officer is not licensed to originate loans in this state or the license is expired',
          },
          required: required.state,
        }}
        label={labels.state ?? defaultLabels.state}
        disabled={disabled}
        options={statesOptions}
      />
    ),
    zip: (
      <ConnectedZipcodeInput
        name={keys.zip || 'zip'}
        rules={{
          required: required.zip,
          pattern: {
            value: /^\d{5}(-\d{4})?$/,
            message: 'Please enter a valid zipcode',
          },
        }}
        label={labels.zip ?? defaultLabels.zip}
        disabled={disabled}
      />
    ),
    county: (
      <ConnectedPlainInput
        name={keys.county || 'county'}
        rules={{
          required: required.county,
        }}
        label={labels.county ?? defaultLabels.county}
        disabled={disabled}
        max={max?.county}
      />
    ),
    country: (
      <ConnectedSelectInput
        name={keys.country || 'country'}
        rules={{
          required: required.country,
        }}
        label={labels.country ?? defaultLabels.country}
        disabled={disabled}
        options={countriesOptions}
      />
    ),
    unitIdentifier: (
      <ConnectedPlainInput
        name={keys.unitIdentifier || 'unitIdentifier'}
        rules={{
          required: required.unitIdentifier,
        }}
        label={labels.unitIdentifier ?? defaultLabels.unitIdentifier}
        disabled={disabled}
      />
    ),
    numUnits: (
      <ConnectedNumberInput
        name={keys.numUnits || 'numUnits'}
        rules={{
          required: required.numUnits,
        }}
        label={labels.numUnits ?? defaultLabels.numUnits}
        disabled={disabled}
        decimalLimit={0}
        min={0}
      />
    ),
    unitType: (
      <ConnectedSelectInput
        name={keys.unitType || 'unitType'}
        rules={{
          required: required.unitType,
        }}
        label={labels.unitType ?? defaultLabels.unitType}
        disabled={disabled}
        options={unitTypeOptions}
      />
    ),
    propertyType: (
      <ConnectedSelectInput
        name={keys.propertyType || 'propertyType'}
        rules={{
          required: required.propertyType,
        }}
        label={labels.propertyType ?? defaultLabels.propertyType}
        disabled={disabled}
        options={propertyTypeOptions}
      />
    ),
  };

  return (
    <div
      className={clsx(
        styles.addressInput,
        fullWidth && styles.fullWidth,
        className,
      )}
    >
      {isLoaded ? (
        <>
          {rows.map((row, i) => (
            <div className={clsx(styles.inputRow, classes.inputRow)} key={i}>
              {row.map((key, j) => (
                <div
                  className={clsx(styles.inputItem, classes.inputItem)}
                  key={j}
                >
                  {inputs[key]}
                </div>
              ))}
            </div>
          ))}
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

/**
 * @param {'address' | 'city' | 'administrative_area_level_1' | 'postal_code' | 'administrative_area_level_2' | 'country' | 'county'} part
 * @param {{ address_components: {
 *             long_name: string,
 *             short_name: string,
 *             types: string[]
 *           }[],
 *           name: string,
 *           vicinity: string
 *         }} place
 * @param {boolean?} short
 */
const getAddressPart = (part, place, short = false) => {
  const section = short ? 'short_name' : 'long_name';
  switch (part) {
    case 'address':
      return place.name || '';
    case 'city':
      return place.vicinity || '';
    case 'postal_code': {
      const postalCode = place.address_components?.find((x) =>
        x.types.includes('postal_code'),
      );
      const postalCodeSuffix = place.address_components?.find((x) =>
        x.types.includes('postal_code_suffix'),
      );
      return `${postalCode?.[section] || ''}${
        postalCodeSuffix?.[section] || ''
      }`;
    }
    default: {
      const matchingComponent = place.address_components?.find((x) =>
        x.types.includes(part),
      );
      return matchingComponent?.[section] || '';
    }
  }
};

ConnectedAddressInput.defaultProps = {
  required: {
    address: true,
  },
  max: {},
  labels: defaultLabels,
  rows: [
    ['address', 'city'],
    ['state', 'zip'],
  ],
  unitTypeOptions: UNIT_TYPE_OPTIONS,
  propertyTypeOptions: LOAN_PRODUCT_GSE_PROPERTY_TYPE_OPTIONS,
  statesOptions: states,
  classes: {},
};

ConnectedAddressInput.propTypes = {
  className: PropTypes.string,
  keys: PropTypes.shape({
    address: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    county: PropTypes.string,
    country: PropTypes.string,
    unitIdentifier: PropTypes.string,
    numUnits: PropTypes.string,
    unitType: PropTypes.string,
    propertyType: PropTypes.string,
  }),
  required: PropTypes.shape({
    address: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    address2: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    city: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    zip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    county: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    country: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    unitIdentifier: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    numUnits: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    unitType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    propertyType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  labels: PropTypes.shape({
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    county: PropTypes.string,
    country: PropTypes.string,
    unitIdentifier: PropTypes.string,
    numUnits: PropTypes.string,
    unitType: PropTypes.string,
    propertyType: PropTypes.string,
  }),
  max: PropTypes.shape({
    address: PropTypes.number,
    address2: PropTypes.number,
    city: PropTypes.number,
    county: PropTypes.number,
  }),
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOf([
        '', // empty slot
        'address',
        'address2',
        'city',
        'state',
        'zip',
        'county',
        'country',
        'unitIdentifier',
        'numUnits',
        'unitType',
        'propertyType',
      ]),
    ),
  ),
  disabled: PropTypes.bool,
  unitTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  statesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  fullWidth: PropTypes.bool,
  classes: PropTypes.shape({
    inputRow: PropTypes.string,
    inputItem: PropTypes.string,
  }),
  propertyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

export default ConnectedAddressInput;
