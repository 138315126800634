import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import env from 'src/env';
import LogRocket from 'logrocket';
import { deepCopy } from 'src/util';

const initialState = {};

const resetCustomFields = (obj = {}) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => !key.toLowerCase().includes('ssn')),
  );

const middleWare = [
  thunk,
  LogRocket.reduxMiddleware({
    stateSanitizer: function (state) {
      let { loanOfficer, loans } = deepCopy(state);

      for (const loanGuid in loans) {
        const loan = loans[loanGuid];
        let { applications, customFields } = loan;
        applications = applications?.map((app) => {
          return {
            ...app,
            borrower: {
              ...app.borrower,
              taxIdentificationIdentifier: app.borrower
                ?.taxIdentificationIdentifier
                ? 'removed'
                : undefined,
            },
            coBorrower: {
              ...app.coBorrower,
              taxIdentificationIdentifier: app.coBorrower
                ?.taxIdentificationIdentifier
                ? 'removed'
                : undefined,
            },
          };
        });

        customFields = resetCustomFields(customFields);

        loans[loanGuid] = {
          ...loan,
          applications,
          customFields,
        };
      }

      return {
        ...state,
        loanOfficer: {
          ...loanOfficer,
          encompassPassword: undefined,
          creditPassword: undefined,
        },
        loans,
      };
    },
  }),
];

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleWare),
    window.__REDUX_DEVTOOLS_EXTENSION__ && env.DEPLOY_ENV !== 'production'
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f,
  ),
);

export default store;
