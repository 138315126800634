export enum NQMLoanProgramType {
  PrimeA = 'Prime A',
  PrimeB = 'Prime B',
  Verus = 'Verus',
  SGCapital = 'SG Capital',
  Other = 'Other',
}

export interface EncompassCustomNQM {
  investor?: string;
  loanEstimateStatus: string;
  businessPurposeLoan: string;
  businessPurposeLLC: string;
  loanAmountApproved?: string;
  nqmLoanProgramType?: NQMLoanProgramType;
  nqmLoanProgramTypeOther: string;
  // v2: EncompassCustomNQMV2
}
