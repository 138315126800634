import { apiAxios } from 'src/util';

export const fetchTermSheetDraft = (loanGuid) => async (dispatch) => {
  const res = await apiAxios.get(`loans/${loanGuid}/termsheets/draft`);
  dispatch({
    type: 'set_termsheet_draft',
    data: {
      ...res.data,
      loanGuid,
    },
  });
  return res.data;
};

export const updateTermSheetDraft = (loanGuid, data) => async (dispatch) => {
  const res = await apiAxios.patch(`loans/${loanGuid}/termsheets/draft`, data);
  dispatch({
    type: 'update_termsheet_draft',
    data: {
      ...res.data,
      loanGuid,
    },
  });
};

export const requestManagerInput = (loanGuid) => async () => {
  await apiAxios.post(`loans/${loanGuid}/termsheets/requestmanagerinput`);
};

export const processManagerInput = (loanGuid) => async () => {
  await apiAxios.post(`loans/${loanGuid}/termsheets/processmanagerinput`);
};

export const saveManagerInput = (loanGuid) => async () => {
  await apiAxios.post(`loans/${loanGuid}/termsheets/save-manager-input`);
};

/**
 * @param {string} loanGuid
 * @param {'Approved' | 'Rejected'} status
 * @returns
 */
export const updateTermsheetDraftApprovalStatus =
  (loanGuid, status) => async (dispatch) => {
    if (status !== 'Approved' && status !== 'Rejected') {
      throw new Error('Invalid approval status');
    }
    await apiAxios.post(
      `loans/${loanGuid}/termsheets/draft/${status.toLowerCase()}`,
    );
    dispatch({
      type: 'update_termsheet_draft',
      data: {
        approvalStatus: status,
        loanGuid,
      },
    });
  };

export const generateTermSheetPreview = (loanGuid) => async () => {
  try {
    const res = await apiAxios({
      method: 'post',
      url: `loans/${loanGuid}/termsheets/termsheet-preview`,
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Termsheet_preview.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return res;
  } catch (err) {
    return err;
  }
};

export const previewTermsheet = async (data, loanGuid) => {
  try {
    const res = await apiAxios({
      method: 'post',
      url: `/loans/${loanGuid}/termsheets/termsheet-preview-multifamily`,
      data: data,
      responseType: 'arraybuffer',
    });
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'termsheet_preview.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      return 'Error Failed to download file';
    }
  } catch (error) {
    return 'Error occurred while downloading file: ' + error;
  }
};
export const termsheetStatus = async (loanGuid) => {
  const res = await apiAxios.get(
    `/loans/${loanGuid}/termsheets/termsheet-status`,
  );
  return res.data;
};
export const requestTermsheet = async (loanGuid) => {
  const res = await apiAxios.get(
    `/loans/${loanGuid}/termsheets/termsheet-request`,
  );
  return res.data;
};
export const requestTermsheetFNF = async (loanGuid) => {
  const res = await apiAxios.get(
    `/loans/${loanGuid}/termsheets/termsheet-request-fnf`,
  );
  return res.data;
};
export const submitTermsheet = async (loanGuid, data, saveOnly = false) => {
  const res = await apiAxios.post(
    `/loans/${loanGuid}/termsheets/termsheet-submit/${saveOnly}`,
    data,
  );
  return res.data;
};
export const rejectTermsheet = async (loanGuid) => {
  const res = await apiAxios.get(
    `/loans/${loanGuid}/termsheets/termsheet-reject`,
  );
  return res.data;
};
export const approveTermsheet = async (loanGuid) => {
  const res = await apiAxios.get(
    `/loans/${loanGuid}/termsheets/termsheet-approve`,
  );
  return res.data;
};
export const getTermsheetData = async (loanGuid) => {
  const res = await apiAxios.get(
    `/loans/${loanGuid}/termsheets/termsheet-data`,
  );
  return res.data;
};
