import { deepFreeze } from 'src/util';

const initialState = Object.freeze({});

/**
 * @param {{ [loanGuid: string]: { loanGuid: string } }} state
 * @param {{ type: string, data: { loanGuid: string } }} action
 */
const reduce = function (state = initialState, { type, data }) {
  switch (type) {
    case 'set_lock': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: data,
      });
    }
    case 'remove_lock': {
      const newState = {
        ...state,
      };
      delete newState[data.loanGuid];
      return deepFreeze(newState);
    }
    default:
      return state;
  }
};

export default reduce;
