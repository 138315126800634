import { BorrowerType } from 'src/types';

export enum GiftGrantAssetType {
  GiftOfCash = 'GiftOfCash',
  GiftOfPropertyEquity = 'GiftOfPropertyEquity',
  Grant = 'Grant',
}

export enum GiftGrantSourceType {
  CommunityNonprofit = 'CommunityNonprofit',
  Employer = 'Employer',
  FederalAgency = 'FederalAgency',
  Institutional = 'Institutional',
  Lender = 'Lender',
  LocalAgency = 'LocalAgency',
  Other = 'Other',
  NonParentRelative = 'NonParentRelative',
  Parent = 'Parent',
  Relative = 'Relative',
  ReligiousNonprofit = 'ReligiousNonprofit',
  StateAgency = 'StateAgency',
  UnrelatedFriend = 'UnrelatedFriend',
  UnmarriedPartner = 'UnmarriedPartner',
}

export interface EncompassGiftGrant {
  id: string;
  owner?: BorrowerType;
  title?: string;
  assetType?: GiftGrantAssetType;
  source?: GiftGrantSourceType;
  amount?: number;
  depositedIndicator?: boolean;
}
