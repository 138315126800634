import { apiAxios } from 'src/util';

export const getLoanAssociates = (loanGuid: string) => async (dispatch) => {
  const res = await apiAxios.get(`loans/${loanGuid}/associates`);
  dispatch({
    type: 'set_loan_associates',
    data: {
      loanGuid,
      associates: res.data,
    },
  });
};
