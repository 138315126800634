import { Avatar, Badge } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useWebsocket } from 'src/hooks/signalr/use-websocket';
import styles from './index.module.scss';

export const AvatarContainer = ({
  user,
  className,
  size = 80,
  hideWebsocketStatus = false,
}) => {
  const { status } = useWebsocket();
  return (
    <div className={clsx('flex-container', styles.avatarContainer, className)}>
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{
          badge: clsx(styles.socketStatus, styles[`socketStatus${status}`]),
        }}
        variant='dot'
        invisible={hideWebsocketStatus}
      >
        <Avatar
          aria-label='Profile picture'
          src={user?.picture}
          className={styles.avatar}
          style={{
            height: size,
            width: size,
          }}
        >
          {`${user?.firstName?.[0]}${user?.lastName?.[0]}`.toUpperCase().trim()}
        </Avatar>
      </Badge>
      <div
        className={clsx(
          'flex flex-container flex-vertical',
          styles.infoContainer,
        )}
      >
        <div className={styles.stack}>
          <span className={styles.name}>{user?.name}</span>
          <span className={styles.email}>{user?.email}</span>
        </div>
      </div>
    </div>
  );
};
