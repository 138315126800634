export enum VOEFulfillmentType {
  Instant = 'Instant',
  Manual = 'Manual',
}

export interface VOEServiceOrder {
  fulfillmentType: VOEFulfillmentType;
  loanGuid: string;
  applicationGuid: string;
  borrowerGuid: string;
  // Only required for manual fulfillment
  employmentGuid?: string;
  authDocAttachmentGuid?: string;
  instantOrderIdentifier?: number;
}
