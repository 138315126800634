import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';
import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import styles from './index.module.scss';
import clsx from 'clsx';

const emptyArray = [];

export const ConnectedAutocompleteInput = ({
  name,
  rules,
  required,
  id,
  pattern,
  itemPattern,
  multiple,
  freeSolo,
  autoComplete,
  autoSelect,
  options,
  label,
  InputProps,
  ...props
}) => {
  const { triggerChange, id: contextId } = useAutosavingFormContext();

  return (
    <Controller
      name={name}
      rules={{
        required,
        pattern,
        validate: {
          itemPattern: (value) => {
            if (Array.isArray(value) && itemPattern) {
              if (!value.every((item) => itemPattern.test(item))) {
                return 'One or more items are invalid';
              }
            }
            return null;
          },
        },
        ...rules,
      }}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => (
        <Autocomplete
          className={styles.autocompleteInput}
          id={id ?? contextId ? `${contextId}.${name}` : name}
          multiple={multiple}
          freeSolo={freeSolo}
          autoComplete={autoComplete}
          autoSelect={autoSelect}
          {...props}
          options={options ?? emptyArray}
          value={
            typeof value === 'string' && value.length && multiple
              ? value?.split(',')
              : value || (multiple ? emptyArray : null)
          }
          name={name}
          required={Boolean(required || rules?.required)}
          fullWidth
          onChange={(e, value) => {
            onChange(value);
            if (triggerChange) triggerChange();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onBlur={onBlur}
              label={label}
              variant='outlined'
              size='small'
              required={Boolean(required || rules?.required)}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
              }}
              error={invalid}
              helperText={error?.message}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                size='small'
                label={option}
                {...getTagProps({ index })}
                className={clsx(
                  itemPattern &&
                    !itemPattern.test(option) &&
                    styles.invalidChip,
                  getTagProps({ index }).className,
                )}
              />
            ))
          }
        />
      )}
    />
  );
};

ConnectedAutocompleteInput.displayName = 'ConnectedAutocompleteInput';

ConnectedAutocompleteInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  freeSolo: PropTypes.bool,
  autoComplete: PropTypes.bool,
};
