import { BorrowerType, EncompassEntityReference } from 'src/types';

export enum LiabilityType {
  ChildCare = 'ChildCare',
  ChildSupport = 'ChildSupport',
  CollectionsJudgementsAndLiens = 'CollectionsJudgementsAndLiens',
  HELOC = 'HELOC',
  Installment = 'Installment',
  LeasePayments = 'LeasePayments',
  MortgageLoan = 'MortgageLoan',
  Open30DayChargeAccount = 'Open30DayChargeAccount',
  OtherLiability = 'OtherLiability',
  Revolving = 'Revolving',
  SeparateMaintenanceExpense = 'SeparateMaintenanceExpense',
  OtherExpense = 'OtherExpense',
  Taxes = 'Taxes',
  TaxLien = 'TaxLien',
}
export interface EncompassVol {
  id: string;
  accountIdentifier: string;
  attention: string;
  date?: string;
  exclusionIndicator?: boolean;
  holderAddressCity: string;
  holderAddressPostalCode: string;
  holderAddressState: string;
  holderAddressStreetLine1: string;
  holderComments: string;
  holderEmail: string;
  holderFax: string;
  holderName: string;
  holderPhone: string;
  liabilityType?: LiabilityType;
  monthlyPaymentAmount?: number;
  monthsToExclude?: number;
  nameInAccount: string;
  noLinkToDocTrackIndicator?: boolean;
  owner?: BorrowerType;
  payoffStatusIndicator?: boolean;
  prepaymentPenaltyAmount?: number;
  printAttachmentIndicator?: boolean;
  remainingTermMonths?: number;

  reoProperty?: EncompassEntityReference;
  requestId: string;
  subjectLoanResubordinationIndicator?: boolean;
  title: string;
  titleFax: string;
  titlePhone: string;
  toBePaidOffAmount?: number;
  unpaidBalanceAmount?: number;
  descriptionOfPurpose: string;
  isDebtNotSecuredToSubjectPropertyIndicator?: boolean;
  // uCDPayoffType?: UCDPayoffType;
  payoffIncludedIndicator?: boolean;
  printUserNameIndicator?: boolean;
  printUserJobTitleIndicator?: boolean;
  lates12Month30Day?: number;
  lates24Month30Day?: number;
  lates25Month30Day?: number;

  lates12Month60Day?: number;
  lates24Month60Day?: number;
  lates25Month60Day?: number;

  lates12Month90Day?: number;
  lates24Month90Day?: number;
  lates25Month90Day?: number;

  lates12Month120Day?: number;
  lates24Month120Day?: number;
  lates25Month120Day?: number;

  lates12Month150Day?: number;
  lates24Month150Day?: number;
  lates25Month150Day?: number;

  subjectPropertyIndicator?: boolean;
  currentLienPosition: string;
  proposedLienPosition: string;
  bankLiabilityConsideredIndicator?: boolean;
  creditLimit?: number;
  includeTaxesAndInsuranceIndicator?: boolean;
  mortgageType: string;
  otherDescription: string;
  foreignAddressIndicator?: boolean;
  country: string;
  altId: string;
}
