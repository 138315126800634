import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { FormProvider } from 'react-hook-form';
import {
  debounce,
  deepCopy,
  INPUT_DEBOUNCE_DELAY,
  SUBMIT_MODE,
} from 'src/util';
import PropTypes from 'prop-types';
import { isObjectEmpty } from '../loanOfficer/helpers';

const AutosavingFormContext = createContext({});

/**
 * @param {{
 *   onSave: (data: any) => Promise<void>,
 *   defaultValues: {},
 *   ready: boolean,
 *   formMethods: import('react-hook-form').UseFormReturn,
 *   children: import('react').ReactNode,
 *   mode: 'onChange' | 'onIsValid',
 *   disabled?: boolean
 * }} props
 * @returns
 */
export const AutosavingFormProvider = ({
  onSave,
  onError,
  defaultValues,
  ready,
  formMethods,
  children,
  mode,
  disabled,
  saveDependencies,
  id,
}) => {
  const {
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
  } = formMethods;
  const formValues = watch();
  const initialized = useRef(false);
  const firstMount = useRef(true);
  const formValueString = JSON.stringify(formValues);
  const dirtyFieldsString = JSON.stringify(dirtyFields ?? {});
  const errorsString = JSON.stringify(errors ?? {});
  const errorsRef = useRef(errorsString);
  errorsRef.current = errorsString;

  useEffect(() => {
    if (ready && !initialized.current) {
      initialized.current = ready;
      reset(defaultValues || {});
    }
  }, [ready, defaultValues, reset]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveData = useCallback(onSave, saveDependencies);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const triggerChange = useCallback(
    debounce(({ data, dirtyFields }) => {
      if (!disabled) {
        const errors = JSON.parse(errorsRef.current);

        const dataCopy = deepCopy(data);
        if (mode === SUBMIT_MODE.onChange) {
          saveData(dataCopy, getOnlyDirtyValuesFromData(dataCopy, dirtyFields));
          reset(data, { keepErrors: true, keepValues: true });
        } else if (mode === SUBMIT_MODE.onIsPartialValid) {
          if (isObjectEmpty(errors)) {
            saveData(
              dataCopy,
              getOnlyDirtyValuesFromData(dataCopy, dirtyFields),
            );
          } else {
            const validData = getPartialValidData(dataCopy, errors);
            const dirtyValues = getOnlyDirtyValuesFromData(
              validData,
              dirtyFields,
            );
            if (!isObjectEmpty(dirtyValues)) {
              saveData(validData, dirtyValues);
            }
          }
          reset(data, { keepErrors: true, keepValues: true });
        } else {
          handleSubmit(
            (data) =>
              saveData(data, getOnlyDirtyValuesFromData(data, dirtyFields)),
            onError,
          )();
        }
      }
    }, INPUT_DEBOUNCE_DELAY),
    [mode, handleSubmit, saveData, onError, disabled],
  );

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
    const dirtyFields = JSON.parse(dirtyFieldsString);
    if (Object.keys(dirtyFields).length) {
      triggerChange({
        data: JSON.parse(formValueString),
        dirtyFields,
      });
    }
  }, [dirtyFieldsString, formValueString, triggerChange]);

  const providerValue = useMemo(
    () => ({
      triggerChange,
      id,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerChange, id],
  );

  return (
    <AutosavingFormContext.Provider value={providerValue}>
      <FormProvider {...formMethods}>{children}</FormProvider>
    </AutosavingFormContext.Provider>
  );
};

AutosavingFormProvider.defaultProps = {
  onSave: () => null,
  onError: () => null,
  mode: SUBMIT_MODE.onChange,
  saveDependencies: [],
};

AutosavingFormProvider.propTypes = {
  mode: PropTypes.oneOf([
    SUBMIT_MODE.onChange,
    SUBMIT_MODE.onIsValid,
    SUBMIT_MODE.onIsPartialValid,
  ]),
  disabled: PropTypes.bool,
};

export const useAutosavingFormContext = () => {
  return useContext(AutosavingFormContext);
};

const getPartialValidData = (data, errors) => {
  const result = {};
  for (const key in data) {
    const value = data[key];
    if (!errors[key] || value === '') {
      result[key] = value;
    } else if (errors[key]) {
      if (errors[key].type && errors[key].ref) {
        // this is an error, and the value is not ""
        // so it cannot be saved
      } else if (value && typeof value === 'object') {
        // this is a nested error
        result[key] = getPartialValidData(value, errors[key]);
      }
    }
  }
  return result;
};

const getOnlyDirtyValuesFromData = (data, dirtyFields) => {
  const res = {};
  for (const k in data) {
    if (dirtyFields[k] === true) {
      res[k] = data[k];
    } else if (dirtyFields[k] !== null && typeof dirtyFields[k] === 'object') {
      const innerDirty = getOnlyDirtyValuesFromData(data[k], dirtyFields[k]);
      if (innerDirty && Object.keys(innerDirty).length) {
        res[k] = innerDirty;
      }
    }
  }
  return res;
};
