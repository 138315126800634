import { HubConnectionState } from '@microsoft/signalr';
import { useCallback, useContext, useEffect, useState } from 'react';
import { WebsocketContext } from './WebsocketProvider';

/**
 * @typedef {Object} UseWebsocketReturn
 * @property {boolean} ready Whether the SignalR hub is ready to send and receive messages
 * @property {HubConnectionState} status The SignalR hub connection status
 * @property {(methodName: string, ...args: any[]) => Promise<void>} send Send a message to the hub and resolve after it is sent.
 * @property {(methodName: string, ...args: any[]) => Promise<any>} invoke Send a message to the hub and resolve after the hub sends a response.
 * @property {(methodName: string, handler: (...args: any[]) => void) => { unsubscribe: () => Promise<void> }} on Add a callback to the SignalR connection to run when an event is received
 */

/**
 * Hook into the WebsocketProvider to connect to the main event hub
 * @returns {UseWebsocketReturn}
 */
export const useWebsocket = () => {
  const { connection, status } = useContext(WebsocketContext);
  const [, setSubscriptions] = useState([]);

  useEffect(() => {
    setSubscriptions(subs => {
      for (const sub of subs) {
        console.log(
          `%c[websocket]%c Please call <code>x.unsubscribe()</code> after using <code>const x = on('${sub.name}', callback)</code>`,
          'color:pink',
          'color:auto',
        );
        connection.off(sub.name, sub.handler);
      }
      return [];
    });
  }, [connection]);

  const send = useCallback(
    /**
     * @param {string} methodName
     * @param {any[]} args
     * @returns {Promise<void>}
     */
    (methodName, ...args) => {
      if (!connection) throw new Error('Connection has not been created yet');
      if (args === undefined) {
        return connection.send(methodName);
      } else {
        return connection.send(methodName, ...args);
      }
    },
    [connection],
  );

  const invoke = useCallback(
    /**
     * @param {string} methodName
     * @param {any[]} args
     * @returns {Promise<any>}
     */
    (methodName, ...args) => {
      if (!connection) throw new Error('Connection has not been created yet');
      if (args === undefined) {
        return connection.invoke(methodName);
      } else {
        return connection.invoke(methodName, ...args);
      }
    },
    [connection],
  );

  const on = useCallback(
    /**
     * @param {string} methodName
     * @param {(...args: any[]) => void} handler
     * @returns {{ unsubscribe: () => Promise<void>}}
     */
    (methodName, handler) => {
      if (!connection) throw new Error('Connection has not been created yet');
      connection.on(methodName, handler);
      setSubscriptions(subs => [
        ...subs,
        {
          name: methodName,
          handler,
        },
      ]);
      return {
        unsubscribe: () => {
          setSubscriptions(subs =>
            subs.filter(x => !(x.name === methodName && x.handler === handler)),
          );
          connection.off(methodName, handler);
        },
      };
    },
    [connection],
  );

  return {
    ready: status === HubConnectionState.Connected,
    status,
    send,
    invoke,
    on,
  };
};
