import { apiAxios } from 'src/util';

export const updateItemization = async (loanGuid, attachmentId, data) => {
  const res = await apiAxios.patch(
    `/services/title-invoice/loans/${loanGuid}/attachments/${attachmentId}`,
    data,
  );
  return res.data;
};

export const getTitleInvoiceExtraction = async (
  loanGuid,
  attachmentId,
  skipCache,
  highResolution,
) => {
  let queryParam = '';
  if (skipCache) {
    queryParam = '?skipCache=true';
    if (highResolution) {
      queryParam += '&highResolution=true';
    }
  }
  const res = await apiAxios.get(
    `/services/title-invoice/extract/loans/${loanGuid}/attachments/${attachmentId}${queryParam}`,
  );
  return res.data;
};

export const getTitleCompanies = async () => {
  const res = await apiAxios.get('/services/title-invoice/companies');
  return res.data;
};

export const getTitleFees = () => async (dispatch) => {
  const res = await apiAxios.get('/services/title-invoice/fees');
  dispatch({
    type: 'set_title_fees',
    data: res.data,
  });
  return res.data;
};

export const addTitleFee = (data) => async (dispatch) => {
  const res = await apiAxios.post('/services/title-invoice/fees', data);
  dispatch({
    type: 'add_title_fee',
    data: res.data,
  });
  return res.data;
};

export const updateTitleFee = (id, data) => async (dispatch) => {
  const res = await apiAxios.patch(`services/title-invoice/fees/${id}`, data);
  dispatch({
    type: 'update_title_fee',
    data: res.data,
  });
  return res.data;
};

export const deleteTitleFee = (id) => async (dispatch) => {
  await apiAxios.delete(`services/title-invoice/fees/${id}`);
  dispatch({
    type: 'delete_title_fee',
    data: id,
  });
};

export const getMappingResults = async (
  titleCompanyName,
  extractedRows,
  propertyState,
) => {
  const res = await apiAxios.post('/services/title-invoice/map-results', {
    titleCompanyName,
    extractedRows,
    propertyState,
  });
  return res.data;
};

export const getAllTitleFeeMappings = async () => {
  const res = await apiAxios.get('/services/title-invoice/mapping');
  return res.data;
};

export const addTitleFeeMapping = async (data) => {
  const res = await apiAxios.post('/services/title-invoice/mapping', data);
  return res.data;
};

export const deleteTitleFeeMapping = async (id) => {
  const res = await apiAxios.delete(`/services/title-invoice/mapping/${id}`);
  return res.data;
};

export const updateTitleFeeMapping = async (id, data) => {
  const res = await apiAxios.patch(
    `/services/title-invoice/mapping/${id}`,
    data,
  );
  return res.data;
};
