import { apiAxios } from 'src/util';

export const orderTaxVerification = async (data) => {
  return await apiAxios.post('services/tax-verification', data);
};

export const getTaxVerificationOrders = async (loanGuid) => {
  const res = await apiAxios.get(`services/tax-verification/loans/${loanGuid}`);
  return res.data;
};

export const getHalcyonTaxIdentifiers = async (loanGuid) => {
  const res = await apiAxios.get(
    `services/tax-verification/halcyon/taxidentifiers/loans/${loanGuid}`,
  );
  return res.data;
};

export const processTaxVerificationOrder = async (orderId) => {
  const res = await apiAxios.post(`services/tax-verification/${orderId}`);
  return res.data;
};

export const downloadAuthForm = async (
  loanGuid,
  borrowerGuid,
  isBusinessForm,
  data,
) => {
  const res = await apiAxios({
    method: 'post',
    url: `services/tax-verification/auth-form/loans/${loanGuid}/borrowers/${borrowerGuid}?isBusinessForm=${isBusinessForm}`,
    data: data,
    responseType: 'arraybuffer',
  });
  const url = window.URL.createObjectURL(
    new Blob([res.data], { type: 'application/pdf' }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.click();
  link.remove();
};

export const requestSignatureAuthForm = async (
  loanGuid,
  borrowerGuid,
  isBusinessForm,
  data,
) => {
  await apiAxios({
    method: 'post',
    url: `services/tax-verification/auth-form/loans/${loanGuid}/borrowers/${borrowerGuid}/request-signature?isBusinessForm=${isBusinessForm}`,
    data: data,
  });
};

export const getRequestedBusinessAuthForms = async (loanGuid) => {
  const res = await apiAxios.get(
    `services/tax-verification/auth-form/loans/${loanGuid}`,
  );
  return res.data;
};
