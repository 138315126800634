import { addHours } from 'date-fns';
import { elliemaeStatusCenterAxios, apiAxios } from 'src/util';

const statuses = {
  'Operating Normally': 0,
  Informational: 1,
  'Performance Issues': 2,
  'Service Disruption': 3,
};

const xmlToDocument = (xmlString = '') => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(
    xmlString.replace(/&amp;/g, '&').replace(/&/g, '&amp;'),
    'application/xml',
  );
  return doc;
};

const parseHtmlEntities = (str = '') => {
  return str.replace(/&#([0-9]{1,3});/gi, (match, numStr) => {
    const num = parseInt(numStr, 10);
    return String.fromCharCode(num);
  });
};

const prettifyStatusDescription = (str = '') => {
  return str.replace(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}:\s/, '').trim();
};

const delay = (n = 100) => {
  return new Promise((resolve) => {
    setTimeout(resolve, n);
  });
};

export const fetchElliemaeServiceStatus = (fetchEvents) => async (dispatch) => {
  const res = await elliemaeStatusCenterAxios.get('services.rss');

  const doc = xmlToDocument(res.data);
  const items = [...doc.querySelectorAll('item')];

  const data = items.map((el) => {
    const title = el.querySelector('title')?.textContent || '';
    const titleParts = title.split(' - ');
    const description = prettifyStatusDescription(
      parseHtmlEntities(el.querySelector('description')?.textContent || ''),
    );
    const pubDate = new Date(el.querySelector('pubDate')?.textContent); // pdt timezone
    const statusWords = titleParts[1]?.trim();

    const descriptionWithoutAppendedEntries = description.split(
      /\+\+\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/,
    )[0]; // encompass puts extra entries under ++date

    return {
      name: titleParts[0]?.trim(),
      statusWords,
      status: statuses[statusWords] ?? 1, // sometimes statusWords will be something elliemae types in as a description, so default to informational
      description: descriptionWithoutAppendedEntries,
      updatedAt: addHours(pubDate, 3).toISOString(),
      events: [],
    };
  });

  if (fetchEvents) {
    for (const service of data) {
      if (service.status !== 0) {
        try {
          service.events = await getServiceEvents(service);
        } catch (e) {
          console.warn(
            `${e.response.status} - Could not get extra status info for ${service.name}: Current status is ${service.statusWords} (${service.status})`,
          );
        }
        await delay(300);
      }
    }
  }

  dispatch({
    type: 'update_service_statuses',
    data: data.reduce((a, item) => {
      const key = `elliemae_${item.name
        .toLowerCase()
        .replace(/[^\w\d]/gi, '')}`;
      const res = {
        ...a,
        [key]: item,
      };
      return res;
    }, {}),
  });
};

const getServiceEvents = async (service) => {
  const events = [];
  const res = await elliemaeStatusCenterAxios.get(
    `service.rss?sn=${encodeURIComponent(service.name)}`,
  );
  const doc = xmlToDocument(res.data);
  const items = [...doc.querySelectorAll('item')].slice(2); // remove title item and copy of item from all services feed
  for (const el of items) {
    const title = el.querySelector('title')?.textContent || '';
    const titleParts = title.split(' - ');
    const description = prettifyStatusDescription(
      parseHtmlEntities(el.querySelector('description')?.textContent || ''),
    );
    const pubDate = new Date(el.querySelector('pubDate')?.textContent); // pdt timezone
    const statusWords = titleParts[1]?.trim();

    events.push({
      name: statusWords,
      description,
      updatedAt: addHours(pubDate, 3).toISOString(),
    });
  }
  return events;
};

export const getLoanCheckListData = async (loanGuid) => {
  const res = await apiAxios.get(`/services/UWConditions/${loanGuid}`);
  return res.data;
};

export const runLoanCheckListData = async (loanGuid) => {
  const res = await apiAxios.post(`/services/UWConditions/${loanGuid}`);
  return res.data;
};

export const runRetryUWTaskCondition = async (loanGuid, id) => {
  const res = await apiAxios.post(
    `/services/UWConditions/${loanGuid}/retry/${id}`,
  );
  return res.data;
};

export const runCompleteUWTaskCondition = async (loanGuid, id) => {
  const res = await apiAxios.post(
    `/services/UWConditions/${loanGuid}/complete/${id}`,
  );
  return res.data;
};

export const runRemoveCompleteStatusUWTaskCondition = async (loanGuid, id) => {
  const res = await apiAxios.post(
    `/services/UWConditions/${loanGuid}/removecomplete/${id}`,
  );
  return res.data;
};

export const runIgnoreUWTaskCondition = async (loanGuid, id) => {
  const res = await apiAxios.post(
    `/services/UWConditions/${loanGuid}/ignore/${id}`,
  );
  return res.data;
};

export const runFinalizeUWTaskCondition = async (loanGuid, id) => {
  const res = await apiAxios.post(
    `/services/UWConditions/${loanGuid}/finalize/${id}`,
  );
  return res.data;
};
