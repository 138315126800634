import {
  EncompassApplyPlanCodeResponse,
  EncompassLoanAudit,
} from 'src/types/encompass';

export enum NotificationSeverity {
  None,
  Info,
  Success,
  Warning,
  Error,
}

export enum NotificationType {
  Default = 'Default',
  TermSheetDraft = 'TermSheetDraft',
  NewMessage = 'NewMessage',
  InitialDisclosureOrder = 'InitialDisclosureOrder',
  ChangeOfCircumstanceDisclosureOrder = 'ChangeOfCircumstanceDisclosureOrder',
}

export enum LongProcessStatus {
  Pending = 'Pending',
  Running = 'Running',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  TimedOut = 'TimedOut',
}

export interface LongProcessStep {
  id: number;
  title: string;
  error?: string;
  status: LongProcessStatus;
  longProcessId: number;
  parentStepId?: number;
  applicationIndex?: number;
  createdAt: string;
  updatedAt: string;
  steps?: LongProcessStep[];
  meta?: CinchLongProcessStepMetadata;
}

export type CinchLongProcessStepMetadata =
  | {
      type: 'MaventLoanAudit';
      data: EncompassLoanAudit;
    }
  | {
      type: 'PlancodeResponse';
      data: EncompassApplyPlanCodeResponse;
    };

export interface CinchNotification {
  id: number;
  loanOfficerId?: number;
  loanGuid?: string;
  title: string;
  description?: string;
  notificationType: NotificationType;
  severity: NotificationSeverity;
  longProcessStatus?: LongProcessStatus;
  dismissed: boolean;
  unseen: boolean;
  metadata?: object;
  createdAt: string;
  updatedAt: string;
  longProcessSteps?: LongProcessStep[];
}
