import { mdiAlert } from '@mdi/js';
import Icon from '@mdi/react';
import axios from 'axios';
import LogRocket from 'logrocket';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLoggedInUser } from 'src/actions';
import { useAuth0 } from 'src/applicationproviders';
import Empty from 'src/components/baseComponents/Empty';
import Loading from 'src/components/baseComponents/Loading';
import { useCurrentLoanOfficer } from 'src/hooks';
import { apiAxios } from 'src/util';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

export const AuthenticationGate = ({ children }) => {
  const dispatch = useDispatch();
  const { ready, authenticated, user, getToken } = useAuth0();
  const loanOfficer = useCurrentLoanOfficer();
  const [apisReady, setApisReady] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      if (ready && !authenticated) {
        try {
          await getToken(true);
        } catch (e) {
          setError(e);
        }
      }
    })();
  }, [authenticated, getToken, ready]);

  useEffect(() => {
    const setAuthToken = async (config) => {
      if (!authenticated) return config;

      const token = await getToken(false);
      config.headers['Authorization'] = `Bearer ${token}`;
      return config;
    };
    const apiInterceptorId = apiAxios.interceptors.request.use(setAuthToken);
    const apiInterceptorId2 = axios.interceptors.request.use(setAuthToken);
    setApisReady(true);

    return () => {
      apiAxios.interceptors.request.eject(apiInterceptorId);
      axios.interceptors.request.eject(apiInterceptorId2);
    };
  }, [getToken, authenticated]);

  useEffect(() => {
    (async () => {
      if (authenticated && apisReady) {
        try {
          dispatch(
            getLoggedInUser({
              licenses: true,
              personas: true,
            }),
          );
        } catch (e) {
          setError(e);
        }
      }
    })();
  }, [authenticated, dispatch, apisReady]);

  useEffect(() => {
    if (user?.sub) {
      LogRocket.identify(user.sub, {
        name: user.name,
        email: user.email,
      });
    }
  }, [user?.sub, authenticated, user?.name, user?.email]);

  const loadingMessage = useMemo(() => {
    if (!authenticated || !apisReady) return 'Authenticating...';
    if (!Object.keys(loanOfficer).length) return 'Loading user information...';
    return null;
  }, [apisReady, authenticated, loanOfficer]);

  if (error) {
    return (
      <Empty
        icon={<Icon path={mdiAlert} />}
        text={error.response?.data?.messages?.[0] ?? error.message}
      >
        <Typography variant='body1'>
          Please <Link to='/logout'>try logging in again.</Link>
        </Typography>
      </Empty>
    );
  }
  if (authenticated && apisReady && Object.keys(loanOfficer).length)
    return children;
  return <Loading label={loadingMessage} />;
};
