export const defaultState = [];

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_disclosure_report': {
      return data;
    }
    default:
      return state;
  }
};

export default reducer;
