export const defaultState = [];

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'admin_set_pipeline_queries': {
      const items = [...state];
      for (const item of data) {
        const matchIndex = state.findIndex(
          (x) => x.id.toString() === item.id.toString(),
        );
        if (matchIndex > -1) {
          items.splice(matchIndex, 1, {
            ...items[matchIndex],
            ...item,
          });
        } else {
          items.push(item);
        }
      }
      return items.sort((a, b) => a.firstName?.localeCompare(b.firstName) || 0);
    }
    case 'admin_update_pipeline_query': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.id.toString(),
      );
      const items = [...state];

      items.splice(matchIndex, 1, {
        ...items[matchIndex],
        ...data,
      });
      return items;
    }
    case 'admin_add_pipeline_query': {
      const items = [...state, data];
      return items;
    }
    case 'admin_remove_pipeline_query': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.toString(),
      );
      const items = [...state];
      items.splice(matchIndex, 1);
      return items;
    }
    case 'admin_deploy_pipeline_query': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.id.toString(),
      );
      const items = state.map((item) => {
        if (item.purpose === data.purpose) {
          item.isCurrent = false;
        }
        return item;
      });

      items.splice(matchIndex, 1, {
        ...items[matchIndex],
        ...data,
      });
      return items;
    }
    default:
      return state;
  }
};

export default reducer;
