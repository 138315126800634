import { apiAxios } from 'src/util';

export const createReportingSchedule = (schedule) => async (dispatch) => {
  const res = await apiAxios.post('/reporting/schedules', schedule);

  dispatch({
    type: 'add_reporting_schedule',
    data: res.data,
  });
};

export const fetchReportingSchedules = () => async (dispatch) => {
  const res = await apiAxios.get('/reporting/schedules');
  dispatch({
    type: 'set_reporting_schedules',
    data: res.data,
  });
};

export const updateReportingSchedule = (id, data) => async (dispatch) => {
  const res = await apiAxios.patch(`/reporting/schedules/${id}`, data);
  dispatch({
    type: 'update_reporting_schedule',
    data: res.data,
  });
};

export const deleteReportingSchedule = (id) => async (dispatch) => {
  await apiAxios.delete(`/reporting/schedules/${id}`);
  dispatch({
    type: 'remove_reporting_schedule',
    data: id,
  });
};

export const sendReportingScheduleEmail = async (id) => {
  await apiAxios.post(`/reporting/schedules/${id}/send`, null, {
    params: {
      single: true,
    },
  });
};
