import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const storage = {
  local: window.localStorage,
  session: window.sessionStorage,
};

/**
 * @param {"local" | "session"} type
 */
const buildDefaultState = (type) => {
  const values = {};
  const store = storage[type];
  for (const k of Object.keys(store)) {
    const value = store.getItem(k);
    try {
      values[k] = JSON.parse(value);
    } catch (e) {
      values[k] = value;
    }
  }
  return values;
};

const defaultProviderState = {
  local: buildDefaultState('local'),
  session: buildDefaultState('session'),
};

const BrowserStorageContext = createContext({});

export const BrowserStorageProvider = ({ children }) => {
  const [allValues, setAllValues] = useState(defaultProviderState);
  const setValue = useCallback((storageType, key, newValue) => {
    const store = storage[storageType];
    if (newValue === null || newValue === undefined) {
      store.removeItem(key);
      setAllValues((old) => ({
        ...old,
        [storageType]: {
          ...old[storageType],
          [key]: undefined,
        },
      }));
    } else {
      try {
        store.setItem(key, JSON.stringify(newValue));
      } catch (e) {
        store.setItem(key, newValue);
      }
      setAllValues((old) => ({
        ...old,
        [storageType]: {
          ...old[storageType],
          [key]: newValue,
        },
      }));
    }
  }, []);
  const value = useMemo(
    () => ({ values: allValues, setValues: setValue }),
    [allValues, setValue],
  );

  return (
    <BrowserStorageContext.Provider value={value}>
      {children}
    </BrowserStorageContext.Provider>
  );
};

/**
 * Hook to access localStorage or sessionStorage
 * @param {"local" | "session"} type
 * @param {string} storageKey
 */
const useStorage = (type, storageKey) => {
  const { values, setValues } = useContext(BrowserStorageContext);

  const value = useMemo(
    () => values[type][storageKey],
    [values, type, storageKey],
  );

  const setValue = useCallback(
    (newValue) => {
      setValues(type, storageKey, newValue);
    },
    [setValues, storageKey, type],
  );

  return useMemo(() => [value, setValue], [value, setValue]);
};

/**
 * Hook to access sessionStorage
 * @param {string} storageKey
 * @returns {[any, (newValue: any) => void]}
 */
export const useSessionStorage = (storageKey) => {
  return useStorage('session', storageKey);
};

/**
 * Hook to access localStorage
 * @param {string} storageKey
 * @returns {[any, (newValue: any) => void]}
 */
export const useLocalStorage = (storageKey) => {
  return useStorage('local', storageKey);
};
