import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Button, Paper, TextField } from '@material-ui/core';
import logoImage from 'src/images/logo/cinch-logo-small.svg';
import ButtonBlock from 'src/components/loanOfficer/Loan/Forms/MultiFamilyForms/ButtonBlock.js';
import clsx from 'clsx';
import { processBorrowerPortalRequest, borrowerFileUpload } from 'src/actions';
import { useLocation } from 'react-router-dom';
import { useConfirmation } from 'src/hooks';
import { SlowSuspense } from 'src/components/baseComponents/SlowSuspense';
import Loading from 'src/components/baseComponents/Loading';

const MultifamilyPortal = () => {
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [otherDescription, setOtherDescription] = useState('');
  const [code, setCode] = useState('');
  const [status, setStatus] = useState(false);
  const [userData, setUserData] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const link = searchParams.get('link');
  const confirm = useConfirmation();

  const allowedFileTypes = [
    '.xlsx',
    '.doc',
    '.txt',
    '.csv',
    '.pdf',
    '.png',
    '.jpg',
    '.gif',
    '.bmp',
    '.tif',
    '.tiff',
    '.svg',
    '.xls',
    '.xlxs',
  ];
  const handleFieldUpdate = (_, value) => {
    setSelectedType(value);
  };
  const allowFileUpload =
    selectedType !== '' &&
    (selectedType !== 'Other' || otherDescription.trim() !== '');

  const handleChange = (event) => {
    // Ensure only numbers are entered
    const value = event.target.value.replace(/\D/g, '');
    setCode(value);
  };
  const handleDescriptionChange = (event) => {
    setOtherDescription(event.target.value);
  };

  useEffect(() => {
    if (code < 10000) {
      return;
    }
    const fetchData = async () => {
      try {
        if (link) {
          setLoading(true);
          const response = await processBorrowerPortalRequest(link, code);
          if (response?.response?.data?.messages[0]) {
            await confirm({
              variant: 'alert',
              title: 'Error',
              description: response.response.data.messages[0],
            });
          } else if (response?.name !== '') {
            setUserData(response);
            setStatus(true);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [code, confirm, link]);

  const handleFileSelect = async (event) => {
    const uploadedFiles = Array.from(event.target.files);
    await handleFileUpload(uploadedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const uploadedFiles = Array.from(event.dataTransfer.files);
    await handleFileUpload(uploadedFiles);
  };

  const handleFileUpload = async (uploadedFiles) => {
    // Filter files by allowed types
    const validFiles = uploadedFiles.filter((file) => {
      const fileType = `.${file.name.split('.').pop()}`;
      return allowedFileTypes.includes(fileType.toLowerCase());
    });
    setLoading(true);
    const fileType = selectedType === 'Other' ? otherDescription : selectedType;
    const formData = new FormData();
    for (let i = 0; i < validFiles.length; i++) {
      formData.append('files', validFiles[i]);
    }
    const response = await borrowerFileUpload(link, code, fileType, formData);
    if (response?.response?.data?.messages[0]) {
      await confirm({
        variant: 'alert',
        title: 'Error',
        description: response.response.data.messages[0],
      });
    } else {
      const updatedData = userData;
      updatedData.files = response;
      setUserData(updatedData);
    }
    setLoading(false);
  };

  const removeAppendedString = (fileName) => {
    const regex = /\*\*\*.+?\*\*\*/;
    return fileName.replace(regex, '');
  };

  const handleExitPage = () => {
    window.close();
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <Paper className={styles.fullHeight}>
        <SlowSuspense fallback={<Loading />}>
          {loading && <Loading />}
          {!loading && (
            <>
              <div
                className={clsx(
                  styles.logoContainer,
                  styles.margin_left,
                  styles.flex_size_3,
                )}
              >
                <div className={styles.logoContainer}>
                  <img src={logoImage} alt='Logo' className={styles.logo} />
                  <div className={styles.logoLabel}>Fm Home Loans</div>
                </div>
                {status === true && (
                  <>
                    <div
                      className={clsx(
                        styles.logoContainer,
                        styles.file_column,
                        styles.flex_size_1,
                      )}
                    >
                      <h1>{userData.projectName}</h1>
                      <h3>Loan # {userData.loanNumber}</h3>
                      <h4>{userData.address}</h4>
                    </div>
                  </>
                )}
              </div>
              {status !== true && (
                <>
                  <h1 className={styles.codeDivMessage}>
                    Please enter your 5 digit passcode below
                  </h1>
                  <h4 className={styles.codeDivMessage}>
                    (Passcode should be in the email that you received.)
                  </h4>
                  <div className={styles.codeDiv}>
                    <TextField
                      label='Passcode'
                      variant='outlined'
                      value={code}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 5,
                        pattern: '\\d{5}', // Ensure only digits are allowed
                      }}
                    />
                  </div>
                </>
              )}
              {status === true && (
                <>
                  <section className={styles.content}>
                    <h1>
                      Welcome {userData?.name} / {userData?.email}
                    </h1>
                    <label>
                      Allowed file types '.xlsx', '.doc', '.txt', '.csv',
                      '.pdf', '.png', '.jpg', '.gif', '.bmp', '.tif', '.tiff',
                      '.svg', '.xls', '.xlxs'
                    </label>
                    <label>max file size 2mb</label>
                    <h2>Please select type of file to upload</h2>
                    <div className={styles.file_container}>
                      <div
                        className={clsx(styles.file_column, styles.flex_size_1)}
                      >
                        <div>
                          <ButtonBlock
                            name='Rent Roll'
                            label='Rent Roll'
                            selected={selectedType === 'Rent Roll'}
                            onClick={handleFieldUpdate}
                          />
                        </div>
                        <div>
                          <ButtonBlock
                            name='Expenses'
                            label='Expenses'
                            selected={selectedType === 'Expenses'}
                            onClick={handleFieldUpdate}
                          />
                        </div>
                        {(userData.documentType === 1 ||
                          userData.documentType === '1') && (
                          <>
                            <div>
                              <ButtonBlock
                                name='Constuction Budget'
                                label='Constuction Budget'
                                selected={selectedType === 'Constuction Budget'}
                                onClick={handleFieldUpdate}
                              />
                            </div>
                          </>
                        )}
                        <div>
                          <ButtonBlock
                            name='Financial Statements'
                            label='Financial Statements'
                            selected={selectedType === 'Financial Statements'}
                            onClick={handleFieldUpdate}
                          />
                        </div>
                        {userData.loanType === 'Purchase' && (
                          <div>
                            <ButtonBlock
                              name='Sales Contract'
                              label='Sales Contract'
                              selected={selectedType === 'Sales Contract'}
                              onClick={handleFieldUpdate}
                            />
                          </div>
                        )}
                        <div>
                          <ButtonBlock
                            name='Operating Agreement'
                            label='Operating Agreement'
                            selected={selectedType === 'Operating Agreement'}
                            onClick={handleFieldUpdate}
                          />
                        </div>
                        <div>
                          <ButtonBlock
                            name='Organization Chart'
                            label='Organization Chart'
                            selected={selectedType === 'Organization Chart'}
                            onClick={handleFieldUpdate}
                          />
                        </div>
                        <div>
                          <ButtonBlock
                            name='Other'
                            label='Other'
                            selected={selectedType === 'Other'}
                            onClick={handleFieldUpdate}
                          />
                        </div>
                      </div>
                      <div
                        className={clsx(styles.file_column, styles.flex_size_3)}
                      >
                        <Button
                          variant='contained'
                          color='primary'
                          component='label' // Use 'label' component to trigger file input
                          hidden={!allowFileUpload}
                        >
                          Select Files to Upload
                          <input
                            type='file'
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                            multiple
                          />
                        </Button>
                        <div
                          className={styles.drag_drop_area}
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          hidden={!allowFileUpload}
                        >
                          Drag and drop files here
                        </div>
                      </div>
                    </div>
                    <div className={styles.logoContainer}>
                      <TextField
                        label='Other File Description *'
                        variant='outlined'
                        className={styles.width100}
                        value={otherDescription}
                        hidden={selectedType !== 'Other'}
                        onChange={handleDescriptionChange}
                      />
                    </div>
                  </section>
                  <section>
                    <h1>Uploaded Files</h1>
                    <div className={styles.file_container}>
                      {userData?.files && userData?.files.length > 0 ? (
                        userData.files
                          .sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt),
                          )
                          .map((file, index) => (
                            <div key={index} className={styles.file_item}>
                              <div className={styles.file_info}>
                                <p>
                                  <strong>Name:</strong>{' '}
                                  {removeAppendedString(file.name)}
                                </p>
                                <p>
                                  <strong>Size:</strong>{' '}
                                  {(file.contentLength / 1024).toFixed(2)} KB
                                </p>
                                <p>
                                  <strong>Created Date:</strong>{' '}
                                  {new Date(file.createdAt).toLocaleString()}
                                </p>
                              </div>
                            </div>
                          ))
                      ) : (
                        <p>No files uploaded</p>
                      )}
                    </div>
                  </section>
                  <Button
                    variant='contained'
                    color='primary'
                    component='label'
                    onClick={handleExitPage}
                  >
                    DONE
                  </Button>
                </>
              )}
            </>
          )}
        </SlowSuspense>
      </Paper>
    </div>
  );
};

export default MultifamilyPortal;
