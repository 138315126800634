import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';

export const ConnectedYNCheckboxInput = React.forwardRef(
  ({ name, rules, required, label, options, id, ...props }, ref) => {
    const { id: contextId } = useAutosavingFormContext();

    return (
      <Controller
        name={name}
        rules={{
          required,
          ...rules,
        }}
        render={({ field: { value, onChange, onBlur } }) => {
          const checked = value === options.y;
          return (
            <FormControlLabel
              id={id ?? contextId ? `${contextId}.${name}` : name}
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    const value =
                      e.target.checked === true ? options.y : options.n;
                    onChange(value);
                  }}
                  {...props}
                  name={name}
                  onBlur={onBlur}
                  ref={ref}
                  required={Boolean(required)}
                />
              }
              label={label}
              disabled={props.disabled}
            />
          );
        }}
      />
    );
  },
);

ConnectedYNCheckboxInput.defaultProps = {
  options: {
    y: 'Y',
    n: 'N',
  },
};

ConnectedYNCheckboxInput.displayName = 'ConnectedYNCheckboxInput';

ConnectedYNCheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  options: PropTypes.shape({
    y: PropTypes.string.isRequired,
    n: PropTypes.string.isRequired,
  }),
};
