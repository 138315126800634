import {
  GET_CINCH_TASKS_RELEASE,
  SET_CINCH_TASKS_RELEASE,
  SET_CINCH_TASKS_RELEASE_ERROR,
} from 'src/actions/types';

const defaultState = {
  data: [],
  isWorking: false,
  error: null,
};

const reduce = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_CINCH_TASKS_RELEASE: {
      return {
        ...state,
        isWorking: true,
        data: [],
      };
    }
    case SET_CINCH_TASKS_RELEASE: {
      return {
        ...state,
        isWorking: false,
        data: payload || [],
      };
    }
    case SET_CINCH_TASKS_RELEASE_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export default reduce;
