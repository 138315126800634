import { SET_LOAN_FOLDERS, REQUEST_LOAN_FOLDERS } from './types';
import { apiAxios } from 'src/util';

export const getLoanFolders = () => async (dispatch) => {
  dispatch({
    type: REQUEST_LOAN_FOLDERS,
  });
  try {
    const res = await apiAxios.get('loanFolders');
    if (res.data) {
      dispatch({
        type: SET_LOAN_FOLDERS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: SET_LOAN_FOLDERS,
        payload: null,
      });
    }
    return res;
  } catch (error) {
    dispatch({
      type: SET_LOAN_FOLDERS,
      payload: null,
    });
    console.error(error);
    return error;
  }
};
