export const defaultState = [];

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_addresses': {
      const addresses = [...state];
      for (const address of data) {
        const matchIndex = state.findIndex(
          x => x.id.toString() === address.id.toString(),
        );
        if (matchIndex > -1) {
          addresses.splice(matchIndex, 1, {
            ...addresses[matchIndex],
            ...address,
          });
        } else {
          addresses.push(address);
        }
      }
      return addresses.sort(
        (a, b) => a.addressLine1?.localeCompare(b.addressLine1) || 0,
      );
    }
    case 'update_address': {
      const matchIndex = state.findIndex(
        x => x.id.toString() === data.id.toString(),
      );
      const addresses = [...state];

      addresses.splice(matchIndex, 1, {
        ...addresses[matchIndex],
        ...data,
      });
      return addresses;
    }
    case 'add_address': {
      const addresses = [...state, data];
      return addresses;
    }
    case 'remove_address': {
      const matchIndex = state.findIndex(
        x => x.id.toString() === data.toString(),
      );
      const addresses = [...state];
      addresses.splice(matchIndex, 1);
      return addresses;
    }
    default:
      return state;
  }
};

export default reducer;
