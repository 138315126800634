import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDepartments } from 'src/hooks/use-departments';
import { useCurrentLoanOfficer } from 'src/hooks/use-loan-officer';
import {
  ReportingDashboard,
  ReportingDashboardTemplateFilter,
  ReportingDashboardWidget,
  ReportingDashboardWidgetTemplate,
} from 'src/types/Reporting';

export const useReportingDashboards = (): ReportingDashboard[] => {
  const dashboards = useSelector(
    // @ts-ignore
    (store) => store.reportingDashboards,
  ) as ReportingDashboard[];
  return dashboards;
};

export const useReportingDashboard = (
  dashboardId: number | string,
): ReportingDashboard | null => {
  const dashboards = useReportingDashboards();
  if (!dashboardId) return null;
  return (
    dashboards.find((d) => d.id.toString() === dashboardId.toString()) ?? null
  );
};

export const useReportingDashboardWidget = (
  dashboardId: number | string,
  widgetId: number | string,
): ReportingDashboardWidget | null => {
  const dashboard = useReportingDashboard(dashboardId);
  if (!widgetId || !dashboard) return null;
  return (
    dashboard.widgets?.find((w) => w.id.toString() === widgetId?.toString()) ??
    null
  );
};
type UseReportingDashboardTemplates = {
  (): ReportingDashboardWidgetTemplate[];
  (options: { group: boolean }): {
    id: number;
    type: string;
    name: string;
    templates: ReportingDashboardWidgetTemplate[];
  }[];
};
// @ts-ignore
export const useReportingDashboardTemplates: UseReportingDashboardTemplates =
  (options?: { group: boolean }) => {
    const templates = useSelector(
      (store) =>
        // @ts-ignore
        store.reportingDashboardTemplates,
    );
    const departments = useDepartments();
    const loanOfficer = useCurrentLoanOfficer();
    const loanOfficerId = loanOfficer?.id;

    return useMemo(() => {
      if (options?.group) {
        const departmentIds = [
          ...new Set(
            templates
              .map((f) => f.visibleTo)
              .flat()
              .map((entity) => entity.id),
          ),
        ].filter((id) => departments.some((dep) => dep.id === id));

        const res = departmentIds
          .map((depId) => {
            return {
              id: depId,
              type: 'department',
              name:
                departments.find((dep) => dep.id === depId)?.name ??
                `Dept. ${depId}`,
              templates: templates
                .filter((t) => t.visibleTo.some((ref) => ref.id === depId))
                .sort((a, b) => sortTemplatesByOwner(a, b, loanOfficerId)),
            };
          })
          .filter((group) => group.templates.length);

        const templatesWithoutDepartments = templates.filter(
          (f) => f.visibleTo.length === 0,
        );
        if (templatesWithoutDepartments.length) {
          res.unshift({
            id: 0,
            type: 'department',
            name: 'All departments',
            templates: templatesWithoutDepartments.sort((a, b) =>
              sortTemplatesByOwner(a, b, loanOfficerId),
            ),
          });
        }
        return res;
      } else {
        return templates;
      }
    }, [options?.group, templates, departments, loanOfficerId]);
  };

const sortTemplatesByOwner = (
  a: ReportingDashboardWidgetTemplate,
  b: ReportingDashboardWidgetTemplate,
  loanOfficerId: number,
) => {
  return (
    (b.ownerId === loanOfficerId ? 1 : 0) -
    (a.ownerId === loanOfficerId ? 1 : 0)
  );
};

export const useReportingDashboardTemplate = (
  templateId: number | string,
): ReportingDashboardWidgetTemplate | null => {
  const templates = useReportingDashboardTemplates();
  if (!templateId) return null;
  return (
    templates?.find((t) => t.id.toString() === templateId.toString()) ?? null
  );
};

type UseReportingDashboardTemplateFilters = {
  (): ReportingDashboardTemplateFilter[];
  (options: { group: boolean }): {
    id: number;
    type: string;
    name: string;
    filters: ReportingDashboardTemplateFilter[];
  }[];
};
// @ts-ignore
export const useReportingDashboardTemplateFilters: UseReportingDashboardTemplateFilters =
  (options?: { group: boolean }) => {
    const filters = useSelector(
      (store) =>
        // @ts-ignore
        store.reportingDashboardTemplateFilters,
    ) as ReportingDashboardTemplateFilter[];
    const departments = useDepartments();

    return useMemo(() => {
      if (options?.group) {
        const departmentIds = [
          ...new Set(
            filters
              .map((f) => f.visibleTo)
              .flat()
              .map((entity) => entity.id),
          ),
        ].filter((id) => departments.some((dep) => dep.id === id));

        const res = departmentIds
          .map((depId) => {
            return {
              id: depId,
              type: 'department',
              name:
                departments.find((dep) => dep.id === depId)?.name ??
                `Dept. ${depId}`,
              filters: filters.filter((f) =>
                f.visibleTo.some((ref) => ref.id === depId),
              ),
            };
          })
          .filter((group) => group.filters.length);
        const filtersWithoutDepartments = filters.filter(
          (f) => f.visibleTo.length === 0,
        );
        if (filtersWithoutDepartments.length) {
          res.unshift({
            id: 0,
            type: 'department',
            name: 'All departments',
            filters: filtersWithoutDepartments,
          });
        }
        return res;
      } else {
        return filters;
      }
    }, [departments, filters, options?.group]);
  };

export const useReportingDashboardTemplateFilter = (
  filterId,
): ReportingDashboardTemplateFilter | null => {
  const filters = useReportingDashboardTemplateFilters();
  if (!filterId) return null;
  return filters?.find((t) => t.id.toString() === filterId.toString()) ?? null;
};

export const useReportingDashboardTemplateFilterData = (
  filterId,
):
  | {
      results: { loanGuid: string; fieldIds: Record<string, object> }[];
      prevResults: { loanGuid: string; fieldIds: Record<string, object> }[];
    }
  | { error: string }
  | null => {
  const [prevResultsCache, setPrevResultsCache] = useState(null);
  const [prevPrevResultsCache, setPrevPrevResultsCache] = useState(null);
  const data = useSelector(
    (store) =>
      // @ts-ignore
      store.reportingDashboardTemplateFilterData,
  );

  if (!filterId) return null;
  const item = data[filterId];
  if (item?.error) return item;
  if (item?.results) {
    let resultsToReturn = item.results;
    let prevResultsToReturn = item.prevResults ?? null;

    if (JSON.stringify(prevResultsCache) === JSON.stringify(resultsToReturn))
      resultsToReturn = prevResultsCache;
    else setPrevResultsCache(resultsToReturn);
    if (
      JSON.stringify(prevPrevResultsCache) ===
      JSON.stringify(prevResultsToReturn)
    )
      prevResultsToReturn = prevPrevResultsCache;
    else setPrevPrevResultsCache(prevResultsToReturn);

    return {
      results: resultsToReturn,
      prevResults: prevResultsToReturn,
    };
  }
  return null;
};

export const useIsReportingDashboardFilterLoading = (filterId): boolean => {
  const data = useSelector(
    (store) =>
      // @ts-ignore
      store.reportingDashboardTemplateFilterData,
  );
  if (!filterId) return false;
  return data[filterId]?.loading ?? false;
};

export const useRegisteredDashboardFilters = () => {
  const prevRegisteredFiltersRef = useRef<(string | boolean)[][]>([]);
  const registered = useSelector(
    (store) =>
      // @ts-ignore
      store.reportingDashboardActiveFilters,
  );

  const result = Object.keys(registered)
    .filter((key) => Boolean(registered[key].count))
    .sort((a, b) => +a - +b)
    .map((id) => [id, Boolean(registered[id].needsPrevious)]);

  if (
    JSON.stringify(result) === JSON.stringify(prevRegisteredFiltersRef.current)
  ) {
    return prevRegisteredFiltersRef.current;
  }
  prevRegisteredFiltersRef.current = result;
  return result;
};

export const useReportingSchedules = () => {
  const schedules = useSelector(
    (store) =>
      // @ts-ignore
      store.reportingSchedules,
  );
  return schedules;
};

export const useReportingSchedule = (scheduleId: number | string) => {
  const schedules = useReportingSchedules();
  if (!scheduleId) return null;
  return (
    schedules.find((s) => s.id.toString() === scheduleId.toString()) ?? null
  );
};
