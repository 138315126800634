import { Identifiable } from 'src/types';

const defaultArrayReducer = <T>(arrayLeft: T[], item: T): T[] => {
  return [...arrayLeft, item];
};

const getKeyValue = (key: string | string[], item: object): string => {
  if (Array.isArray(key)) {
    return key.map((k) => item[k]).join('|');
  } else {
    return item[key];
  }
};

const hasKeyValue = (key: string | string[], item: object): boolean => {
  if (Array.isArray(key)) {
    return key.reduce((a, k) => a && {}.hasOwnProperty.call(item, k), true);
  } else {
    return {}.hasOwnProperty.call(item, key);
  }
};

export const byIdArrayReducer = <T extends Identifiable>(
  arrayLeft: T[],
  item: T,
): T[] => {
  const possibleIdKeys = [
    'id',
    'itemNumber',
    'fieldName',
    'feeType',
    ['gfe2010FeeIndex', 'gfe2010FeeParentType', 'gfe2010FeeType'],
  ];
  let matchIndex = -1;
  for (const key of possibleIdKeys) {
    if (hasKeyValue(key, item)) {
      matchIndex = arrayLeft.findIndex(
        (x) => getKeyValue(key, x) === getKeyValue(key, item),
      );
      if (matchIndex > -1) break;
    }
  }
  if (matchIndex > -1) {
    const copy = [...arrayLeft];
    const itemAt = arrayLeft[matchIndex];
    copy.splice(
      matchIndex,
      1,
      mergeDeepWith(itemAt, item, mergeArray, byIdArrayReducer),
    );
    return copy;
  } else {
    return [...arrayLeft, item];
  }
};

export const mergeArray = <T>(
  arrayLeft: T[],
  arrayRight: T[],
  reducer: (arrayLeft: T[], item: T) => T[] = defaultArrayReducer,
): T[] => {
  return arrayRight.reduce(reducer, arrayLeft);
};

export const mergeDeep = <T>(target: T, source: T): T => {
  return mergeDeepWith(target, source, mergeArray, byIdArrayReducer);
};

export const mergeDeepWith = <T>(
  target: T,
  source: T,
  arrayMergeHandler: <TArrayValue>(
    arrayLeft: TArrayValue[],
    arrayRight: TArrayValue[],
    reducer?: (arrayLeft: TArrayValue[], item: TArrayValue) => TArrayValue[],
  ) => TArrayValue[] = mergeArray,
  arrayReducer?: <TArrayValue extends Identifiable>(
    arrayLeft: TArrayValue[],
    item: TArrayValue,
  ) => TArrayValue[],
): T => {
  if (typeof target === 'object') {
    // eslint-disable-next-line no-unused-vars
    const result: { [key in keyof T]?: unknown } = {};

    for (const key in target) {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (sourceValue !== undefined) {
        if (sourceValue === null) {
          // if the source value is null, replace it
          result[key] = null;
        } else {
          if (Array.isArray(sourceValue) && Array.isArray(targetValue)) {
            // if source is an array, merge it
            result[key] = arrayMergeHandler<typeof sourceValue[number]>(
              targetValue,
              sourceValue,
              arrayReducer,
            );
          } else if (
            typeof sourceValue === 'object' &&
            typeof targetValue === 'object'
          ) {
            // if source is an object, merge it
            result[key] = mergeDeepWith(
              targetValue,
              sourceValue,
              arrayMergeHandler,
              arrayReducer,
            );
          } else {
            // otherwise, replace target with source
            result[key] = sourceValue;
          }
        }
      } else {
        // if source value doesnt exist, use target
        result[key] = targetValue;
      }
    }
    for (const key in source) {
      const targetValue = result[key];
      const sourceValue = source[key];
      if (targetValue === undefined) {
        result[key] = sourceValue;
      }
    }

    return result as T;
  } else {
    throw new Error(`unsupported type: ${typeof target}`);
  }
};

// eslint-disable-next-line max-statements
export const deleteDeepById = <T>(target: T, location: T): T => {
  if (Array.isArray(target) && Array.isArray(location)) {
    if (location.length !== 1) {
      throw new Error('location must contain only 1 element');
    } else {
      const matcher = location[0];
      const locationKeys = Object.keys(matcher);
      if (!locationKeys.some((x) => x === 'id')) {
        throw new Error("location item is missing 'id'");
      }
      const matchIndex = target.findIndex((x) => x.id === matcher.id);
      if (matchIndex === -1) {
        throw new Error(`no match found for ${matcher.id}`);
      }
      const copy = [...target] as T & unknown[];
      if (locationKeys.length > 1) {
        // is not last, continue
        copy.splice(matchIndex, 1, deleteDeepById(copy[matchIndex], matcher));
      } else {
        // is last, remove
        copy.splice(matchIndex, 1);
      }
      return copy;
    }
  } else if (typeof target === 'object' && typeof location === 'object') {
    const locationKeys = Object.keys(location).filter((x) => x !== 'id');
    if (locationKeys.length !== 1) {
      throw new Error('location must contain only 1 key besides id');
    } else {
      const key = locationKeys[0];
      if (target[key] === undefined) {
        throw new Error(`target does not have key: '${key}'`);
      }
      return {
        ...target,
        [key]: deleteDeepById(target[key], location[key]),
      };
    }
  } else {
    throw new Error(`unsupported type: ${typeof target}`);
  }
};
