import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { ConnectedSelectInput } from 'src/components/ConnectedInputs';
import { MONTHS, MONTHS_SHORT } from 'src/util';
import styles from '../index.module.scss';

export const Months = ({ disabled }) => {
  const months = useWatch({
    name: 'months',
  });
  const tooltip = useMemo(() => getDisplayValue(months), [months]);

  return (
    <Tooltip title={tooltip} arrow placement='top'>
      <ConnectedSelectInput
        multiple
        displayEmpty
        name='months'
        options={MONTHS}
        disabled={disabled}
        fullWidth={false}
        className={clsx(styles.input, styles.monthsInput)}
        renderValue={getDisplayValue}
      />
    </Tooltip>
  );
};
const getDisplayValue = (values) =>
  values.length
    ? values
      .sort((a, b) => a - b)
      .map((value) => MONTHS_SHORT[value - 1]?.text)
      .join(', ')
    : 'Every month';
