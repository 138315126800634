import { Card, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { approveCommercialException } from 'src/actions/commercialExceptionRequests';
import Loading from '../baseComponents/Loading';
import './index.scss';

const CommercialExceptionApproval = () => {
  const [verifying, setVerifying] = useState(true);
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState('');
  const { loanId, userEmail } = useParams();
  const history = useHistory();

  useEffect(() => {
    const handleApproval = async () => {
      const res = await approveCommercialException({ loanId, userEmail });
      setMessage(res?.message);
      setVerifying(false);
      setVerified(true);
      setTimeout(() => {
        history.replace('/dashboard');
      }, 3000);
    };

    if (loanId) handleApproval();
  }, [loanId, userEmail, history]);

  return (
    <div className='commercial-exception-approval flex flex-container flex-center'>
      <Container maxWidth='md'>
        <Card className='flex-container container' elevation={4}>
          <div className='flex flex-container flex-center'>
            <div className='flex flex-container flex-center'>
              <h1 class='title'>Commercial Exception Approval</h1>
              {verifying && (
                <>
                  <Loading label='Approving...' />
                </>
              )}
              {verified && (
                <Alert variant='filled' severity='success'>
                  {message}
                </Alert>
              )}
              {!verified && !verifying && (
                <Alert variant='filled' severity='error'>
                  {message}
                </Alert>
              )}
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default CommercialExceptionApproval;
