/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { Card, Button, CardContent } from '@material-ui/core';
import './index.scss';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from 'src/applicationproviders';
import { Logo } from 'src/components/baseComponents/Header/Logo';

const LoLogin = () => {
  const { login, authenticated } = useAuth0();
  if (authenticated) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <div className='signup-signin flex-container flex-center'>
      <div className='grid-red' />
      <div className='grid-grey' />
      <Card className='card'>
        <CardContent>
          <Logo />
        </CardContent>
        <CardContent>
          <Button variant='contained' color='primary' onClick={login} fullWidth>
            Loan officer login
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoLogin;
