import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  Suspense,
  useMemo,
  useCallback,
} from 'react';
// https://dev.to/icyjoseph/react-slow-suspense-4p02

const SlowSuspenseContext = createContext({});

export const SlowSuspense = ({ children, fallback = null }) => {
  const [show, setShow] = useState(false);
  const remove = useCallback(() => setTimeout(() => setShow(false), 500), []);
  const activate = useCallback(() => setTimeout(() => setShow(true), 250), []);

  const value = useMemo(() => ({ activate, remove }), [activate, remove]);

  return (
    <SlowSuspenseContext.Provider value={value}>
      {show && fallback}
      <Suspense fallback={<InvokeFallback />}>{!show && children}</Suspense>
    </SlowSuspenseContext.Provider>
  );
};

const InvokeFallback = () => {
  const { activate, remove } = useContext(SlowSuspenseContext);

  useEffect(() => {
    let timer = activate();
    return () => {
      clearTimeout(timer);
      remove();
    };
  }, [activate, remove]);

  return null;
};
