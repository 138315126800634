export enum OwnershipInterestType {
  LessThan25Percent = 'LessThan25Percent',
  GreaterThanOrEqualTo25Percent = 'GreaterThanOrEqualTo25Percent',
}

export interface EncompassEmployment {
  id: string;
  selfEmployedIndicator?: boolean;
  specialEmployerRelationshipIndicator?: boolean;
  currentEmploymentIndicator?: boolean;
  ownershipInterestType?: OwnershipInterestType;
  businessOwnedPercent?: number;
  employerComments?: string;
  employerName?: string;
  startDate?: string;
  endDate?: string;
  title?: string;
  positionDescription?: string;
  timeInLineOfWorkYears?: string;
  phoneNumber?: string;
  addressStreetLine1?: string;
  addressCity?: string;
  addressState?: string;
  addressPostalCode?: string;
  otherAmount?: number;
  basePayAmount?: number;
  overtimeAmount?: number;
  commissionsAmount?: number;
  bonusAmount?: number;
  militaryEntitlement?: number;
  militaryCombatPay?: number;
  militaryFlightPay?: number;
  militaryHazardPay?: number;
  militaryOverseasPay?: number;
  militaryPropPay?: number;
  clothingAllowance?: number;
  rationsAllowance?: number;
  variableHousingAllowance?: number;
  quartersAllowance?: number;
  email?: string;
}
