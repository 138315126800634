import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';

const NotFound = () => {
  const history = useHistory();

  return (
    <div className='flex flex-container flex-center not-found'>
      <h1>404</h1>
      <p>Page not found</p>
      <div className='button-group'>
        <Button variant='outlined' onClick={() => history.goBack()}>
          Back
        </Button>
        <Button variant='outlined' onClick={() => history.replace('/')}>
          Home
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
