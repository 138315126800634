const defaultState = [];

const reportingDashboardsReducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_reporting_dashboards': {
      return data;
    }
    case 'add_reporting_dashboard': {
      return [...state, data];
    }
    case 'update_reporting_dashboard': {
      const newState = [...state];
      const matchIndex = newState.findIndex(
        (x) => x.id.toString() === data.id.toString(),
      );
      newState.splice(matchIndex, 1, {
        ...newState[matchIndex],
        ...data,
      });
      return newState;
    }
    case 'add_reporting_dashboard_widget':
    case 'add_or_update_reporting_dashboard_widget': {
      const newState = [...state];
      const matchIndex = newState.findIndex(
        (x) => x.id.toString() === data.dashboardId.toString(),
      );
      const newDashboard = newState[matchIndex];
      const widgetIndex = newDashboard.widgets?.findIndex(
        (w) => w.id.toString() === data.widget?.id?.toString(),
      );
      const newWidgets = [...(newDashboard.widgets ?? [])];

      if (widgetIndex > -1) {
        newWidgets.splice(widgetIndex, 1, {
          ...newWidgets[widgetIndex],
          ...data.widget,
        });
      } else {
        newWidgets.push(data.widget);
      }

      const newData = {
        ...newDashboard,
        widgets: newWidgets,
        widgetCount: newWidgets.length,
      };
      newState.splice(matchIndex, 1, {
        ...newState[matchIndex],
        ...newData,
      });
      return newState;
    }
    case 'update_reporting_dashboard_widget_positions': {
      const newState = [...state];
      const matchIndex = newState.findIndex(
        (x) => x.id.toString() === data.dashboardId.toString(),
      );
      const newDashboard = newState[matchIndex];
      const newData = {
        ...newDashboard,
        widgets: (newDashboard?.widgets ?? []).map((w) => ({
          ...w,
          positions:
            data.widgets.find((x) => x.widgetId.toString() === w.id.toString())
              ?.positions ?? w.positions,
        })),
      };
      newState.splice(matchIndex, 1, {
        ...newState[matchIndex],
        ...newData,
      });
      return newState;
    }
    case 'remove_reporting_dashboard': {
      const newState = [...state];
      const matchIndex = newState.findIndex(
        (x) => x.id.toString() === data.toString(),
      );
      newState.splice(matchIndex, 1);
      return newState;
    }
    case 'remove_reporting_dashboard_widget': {
      const newState = [...state];
      const matchIndex = newState.findIndex(
        (x) => x.id.toString() === data.dashboardId.toString(),
      );
      const newDashboard = newState[matchIndex];
      const widgetIndex = newDashboard.widgets?.findIndex(
        (w) => w.id.toString() === data.widgetId?.toString(),
      );
      const newWidgets = [...newDashboard.widgets];

      if (widgetIndex > -1) {
        newWidgets.splice(widgetIndex, 1);
      }

      const newData = {
        ...newDashboard,
        widgets: newWidgets,
        widgetCount: newWidgets.length,
      };
      newState.splice(matchIndex, 1, {
        ...newState[matchIndex],
        ...newData,
      });
      return newState;
    }
    default:
      return state;
  }
};

export default reportingDashboardsReducer;
