import {
  SET_APP_SETTINGS,
  SET_CINCH_SETTING,
  UPDATE_CINCH_SETTING,
} from 'src/actions/types';
import { CINCHSETTING_EXTERNAL_LINKS, CINCH_EXCLUDED_FIELDS } from 'src/util';

const defaultState = {
  [CINCH_EXCLUDED_FIELDS]: {
    value: [],
  },
  [CINCHSETTING_EXTERNAL_LINKS]: {
    value: [],
  },
};

// eslint-disable-next-line max-statements, complexity
const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case SET_APP_SETTINGS: {
      return {
        ...state,
        appSettings: data,
      };
    }

    case SET_CINCH_SETTING:
    case UPDATE_CINCH_SETTING: {
      return {
        ...state,
        [data.key]: {
          ...data.data,
          value: JSON.parse(data.data.value),
        },
      };
    }

    default:
      return state;
  }
};

export default reduce;
