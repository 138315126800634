import { REQUEST_LOAN_FOLDERS, SET_LOAN_FOLDERS } from '../actions/types';

const defaultState = {
  data: null,
  isFetching: false,
};

const reduce = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_LOAN_FOLDERS:
      return {
        ...state,
        isFetching: false,
        data: payload,
      };
    case REQUEST_LOAN_FOLDERS:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};

export default reduce;
