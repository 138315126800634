import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
  Checkbox,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './index.module.scss';

export const SelectInput = ({
  label,
  options,
  value,
  onChange = () => null,
  onClose = () => null,
  fullWidth,
  className,
  style,
  startAdornment,
  optionRenderer,
  disabled,
  displayEmpty,
  renderValue,
  defaultValue,
  error,
  helperText,
  variant = 'outlined',
  multiple,
}) => {
  const opts = useMemo(() => {
    if (options?.length) return options;
    else
      return [
        {
          text: 'No options available',
          value: '',
          disabled: true,
        },
      ];
  }, [options]);
  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      className={clsx(className, styles.selectInput)}
      disabled={disabled}
      style={style}
      error={error}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value ?? (multiple ? [] : '')}
        onChange={onChange}
        label={label}
        startAdornment={startAdornment}
        displayEmpty={displayEmpty}
        renderValue={
          renderValue ? renderValue : multiple ? getMultiDisplayValue : null
        }
        defaultValue={defaultValue?.value}
        multiple={multiple}
        onClose={onClose}
      >
        {opts.map((option, i) =>
          option.subheader ? (
            <ListSubheader key={i} className={styles.subheader}>
              {option.subheader}
            </ListSubheader>
          ) : (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {multiple && (
                <Checkbox
                  checked={value.indexOf(option.value) > -1}
                  className={styles.multiSelectCheckbox}
                />
              )}
              {optionRenderer(option)}
            </MenuItem>
          ),
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

SelectInput.defaultProps = {
  fullWidth: true,
  optionRenderer: (opt) => opt.text,
};

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
]).isRequired;

SelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
          .isRequired,
      }),
      PropTypes.shape({
        text: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
        ]).isRequired,
        value: valueType,
        disabled: PropTypes.bool,
      }),
    ]),
  ).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([valueType, PropTypes.arrayOf(valueType)]),
};

const getMultiDisplayValue = (items) => {
  if (items.length === 0) return '';
  else if (items.length === 1) return items[0];
  else if (items.length === 2) return `${items[0]}, ${items[1]}`;
  else return `${items[0]}, ${items[1]} and ${items.length - 2} more`;
};
