import React, { useMemo, useState } from 'react';
import './index.scss';
import {
  ListItem,
  Grid,
  List,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core';
import './steps.scss';
import { green, orange, red } from '@material-ui/core/colors';
import { format, parseISO } from 'date-fns';
import Expandable from 'src/components/loanOfficer/NotificationsPopover/Expandable';
import { LoadingIcon } from 'src/components/baseComponents/Loading';
import Icon from '@mdi/react';
import { mdiAlert, mdiAlertCircle, mdiCheck, mdiTimer } from '@mdi/js';
import { MaventAuditResults } from 'src/components/loanOfficer/NotificationsManager/MaventAuditResults';

const renderMissingFields = (fields) => {
  let missingFields = fields
    .map((m) => (typeof m === 'string' ? m : m.Value))
    .flat(Infinity);
  missingFields = [...new Set(missingFields)];
  missingFields = missingFields.map((x) => ({
    fieldId: x.split('`')[0],
    action: x.split('`')[1],
    fieldDescription: x.split('`')[2],
  }));
  return (
    <>
      <p>Missing fields:</p>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell colSpan='5'>
              <span style={{ color: 'red' }}>
                Warning: Disclosures cannot be ordered until the items below are
                fixed
              </span>
              <br /> <i>Once All Have Been Fixed Please Re-run This Process.</i>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Field ID</TableCell>
            <TableCell>Description</TableCell>
            <TableCell colSpan='3'>Action you should take to resolve</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {missingFields.map((field, index) => (
            <TableRow key={index}>
              <TableCell>{field.fieldId.replace(',', '').trim()}</TableCell>
              <TableCell>{field.fieldDescription}</TableCell>
              <TableCell colSpan='3'>
                {field.action.trim().startsWith('*') ? (
                  <>
                    Update this field on one of the following forms:
                    <br /> <i>{field.action.trim().substring(1)}</i>
                  </>
                ) : (
                  field.action
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const OuterStep = ({ step, i, inner }) => {
  const icon = useMemo(() => getIconForStep(step.status), [step.status]);
  const displayDate = useMemo(
    () =>
      inner ? '' : format(parseISO(step.updatedAt), "MMM dd, yyyy 'at' h:mm a"),
    [inner, step.updatedAt],
  );
  const [disableTooltip, setDisableTooltip] = useState(false);

  const error = useMemo(() => {
    let error = step.error || '';
    if (step.friendlyId === 'LoanFieldCheck') {
      try {
        const eJson = JSON.parse(error);
        error = renderMissingFields(eJson);
      } catch (e) {
        // nothing
      }
    }
    return error;
  }, [step.error, step.friendlyId]);

  const maventAuditResults = useMemo(() => {
    if (step.friendlyId === 'ComplianceCheck' && step.meta?.type) {
      return step.meta;
    }
    return null;
  }, [step.friendlyId, step.meta]);

  return (
    <ListItem key={i} className='step'>
      <Tooltip title={disableTooltip ? '' : displayDate} placement='left' arrow>
        <Grid container direction='row' wrap='nowrap' spacing={1}>
          <Grid item className='icon'>
            {icon}
          </Grid>
          <Grid item>
            <p className={icon === '' ? 'pending' : ''}>{step.title}</p>
            {maventAuditResults && error ? (
              <MaventAuditResults
                data={maventAuditResults}
                onDialogOpened={() => setDisableTooltip(true)}
                onDialogClosed={() => setDisableTooltip(false)}
              />
            ) : (
              <>
                {error && typeof error === 'string' && (
                  <Expandable className='error' component='div' maxLength={100}>
                    {error}
                  </Expandable>
                )}
                {error && typeof error !== 'string' && (
                  <div className='error'>{error}</div>
                )}
              </>
            )}
            {step.steps?.length > 0 && (
              <List className='notification-steps-inner'>
                {step.steps.map((x, i, a) => (
                  <OuterStep key={i} inner {...{ step: x, i, a }} />
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </Tooltip>
    </ListItem>
  );
};

const Steps = ({ steps }) => {
  const stepsToShow = useMemo(() => {
    if (!steps?.length) return null;
    const okStatuses = ['complete', 'running', 'pending', 'warning'];
    const firstFail = steps.findIndex(
      (s) => !okStatuses.includes(s.status.toLowerCase()),
    );
    if (firstFail === -1) return steps;
    return steps.slice(0, firstFail + 1);
  }, [steps]);

  if (!stepsToShow?.length) return null;

  return (
    <List className='notification-steps'>
      {stepsToShow.map((x, i, a) => (
        <OuterStep {...{ step: x, i, a }} key={i} />
      ))}
    </List>
  );
};

const getIconForStep = (status) => {
  let icon =
    status.toLowerCase() === 'complete' ? (
      <Icon path={mdiCheck} style={{ color: green[300] }} />
    ) : status.toLowerCase() === 'running' ? (
      <LoadingIcon />
    ) : (
      ''
    );

  const failStatuses = ['failed', 'cancelled', 'canceled'];
  if (failStatuses.includes(status.toLowerCase())) {
    icon = <Icon path={mdiAlert} style={{ color: red[500] }} />;
  } else if (status.toLowerCase() === 'timedout') {
    icon = <Icon path={mdiTimer} style={{ color: orange[500] }} />;
  } else if (status.toLowerCase() === 'warning') {
    icon = <Icon path={mdiAlertCircle} style={{ color: '#fdd835' }} />;
  }
  return icon;
};

export default Steps;
