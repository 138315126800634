import React from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import styles from './index.module.scss';

export default function Loading ({
  fontSize = 16,
  label = 'Loading',
  dots,
  hideCircle,
  horizontal,
  style = {},
  mask = false,
  ...props
}) {
  return (
    <div
      className={clsx(
        styles.loading,
        'flex flex-container flex-center',
        horizontal && styles.horizontal,
        mask && styles.mask,
      )}
      style={style}
    >
      {!hideCircle && <CircularProgress className={styles.circle} {...props} />}

      <p
        className={clsx(dots && styles.dots, styles.label)}
        style={{ fontSize }}
      >
        {label}
      </p>
    </div>
  );
}

export const LoadingIcon = ({ sm, lg, ...props }) => {
  const size = sm ? 14 : lg ? 26 : 20;
  return <CircularProgress size={size} {...props} />;
};
