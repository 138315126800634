import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoanSummary, getMilestones } from 'src/actions';
import { useCinchAppConfig } from 'src/hooks/use-cinchconfig';
import { CinchLoanType } from 'src/types/CinchLoanType';
import {
  ConfigKeyInvestorLoanAmountLimit,
  ConfigKeySbcAdjustRentPercent,
  ConfigKeySbcOccupiedRentPercent,
  DYNAMIC_NQM_PROGRAM_NAME,
  ROLE_NAMES,
} from 'src/util';
import { fieldIds } from 'src/util/fieldIds';
import {
  CinchLoanSummary,
  EncompassClosingDocument,
  EncompassCustomFieldsMapped,
  EncompassGfe2010Fee,
  EncompassGfe2010Section,
  EncompassLoan,
  EncompassLoanContact,
  EncompassLoanFee,
  EncompassLoanProductData,
  EncompassMilestone,
  EncompassProperty,
  EncompassResourceLock,
  EncompassTql,
  LoanChannel,
  PropertyUsageType,
  RootStore,
  WorkState,
} from '../types';
import { CinchLoanProgramSettings } from 'src/types/CinchLoanProgramSettings';
import { isBefore } from 'date-fns';
import { Decimal } from 'decimal.js';
import { useUserRoles } from 'src/hooks';

const emptyArray = [];
const emptyObject = {};

export const useLoan = (loanGuid: string): EncompassLoan | null => {
  const loans = useLoans();
  const prev = useRef<EncompassLoan | null>(null);
  if (!loanGuid) return null;
  if (JSON.stringify(loans[loanGuid]) !== JSON.stringify(prev.current)) {
    prev.current = loans[loanGuid] ?? null;
  }
  return prev.current;
};

export const useLoanTypeFlags = (
  loanGuid: string,
): {
  isFnf: boolean;
  isCommercial: boolean;
  isResidential: boolean;
  isNqm: boolean;
  isMultifamilyCommercial: boolean;
} => {
  const loan = useLoan(loanGuid);
  const customFields = loan?.customFields ?? emptyObject;
  const isFnf = customFields[fieldIds.loanTypeFixAndFlip] === 'Yes';
  const isMultifamilyCommercial =
    customFields[fieldIds.loanProgram] === 'MultiFamily - Commercial' ||
    customFields[fieldIds.loanProgram] === 'SB Multi family';
  const isCommercial =
    !isFnf &&
    !isMultifamilyCommercial &&
    customFields[fieldIds.loanTypeCommercial] === 'Yes';
  const isNqm = customFields[fieldIds.loanProgram]?.includes('NON-QM');

  return useMemo(
    () => ({
      isFnf,
      isCommercial,
      isResidential: !isFnf && !isCommercial && !isMultifamilyCommercial,
      isNqm,
      isMultifamilyCommercial,
    }),
    [isFnf, isCommercial, isNqm, isMultifamilyCommercial],
  );
};

export const useLoanType = (loanGuid: string): CinchLoanType => {
  const flags = useLoanTypeFlags(loanGuid);
  if (flags.isFnf) return CinchLoanType.FixAndFlip;
  if (flags.isMultifamilyCommercial) return CinchLoanType.MultiFamilyCommercial;
  if (flags.isCommercial) return CinchLoanType.Commercial;
  return CinchLoanType.Residential;
};

export const useLoanCurrentMilestone = (loanGuid: string): string | null => {
  const loan = useLoan(loanGuid);
  return loan?.milestoneCurrentName ?? null;
};

export const useLoanMilestones = (
  loanGuid: string,
): EncompassMilestone[] | null => {
  const loan = useLoan(loanGuid);
  return loan?.milestones ?? null;
};

export const useInvestorLoanAmountLimit = (loanGuid: string): number | null => {
  const config = useCinchAppConfig();
  const loan = useLoan(loanGuid);
  if (
    loan?.applications[0].propertyUsageType === PropertyUsageType.Investor &&
    loan?.channel !== LoanChannel.Brokered &&
    loan.customFieldsMapped?.loanLimitException?.toLowerCase() !== 'yes' &&
    loan.customFieldsMapped?.targetLoanProgram?.nqm?.loanAmountApproved?.toLowerCase() !==
      'yes'
  ) {
    return (config[ConfigKeyInvestorLoanAmountLimit] as number) ?? 300000;
  }
  return null;
};

export const useJuniorLienLoanAmountLimit = (
  loanGuid: string,
): number | null => {
  const loan = useLoan(loanGuid);
  if (
    loan?.customFieldsMapped?.juniorLien?.requestedLoanAmount &&
    loan?.customFieldsMapped?.juniorLien?.status === 'A'
  ) {
    return (
      (loan?.customFieldsMapped?.juniorLien?.requestedLoanAmount as number) ??
      350000
    );
  }
  return 350000;
};

export const useSbcAdjustRentPercent = () => {
  const config = useCinchAppConfig();
  return config[ConfigKeySbcAdjustRentPercent] as number;
};

export const useSbcOccupiedRentPercent = () => {
  const config = useCinchAppConfig();
  return config[ConfigKeySbcOccupiedRentPercent] as number;
};

export const useLoans = (): Record<string, EncompassLoan> => {
  return useSelector<RootStore, Record<string, EncompassLoan>>(
    (store) => store.loans,
  );
};

export const useLoanLock = (loanGuid: string): EncompassResourceLock | null => {
  const locks = useSelector<RootStore, Record<string, EncompassResourceLock>>(
    (store) => store.locks,
  );
  if (!loanGuid) return null;
  return locks[loanGuid] ?? null;
};

export const useIsApplicationCompleted = (loanGuid: string): boolean => {
  const loan = useLoan(loanGuid);
  return Boolean(loan?.milestoneCompletedDate);
};

export const useIsLoanReadonly = (loanGuid: string): boolean => {
  const lock = useLoanLock(loanGuid);
  const applicationCompleted = useIsApplicationCompleted(loanGuid);
  return (
    lock === null ||
    lock.isLockedByOther ||
    Boolean(lock.error) ||
    applicationCompleted
  );
};

export const useIsMultifamlyLoanLoEditable = (loanGuid: string): boolean => {
  const roles = useUserRoles();
  const isMultifamilyManager =
    roles.includes(ROLE_NAMES.ADMINISTRATOR) ||
    roles.includes(ROLE_NAMES.MULTIFAMILYMANAGER);
  const milestones = useLoanMilestones(loanGuid);
  const processingComplete =
    milestones?.some(
      (ms) => ms.name === 'Processing' && ms.doneIndicator === true,
    ) ?? false;
  return processingComplete && !isMultifamilyManager;
};

export const useLoanWorking = (loanGuid: string) => {
  const workstates = useSelector<RootStore, Record<string, WorkState>>(
    (store) => store.workState,
  );
  return workstates[loanGuid]?.working ?? 0;
};

export const useLoanUpdateError = (loanGuid: string) => {
  const workstates = useSelector<RootStore, Record<string, WorkState>>(
    (store) => store.workState,
  );
  return workstates[loanGuid]?.error ?? null;
};

export const useLoanProperty = (loanGuid: string): EncompassProperty | null => {
  const loan = useLoan(loanGuid);
  return loan?.property ?? null;
};

/**
 * @deprecated Use useLoanCustomFieldsMapped
 */
export const useLoanCustomFields = (
  loanGuid: string,
): Record<string, string> => {
  const loan = useLoan(loanGuid);
  return loan?.customFields ?? emptyObject;
};

export const useLoanCustomFieldsMapped = (
  loanGuid: string,
): EncompassCustomFieldsMapped | null => {
  const loan = useLoan(loanGuid);
  return loan?.customFieldsMapped ?? emptyObject;
};

export const useLoanProductData = (
  loanGuid: string,
): EncompassLoanProductData | null => {
  const loan = useLoan(loanGuid);
  return loan?.loanProductData ?? null;
};

export const useLoanFees = (loanGuid: string): EncompassLoanFee[] | null => {
  const loan = useLoan(loanGuid);
  return loan?.fees ?? emptyArray;
};

export const useLoanGfe2010Section = (
  loanGuid: string,
): EncompassGfe2010Section | null => {
  const loan = useLoan(loanGuid);
  return loan?.closingCost?.gfe2010Section ?? emptyObject;
};

export const useLoanGfe2010Fees = (
  loanGuid: string,
): EncompassGfe2010Fee[] | null => {
  const loan = useLoan(loanGuid);
  return loan?.closingCost?.gfe2010?.gfe2010Fees ?? emptyArray;
};

export const useLoanContact = (
  loanGuid: string,
): EncompassLoanContact[] | null => {
  const loan = useLoan(loanGuid);
  return loan?.contacts ?? emptyArray;
};

export const useLoanTql = (loanGuid: string): EncompassTql | null => {
  const loan = useLoan(loanGuid);
  return loan?.tql ?? emptyObject;
};

export const useLoanClosingDocument = (
  loanGuid: string,
): EncompassClosingDocument | null => {
  const loan = useLoan(loanGuid);
  return loan?.closingDocument ?? emptyObject;
};

export const useLoanMilestonesWithUpdate = (
  loanGuid: string,
): EncompassMilestone[] | null => {
  const dispatch = useDispatch();
  const loan = useLoan(loanGuid);
  const [milestones, setMilestones] = useState<EncompassMilestone[] | null>(
    null,
  );
  useEffect(() => {
    (async () => {
      const currentMilestones = await getMilestones(loanGuid);
      const updatedMilestones = currentMilestones.map((milestone) => ({
        ...milestone,
        name: milestone.milestoneName,
        doneIndicator: milestone.doneIndicator,
      }));
      setMilestones(updatedMilestones);
    })();
    return () => setMilestones(null);
  }, [dispatch, loanGuid, loan?.customFieldsMapped?.multiFamily?.loanStatus]);
  return milestones;
};

export const useLoanSummary = (loanGuid: string): CinchLoanSummary => {
  const dispatch = useDispatch();
  const loan = useLoan(loanGuid);
  const [fullLoanSummary, setFullLoanSummary] =
    useState<CinchLoanSummary | null>(null);

  const isRefinance = loan?.property?.loanPurposeType?.includes('Refinance');
  const isPurchase = loan?.property?.loanPurposeType?.includes('Purchase');

  useEffect(() => {
    (async () => {
      const summary = await dispatch(fetchLoanSummary(loanGuid));
      // @ts-ignore
      setFullLoanSummary(summary);
    })();

    return () => setFullLoanSummary(null);
  }, [dispatch, loanGuid]);

  const getValues = useMemo(
    () => ({
      getLTC: (loan: EncompassLoan | null) => {
        if (!loan) return null;
        const renovationCost =
          loan?.customFieldsMapped?.rtl?.renovationCost ?? 0;
        const asIsValue = loan?.customFieldsMapped?.rtl?.valueAsIs ?? 0;
        if (renovationCost > 0 || asIsValue > 0) {
          const renoAndAsIs = new Decimal(+renovationCost).plus(
            new Decimal(+asIsValue),
          );
          return new Decimal(+(loan?.borrowerRequestedLoanAmount ?? 0))
            .div(renoAndAsIs)
            .times(100)
            .toNumber();
        }
        return null;
      },
      getLTAIV: (loan: EncompassLoan | null) => {
        // as is value
        if (!loan) return null;
        const purchasePrice = loan?.purchasePriceAmount ?? 0;
        const asIsValue = loan?.customFieldsMapped?.rtl?.valueAsIs ?? 0;
        const day1LoanAmount =
          loan?.customFieldsMapped?.rtl?.dayOneLoanAmount ?? 0;

        if (isPurchase) {
          if (purchasePrice > 0) {
            // day 1 loan amount divided by whatever is lower between purchase and as is value
            if (asIsValue > 0 && asIsValue < purchasePrice) {
              return new Decimal(+day1LoanAmount)
                .div(new Decimal(+asIsValue))
                .times(100)
                .toNumber();
            } else {
              return new Decimal(+day1LoanAmount)
                .div(new Decimal(+purchasePrice))
                .times(100)
                .toNumber();
            }
          }
        }

        // Refinance transaction formula --> TLA divided by the sum of (AIV + renovation amount)]
        if (isRefinance) {
          if (asIsValue > 0) {
            return new Decimal(+day1LoanAmount)
              .div(new Decimal(+asIsValue))
              .times(100)
              .toNumber();
          }
        }

        return null;
      },
      getLTARV: (loan: EncompassLoan | null) => {
        if (!loan) return null;
        const afterRenovationValue =
          loan?.customFieldsMapped?.rtl?.afterRenovationValue ?? 0;

        if (afterRenovationValue > 0) {
          return new Decimal(+(loan?.borrowerRequestedLoanAmount ?? 0))
            .div(new Decimal(+afterRenovationValue))
            .times(100)
            .toNumber();
        }

        return null;
      },
      getHoldbackLoanAmount: (loan: EncompassLoan | null) => {
        if (!loan) return null;
        const renoCost = loan?.customFieldsMapped?.rtl?.renovationCost ?? 0;
        const holdBackAmount =
          loan?.customFieldsMapped?.rtl?.disbursedHoldbackAmount ?? 0;
        const improvementAmount =
          loan?.property?.constructionImprovementCostsAmount ?? 0;

        if (holdBackAmount > 0) {
          return new Decimal(+holdBackAmount).toDecimalPlaces(2).toNumber();
        }
        if (renoCost > 0) {
          return new Decimal(+renoCost).toDecimalPlaces(2).toNumber();
        }
        if (improvementAmount > 0) {
          return new Decimal(+improvementAmount).toDecimalPlaces(2).toNumber();
        }

        return null;
      },
      getLoanAmount: (loan: EncompassLoan | null) => {
        if (!loan) return null;
        const loanProgram =
          loan?.customFieldsMapped?.targetLoanProgram?.program ?? '';
        if (loanProgram.toLowerCase().includes('fix and flip')) {
          return loan?.customFieldsMapped?.rtl?.dayOneLoanAmount ?? 0;
        } else {
          return (
            loan?.borrowerRequestedLoanAmount ??
            0 - (loan?.property?.constructionImprovementCostsAmount ?? 0)
          );
        }
      },
    }),
    [isPurchase, isRefinance],
  );
  return useMemo(
    () => ({
      ...fullLoanSummary,
      loanNumber: loan?.loanNumber,
      titleHolderName1: loan?.titleHolderName1,
      addressLine1: loan?.property?.streetAddress,
      addressLine2: loan?.property?.streetAddress2,
      city: loan?.property?.city,
      state: loan?.property?.state,
      postalCode: loan?.property?.postalCode,
      totalLoanAmount: loan?.borrowerRequestedLoanAmount,
      initialLoanAmount: getValues.getLoanAmount(loan),
      cltv: loan?.combinedLtv,
      rate: loan?.requestedInterestRatePercent,
      ltv: loan?.ltv,
      holdbackLoanAmount: getValues.getHoldbackLoanAmount(loan),
      ltaiv: getValues.getLTAIV(loan),
      ltarv: getValues.getLTARV(loan),
      ltc: getValues.getLTC(loan),
      topRatioPercent: loan?.applications[0]?.topRatioPercent,
      bottomRatioPercent: loan?.applications[0]?.bottomRatioPercent,
      googleStreetAddress: `${loan?.property?.addressLineText}, ${loan?.property?.city}, ${loan?.property?.state}, ${loan?.property?.postalCode}`,
      applicationCount: loan?.applications?.length ?? 0,
      scheduledClosingDate: loan?.loanProductData?.loanScheduledClosingDate,
      multiFamily: {
        loanStatus: loan?.customFieldsMapped?.multiFamily?.loanStatus ?? '',
        statusLog: loan?.customFieldsMapped?.multiFamily?.statusLog ?? '',
        statusDate: loan?.customFieldsMapped?.multiFamily?.statusDate ?? '',
      },
    }),
    [fullLoanSummary, loan, getValues],
  );
};

export const useLoanProgramSettings = (
  loanGuid: string,
): CinchLoanProgramSettings => {
  const loan = useLoan(loanGuid);
  const milestone = useLoanCurrentMilestone(loanGuid);
  const isStartedMilestone = milestone?.toLowerCase()?.endsWith('started');
  const milestones = useLoanMilestones(loanGuid);
  const approvedMilestone = milestones?.find(
    (milestone) => milestone.name === 'Approved',
  );
  return useMemo(() => {
    return {
      loanProgramName:
        loan?.customFieldsMapped?.targetLoanProgram?.program ?? null,
      isNqmV2:
        loan?.customFieldsMapped?.targetLoanProgram?.program ===
        DYNAMIC_NQM_PROGRAM_NAME,
      isNonTridLoan:
        loan?.customFieldsMapped?.targetLoanProgram?.nqm
          ?.businessPurposeLoan === 'Yes' &&
        loan?.customFieldsMapped?.targetLoanProgram?.nqm?.businessPurposeLLC ===
          'Yes' &&
        !isStartedMilestone,
      closingDocumentLoanIsLocked: loan?.closingDocument?.loanIsLocked === 'Y',
      isBeforeNqmV2: isBefore(
        new Date(loan?.originationDate ?? ''),
        new Date(2023, 10, 25),
      ),
      isLoanApproved: approvedMilestone?.doneIndicator,
      fieldsDisabled:
        approvedMilestone?.doneIndicator ||
        loan?.closingDocument?.loanIsLocked === 'Y',
    };
  }, [loan, isStartedMilestone, approvedMilestone]);
};
