import { CitizenshipResidencyType, PropertyUsageType } from 'src/types';
import { CinchColorTheme } from 'src/types/ColorTheme';

export const INPUT_DEBOUNCE_DELAY = 600;
export const DATE_FORMAT_STRING = "MM/dd/yy 'at' h:mm a";
export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const PHONE_REGEX = /^\d{3}-\d{3}-\d{4}(\s\d{1,4}|\s?)?$/;
export const POSTALCODE_REGEX = /^\d{5}(-\d{4})?$/;
export const SSN_REGEX = /^\d{3}-\d{2}-\d{4}$/;
export const EIN_REGEX = /^\d{9}$/;
export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
export const GUID_REGEX =
  /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/;

export const ConfigKeyInvestorLoanAmountLimit =
  'Cinch:Settings:InvestorLoanAmount';
export const ConfigKeySbcAdjustRentPercent =
  'Cinch:Settings:SbcAdjustRentPercent';
export const ConfigKeySbcOccupiedRentPercent =
  'Cinch:Settings:SbcOccupiedRentPercent';

export const DEFAULT_PROFILE_PASSWORD = '******';

export const FLOOD_SERVICE_CERT_NOT_READY_MESSAGE =
  'Flood certificate is not ready to display.  Please check back later.';

export const MAX_REPORT_WIDGETS = 20;
export const ALL_FOLDERS = '<All Folders>';
export const ACTIVE_FOLDERS = '<Active Folders>';
export const ARCHIVE_FOLDERS = '<All Archive Folders>';
export const CHART_COLORS = [
  '#448aff',
  '#69f0ae',
  '#ff80ab',
  '#ffab00',
  '#b388ff',
  '#00e5ff',
  '#64dd17',
  '#ffd740',
  '#7c4dff',
  '#ff5252',
];

export const ROLE_NAMES = {
  ADMINISTRATOR: 'Administrator',
  APPRAISALMANAGER: 'AppraisalManager',
  COMMERCIALMANAGER: 'CommercialManager',
  COMPLIANCEMANAGER: 'ComplianceManager',
  DISCLOSUREDESK: 'DisclosureDesk',
  DISCLOSUREDESKMANAGER: 'DisclosureDeskManager',
  CLOSINGMANAGER: 'ClosingManager',
  RATESMANAGER: 'RatesManager',
  TERMSHEETMANAGER: 'TermsheetManager',
  DEVELOPER: 'Developer',
  ACCOUNTING: 'Accounting',
  FNFUNDERWRITER: 'FixAndFlipUnderwriter',
  MULTIFAMILYMANAGER: 'MultiFamilyManager',
  FMCAPITAL: 'FMCAPITAL',
  FMM: 'FMM',
  VOEWITHSIGNNOW: 'VOEWithSignNow',
} as const;

export const STEP_FRIENDLY_IDS = {
  LOANFEES: 'LoanFees',
  LOANFIELDCHECK: 'LoanFieldCheck',
  LOANEMAILCHECK: 'LoanEmailCheck',
  MAVENTQUEUE: 'MaventQueue',
  COMPLIANCECHECK: 'ComplianceCheck',
  SENDINGSIGNNOW: 'SendingSignnow',
} as const;

export const COLOR_THEMES: CinchColorTheme[] = [
  { name: 'Default', id: 'default', base: 'light' },
  { name: 'Blue', id: 'blue', base: 'light' },
  { name: 'Green', id: 'green', base: 'light' },
  { name: 'Dark red', id: 'darkred', base: 'dark' },
  { name: 'Dark blue', id: 'darkblue', base: 'dark' },
  { name: 'Dark green', id: 'darkgreen', base: 'dark' },
  { name: 'Azure', id: 'azure', base: 'dark', experimental: true },
  { name: '???', id: 'perfection', base: 'dark', experimental: true },
];

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
].map((day, i) => ({
  text: day,
  value: i,
}));

export const WEEKDAYS_SHORT = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
].map((day, i) => ({
  text: day,
  value: i,
}));

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((month, i) => ({
  text: month,
  value: i + 1,
}));

export const MONTHS_SHORT = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
].map((month, i) => ({
  text: month,
  value: i + 1,
}));

export const FNF_PROPERTY_TYPE_OPTIONS = [
  {
    value: 'SFR',
    text: 'SFR',
  },
  {
    value: '2-4 Units',
    text: '2 - 4 Units',
  },
  {
    value: '5+ Units',
    text: '5+ Units',
  },
  {
    value: 'Mixed use',
    text: 'Mixed use',
  },
  {
    value: 'Condo',
    text: 'Condo',
  },
];

export const LOAN_PRODUCT_GSE_PROPERTY_TYPE_OPTIONS = [
  {
    value: 'Attached',
    text: 'Attached',
  },
  {
    value: 'Condominium',
    text: 'Condominium',
  },
  {
    value: 'Cooperative',
    text: 'Co-Operative',
  },
  {
    value: 'Detached',
    text: 'Detached',
  },
  {
    value: 'HighRiseCondominium',
    text: 'High Rise Condominium',
  },
  {
    value: 'ManufacturedHousing',
    text: 'Manufactured Housing',
  },
  {
    value: 'PUD',
    text: 'PUD',
  },
  {
    value: 'DetachedCondo',
    text: 'Detached Condo',
  },
  {
    value: 'ManufacturedHomeCondoPUDCoOp',
    text: 'Mfd Home/Condo/PUD/Co-Op',
  },
  {
    value: 'MHSelect',
    text: 'MH Select',
  },
  {
    value: 'MHAdvantage',
    text: 'MH Advantage',
  },
];

export const GSE_PROPERTY_TYPE_OPTIONS = [
  {
    value: 'Condominium',
    text: 'Condo',
  },
  {
    value: 'Townhouse',
    text: 'Town home',
  },
  {
    value: 'SingleFamily',
    text: 'Single Family',
  },
  {
    value: 'MultifamilyMoreThanFourUnits',
    text: 'Multi Family',
  },
  {
    value: 'ManufacturedMobileHome',
    text: 'Manufactured Home',
  },
  {
    value: 'TwoToFourUnitProperty',
    text: '2 to 4 Units',
  },
];

export const LOAN_PURPOSE_OPTIONS = [
  {
    value: 'Purchase',
    text: 'Purchase',
  },
  {
    value: 'Cash-Out Refinance',
    text: 'Cash-Out Refi',
  },
  {
    value: 'NoCash-Out Refinance',
    text: 'No Cash-Out Refi',
  },
  /* {
    value: 'ConstructionToPermanent',
    text: 'Construction - Perm'
  },
  {
    value: 'ConstructionOnly',
    text: 'Construction'
  },
  {
    value: 'Other',
    text: 'Other'
  } */
];

export const SUBJECT_PROPERTY_MANNER_HELD_OPTIONS = [
  {
    value: 'Community property',
    text: 'Community property',
  },
  {
    value: 'Joint tenants',
    text: 'Joint tenants',
  },
  {
    value: 'Single man',
    text: 'Single man',
  },
  {
    value: 'Single woman',
    text: 'Single woman',
  },
  {
    value: 'Married man',
    text: 'Married man',
  },
  {
    value: 'Married woman',
    text: 'Married woman',
  },
  {
    value: 'Tenants in common',
    text: 'Tenants in common',
  },
  {
    value: 'Tenancy by entirety',
    text: 'Tenancy by entirety',
  },
  {
    value: 'To be decided in escrow',
    text: 'To be decided in escrow',
  },
  {
    value: 'Unmarried man',
    text: 'Unmarried man',
  },
  {
    value: 'Unmarried woman',
    text: 'Unmarried woman',
  },
  {
    value: 'Spouses Married to Each Other',
    text: 'Spouses Married to Each Other',
  },
  {
    value: 'Other',
    text: 'Other',
  },
];

export const SUBJECT_PROPERTY_ESTATE_HELD_OPTIONS = [
  {
    value: 'FeeSimple',
    text: 'Fee Simple',
  },
  {
    value: 'Leasehold',
    text: 'Leasehold',
  },
];

export const UNIT_TYPE_OPTIONS = [
  {
    value: null,
    text: '(none)',
  },
  {
    value: 'Apartment',
    text: 'Apartment',
  },
  {
    value: 'Basement',
    text: 'Basement',
  },
  {
    value: 'Building',
    text: 'Building',
  },
  {
    value: 'Condo',
    text: 'Condo',
  },
  {
    value: 'Department',
    text: 'Department',
  },
  {
    value: 'Floor',
    text: 'Floor',
  },
  {
    value: 'Front',
    text: 'Front',
  },
  {
    value: 'Hangar',
    text: 'Hangar',
  },
  {
    value: 'Key',
    text: 'Key',
  },
  {
    value: 'Lobby',
    text: 'Lobby',
  },
  {
    value: 'Lot',
    text: 'Lot',
  },
  {
    value: 'Lower',
    text: 'Lower',
  },
  {
    value: 'Office',
    text: 'Office',
  },
  {
    value: 'Penthouse',
    text: 'Penthouse',
  },
  {
    value: 'Pier',
    text: 'Pier',
  },
  {
    value: 'Rear',
    text: 'Rear',
  },
  {
    value: 'Room',
    text: 'Room',
  },
  {
    value: 'Side',
    text: 'Side',
  },
  {
    value: 'Space',
    text: 'Space',
  },
  {
    value: 'Stop',
    text: 'Stop',
  },
  {
    value: 'Suite',
    text: 'Suite',
  },
  {
    value: 'Trailer',
    text: 'Trailer',
  },
  {
    value: 'Unit',
    text: 'Unit',
  },
  {
    value: 'Upper',
    text: 'Upper',
  },
];

export const CITIZENRESIDENCYTYPE_OPTIONS = [
  {
    text: 'US Citizen',
    value: CitizenshipResidencyType.USCitizen,
  },
  {
    text: 'Permanent Resident Alien',
    value: CitizenshipResidencyType.PermanentResidentAlien,
  },
  {
    text: 'Non Permanent Resident Alien',
    value: CitizenshipResidencyType.NonPermanentResidentAlien,
  },
];

export const DUPLICATION_LICENSED_TYPES = {
  BOTH: 'Both',
  CURRENT: 'Current',
  ORIGINAL: 'Original',
  NONE: 'None',
};

export const DEFAULT_FLOOD_PRODUCT_TYPE = 'FL';

export const FLOOD_PRODUCT_TYPE_OPTIONS = [
  // {
  //   text: 'Basic Flood Certification',
  //   value: 'F',
  // },
  // {
  //   text: 'Basic with Census Data',
  //   value: 'FC',
  // },
  // {
  //   text: 'Life of Loan Flood Determination',
  //   value: 'FL',
  // },
  {
    text: 'Life of Loan with Census Tract',
    value: 'FLC',
  },
  // {
  //   text: 'Life of Loan Related Loan',
  //   value: 'FLR',
  // },
  // {
  //   text: 'Life of Loan Related Loan with Census Data',
  //   value: 'FLCR',
  // },
  {
    text: 'Life of Loan Special Property',
    value: 'FLO',
  },
  // {
  //   text: 'Life of Loan Special Property with Census',
  //   value: 'FLCO',
  // },
];

export const AMORTIZATION_TYPE_OPTIONS = [
  { value: '5/1 ARM', text: '5/1 ARM' },
  {
    value: '5/1 ARM 10 Year IO',
    text: '5/1 ARM 10 Year IO',
  },
  { value: '7/1 ARM', text: '7/1 ARM' },
  {
    value: '7/1 ARM 10 Year IO',
    text: '7/1 ARM 10 Year IO',
  },
  {
    value: '30 Year Fixed',
    text: '30 Year Fixed',
  },
];

export const AMORTIZATION_TYPE_OPTIONS_2022 = [
  {
    value: '15 Year Fixed 3 YR PPP',
    text: '15 Year Fixed 3 YR PPP',
  },
  {
    value: '30 Year Fixed 3 YR PPP',
    text: '30 Year Fixed 3 YR PPP',
  },
  {
    value: '15 Year Fixed 5 YR PPP',
    text: '15 Year Fixed 5 YR PPP',
  },
  {
    value: '30 Year Fixed 5 YR PPP',
    text: '30 Year Fixed 5 YR PPP',
  },
  {
    value: '5 YR ARM 3 YR PPP',
    text: '5 YR ARM 3 YR PPP',
  },
  {
    value: '5 YR ARM 3 YR PPP IO',
    text: '5 YR ARM 3 YR PPP IO',
  },
  {
    value: '5 YR ARM 5 YR PPP',
    text: '5 YR ARM 5 YR PPP',
  },
  {
    value: '5 YR ARM 5 YR PPP IO',
    text: '5 YR ARM 5 YR PPP IO',
  },
  {
    value: '7 YR ARM 3 YR PPP',
    text: '7 YR ARM 3 YR PPP',
  },
  {
    value: '7 YR ARM 3 YR PPP IO',
    text: '7 YR ARM 3 YR PPP IO',
  },
  {
    value: '7 YR ARM 5 YR PPP',
    text: '7 YR ARM 5 YR PPP',
  },
  {
    value: '7 YR ARM 5 YR PPP IO',
    text: '7 YR ARM 5 YR PPP IO',
  },
];

export const PROPERTYUSAGETYPE_OPTIONS = [
  {
    text: 'Primary',
    value: PropertyUsageType.PrimaryResidence,
  },
  {
    text: 'Second Home',
    value: PropertyUsageType.SecondHome,
  },
  {
    text: 'Investor',
    value: PropertyUsageType.Investor,
  },
];

export const SUBMIT_MODE = {
  onChange: 'onChange',
  onIsPartialValid: 'onIsPartialValid',
  onIsValid: 'onIsValid',
};

export const PRE_APPROVAL_TEMPLATE = {
  version1: 'Pre-Approval V1.pdf',
  version2: 'Pre-Approval V2.pdf',
};

export const PRE_APPROVAL_PROPERTY_TYPE_OPTIONS = [
  {
    text: 'Single Family',
    value: 'Single Family',
  },
  {
    text: 'Multi Family',
    value: 'Multi Family',
  },
  {
    text: '2 Family',
    value: '2 Family',
  },
  {
    text: '3 Family',
    value: '3 Family',
  },
  {
    text: '4 Family',
    value: '4 Family',
  },
  {
    text: 'Condominium',
    value: 'Condominium',
  },
  {
    text: 'Co-Operative',
    value: 'Co-Operative',
  },
  {
    text: 'Mixed Use',
    value: 'Mixed Use',
  },
];

export const DYNAMIC_NQM_PROGRAM_NAME = 'NON-QM';

export const MARITAL_STATUS_OPTIONS = [
  {
    text: 'Married',
    value: 'Married',
  },
  {
    text: 'Separated',
    value: 'Separated',
  },
  {
    text: 'Unmarried',
    value: 'Unmarried',
  },
];

export const DOMESTIC_RELATIONSHIP_TYPE_OPTIONS = [
  {
    value: 'CivilUnion',
    text: 'Civil Union',
  },
  {
    value: 'DomesticPartnership',
    text: 'Domestic Partnership',
  },
  {
    value: 'Other',
    text: 'Other',
  },
  {
    value: 'RegisteredReciprocalBeneficiaryRelationship',
    text: 'Registered Reciprocal Beneficiary Relationship',
  },
];
