import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  mdiAlert,
  mdiAlertCircle,
  mdiCheckCircle,
  mdiClose,
  mdiInformation,
  mdiInformationOutline,
  mdiRefresh,
} from '@mdi/js';
import Icon from '@mdi/react';
import { addHours, format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchElliemaeServiceStatus } from 'src/actions';
import { LoadingIcon } from '../../Loading';
import './index.scss';

const EllieMaeStatusBanner = () => {
  const sessionDismissed = sessionStorage.getItem(
    'cinch-servicestatusbanner-dismissed',
  );
  const dismissedAtOnLoad = sessionDismissed
    ? parseISO(sessionDismissed)
    : null;
  const dispatch = useDispatch();
  const services = useSelector((store) => store.serviceStatus);
  const [dismissedAt, setDismissedAt] = useState(dismissedAtOnLoad);
  const [showDialog, setShowDialog] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const statuses = useMemo(() => {
    const items = Object.values(services);
    const res = { ok: [], info: [], performance: [], disruption: [] };
    for (const item of items) {
      res[Object.keys(res)[item.status]].push(item);
    }
    return res;
  }, [services]);

  const refresh = useCallback(
    async (fetchEvents = false) => {
      setRefreshing(true);
      await dispatch(fetchElliemaeServiceStatus(fetchEvents));
      setRefreshing(false);
    },
    [dispatch],
  );

  useEffect(() => {
    const delay =
      statuses.info.length ||
      statuses.performance.length ||
      statuses.disruption.length
        ? 3 * 60000
        : 10 * 60000; // 3 or 10 mins, statuscenter updates every 10 mins
    const timer = setTimeout(() => {
      refresh(false);
    }, delay);
    return () => clearTimeout(timer);
  }, [dispatch, refresh, statuses]);

  useEffect(() => {
    refresh(false);
  }, [dispatch, refresh]);

  const handleShowDialog = useCallback(() => {
    setShowDialog(true);
    refresh(true);
  }, [refresh]);

  const statusType = statuses.disruption.length
    ? 'disruption'
    : statuses.performance.length
      ? 'performance'
      : statuses.info.length
        ? 'info'
        : '';

  const getMessage = useCallback(
    (messageText, detailsBtnText) => {
      const showSingle = statuses[statusType]?.length === 1;
      return (
        <div className='message' onClick={handleShowDialog} role='button'>
          <span>{messageText}</span>
          {showSingle ? (
            <>
              <strong className='service-name'>
                [{statuses?.[statusType]?.[0]?.name}]
              </strong>
              <span className='service-message'>
                {statuses?.[statusType]?.[0]?.description}.
              </span>
            </>
          ) : (
            <strong className='service-name'>{detailsBtnText}</strong>
          )}
        </div>
      );
    },
    [handleShowDialog, statusType, statuses],
  );

  const dismiss = () => {
    const now = new Date();
    setDismissedAt(now);
    sessionStorage.setItem(
      'cinch-servicestatusbanner-dismissed',
      now.toISOString(),
    );
  };

  if (dismissedAt && dismissedAt > addHours(new Date(), -5)) return null;

  if (
    !statuses.info.length &&
    !statuses.performance.length &&
    !statuses.disruption.length
  ) {
    return null;
  }

  return (
    <div className='elliemae-status-banner' data-status={statusType}>
      <div className='banner-content'>
        {statusType === 'info' && (
          <>
            <Icon path={mdiInformation} />
            {getMessage('EllieMae service information:', 'Click for details')}
          </>
        )}
        {statusType === 'performance' && (
          <>
            <Icon path={mdiInformationOutline} />
            {getMessage(
              `EllieMae service performance issue${
                statuses?.performance?.length === 1 ? ':' : 's:'
              }`,
              'Click for more information',
            )}
          </>
        )}
        {statusType === 'disruption' && (
          <>
            <Icon path={mdiAlert} />
            {getMessage(
              `EllieMae service disruption${
                statuses?.disruption?.length === 1 ? ':' : 's:'
              }`,
              'Click for more information',
            )}
          </>
        )}
      </div>
      <Tooltip title='Dismiss' placement='left' arrow>
        <IconButton onClick={dismiss} className='btn-close'>
          <Icon path={mdiClose} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        className='elliemae-status-dialog'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className='flex-container' disableTypography>
          <Typography className='flex' component='h2' variant='h6'>
            EllieMae service status
          </Typography>
          <Tooltip title='Refresh now' arrow placement='left'>
            <IconButton
              onClick={() => !refreshing && refresh(true)}
              size='small'
            >
              {refreshing ? <LoadingIcon /> : <Icon path={mdiRefresh} />}
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <ul>
            {statuses.disruption.map((x, i) => (
              <Service key={i} {...x} />
            ))}
            {statuses.performance.map((x, i) => (
              <Service key={i} {...x} />
            ))}
            {statuses.info.map((x, i) => (
              <Service key={i} {...x} />
            ))}
            {statuses.ok.map((x, i) => (
              <Service key={i} {...x} />
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={() => setShowDialog(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Service = ({ name, description, status, updatedAt, events }) => {
  const iconPath = {
    0: mdiCheckCircle,
    1: mdiInformation,
    2: mdiAlertCircle,
    3: mdiAlert,
  }[status];
  const statusType = ['ok', 'info', 'performance', 'disruption'][status];

  return (
    <li className='flex-container' data-status={statusType}>
      <Icon path={iconPath} />
      <div className='info flex flex-container flex-vertical'>
        <div className='name flex-container'>
          <h3 className='flex'>{name}</h3>
          <span className='timestamp'>
            {format(parseISO(updatedAt), 'MMM dd h:mm a')}
          </span>
        </div>
        <p>{description}</p>
        {events.length > 0 && (
          <ol>
            {events.map((ev, i) => (
              <li key={i} className='flex-container'>
                <p className='flex'>{ev.description}</p>
                <span className='timestamp'>
                  {format(parseISO(ev.updatedAt), 'MMM dd h:mm a')}
                </span>
              </li>
            ))}
          </ol>
        )}
      </div>
    </li>
  );
};

export default EllieMaeStatusBanner;
