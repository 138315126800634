import React, {
  createContext,
  useState,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import PromptDialog from 'src/components/baseComponents/Dialog/PromptDialog';
import ConfirmationDialog from '../components/baseComponents/Dialog/ConfirmDialog';
const ConfirmationContext = createContext(Promise.reject);

export const ConfirmationProvider = ({ children }) => {
  const [confirmation, setConfirmation] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const awaitingRef = useRef();

  const openConfirmation = useCallback((options) => {
    setConfirmation(options);
    return new Promise((resolve, reject) => {
      awaitingRef.current = { resolve, reject };
    });
  }, []);

  const openPrompt = useCallback((options) => {
    setPrompt(options);
    return new Promise((resolve, reject) => {
      awaitingRef.current = { resolve, reject };
    });
  }, []);

  const handleCloseConfirmation = () => {
    if (awaitingRef.current) {
      awaitingRef.current.resolve(false);
    }
    setConfirmation(null);
  };

  const handleClosePrompt = () => {
    if (awaitingRef.current) {
      awaitingRef.current.resolve(undefined);
    }
    setPrompt(null);
  };

  const handleSubmitConfirmation = () => {
    if (awaitingRef.current) awaitingRef.current.resolve(true);
    setConfirmation(null);
  };

  const handleSubmitPrompt = (value) => {
    if (awaitingRef.current) awaitingRef.current.resolve(value);
    setPrompt(null);
  };

  const openers = useMemo(
    () => ({ prompt: openPrompt, confirm: openConfirmation }),
    [openPrompt, openConfirmation],
  );

  return (
    <>
      <ConfirmationContext.Provider value={openers}>
        {children}
      </ConfirmationContext.Provider>
      <ConfirmationDialog
        open={Boolean(confirmation)}
        onSubmit={handleSubmitConfirmation}
        onClose={handleCloseConfirmation}
        {...confirmation}
      />
      <PromptDialog
        open={Boolean(prompt)}
        onSubmit={handleSubmitPrompt}
        onClose={handleClosePrompt}
        {...prompt}
      />
    </>
  );
};

/**
 * @returns {(options: {
 *   variant: 'confirm' | 'alert',
 *   title?: string,
 *   description?: string,
 *   buttons?: {
 *     submit?: string,
 *     cancel?: string
 *   },
 *   onClick?: {
 *     submit?: () => Promise<void>
 *   }
 * }) => Promise<boolean>}
 */
export const useConfirmation = () => useContext(ConfirmationContext).confirm;

/**
 * @returns {(options: {
 *   title?: string,
 *   description?: string,
 *   buttons?: {
 *     submit?: string,
 *     cancel?: string
 *   },
 *   cancellable?: boolean,
 *   required?: boolean,
 *   defaultValue?: any,
 *   InputComponent: import('react').FC
 * })}
 */
export const usePrompt = () => useContext(ConfirmationContext).prompt;

export const withConfirmation = (Component) => (props) => {
  return (
    <ConfirmationContext.Consumer>
      {({ confirm }) => <Component {...props} confirm={confirm} />}
    </ConfirmationContext.Consumer>
  );
};

export const withPrompt = (Component) => (props) => {
  return (
    <ConfirmationContext.Consumer>
      {({ prompt }) => <Component {...props} prompt={prompt} />}
    </ConfirmationContext.Consumer>
  );
};
