import { useMultiFamilyLoanCalculationsLtvc } from './use-loan-calculations-ltvc';
import { useMultiFamilyLoanCalculationsAmortization } from './use-loan-calculations-amortization';
import { useMultiFamilyLoanCalculationsIncome } from './use-loan-calculations-income';
import { useMultiFamilyLoanCalculationsCosts } from './use-loan-calculations-costs';

export const useMultiFamilyLoanCalculations = (formMethods, debounceDelay) => {
  useMultiFamilyLoanCalculationsCosts(formMethods);
  useMultiFamilyLoanCalculationsLtvc(formMethods);
  useMultiFamilyLoanCalculationsIncome(formMethods);
  const amortizationSchedule = useMultiFamilyLoanCalculationsAmortization(
    formMethods,
    debounceDelay ?? 1000,
  );
  return {
    amortizationSchedule: amortizationSchedule,
  };
};
