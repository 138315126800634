import {
  differenceInDays,
  differenceInSeconds,
  format,
  formatDistanceStrict,
  isSameDay,
  parseISO,
  startOfDay,
} from 'date-fns';
import { DATE_FORMAT_STRING } from 'src/util/constant';

// https://stackoverflow.com/a/20732091/3605190
export const formatBytes = (bytes) => {
  const i = !bytes ? 0 : Math.floor(Math.log(+bytes) / Math.log(1024));
  return (
    (+bytes / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]
  );
};

export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const numberFormatter = new Intl.NumberFormat('en-US');

export const moneyFormatterNoCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const getFormattedTimeAgo = (isoString) => {
  if (!isoString) return 'Invalid date';
  const createdDate = parseISO(isoString);
  const now = new Date();
  const daysAgo = Math.abs(
    differenceInDays(startOfDay(now), startOfDay(createdDate)),
  );
  const sameDay = isSameDay(now, createdDate);
  if (sameDay) {
    const secondsAgo = Math.abs(differenceInSeconds(now, createdDate));
    if (secondsAgo === 0) {
      return 'Just now';
    } else if (secondsAgo < 60) {
      return `${secondsAgo} second${secondsAgo === 1 ? '' : 's'} ago`;
    } else {
      return formatDistanceStrict(now, createdDate) + ' ago';
    }
  } else if (daysAgo < 2) {
    return 'Yesterday at ' + format(createdDate, 'h:mm a');
  } else {
    return format(createdDate, DATE_FORMAT_STRING);
  }
};

const exceptionsToYIES = ['day', 'way'];

export const pluralize = (singularString, count, pluralString = null) => {
  if (pluralString && count !== 1) {
    return pluralString;
  }
  if (count !== 1) {
    if (
      singularString.toLowerCase().endsWith('y') &&
      !exceptionsToYIES.includes(singularString.toLowerCase())
    ) {
      return singularString + 'ies';
    }
    return singularString + 's';
  }
  return singularString;
};

export const valueWithinFnfTermRangeOrDefault = (value) => {
  const defaultValue = 12;
  return Number.parseInt(value) < 12 || Number.parseInt(value) > 24
    ? defaultValue
    : value;
};

export const capitalize = (str) => {
  if (!str) return '';
  return str[0].toUpperCase() + str.substring(1);
};

export const formatAddress = ({ street, city, state, zip }) => {
  // street, city, state zip
  // street, state zip
  // street, zip
  let res = street;
  if (city) res += `, ${city},`;
  if (state && !city) res += ',';
  if (state) res += ` ${state}`;
  if (zip && !(city || state)) res += ',';
  if (zip) res += ` ${zip}`;
  return res;
};

export const formatDateOrReturnEmptyString = (
  date,
  dateFormat = 'MM/dd/yyyy',
) => {
  if (!date) {
    return '';
  }
  const newDateObject = new Date(date);
  if (newDateObject.toDateString() === 'Invalid Date') {
    return '';
  }
  return format(newDateObject, dateFormat);
};

export const formatMoneyOrReturnEmptyString = (value) => {
  return value || value === 0 ? moneyFormatter.format(value) : '';
};

export const formatNumberOrReturnEmptyString = (
  value,
  minimumFractionDigits,
) => {
  return value || value === 0
    ? new Intl.NumberFormat('en-US', {
      minimumFractionDigits: minimumFractionDigits,
    }).format(value)
    : '';
};
