import { compareDesc, parseISO } from 'date-fns';

const defaultState = [];

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_notifications': {
      const items = [...state];
      for (const item of data) {
        const matchIndex = state.findIndex(
          (x) => x.id.toString() === item.id.toString(),
        );
        if (matchIndex > -1) {
          items.splice(matchIndex, 1, {
            ...items[matchIndex],
            ...item,
          });
        } else {
          items.push(item);
        }
      }
      return items.sort((a, b) =>
        compareDesc(parseISO(a.createdAt), parseISO(b.createdAt)),
      );
    }
    case 'update_notification': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.id.toString(),
      );
      if (matchIndex === -1) return state;
      const items = [...state];

      items.splice(matchIndex, 1, {
        ...items[matchIndex],
        ...data,
      });
      return items;
    }
    case 'remove_notification': {
      const matchIndex = state.findIndex(
        (x) => x.id.toString() === data.toString(),
      );
      if (matchIndex === -1) return state;
      const items = [...state];
      items.splice(matchIndex, 1);
      return items;
    }
    case 'remove_all_notifications':
      return [];
    default:
      return state;
  }
};

export default reduce;
