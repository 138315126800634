import { apiAxios } from 'src/util';

export const createMaventRuleExclusion = async (data) => {
  const res = await apiAxios.post('mavent-rule-exclusions', data);
  return res.data;
};

export const updateMaventRuleExclusion = async (id, data) => {
  const res = await apiAxios.patch(`mavent-rule-exclusions/${id}`, data);
  return res.data;
};

export const deleteMaventRuleExclusion = async (id) => {
  await apiAxios.delete(`mavent-rule-exclusions/${id}`);
  return id;
};
export const fetchMaventRuleExclusion = async (id) => {
  const res = await apiAxios.get(`mavent-rule-exclusions/${id}`);
  return res.data;
};
export const fetchMaventRuleExclusions = async () => {
  const res = await apiAxios.get('mavent-rule-exclusions');
  return res.data;
};
