import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import './mark-as-read.scss';

const MarkAsReadButton = ({ id, read, onChange }) => {
  return (
    <Tooltip
      title={read ? 'Mark as unread' : 'Mark as read'}
      arrow
      placement='bottom'
    >
      <div className='mark-as-read-button' onClick={(e) => e.stopPropagation()}>
        <input
          type='checkbox'
          id={`mark-as-read-${id}`}
          defaultChecked={!read}
          onChange={(e) => onChange(e.target.checked)}
        />
        <label htmlFor={`mark-as-read-${id}`} />
      </div>
    </Tooltip>
  );
};

MarkAsReadButton.defaultProps = {
  onChange: () => null,
  read: false,
};

MarkAsReadButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  read: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default MarkAsReadButton;
