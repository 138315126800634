/* eslint-disable max-statements */
import { apiAxios } from 'src/util';
import { SET_SHOW_LONG_PROCESS, LOADING } from './types';

const CHANGE_OF_CIRCUMSTANCE = 'ChangeOfCircumstance';
const INITIAL = 'Initial';
const CLOSING = 'Closing';

export const orderDisclosures =
  (
    guid,
    pairIndexes,
    loanNumber,
    residentialOrCommercial,
    borrowerName,
    addressState,
    userEmail,
  ) =>
    async (dispatch) => {
      dispatch({
        type: SET_SHOW_LONG_PROCESS,
        payload: true,
      });

      const disclosureOrder = {
        loanGuid: guid,
        applicationIndexes: pairIndexes,
        loanNumber,
        residentialOrCommercial,
        borrowerName,
        loEmail: userEmail,
        state: addressState,
        disclosureType: INITIAL,
      };

      await apiAxios.post(
      `/disclosures/${guid}/${INITIAL}/order`,
      disclosureOrder,
      );

      dispatch({
        type: LOADING,
        payload: false,
      });
    };

export const orderCocDisclosures =
  (
    loanGuid,
    pairIndexes,
    loanNumber,
    residentialOrCommercial,
    borrowerName,
    addressState,
    loanType,
    userEmail,
  ) =>
    async () => {
      const disclosureOrder = {
        loanGuid,
        applicationIndexes: pairIndexes,
        loanNumber,
        residentialOrCommercial,
        borrowerName,
        loEmail: userEmail,
        state: addressState,
        loanType,
        disclosureType: CHANGE_OF_CIRCUMSTANCE,
      };

      await apiAxios.post(
      `/disclosures/${loanGuid}/${CHANGE_OF_CIRCUMSTANCE}/order`,
      disclosureOrder,
      );
    };

export const orderClosingDisclosures =
  (
    guid,
    pairIndexes,
    loanNumber,
    residentialOrCommercial,
    borrowerName,
    addressState,
    userEmail,
  ) =>
    async (dispatch) => {
      dispatch({
        type: SET_SHOW_LONG_PROCESS,
        payload: true,
      });

      const disclosureOrder = {
        loanGuid: guid,
        applicationIndexes: pairIndexes,
        loanNumber,
        residentialOrCommercial,
        borrowerName,
        loEmail: userEmail,
        state: addressState,
        disclosureType: CLOSING,
      };

      await apiAxios.post(
      `/disclosures/${guid}/${CLOSING}/order`,
      disclosureOrder,
      );

      dispatch({
        type: LOADING,
        payload: false,
      });
    };

export const runInitialDisclosurePrecheck = async (loanGuid) => {
  const res = await apiAxios.get(`/disclosures/${loanGuid}/initial/precheck`);
  return res.data;
};

export const runCOCDisclosurePrecheck = async (loanGuid) => {
  const res = await apiAxios.get(
    `/disclosures/${loanGuid}/changeofcircumstance/precheck`,
  );
  return res.data;
};

export const runClosingDisclosurePrecheck = async (loanGuid) => {
  const res = await apiAxios.get(`/disclosures/${loanGuid}/closing/precheck`);
  return res.data;
};
