import { Tooltip } from '@material-ui/core';
import {
  mdiAlert,
  mdiAlertCircle,
  mdiCheck,
  mdiFileDocument,
  mdiForum,
  mdiHistory,
  mdiInformation,
  mdiTimerOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { format, isPast, parseISO } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dismissNotification, fetchNotifications } from 'src/actions';
import Empty from 'src/components/baseComponents/Empty';
import Loading from 'src/components/baseComponents/Loading';
import { VirtualList } from 'src/components/baseComponents/VirtualList';
import Expandable from 'src/components/loanOfficer/NotificationsPopover/Expandable';
import MarkAsReadButton from 'src/components/loanOfficer/NotificationsPopover/MarkAsRead';
import Steps from 'src/components/loanOfficer/NotificationsManager/Steps';
import { useNextCursor, useScrollbarWidth } from 'src/hooks';
import { useNotifications } from 'src/hooks/use-notifications';
import { getFormattedTimeAgo } from 'src/util';

const NotificationsList = ({ onClose, onHeightChanged }) => {
  const dispatch = useDispatch();
  const notifications = useNotifications({ includeDismissed: false });
  const nextCursor = useNextCursor(`notifications-${false}`);
  const [loading, setLoading] = useState(false);
  const renderNotificationRow = useCallback(
    (index, data) => {
      return <Notification {...data} onClose={onClose} />;
    },
    [onClose],
  );

  const loadMoreResults = useCallback(async () => {
    try {
      if (!nextCursor) return;
      setLoading(true);
      await dispatch(
        fetchNotifications({
          includeDismissed: false,
          cursor: nextCursor,
        }),
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [dispatch, nextCursor]);

  return (
    <div className='notifications-list flex flex-container'>
      {notifications.length === 0 ? (
        <Empty
          text='You have no new notifications'
          textSize={14}
          iconSize={46}
        />
      ) : (
        <VirtualList
          data={notifications}
          renderRow={renderNotificationRow}
          onLoadMoreItems={loadMoreResults}
          hasMoreRows={Boolean(nextCursor)}
          loadingMoreRows={loading}
          onHeightChanged={onHeightChanged}
          Components={{
            Footer: nextCursor && (
              <Loading label='Loading more notifications' dots hideCircle />
            ),
          }}
        />
      )}
    </div>
  );
};
const Notification = ({
  id,
  createdAt,
  expiresAt,
  title,
  description,
  notificationType,
  severity,
  longProcessSteps,
  dismissed,
  unseen,
  url,
  onClose,
}) => {
  const dispatch = useDispatch();
  const scrollbarSize = useScrollbarWidth();
  const history = useHistory();
  const displayTime = getFormattedTimeAgo(createdAt);
  const exactTime = format(parseISO(createdAt), "MMM dd, yyyy 'at' h:mm a");
  const status = useMemo(() => getNotificationStatus(severity), [severity]);
  const icon = useMemo(
    () => getNotificationIcon(notificationType),
    [notificationType],
  );

  const handleDismiss = async () => {
    await dispatch(dismissNotification(id, true));
  };

  const expiryDate = expiresAt ? parseISO(expiresAt) : null;
  const isExpired = expiresAt && isPast(expiryDate);

  return (
    <div
      className={clsx(
        'notification flex-container',
        status.name,
        unseen && 'unseen',
        isExpired && 'expired',
        url?.length && 'has-resource-link',
      )}
      style={{ width: 440 - scrollbarSize }}
      onClick={
        url
          ? () => {
            history.push(url);
            onClose();
          }
          : null
      }
    >
      <div className='icon-container'>
        {icon || status.icon}
        <MarkAsReadButton id={id} read={dismissed} onChange={handleDismiss} />
      </div>
      <div className='notification-content flex'>
        <h2 className='flex-container'>
          <span className='flex' dangerouslySetInnerHTML={{ __html: title }} />
          {expiryDate && !isExpired && (
            <span className='expiry future'>
              <Icon path={mdiTimerOutline} />
              Expires {format(expiryDate, 'MM/dd/yyyy h:mm a')}
            </span>
          )}
          {expiryDate && isExpired && (
            <span className='expiry past'>
              <Icon path={mdiHistory} />
              Expired {format(expiryDate, 'MM/dd/yyyy h:mm a')}
            </span>
          )}
        </h2>
        <Expandable maxLength={100}>{description}</Expandable>
        <Tooltip title={exactTime} arrow placement='right'>
          <span className='timestamp'>
            <span className='time'>{displayTime}</span>
          </span>
        </Tooltip>

        {longProcessSteps?.length > 0 && severity !== 3 && (
          <Steps steps={longProcessSteps} />
        )}
        {longProcessSteps?.length > 0 && severity === 3 && (
          <div className='steps'>
            This disclosure ordering process has been cancelled for this loan,
            likely due to disclosures being ordered again.
          </div>
        )}
      </div>
    </div>
  );
};

export const getNotificationIcon = (type) => {
  return (
    {
      TermSheetDraft: <Icon path={mdiFileDocument} />,
      NewMessage: <Icon path={mdiForum} />,
    }[type] || null
  );
};

const statusData = [
  {
    icon: null,
    name: 'no-status',
  },
  {
    icon: <Icon path={mdiInformation} />,
    name: 'info',
  },
  {
    icon: <Icon path={mdiCheck} />,
    name: 'success',
  },
  {
    icon: <Icon path={mdiAlertCircle} />,
    name: 'warning',
  },
  {
    icon: <Icon path={mdiAlert} />,
    name: 'error',
  },
];

export const getNotificationStatus = (num) => {
  return (
    statusData[num] || {
      icon: null,
      name: 'unknown-status',
    }
  );
};

NotificationsList.defaultProps = {
  onClose: () => null,
  onHeightChanged: () => null,
};

export default NotificationsList;
