import clsx from 'clsx';
import React, { FC } from 'react';
import { EncompassApplyPlanCodeResponseConflict } from 'src/types';
import styles from './index.module.scss';

interface PlanCodeConflictsProps {
  messages: EncompassApplyPlanCodeResponseConflict[];
}

export const PlanCodeConflicts: FC<PlanCodeConflictsProps> = ({ messages }) => {
  return (
    <ul className={styles.messagesList}>
      {messages.map((message) => (
        <li
          className={clsx(
            styles.message,
            styles.planCodeConflictMessage,
            'flex-container',
          )}
        >
          <div className={clsx(styles.descriptionContainer, 'flex')}>
            <span className={styles.description}>{message.description}</span>
            <span className={styles.fieldId}>{message.encompassFieldId}</span>
          </div>
          <div className={clsx(styles.fieldValues, 'flex flex-container')}>
            <div className={clsx(styles.pair, 'flex')}>
              <span className={styles.label}>Expected:</span>
              <span className={styles.value}>{message.planValue}</span>
            </div>
            <div className={clsx(styles.pair, 'flex')}>
              <span className={styles.label}>Actual:</span>
              <span className={styles.value}>{message.encompassValue}</span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
