import React, { useMemo } from 'react';
import { Divider, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import styles from './index.module.scss';
import { ROLE_NAMES } from 'src/util';
import {
  useCurrentLoanOfficer,
  useCurrentLoanOfficerDepartments,
  useUserRoles,
} from 'src/hooks';
import Icon from '@mdi/react';
import {
  mdiAccount,
  mdiAccountMultiple,
  mdiBellAlert,
  mdiHomeEdit,
  mdiInformationOutline,
  mdiLogoutVariant,
  mdiShieldOutline,
  mdiViewList,
} from '@mdi/js';
import { AvatarContainer } from 'src/components/baseComponents/Header/UserMenu/AvatarContainer';
import clsx from 'clsx';

const UserMenu = ({ anchorEl, onClose }) => {
  const user = useCurrentLoanOfficer();
  const menuItemGroups = useUserMenuItemGroups();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: -8,
        horizontal: 'center',
      }}
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      onClose={onClose}
      keepMounted
      PaperProps={{
        className: styles.userMenu,
      }}
    >
      <header className='flex-container'>
        <AvatarContainer user={user} size={80} hideWebsocketStatus />
      </header>
      <Divider className={styles.divider} />
      {menuItemGroups.map((group, i, a) => {
        const items = group.map(({ name, to, icon, red, forAdmin }) => (
          <MenuItem
            onClick={onClose}
            className={clsx({
              [styles.adminListItem]: forAdmin,
              [styles.redListItem]: red,
            })}
            component={RouterLink}
            key={to}
            to={to}
          >
            <ListItemIcon>
              <Icon path={icon} />
            </ListItemIcon>
            {name}
          </MenuItem>
        ));
        if (i < a.length - 1)
          items.push(
            <Divider className={styles.divider} key={`divider-${i}`} />,
          );
        return items;
      })}
    </Menu>
  );
};

export default UserMenu;

export const useUserMenuItemGroups = () => {
  const departments = useCurrentLoanOfficerDepartments();
  const roles = useUserRoles();

  const canManageDepartments = useMemo(() => {
    return (
      roles.includes(ROLE_NAMES.ADMINISTRATOR) ||
      departments.some((dep) => dep.isManager)
    );
  }, [departments, roles]);

  return useMemo(
    () => getUserMenuItemGroups({ canManageDepartments, roles }),
    [canManageDepartments, roles],
  );
};

const getUserMenuItemGroups = ({ canManageDepartments, roles }) => {
  return [
    [
      {
        to: '/admin',
        name: 'Admin Dashboard',
        icon: mdiShieldOutline,
        forAdmin: true,
        exact: true,
        roles: [
          ROLE_NAMES.ADMINISTRATOR,
          ROLE_NAMES.ACCOUNTING,
          ROLE_NAMES.RATESMANAGER,
          ROLE_NAMES.COMPLIANCEMANAGER,
          ROLE_NAMES.APPRAISALMANAGER,
          ROLE_NAMES.DISCLOSUREDESKMANAGER,
        ],
      },
    ],
    [
      {
        to: '/profile',
        name: 'Profile',
        icon: mdiAccount,
      },
      {
        to: '/dashboard',
        name: 'Dashboard',
        icon: mdiViewList,
      },
      {
        to: '/reports/disclosures',
        name: 'Disclosure Reports',
        icon: mdiInformationOutline,
      },
      {
        to: '/LOAlerts',
        name: 'Alerts',
        icon: mdiBellAlert,
      },
    ],
    [
      {
        to: '/compliance/addresses',
        name: 'Address Settings',
        icon: mdiHomeEdit,
        roles: [ROLE_NAMES.ADMINISTRATOR, ROLE_NAMES.COMPLIANCEMANAGER],
      },
      canManageDepartments && {
        to: '/departments',
        name: 'Departments',
        icon: mdiAccountMultiple,
      },
    ],
    [
      {
        to: '/logout',
        name: 'Log Out',
        icon: mdiLogoutVariant,
        red: true,
      },
    ],
  ]
    .map((g) =>
      g.filter((x) => {
        if (!x) return false;
        if (!x.roles?.length) return true;
        return x.roles.some((role) => roles.includes(role));
      }),
    )
    .filter((g) => g.length);
};
