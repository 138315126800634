import { apiAxios } from 'src/util';

export const updateLoanOfficerAddress = (
  loanOfficerId,
  address,
) => async dispatch => {
  const res = await apiAxios.patch(
    `loan-officers/${loanOfficerId}/address`,
    address,
  );
  dispatch({
    type: 'admin_update_loan_officer',
    data: {
      id: loanOfficerId,
      address: res.data?.address,
      addressId: res.data?.addressId,
    },
  });
  dispatch({
    type: 'update_loan_officer_addresses',
    data: {
      address: res.data?.address,
      addressId: res.data?.addressId,
    },
  });
};

export const createLoanOfficerAddress = (
  loanOfficerId,
  address,
) => async dispatch => {
  const res = await apiAxios.post(
    `loan-officers/${loanOfficerId}/address`,
    address,
  );
  dispatch({
    type: 'add_address',
    data: {
      id: res.data.addressId,
      ...res.data.address,
    },
  });
  dispatch({
    type: 'admin_update_loan_officer',
    data: {
      id: loanOfficerId,
      address: res.data?.address,
      addressId: res.data?.addressId,
    },
  });
};

export const unlinkLoanOfficerAddress = loanOfficerId => async dispatch => {
  await apiAxios.delete(`loan-officers/${loanOfficerId}/address`);
  dispatch({
    type: 'admin_update_loan_officer',
    data: {
      id: loanOfficerId,
      address: null,
      addressId: null,
    },
  });
};
