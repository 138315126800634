const defaultState = {};

const reportingDashboardActiveFiltersReducer = (
  state = defaultState,
  { type, data },
) => {
  switch (type) {
    case 'add_active_reporting_dashboard_filter': {
      if (!data?.filterId) return state;
      const filterId = data.filterId;
      const needsPrevious = data.needsPreviousDateRange;
      return {
        ...state,
        [filterId]: {
          ...state[filterId],
          count: (state[filterId]?.count || 0) + 1,
          needsPrevious:
            (state[filterId]?.needsPrevious || 0) + (needsPrevious ? 1 : 0),
        },
      };
    }
    case 'remove_active_reporting_dashboard_filter': {
      if (!data?.filterId) return state;
      const filterId = data.filterId;
      const needsPrevious = data.needsPreviousDateRange;
      return {
        ...state,
        [filterId]: {
          ...state[filterId],
          count: (state[filterId]?.count || 0) - 1,
          needsPrevious:
            (state[filterId]?.needsPrevious || 0) - (needsPrevious ? 1 : 0),
        },
      };
    }
    default:
      return state;
  }
};

export default reportingDashboardActiveFiltersReducer;
