const defaultState = {};

const reportingDashboardTemplateFilterDataReducer = (
  state = defaultState,
  { type, data },
) => {
  switch (type) {
    case 'set_reporting_dashboard_template_filter_data_loading': {
      return {
        ...state,
        [data.filterId]: {
          ...state[data.filterId],
          loading: data.loading,
        },
      };
    }
    case 'set_reporting_dashboard_template_filter_data': {
      return {
        ...state,
        [data.filterId]: data.error
          ? { error: data.error, loading: false }
          : {
            results: data.loanPipelineResults,
            prevResults: data.previousDatePeriodLoanPipelineResults,
            loading: false,
          },
      };
    }
    default:
      return state;
  }
};

export default reportingDashboardTemplateFilterDataReducer;
