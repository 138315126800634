import { apiAxios } from 'src/util';

export const smartsheetGetStatus = async (rowId) => {
  const res = await apiAxios.get(`smartsheet/${rowId}/info`);
  return res.data;
};

export const smartsheetGetStatusWithGuid = async (loanGuid) => {
  const res = await apiAxios.get(`smartsheet/loans/${loanGuid}/info`);
  return res.data;
};

export const smartsheetGetAttachments = async (rowId) => {
  const res = await apiAxios.get(`smartsheet/${rowId}/attachments`);
  return res.data;
};

export const smartsheetOrder = async (loanGuid) => {
  const res = await apiAxios.get(`smartsheet/loans/${loanGuid}`);
  return res.data;
};
