import { InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLoanPipelineCanonicalFields } from 'src/actions';
import { LoadingIcon } from 'src/components/baseComponents/Loading';
import { useDebounce, useLoanPipelineCanonicalFields } from 'src/hooks';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

export const CanonicalFieldInput = ({
  value,
  disabled,
  allowedPrefixes = fieldPrefixes,
  onFocus = () => null,
  onBlur = () => null,
  onChange = () => null,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fields = useLoanPipelineCanonicalFields();
  const fieldsJson = JSON.stringify(fields);
  const debouncedValue = useDebounce(value, 300);
  const fieldMatch = useMemo(() => {
    return fields[value];
  }, [fields, value]);

  const hasValidPrefix = useMemo(() => {
    const trimmed = value?.trim()?.split('.')?.[0];
    return !trimmed || (trimmed?.length && allowedPrefixes.includes(trimmed));
  }, [allowedPrefixes, value]);

  useEffect(() => {
    const abortController = new AbortController();
    const fields = JSON.parse(fieldsJson);
    (async () => {
      if (
        touched &&
        debouncedValue?.length &&
        hasValidPrefix &&
        !(fields[debouncedValue]?.name || fields[debouncedValue]?.error)
      ) {
        try {
          setLoading(true);
          await dispatch(
            fetchLoanPipelineCanonicalFields([debouncedValue], abortController),
          );
        } finally {
          setLoading(false);
        }
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [debouncedValue, dispatch, fieldsJson, hasValidPrefix, touched]);

  return (
    <Tooltip
      open={Boolean(focused && (fieldMatch?.name || !hasValidPrefix))}
      title={
        fieldMatch?.name ??
        (!hasValidPrefix
          ? `Value must start with ${allowedPrefixes.join(', ')}`
          : null) ??
        ''
      }
      arrow
      placement='top-start'
    >
      <TextField
        variant='outlined'
        size='small'
        {...props}
        className={clsx(styles.canonicalFieldInput, props.className)}
        error={Boolean(fieldMatch?.error || !hasValidPrefix || props.error)}
        helperText={fieldMatch?.error ?? props.helperText}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          const value = e.target.value?.trim() ?? '';
          if (value?.length) {
            const hasDot = value.endsWith('.');
            const parts = value.split('.');
            const isField = parts[0].toLowerCase() === 'fields';
            const isCustomField =
              parts[1]?.toLowerCase() === 'cx' ||
              parts[1]?.toLowerCase()?.startsWith('cust');

            let rest = parts.slice(1).join('.');
            if (isField && isCustomField) rest = rest.toUpperCase();
            onChange({
              ...e,
              target: {
                ...e.target,
                value: `${parts[0][0].toUpperCase()}${parts[0].slice(1)}${
                  rest || hasDot ? '.' + rest : ''
                }`,
              },
            });
          } else {
            onChange(e);
          }
        }}
        onFocus={() => {
          setFocused(true);
          setTouched(true);
          onFocus();
        }}
        onBlur={() => {
          setFocused(false);
          onBlur();
        }}
        InputProps={{
          ...props.InputProps,
          endAdornment: touched && (loading || value?.trim()?.length > 0) && (
            <InputAdornment position='end'>
              {loading ? (
                <LoadingIcon />
              ) : fieldMatch?.name ? (
                <Icon path={mdiCheck} className={styles.valid} />
              ) : (
                <Icon path={mdiClose} className={styles.invalid} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Tooltip>
  );
};

const fieldPrefixes = [
  'Fields',
  'Alert',
  'LoanProperties',
  'Loan',
  'LockUser',
  'LockStatus',
  'UserActivity',
  'Messages',
  'Alerts',
  'LoanFolder',
  'NextMilestone',
  'CurrentLoanAssociate',
];

CanonicalFieldInput.propTypes = {
  allowedPrefixes: PropTypes.arrayOf(PropTypes.oneOf(fieldPrefixes)),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
