import { BorrowerType } from 'src/types';
import { AddressUnitDesignatorType } from 'src/types/encompass/loan/EncompassProperty';

export enum PropertyUsageType {
  PrimaryResidence = 'PrimaryResidence',
  SecondHome = 'SecondHome',
  Investor = 'Investor',
}

export enum DispositionStatusType {
  RetainForRental = 'RetainForRental',
  Sold = 'Sold',
  PendingSale = 'PendingSale',
}

export enum GsePropertyType {
  Condominium = 'Condominium',
  Townhouse = 'Townhouse',
  SingleFamily = 'SingleFamily',
  MultifamilyMoreThanFourUnits = 'MultifamilyMoreThanFourUnits',
  ManufacturedMobileHome = 'ManufacturedMobileHome',
  TwoToFourUnitProperty = 'TwoToFourUnitProperty',
}

export interface EncompassReoProperty {
  id: string;
  owner?: BorrowerType;
  urla2020StreetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  gsePropertyType?: GsePropertyType;
  unitType?: AddressUnitDesignatorType;
  unitNumber?: string;
  propertyUsageType?: PropertyUsageType;
  dispositionStatusType?: DispositionStatusType;
  rentalIncomeGrossAmount?: number;
  maintenanceExpenseAmount?: number;
  marketValueAmount?: number;
}
