import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Period,
  Months,
  MonthDays,
  WeekDays,
  Hours,
  Minutes,
} from 'src/components/baseComponents/Inputs/CronPicker/fields';
import cronParser from 'cron-parser';
import styles from './index.module.scss';
import clsx from 'clsx';
import { format } from 'date-fns';

const now = new Date();
const timezone = 'UTC';

// eslint-disable-next-line complexity
export const CronPicker = ({
  disabled,
  value = '',
  onChange = () => null,
  error = null,
}) => {
  const valueRef = useRef(null);
  const [innerError, setInnerError] = useState(null);
  const [next, setNext] = useState(null);
  const formMethods = useForm({
    defaultValues: {
      period: 'day',
      months: [],
      monthDays: [],
      weekDays: [],
      hours: [],
      minutes: [],
    },
  });

  const period = formMethods.watch('period');
  const months = formMethods.watch('months');
  const monthDays = formMethods.watch('monthDays');
  const weekDays = formMethods.watch('weekDays');
  const hours = formMethods.watch('hours');
  const minutes = formMethods.watch('minutes');

  useEffect(() => {
    try {
      if (value !== valueRef.current) {
        const cron = cronParser.parseExpression(value, {
          currentDate: now,
          tz: timezone,
        });
        const { month, dayOfMonth, dayOfWeek, hour, minute } =
          getTzOffsetFields(cron.fields, 'fromUTC');
        const daysOfWeekWithoutDuplicateSunday = [...dayOfWeek].filter(
          (x) => x !== 7,
        );
        formMethods.reset({
          period: getPeriodFromCronFields(cron.fields),
          months: month.length === 12 ? [] : month,
          monthDays: dayOfMonth.length === 31 ? [] : dayOfMonth,
          weekDays:
            daysOfWeekWithoutDuplicateSunday.length === 7
              ? []
              : daysOfWeekWithoutDuplicateSunday,
          hours: hour.length === 24 ? [] : hour,
          minutes: minute.length === 60 ? [] : minute,
        });
        valueRef.current = value;
      }
    } catch (e) {
      console.warn(e);
      setInnerError('Invalid cron expression');
    }
  }, [formMethods, value]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (
      period ||
      months?.length ||
      monthDays?.length ||
      weekDays?.length ||
      hours?.length ||
      minutes?.length
    ) {
      try {
        let fields = getFieldsFromPeriod(period, {
          months,
          monthDays,
          weekDays,
          hours,
          minutes,
        });
        fields = getTzOffsetFields(fields, 'toUTC');
        if (
          fields.dayOfWeek.length === 7 &&
          fields.dayOfWeek.includes(0) &&
          fields.dayOfWeek.includes(6)
        ) {
          fields.dayOfWeek.push(7);
        }
        const expression = cronParser.fieldsToExpression(fields, {
          currentDate: now,
          tz: timezone,
        });
        setNext(expression.next().toDate());
        const cronString = expression.stringify();
        valueRef.current = cronString;
        onChange({ target: { value: cronString } }, cronString);
      } catch (e) {
        console.warn(e);
        setInnerError('Could not rebuild cron expression');
      }
    }
  }, [hours, minutes, monthDays, months, onChange, period, weekDays]);

  return (
    <FormProvider {...formMethods}>
      <div className={styles.cronPickerContainer}>
        <div className={styles.inputs}>
          <span className={styles.text}>Every</span>
          <Period disabled={disabled} />
          {period === 'year' && (
            <>
              <span className={styles.text}>in</span>
              <Months disabled={disabled} />
            </>
          )}
          {(period === 'year' || period === 'month') && (
            <>
              <span className={styles.text}>on</span>
              <MonthDays disabled={disabled} />
            </>
          )}
          {(period === 'year' || period === 'month' || period === 'week') && (
            <>
              <span className={styles.text}>
                {period === 'week' ? 'on' : 'and'}
              </span>
              <WeekDays disabled={disabled} />
            </>
          )}
          {period !== 'minute' && period !== 'hour' && (
            <>
              <span className={styles.text}>at</span>
              <Hours disabled={disabled} />
              <span className={styles.text}>:</span>
            </>
          )}
          {period !== 'minute' && (
            <>
              {period === 'hour' && <span className={styles.text}>at</span>}
              <Minutes disabled={disabled} />
              {period === 'hour' && (
                <span className={styles.text}>minute(s)</span>
              )}
            </>
          )}
        </div>
        {(error || innerError || next) && (
          <span
            className={clsx(
              (error || innerError) && styles.error,
              styles.helperText,
            )}
          >
            {error ||
              innerError ||
              `Next occurence at ${
                next ? format(next, "M/d/yyyy 'at' h:mm a") : '...'
              }`}
          </span>
        )}
      </div>
    </FormProvider>
  );
};

const getPeriodFromCronFields = ({
  month,
  dayOfMonth,
  dayOfWeek,
  hour,
  minute,
}) => {
  if (month.length !== 12) {
    return 'year';
  } else if (dayOfMonth.length !== 31) {
    return 'month';
  } else if (dayOfWeek.length !== 8) {
    // sunday is 0 and 7
    return 'week';
  } else if (hour.length !== 24) {
    return 'day';
  } else if (minute.length !== 60) {
    return 'hour';
  }
  return 'minute';
};

const getFieldsFromPeriod = (
  period,
  { months, monthDays, weekDays, hours, minutes },
) => {
  return {
    month:
      period === 'year' && months.length
        ? months.sort((a, b) => a - b)
        : new Array(12).fill(0).map((_, i) => i + 1),
    dayOfMonth:
      (period === 'year' || period === 'month') && monthDays.length
        ? monthDays.sort((a, b) => a - b)
        : new Array(31).fill(0).map((_, i) => i + 1),
    dayOfWeek:
      (period === 'year' || period === 'month' || period === 'week') &&
      weekDays.length
        ? weekDays.sort((a, b) => a - b)
        : new Array(7).fill(0).map((_, i) => i),
    hour:
      period !== 'minute' && period !== 'hour' && hours.length
        ? hours.sort((a, b) => a - b)
        : new Array(24).fill(0).map((_, i) => i),
    minute:
      period !== 'minute' && minutes.length
        ? minutes.sort((a, b) => a - b)
        : new Array(60).fill(0).map((_, i) => i),
    second: [0],
  };
};

const getTzOffsetFields = (
  { hour, minute, ...rest },
  direction = 'fromUTC',
) => {
  let offsetMinutes = new Date().getTimezoneOffset();
  let offsetHours = Math.floor(offsetMinutes / 60);
  offsetMinutes %= 60;

  if (direction === 'fromUTC') {
    offsetMinutes = -offsetMinutes;
    offsetHours = -offsetHours;
  }

  const newHours = [...hour].map((h) => modBound(h + offsetHours, 24));
  const newMinutes = [...minute].map((m) => modBound(m + offsetMinutes, 60));

  return {
    ...rest,
    hour: newHours,
    minute: newMinutes,
  };
};

const modBound = (value, mod) => {
  return ((value % mod) + mod) % mod;
};
