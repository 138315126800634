import { useMemo } from 'react';
import {
  BorrowerType,
  EncompassApplication,
  EncompassEmployment,
  EncompassGiftGrant,
  EncompassOtherAsset,
  EncompassOtherIncomeSource,
  EncompassResidence,
  EncompassURLAAlternateName,
  EncompassOtherLiability,
  EncompassVod,
  EncompassVol,
  EncompassReoProperty,
  EncompassBorrower,
} from 'src/types';
import { useLoan } from './index';

const emptyArray = [];

export const useLoanApplications = (
  loanGuid: string,
): EncompassApplication[] => {
  const loan = useLoan(loanGuid);
  return loan?.applications ?? emptyArray;
};

export const useLoanApplicationIndex = (
  loanGuid: string,
  applicationId: string,
): number => {
  const applications = useLoanApplications(loanGuid);
  return useMemo(
    () =>
      applications?.findIndex(
        (application) => application.id === applicationId,
      ),
    [applications, applicationId],
  );
};

export const useLoanApplicationIdByIndex = (
  loanGuid: string,
  index: number,
) => {
  const applications = useLoanApplications(loanGuid);
  if (applications.length === 0) {
    return null;
  } else {
    return applications[index].id ?? null;
  }
};

export const useLoanApplicationById = (
  loanGuid: string,
  applicationId: string,
): EncompassApplication | null => {
  const applications = useLoanApplications(loanGuid);
  return (
    applications?.find((application) => application.id === applicationId) ??
    null
  );
};

export const useLoanApplicationByIdOrFirst = (
  loanGuid: string,
  applicationId: string,
): EncompassApplication => {
  const applications = useLoanApplications(loanGuid);
  return (
    applications?.find((application) => application.id === applicationId) ??
    applications?.[0]
  );
};

export const useLoanBorrower = (
  loanGuid: string,
  applicationId: string,
  borrowerType: BorrowerType,
): EncompassBorrower | null => {
  const borrowerKey =
    borrowerType === BorrowerType.CoBorrower ? 'coBorrower' : 'borrower';
  const application = useLoanApplicationById(loanGuid, applicationId);
  return application?.[borrowerKey] ?? null;
};

export const useLoanIndividualBorrower = (
  loanGuid: string,
  borrowerGuid: string,
): EncompassBorrower | null => {
  const loan = useLoan(loanGuid);
  if (loan?.applications && loan.applications.length > 0) {
    for (let i = 0; i < loan.applications.length; i++) {
      if (loan.applications[i]?.borrower?.id === borrowerGuid) {
        return loan.applications[i].borrower ?? null;
      }
      if (loan.applications[i]?.coBorrower?.id === borrowerGuid) {
        return loan.applications[i].coBorrower ?? null;
      }
    }
  }
  return null;
};

export const useLoanVods = (
  loanGuid: string,
  applicationId: string,
  borrowerType?: BorrowerType,
): EncompassVod[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  const vods = application?.vods ?? emptyArray;
  return useMemo(() => {
    if (!borrowerType) return vods;

    return vods.filter((x) => x.owner === borrowerType);
  }, [vods, borrowerType]);
};

export const useLoanOtherAssets = (
  loanGuid: string,
  applicationId: string,
  borrowerType?: BorrowerType,
): EncompassOtherAsset[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  const otherAssets = application?.otherAssets ?? emptyArray;
  return useMemo(() => {
    if (!borrowerType) return otherAssets;

    return otherAssets.filter((x) => x.borrowerType === borrowerType);
  }, [otherAssets, borrowerType]);
};

export const useLoanVols = (
  loanGuid: string,
  applicationId: string,
  borrowerType?: BorrowerType,
): EncompassVol[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  const vols = application?.vols ?? emptyArray;
  return useMemo(() => {
    if (!borrowerType) return vols;

    return vols.filter((x) => x.owner === borrowerType);
  }, [vols, borrowerType]);
};

export const useLoanOtherLiabilities = (
  loanGuid: string,
  applicationId: string,
  borrowerType?: BorrowerType,
): EncompassOtherLiability[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  const otherLiabilities = application?.otherLiabilities ?? emptyArray;
  return useMemo(() => {
    if (!borrowerType) return otherLiabilities;

    return otherLiabilities.filter((x) => x.borrowerType === borrowerType);
  }, [otherLiabilities, borrowerType]);
};

export const useLoanOtherIncomeSources = (
  loanGuid: string,
  applicationId: string,
  borrowerType?: BorrowerType,
): EncompassOtherIncomeSource[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  const incomes = application?.otherIncomeSources ?? emptyArray;
  return useMemo(() => {
    if (!borrowerType) return incomes;

    return incomes.filter((x) => x.owner === borrowerType);
  }, [incomes, borrowerType]);
};

export const useLoanEmployments = (
  loanGuid: string,
  applicationId: string,
  borrowerType: BorrowerType,
): EncompassEmployment[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  return useMemo(() => {
    const borrower =
      borrowerType === BorrowerType.CoBorrower
        ? application?.coBorrower
        : application?.borrower;
    return borrower?.employment ?? emptyArray;
  }, [application, borrowerType]);
};

export const useLoanAlternateNames = (
  loanGuid: string,
  applicationId: string,
  borrowerType: BorrowerType,
): EncompassURLAAlternateName[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  return useMemo(() => {
    const borrower =
      borrowerType === BorrowerType.CoBorrower
        ? application?.coBorrower
        : application?.borrower;
    return borrower?.urlaAlternateNames ?? emptyArray;
  }, [application, borrowerType]);
};

export const useLoanGiftsGrants = (
  loanGuid: string,
  applicationId: string,
  borrowerType?: BorrowerType,
): EncompassGiftGrant[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  const giftsGrants = application?.giftsGrants ?? emptyArray;
  return useMemo(() => {
    if (!borrowerType) return giftsGrants;

    return giftsGrants.filter((x) => x.owner === borrowerType);
  }, [giftsGrants, borrowerType]);
};

export const useLoanReoProperties = (
  loanGuid: string,
  applicationId: string,
  borrowerType?: BorrowerType,
): EncompassReoProperty[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  const reoProperties = application?.reoProperties ?? emptyArray;
  return useMemo(() => {
    if (!borrowerType) return reoProperties;

    return reoProperties.filter((x) => x.owner === borrowerType);
  }, [reoProperties, borrowerType]);
};

export const useLoanResidences = (
  loanGuid: string,
  applicationId: string,
  borrowerType: BorrowerType,
): EncompassResidence[] => {
  const application = useLoanApplicationById(loanGuid, applicationId);
  return useMemo(() => {
    const borrower =
      borrowerType === BorrowerType.CoBorrower
        ? application?.coBorrower
        : application?.borrower;
    return borrower?.residences ?? emptyArray;
  }, [application, borrowerType]);
};

export const useLoanCollectionsLength = (
  loanGuid: string,
  applicationId: string,
) => {
  const assets = useLoanVods(loanGuid, applicationId);
  const otherAssets = useLoanOtherAssets(loanGuid, applicationId);
  const liabilities = useLoanVols(loanGuid, applicationId);
  const otherLiabilities = useLoanOtherLiabilities(loanGuid, applicationId);
  const borrowerEmployments = useLoanEmployments(
    loanGuid,
    applicationId,
    BorrowerType.Borrower,
  );
  const coBorrowerEmployments = useLoanEmployments(
    loanGuid,
    applicationId,
    BorrowerType.CoBorrower,
  );
  const giftsGrants = useLoanGiftsGrants(loanGuid, applicationId);
  const reoProperties = useLoanReoProperties(loanGuid, applicationId);
  const borrowerResidences = useLoanReoProperties(
    loanGuid,
    applicationId,
    BorrowerType.Borrower,
  );
  const coborrowerResidences = useLoanReoProperties(
    loanGuid,
    applicationId,
    BorrowerType.CoBorrower,
  );
  const borrowerAlternateNames = useLoanAlternateNames(
    loanGuid,
    applicationId,
    BorrowerType.Borrower,
  );
  const coborrowerAlternateNames = useLoanAlternateNames(
    loanGuid,
    applicationId,
    BorrowerType.CoBorrower,
  );
  const otherIncomeSources = useLoanOtherIncomeSources(loanGuid, applicationId);

  return useMemo(
    // eslint-disable-next-line complexity
    () => ({
      [BorrowerType.Both]: {
        assets: assets.length ?? 0,
        otherAssets: otherAssets.length ?? 0,
        liabilities: liabilities.length ?? 0,
        otherLiabilities: otherLiabilities.length ?? 0,
        employments:
          borrowerEmployments.length + coBorrowerEmployments.length ?? 0,
        otherIncomeSources: otherIncomeSources.length ?? 0,
        giftsGrants: giftsGrants.length ?? 0,
        reoProperties: reoProperties.length ?? 0,
        residences: borrowerResidences.length + coborrowerResidences.length,
        alternateNames:
          borrowerAlternateNames.length + coborrowerAlternateNames.length,
      },
      [BorrowerType.Borrower]: {
        assets:
          assets.filter((asset) => asset.owner === BorrowerType.Borrower)
            .length ?? 0,
        otherAssets:
          otherAssets.filter(
            (asset) => asset.borrowerType === BorrowerType.Borrower,
          ).length ?? 0,
        liabilities:
          liabilities.filter(
            (liability) => liability.owner === BorrowerType.CoBorrower,
          ).length ?? 0,
        otherLiabilities:
          otherLiabilities.filter(
            (liability) => liability.borrowerType === BorrowerType.CoBorrower,
          ).length ?? 0,
        employments: borrowerEmployments.length ?? 0,
        otherIncomeSources:
          otherIncomeSources.filter(
            (income) => income.owner === BorrowerType.Borrower,
          ).length ?? 0,
        giftsGrants:
          giftsGrants.filter((x) => x.owner === BorrowerType.Borrower)
            ?.length ?? 0,
        reoProperties:
          reoProperties.filter((x) => x.owner === BorrowerType.Borrower)
            ?.length ?? 0,
        residences: borrowerResidences?.length ?? 0,
        alternateNames: borrowerAlternateNames?.length ?? 0,
      },
      [BorrowerType.CoBorrower]: {
        assets:
          assets.filter((asset) => asset.owner === BorrowerType.CoBorrower)
            .length ?? 0,
        otherAssets:
          otherAssets.filter(
            (asset) => asset.borrowerType === BorrowerType.CoBorrower,
          ).length ?? 0,
        liabilities:
          liabilities.filter(
            (liability) => liability.owner === BorrowerType.CoBorrower,
          ).length ?? 0,
        otherLiabilities:
          otherLiabilities.filter(
            (liability) => liability.borrowerType === BorrowerType.CoBorrower,
          ).length ?? 0,
        employments: coBorrowerEmployments.length ?? 0,
        otherIncomeSources:
          otherIncomeSources.filter(
            (income) => income.owner === BorrowerType.CoBorrower,
          ).length ?? 0,
        giftsGrants:
          giftsGrants.filter((x) => x.owner === BorrowerType.Borrower)
            ?.length ?? 0,
        reoProperties:
          reoProperties.filter((x) => x.owner === BorrowerType.Borrower)
            ?.length ?? 0,
        residences: coborrowerResidences?.length ?? 0,
        alternateNames: coborrowerAlternateNames?.length ?? 0,
      },
    }),
    [
      assets,
      otherAssets,
      liabilities,
      otherLiabilities,
      borrowerEmployments.length,
      coBorrowerEmployments.length,
      otherIncomeSources,
      giftsGrants,
      reoProperties,
      borrowerResidences,
      coborrowerResidences,
      borrowerAlternateNames,
      coborrowerAlternateNames,
    ],
  );
};
