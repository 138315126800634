export enum Gfe2010FeeParentType {
  Section800LOCompensation = 'Section800LOCompensation',
  Section800 = 'Section800',
  Section1100 = 'Section1100',
  Section800BrokerCompensation = 'Section800BrokerCompensation',
}

export enum Gfe2010FeeType {
  DiscountPoint = 'DiscountPoint',
  Line816 = 'Line816',
  Line1101a = 'Line1101a',
  Line801Additional = 'Line801Additional',
  Line801G = 'Line801G',
  Line807 = 'Line807',
  Line810 = 'Line810', //Condo Questionnaire
  Undefined = 'Undefined', // Broker Compensation
  Line812 = 'Line812', // Additional Good Faith Deposit
  LenderCompensationCredit = 'LenderCompensationCredit',
  Line804 = 'Line804', // appraisal
  Line811 = 'Line811', //second appraisal
}

export interface EncompassGfe2010Fee {
  gfe2010FeeParentType?: Gfe2010FeeParentType;
  gfe2010FeeType?: Gfe2010FeeType;
  gfe2010FeeIndex?: number;
  rate?: number;
  additionalAmount?: number;
  paidToName?: string;
  borPaidAmount?: number;
}
