import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Popover,
} from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './index.module.scss';
import Icon from '@mdi/react';
import { mdiMenuDown, mdiMenuUp } from '@mdi/js';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const GridSelectInput = ({
  value = '',
  multiple = false,
  onChange = () => null,
  onClose = () => null,
  options = [],
  columns = 4,
  label,
  helperText,
  error,
  renderValue = (value) => value,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    onClose?.();
    setAnchorEl(null);
  }, [onClose]);

  const handleSelect = useCallback(
    (e, selectedValue) => {
      if (multiple) {
        const newValue = [...value];
        const valueIndex = newValue.indexOf(selectedValue);
        if (valueIndex > -1) {
          newValue.splice(valueIndex, 1);
        } else {
          newValue.push(selectedValue);
        }
        onChange({
          ...e,
          target: {
            ...e.target,
            value: newValue,
          },
        });
      } else {
        onChange({
          ...e,
          target: {
            ...e.target,
            value: selectedValue,
          },
        });
        handleClose();
      }
    },
    [handleClose, multiple, onChange, value],
  );

  const isOptionSelected = useCallback(
    (option) => {
      return (
        value === option.value ||
        (Array.isArray(value) && value.includes(option.value))
      );
    },
    [value],
  );

  const rows = useMemo(() => {
    if (!options.length) {
      return [
        {
          text: 'No options',
          value: null,
          disabled: true,
        },
      ];
    }
    return options;
  }, [options]);

  return (
    <>
      <FormControl
        className={styles.gridSelectInput}
        size='small'
        onClick={handleOpen}
        error={error}
      >
        {label && (
          <InputLabel variant='outlined' size='small'>
            {label}
          </InputLabel>
        )}
        <OutlinedInput
          className={styles.input}
          value={renderValue(value)}
          readOnly
          label={label}
          error={error}
        />
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}

        <div className={styles.arrowIcon}>
          <Icon path={anchorEl ? mdiMenuUp : mdiMenuDown} />
        </div>
      </FormControl>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div
          className={styles.gridSelectPopover}
          style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
        >
          {rows.map((option, i) => (
            <div
              className={clsx(
                styles.option,
                option.disabled && styles.disabled,
                isOptionSelected(option) && styles.selected,
              )}
              key={i}
              onClick={(e) => handleSelect(e, option.value)}
            >
              <span>{option.text}</span>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

GridSelectInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  multiple: PropTypes.bool,
  columns: PropTypes.number,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  renderValue: PropTypes.func,
};
