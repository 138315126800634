import clsx from 'clsx';
import React from 'react';
import { ConnectedSelectInput } from 'src/components/ConnectedInputs';
import styles from '../index.module.scss';

export const Period = ({ disabled }) => {
  return (
    <ConnectedSelectInput
      name='period'
      options={options}
      disabled={disabled}
      fullWidth={false}
      className={clsx(styles.input, styles.periodInput)}
    />
  );
};

const options = [
  {
    text: 'Year',
    value: 'year',
  },
  {
    text: 'Month',
    value: 'month',
  },
  {
    text: 'Week',
    value: 'week',
  },
  {
    text: 'Day',
    value: 'day',
  },
  {
    text: 'Hour',
    value: 'hour',
  },
  {
    text: 'Minute',
    value: 'minute',
  },
];
