import axios from 'axios';
import { apiAxios } from 'src/util';

export const updateLoanAppraisalFee = async (loanGuid, appraisalFee) => {
  await apiAxios.put(`loans/${loanGuid}/appraisals/fee`, {
    appraisalFee,
  });
};

export const orderAppraisal =
  ({ loanNumber, updatedLoan, loan, guid }) =>
    () => {
      return axios.post('AppraisalOrder', {
        loanNumber,
        updatedLoan: updatedLoan ?? {},
        loan: loan ?? {},
        guid,
      });
    };

export const orderAppraisalNew = (loanGuid) => async () => {
  await apiAxios.post(`loans/${loanGuid}/appraisals/order`);
};
