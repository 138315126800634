import { Button } from '@material-ui/core';
import React, { FC, useCallback, useState } from 'react';
import { MaventAuditResultsDialog } from 'src/components/loanOfficer/NotificationsManager/MaventAuditResults/ResultsDialog';
import { CinchLongProcessStepMetadata } from 'src/types';

interface MaventAuditResultsProps {
  data: CinchLongProcessStepMetadata;
  onDialogOpened: () => void;
  onDialogClosed: () => void;
}

export const MaventAuditResults: FC<MaventAuditResultsProps> = ({
  data,
  onDialogOpened = () => null,
  onDialogClosed = () => null,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setOpen(true);
    onDialogOpened();
  }, [onDialogOpened]);

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    onDialogClosed();
  }, [onDialogClosed]);

  return (
    <>
      <p style={{ marginBottom: 4 }}>The loan did not pass Mavent.</p>
      <Button color='secondary' variant='outlined' onClick={handleOpenDialog}>
        View audit results
      </Button>

      <MaventAuditResultsDialog
        data={data}
        open={open}
        onClose={handleCloseDialog}
      />
    </>
  );
};
