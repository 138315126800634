import { apiAxios } from 'src/util';

export const lockLoan = (loanGuid) => async (dispatch) => {
  try {
    const res = await apiAxios.post(`loans/${loanGuid}/locks`);
    dispatch({
      type: 'set_lock',
      data: { isLockedByOther: false, loanGuid, ...res.data },
    });
  } catch (e) {
    const res = e.response;
    const message = res.data?.messages?.[0];
    dispatch({
      type: 'set_lock',
      data:
        res?.status === 409
          ? {
            isLockedByOther: true,
            error: message?.includes('inaccessible')
              ? 'Loan is in readonly mode and cannot be edited right now.'
              : `Loan is locked by ${
                    message?.split('locked by')?.[1]?.trim() ?? 'someone else'
                  } and is readonly.`,
            loanGuid,
          }
          : {
            isLockedByOther: false,
            error:
                res?.data?.messages?.[0] ??
                `Could not lock loan (Error status ${res?.status})`,
            loanGuid,
          },
    });
  }
};

export const refreshLock = (loanGuid) => async (dispatch, getState) => {
  const lock = getState().locks[loanGuid];
  if (!lock?.isLockedByOther) {
    await apiAxios.patch(`loans/${loanGuid}/locks`);
  }
};

export const unlockLoan = (loanGuid) => async (dispatch, getState) => {
  const lock = getState().locks[loanGuid];
  if (!lock?.isLockedByOther) {
    await apiAxios.delete(`loans/${loanGuid}/locks`);
    dispatch({
      type: 'remove_lock',
      data: { loanGuid },
    });
  }
};

export const fetchLoanLocks = async (loanGuid) => {
  const res = await apiAxios.get(`loans/${loanGuid}/locks`);
  return res.data;
};
