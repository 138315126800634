import { useState, useEffect } from 'react';

/**
 * Debounce a value.
 * @param {any} v The value to debounce
 * @param {number} d The debounce delay, in milliseconds
 * @returns The debounced value
 */
export const useDebounce = (v, d) => {
  const [dvalue, setdvalue] = useState(v);
  useEffect(() => {
    const timer = setTimeout(() => {
      setdvalue(v);
    }, d);
    return () => clearTimeout(timer);
  }, [v, d]);

  return dvalue;
};

export default useDebounce;
