import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import clsx from 'clsx';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';

const ButtonBlock = ({ label, value, name, selected, onClick, disabled }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(name, value ?? label);
    }
  };

  return (
    <div
      className={clsx(
        styles.button,
        selected ? styles.selected : null,
        disabled ? styles.multifamily_picture_disabled : '',
      )}
      onClick={handleClick}
    >
      <div
        className={clsx(styles.checkmark, selected ? styles.selected : null)}
      >
        <Icon path={mdiCheckCircleOutline} size={1.1} />
      </div>
      {label}
    </div>
  );
};

ButtonBlock.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ButtonBlock;
