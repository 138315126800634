import { useSelector } from 'react-redux';
import { RootStore } from 'src/types';
import {
  EncompassEFolder,
  EncompassEFolderAttachment,
  EncompassEFolderDocument,
} from 'src/types/encompass/efolder';

const emptyList = [];

export const useLoanEFolder = (loanGuid: string): EncompassEFolder | null => {
  const efolders = useSelector<RootStore, Record<string, EncompassEFolder>>(
    (store) => store.efolder,
  );
  return efolders[loanGuid] || null;
};

export const useLoanEFolderDocuments = (
  loanGuid: string,
): EncompassEFolderDocument[] => {
  const efolder = useLoanEFolder(loanGuid);
  return efolder?.documents ?? emptyList;
};

export const useLoanEFolderAttachments = (
  loanGuid: string,
): EncompassEFolderAttachment[] => {
  const efolder = useLoanEFolder(loanGuid);
  return efolder?.attachments ?? emptyList;
};

export const useLoanEFolderAttachment = (
  loanGuid: string,
  attachmentId: string,
) => {
  const attachments = useLoanEFolderAttachments(loanGuid);
  return attachments?.find((attachment) => attachment.id === attachmentId);
};
