import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import ConfirmationDialog from 'src/components/baseComponents/Dialog/ConfirmDialog';
import env from 'src/env';
const ErrorHandlerContext = createContext(Promise.reject);

export const ErrorHandlerProvider = ({ children }) => {
  const [errorHandlerOptions, setErrorHandlerOptions] = useState(null);
  const [valid, setValid] = useState(false);
  const awaitingRef = useRef();

  const openError = useCallback((options) => {
    if (options) {
      // Always log to console for logrocket sessions
      console.error(
        options.description?.data?.friendlyMessage ??
          (options.description?.data?.messages?.[0] ||
            options.description?.message),
      );

      if (
        (env.DEPLOY_ENV !== 'production' && env.DEPLOY_ENV !== 'staging') ||
        options.showErrorDescription
      ) {
        setValid(true);
        setErrorHandlerOptions(() => {
          return {
            ...options,
            description:
              options.description?.data?.friendlyMessage ??
              (options.description?.data?.messages?.[0] ||
                options.description?.message),
          };
        });
      } else if (options.title) {
        setValid(true);
        setErrorHandlerOptions(() => {
          return {
            ...options,
            description: options.description?.data?.friendlyMessage,
          };
        });
      }
    }

    return new Promise((resolve, reject) => {
      awaitingRef.current = { resolve, reject };
    });
  }, []);

  const handleCloseError = () => {
    if (awaitingRef.current) {
      awaitingRef.current.resolve(false);
    }
    setErrorHandlerOptions(null);
    setValid(false);
  };

  const handleSubmitError = () => {
    if (awaitingRef.current) awaitingRef.current.resolve(true);
    setErrorHandlerOptions(null);
    setValid(false);
  };

  const openers = useMemo(() => ({ error: openError }), [openError]);

  return (
    <>
      <ErrorHandlerContext.Provider value={openers}>
        {children}
      </ErrorHandlerContext.Provider>
      {valid && (
        <ConfirmationDialog
          open={Boolean(errorHandlerOptions)}
          onClose={handleCloseError}
          onSubmit={handleSubmitError}
          {...errorHandlerOptions}
          variant='alert'
        />
      )}
    </>
  );
};

/**
 * @returns {(options: {
 *   showErrorDescription? : boolean,
 *   title?: string,
 *   description?: string,
 *   buttons?: {
 *     submit?: string,
 *     cancel?: string
 *   },
 *   onClick?: {
 *     submit?: () => Promise<void>
 *   }
 * }) => Promise<boolean>}
 */
export const useErrorHandler = () => useContext(ErrorHandlerContext).error;

export const withErrorHandler = (Component) => (props) => {
  return (
    <ErrorHandlerContext.Consumer>
      {({ error }) => <Component {...props} error={error} />}
    </ErrorHandlerContext.Consumer>
  );
};
