import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { ChangeEvent, FC, FocusEventHandler } from 'react';

type RadioInputProps = {
  id?: string;
  fullWidth?: boolean;
  row?: boolean;
  error?: boolean;
  label: string;
  helperText?: string;
  className?: string;
  disabled?: boolean;
  value: string | number;
  onChange: (e: ChangeEvent, value: string | number) => void;
  onBlur: FocusEventHandler;
  options: {
    text: string;
    value: string | number;
    disabled?: boolean;
  }[];
};

export const RadioInput: FC<RadioInputProps> = ({
  id,
  fullWidth,
  error,
  helperText,
  label,
  className,
  disabled,
  value,
  onChange,
  onBlur,
  options = [],
  row,
}) => {
  return (
    <FormControl
      id={id}
      component='fieldset'
      size='small'
      fullWidth={fullWidth}
      error={error}
      className={clsx(className, disabled && 'disabled')}
    >
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <RadioGroup
        value={value ?? ''}
        onChange={(e) => {
          onChange(e, e.target.value);
        }}
        onBlur={onBlur}
        row={row}
      >
        {options.map((x) => {
          return (
            <FormControlLabel
              key={x.value}
              value={x.value ?? ''}
              label={x.text}
              control={
                <Radio color='secondary' disabled={x.disabled || disabled} />
              }
              disabled={x.disabled || disabled}
            />
          );
        })}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
