/* eslint-disable complexity */
import {
  SET_LOAN_OFFICER,
  UPDATE_LOAN_OFFICER_PIPELINE_COLUMNS,
} from '../actions/types';
const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOAN_OFFICER:
      return {
        ...state,
        ...action.payload,
      };

    case 'admin_update_loan_officer': {
      if (state.id?.toString() === action.data.id.toString()) {
        return {
          ...state,
          ...action.data,
        };
      }
      return state;
    }
    case 'update_loan_officer_addresses': {
      if (state.addressId?.toString() === action.data.addressId.toString()) {
        return {
          ...state,
          address: action.data.address,
        };
      }
      return state;
    }

    case UPDATE_LOAN_OFFICER_PIPELINE_COLUMNS: {
      const { tableId } = action.payload;
      const configs = state.pipelineConfigs ?? [];
      const matchIndex = configs.findIndex(
        (config) => config.tableId === tableId,
      );
      const newConfigs = [...configs];
      if (matchIndex === -1) {
        newConfigs.push(action.payload);
      } else {
        newConfigs[matchIndex] = action.payload;
      }
      return {
        ...state,
        pipelineConfigs: newConfigs,
      };
    }
    default:
      return state;
  }
};
export default reducer;
