/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './expandable.scss';
import clsx from 'clsx';

const MAX_LEN = 60;

const Expandable = ({ children, element: Element, className, maxLength }) => {
  const length = maxLength || MAX_LEN;
  const [expandedByDefault, setExpandedByDefault] = useState(
    children.length < length,
  );
  const [expanded, setExpanded] = useState(expandedByDefault);

  useEffect(() => {
    setExpandedByDefault(children.length < length);
    setExpanded(children.length < length);
  }, [children]);

  const text = expanded ? children : children.slice(0, length - 3) + '...';

  return (
    <div className='expandable'>
      <Element className={clsx(className, expanded ? 'expanded' : 'compact')}>
        {text}
      </Element>
      {!expandedByDefault && (
        <button className='expand' onClick={() => setExpanded(!expanded)}>
          Show {expanded ? 'less' : 'more'}
        </button>
      )}
    </div>
  );
};

Expandable.defaultProps = {
  element: 'p',
  children: '',
  className: '',
};

Expandable.propTypes = {
  children: PropTypes.string.isRequired,
  element: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
};

export default Expandable;
