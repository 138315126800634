import { getLoggedInUser } from 'src/actions/loanOfficer';
import { apiAxios } from 'src/util';

export const fetchDepartments =
  ({ includeUsers = true } = {}) =>
    async (dispatch) => {
      const res = await apiAxios.get('departments', {
        params: {
          includeUsers,
        },
      });
      dispatch({
        type: 'update_departments',
        data: res.data,
      });
    };

export const createDepartment = (data) => async (dispatch) => {
  const res = await apiAxios.post('departments', data);
  dispatch({
    type: 'update_departments',
    data: [res.data],
  });
};

export const updateDepartment = (departmentId, data) => async (dispatch) => {
  const res = await apiAxios.patch(`departments/${departmentId}`, data);
  dispatch({
    type: 'update_departments',
    data: [res.data],
  });
  return res.data;
};

export const deleteDepartment = (departmentId) => async (dispatch) => {
  await apiAxios.delete(`departments/${departmentId}`);
  dispatch({
    type: 'remove_department',
    data: {
      id: departmentId,
    },
  });
};

export const fetchUsersInDepartment = async (departmentId) => {
  const res = await apiAxios.get(`departments/${departmentId}/users`);
  return res.data;
};

export const moveUserToDepartment =
  (userId, { fromDepartmentId, toDepartmentId } = {}) =>
    async (dispatch, getState) => {
      await apiAxios.post(`departments/users/${userId}/move`, {
        fromDepartmentId,
        toDepartmentId,
      });

      if (getState().loanOfficer?.id === userId) {
        await dispatch(getLoggedInUser());
      }
    };

export const removeUserFromDepartment = async (departmentId, userId) => {
  await apiAxios.delete(`departments/${departmentId}/users/${userId}`);
};

export const updateDepartmentUser = async (departmentId, userId, data) => {
  await apiAxios.patch(`departments/${departmentId}/users/${userId}`, data);
};
