import {
  EncompassEmployment,
  EncompassMailingAddress,
  EncompassResidence,
  EncompassURLAAlternateName,
} from 'src/types';

export enum CitizenshipResidencyType {
  USCitizen = 'USCitizen',
  PermanentResidentAlien = 'PermanentResidentAlien',
  NonPermanentResidentAlien = 'NonPermanentResidentAlien',
}

export interface EncompassBorrower {
  id?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  fullNameWithSuffix?: string;
  emailAddressText?: string;
  taxIdentificationIdentifier?: string;
  middleName?: string;
  suffixToName?: string;
  birthDate?: string;
  homePhoneNumber?: string;
  urla2020CitizenshipResidencyType?: CitizenshipResidencyType;
  employment?: EncompassEmployment[];
  urlaAlternateNames?: EncompassURLAAlternateName[];
  residences?: EncompassResidence[];
  mailingAddress?: EncompassMailingAddress;
  applicantType?: string;
}
