import { apiAxios } from 'src/util';

export const getNoiExcludedLoans = async (guidsToCheck) => {
  const res = await apiAxios.post('noi/excluded-loans', guidsToCheck);
  return res.data;
};
export const submitLoanNoiAction = async (loanGuid, actionName) => {
  const res = await apiAxios.post(`noi/${loanGuid}/action/${actionName}`);
  await res.data;
};

export const submitLoanNoiIncomplete = async (loanGuid, data) => {
  const res = await apiAxios.post(`noi/${loanGuid}/incomplete`, data);
  await res.data;
};

export const submitLoanNoiWithdrawal = async (loanGuid, data) => {
  const res = await apiAxios.post(`noi/${loanGuid}/withdrawal`, data);
  await res.data;
};

export const submitLoanNoiDenial = async (loanGuid, data) => {
  const res = await apiAxios.post(`noi/${loanGuid}/denial`, data);
  await res.data;
};
