import { createTheme, ThemeProvider } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { useEffect, useMemo, useState } from 'react';
import { useCurrentLoanOfficerSettings, useLocalStorage } from 'src/hooks';
import { COLOR_THEMES } from 'src/util';

const getThemeStyles = () => {
  const style = getComputedStyle(document.documentElement);
  const background1 = style.getPropertyValue('--background-1').trim();
  const background2 = style.getPropertyValue('--background-2').trim();
  return {
    background1,
    background2,
  };
};

export const MuiThemeProvider = ({ children }) => {
  const [localColorTheme, setLocalColorTheme] =
    useLocalStorage('cinch-color-theme');
  const { colorTheme } = useCurrentLoanOfficerSettings();
  const [themeStyles, setThemeStyles] = useState(getThemeStyles());

  const theme = useMemo(() => {
    const effectiveTheme = colorTheme ?? localColorTheme;
    return COLOR_THEMES.find((x) => x.id === effectiveTheme) ?? COLOR_THEMES[0];
  }, [colorTheme, localColorTheme]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (const m of mutations) {
        if (m.type === 'attributes') {
          setThemeStyles(getThemeStyles());
          break;
        }
      }
    });
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-colortheme'],
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const rootEl = document.documentElement;
    rootEl.setAttribute('data-colortheme', theme.id);
    rootEl.setAttribute('data-colortheme-base', theme.base);
    setLocalColorTheme(theme.id);
  }, [setLocalColorTheme, theme]);

  const muiTheme = useMemo(() => {
    const mode = theme.base;
    const { background1, background2 } = themeStyles;
    return createTheme({
      palette: {
        // mode,
        type: mode,
        background: {
          default: background2,
          paper: background1,
        },
        primary: {
          main: grey[700],
        },
        secondary: grey,
      },
      status: {
        danger: 'orange',
      },
      overrides: {
        MuiListItemIcon: {
          root: {
            minWidth: '34px',
          },
        },
        MuiSelect: {
          outlined: {
            padding: '10px 32px 11px 14px',
            background: mode === 'light' ? 'white' : undefined,
          },
        },
        MuiOutlinedInput: {
          root: {
            background: mode === 'light' ? 'white' : undefined,

            '&.Mui-disabled': {
              background: 'transparent',
            },
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: 'translate(15px, 13px) scale(1)',
          },
        },
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: mode === 'light' ? 'white' : undefined,
            color: grey[900],
          },
        },
        MuiToolbar: {
          dense: {
            minHeight: 44,
          },
        },
        MuiTypography: {
          root: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
    });
  }, [theme.base, themeStyles]);

  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;
};
