// https://material-ui-pickers.dev/demo/datepicker#customization
import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { DateInput } from '@fmm/react-encompass-inputs';
import {
  format,
  startOfMonth,
  startOfWeek,
  addDays,
  subMonths,
  isAfter,
  isBefore,
  subDays,
  endOfWeek,
  subWeeks,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  startOfYear,
  endOfYear,
  subYears,
  startOfDay,
  parseISO,
  isWithinInterval,
  isSameDay,
  min,
  max,
  differenceInDays,
} from 'date-fns';
import { Button, IconButton, Popover } from '@material-ui/core';
import clsx from 'clsx';
import Icon from '@mdi/react';
import { mdiCalendarRange, mdiCheck } from '@mdi/js';

const FORMAT = 'MM/dd/yyyy';
// eslint-disable-next-line max-statements
export const DateRangeInput = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onClear,
  maxDays,
  maxDate,
  minDate,
  autoOk,
  className,
  showDateRangeList = false,
  inline = false,
  controls = true,
}) => {
  const [open, setOpen] = useState(false);
  const [picking, setPicking] = useState('start');
  const [hoverDate, setHoverDate] = useState(undefined);
  const containerRef = useRef();

  const calcMaxDate = useMemo(() => {
    if (maxDays && startDate) {
      if (maxDate) {
        if (picking === 'end') {
          return min([addDays(startDate, maxDays), maxDate]);
        } else {
          return maxDate;
        }
      }
    } else {
      return maxDate;
    }
  }, [maxDate, maxDays, startDate, picking]);

  const calcMinDate = useMemo(() => {
    if (startDate) {
      if (picking === 'end') {
        return max([minDate, startDate].filter((x) => x));
      } else {
        return minDate;
      }
    } else {
      return minDate;
    }
  }, [minDate, startDate, picking]);

  const renderDay = useCallback(
    (date, selectedDate, dayInCurrentMonth) => {
      const dayIsBetween =
        startDate && endDate
          ? isWithinInterval(date, { start: startDate, end: endDate })
          : false;
      const isFirstDay = startDate ? isSameDay(date, startDate) : false;
      const isLastDay = endDate ? isSameDay(date, endDate) : false;
      const isSelected = selectedDate ? isSameDay(date, selectedDate) : false;

      const dayIsHovered =
        hoverDate && startDate
          ? isWithinInterval(date, { start: startDate, end: hoverDate })
          : false;
      const isLastDayHovered =
        hoverDate && startDate ? isSameDay(date, hoverDate) : false;

      const wrapperClassName = clsx({
        [styles.dayWrapper]: true,
        [styles.highlight]: dayIsBetween,
        [styles.firstHighlight]: isFirstDay,
        [styles.endHighlight]: isLastDay,
        [styles.hovered]: dayIsHovered,
        [styles.firstHovered]: isFirstDay,
        [styles.lastHovered]: isLastDayHovered,
      });

      const dayClassName = clsx(styles.day, {
        [styles.selected]: isSelected,
        [styles.nonCurrentMonthDay]: !dayInCurrentMonth,
        [styles.highlightNonCurrentMonthDay]:
          !dayInCurrentMonth && dayIsBetween,
      });

      const isDisabledMax = calcMaxDate ? isAfter(date, calcMaxDate) : false;
      const isDisabledMin = calcMinDate ? isBefore(date, calcMinDate) : false;
      const isDisabled = isDisabledMin || isDisabledMax;

      return (
        <div className={wrapperClassName}>
          <IconButton
            className={dayClassName}
            disabled={isDisabled}
            onMouseOver={() => picking === 'end' && setHoverDate(date)}
          >
            <span>{format(date, 'd')}</span>
          </IconButton>
        </div>
      );
    },
    [startDate, endDate, hoverDate, calcMaxDate, calcMinDate, picking],
  );

  const clear = useCallback(() => {
    setHoverDate(undefined);
    onStartDateChange(undefined);
    onEndDateChange(undefined);
    setPicking('start');
    if (onClear) onClear();
  }, [onClear, onEndDateChange, onStartDateChange]);

  const handleChange = useCallback(
    (date) => {
      if (picking === 'start') {
        onEndDateChange(undefined);
        onStartDateChange(date);
        setPicking('end');
      } else {
        setHoverDate(undefined);
        onEndDateChange(date);
        setPicking('start');
        if (autoOk) {
          setOpen(false);
        }
      }
    },
    [autoOk, onEndDateChange, onStartDateChange, picking],
  );

  const selectPredefined = useCallback(
    (pre) => {
      const map = pre.map();
      onStartDateChange(map.start);
      onEndDateChange(map.end);
      setPicking('start');
    },
    [onEndDateChange, onStartDateChange],
  );

  const displayStartDate = startDate ? (
    format(startDate, FORMAT)
  ) : (
    <span className={styles.noDate}>Select a date</span>
  );
  const displayEndDate = endDate ? (
    format(endDate, FORMAT)
  ) : (
    <span className={styles.noDate}>Select a date</span>
  );

  const inlineContent = useMemo(
    () => (
      <div className={clsx(styles.inlineContentContainer, 'flex-container')}>
        {showDateRangeList && (
          <div className={styles.dateRangeList}>
            <ul>
              {PREDEFINED_DATE_RANGES.map((x) => {
                const matches = matchPredefinedDateRange(startDate, endDate, x);
                return (
                  <li
                    className={clsx('flex-container', matches && styles.active)}
                    key={x.key}
                    onClick={() => selectPredefined(x)}
                  >
                    <span className='flex'>{x.name}</span>
                    {matches && <Icon path={mdiCheck} />}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div
          className={clsx(
            'flex flex-container flex-vertical',
            styles.calendarContainer,
          )}
        >
          <DateInput
            value={startDate ? startDate.toISOString() : undefined}
            format={FORMAT}
            onChange={(e) => {
              const value = e.target.value;
              if (value) handleChange(parseISO(value));
              else handleChange(undefined);
            }}
            className={styles.input}
            maxDate={calcMaxDate}
            minDate={calcMinDate}
            orientation='landscape'
            disableToolbar
            pickerVariant='static'
            renderDay={renderDay}
          />
          {controls && (
            <footer className={clsx(styles.footer, 'flex-container')}>
              <Button className={styles.clearButton} onClick={clear}>
                Clear
              </Button>
              {!inline && (
                <>
                  <span className='flex' />
                  <Button onClick={() => setOpen(false)}>Close</Button>
                </>
              )}
            </footer>
          )}
        </div>
      </div>
    ),
    [
      calcMaxDate,
      calcMinDate,
      clear,
      controls,
      endDate,
      handleChange,
      inline,
      renderDay,
      selectPredefined,
      showDateRangeList,
      startDate,
    ],
  );

  if (inline) {
    return inlineContent;
  }

  return (
    <div
      className={clsx(styles.dateRangeInput, className, 'flex-container')}
      ref={containerRef}
    >
      <div className={styles.displayDates} onClick={() => setOpen(true)}>
        <span className={styles.from}>From</span>
        <span className={styles.displayDate}>{displayStartDate}</span>
        <span className={styles.from}>to</span>
        <span className={styles.displayDate}>{displayEndDate}</span>
        <IconButton className={styles.iconButton}>
          <Icon path={mdiCalendarRange} />
        </IconButton>
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={containerRef.current}
        transitionDuration={160}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {inlineContent}
      </Popover>
    </div>
  );
};

export const matchPredefinedDateRange = (start, end, pre) => {
  const map = pre.map();
  return isSameDay(start, map.start) && isSameDay(end, map.end);
};

export const findPredefinedDateRange = (start, end) => {
  return PREDEFINED_DATE_RANGES.find((x) =>
    matchPredefinedDateRange(start, end, x),
  );
};

export const getPreviousDateRange = (startDate, endDate) => {
  const predefinedPrevRange = findPredefinedDateRange(
    startDate ?? new Date(),
    endDate ?? new Date(),
  );
  if (predefinedPrevRange) {
    const dates = predefinedPrevRange.map();
    return {
      startDate: dates.prevStart,
      endDate: dates.prevEnd,
    };
  }
  const dayDiff = differenceInDays(endDate, startDate);
  const prevStart = subDays(startDate, Math.abs(dayDiff) + 1);
  return {
    startDate: prevStart,
    endDate: subDays(startDate, 1),
  };
};

export const PREDEFINED_DATE_RANGES = [
  {
    name: 'Today',
    key: 'today',
    map: () => ({
      start: startOfDay(new Date()),
      end: startOfDay(new Date()),
      prevStart: subDays(startOfDay(new Date()), 1),
      prevEnd: subDays(startOfDay(new Date()), 1),
    }),
  },
  {
    name: 'Yesterday',
    key: 'yesterday',
    map: () => ({
      start: subDays(startOfDay(new Date()), 1),
      end: subDays(startOfDay(new Date()), 1),
      prevStart: subDays(startOfDay(new Date()), 2),
      prevEnd: subDays(startOfDay(new Date()), 2),
    }),
  },
  {
    name: 'This week',
    key: 'this-week',
    map: () => ({
      start: startOfDay(startOfWeek(new Date())),
      end: startOfDay(endOfWeek(new Date())),
      prevStart: subWeeks(startOfDay(startOfWeek(new Date())), 1),
      prevEnd: subWeeks(startOfDay(endOfWeek(new Date())), 1),
    }),
  },
  {
    name: 'Last week',
    key: 'last-week',
    map: () => ({
      start: subWeeks(startOfDay(startOfWeek(new Date())), 1),
      end: subWeeks(startOfDay(endOfWeek(new Date())), 1),
      prevStart: subWeeks(startOfDay(startOfWeek(new Date())), 2),
      prevEnd: subWeeks(startOfDay(endOfWeek(new Date())), 2),
    }),
  },
  {
    name: 'This month',
    key: 'this-month',
    map: () => ({
      start: startOfDay(startOfMonth(new Date())),
      end: startOfDay(endOfMonth(new Date())),
      prevStart: startOfDay(startOfMonth(subMonths(new Date(), 1))),
      prevEnd: startOfDay(endOfMonth(subMonths(new Date(), 1))),
    }),
  },
  {
    name: 'Last month',
    key: 'last-month',
    map: () => ({
      start: startOfDay(startOfMonth(subMonths(new Date(), 1))),
      end: startOfDay(endOfMonth(subMonths(new Date(), 1))),
      prevStart: startOfDay(startOfMonth(subMonths(new Date(), 2))),
      prevEnd: startOfDay(endOfMonth(subMonths(new Date(), 2))),
    }),
  },
  {
    name: 'This quarter',
    key: 'this-quarter',
    map: () => ({
      start: startOfDay(startOfQuarter(new Date())),
      end: startOfDay(endOfQuarter(new Date())),
      prevStart: startOfDay(startOfQuarter(subQuarters(new Date(), 1))),
      prevEnd: startOfDay(endOfQuarter(subQuarters(new Date(), 1))),
    }),
  },
  {
    name: 'Last quarter',
    key: 'last-quarter',
    map: () => ({
      start: startOfDay(startOfQuarter(subQuarters(new Date(), 1))),
      end: startOfDay(endOfQuarter(subQuarters(new Date(), 1))),
      prevStart: startOfDay(startOfQuarter(subQuarters(new Date(), 2))),
      prevEnd: startOfDay(endOfQuarter(subQuarters(new Date(), 2))),
    }),
  },
  {
    name: 'This year',
    key: 'this-year',
    map: () => ({
      start: startOfDay(startOfYear(new Date())),
      end: startOfDay(endOfYear(new Date())),
      prevStart: startOfDay(startOfYear(subYears(new Date(), 1))),
      prevEnd: startOfDay(endOfYear(subYears(new Date(), 1))),
    }),
  },
  {
    name: 'Last year',
    key: 'last-year',
    map: () => ({
      start: startOfDay(startOfYear(subYears(new Date(), 1))),
      end: startOfDay(endOfYear(subYears(new Date(), 1))),
      prevStart: startOfDay(startOfYear(subYears(new Date(), 2))),
      prevEnd: startOfDay(endOfYear(subYears(new Date(), 2))),
    }),
  },
];

DateRangeInput.defaultProps = {
  onStartDateChange: () => null,
  onEndDateChange: () => null,
  onClear: () => null,
  autoOk: false,
};

DateRangeInput.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  maxDays: PropTypes.number,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  autoOk: PropTypes.bool,
  onClear: PropTypes.func,
};
