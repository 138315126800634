import { useEffect, useState } from 'react';
import {
  regularAmortizationCalculation,
  interestOnlyCalculation,
} from 'src/shared/amortization';
import useDebounce from 'src/hooks/use-debounce';

export const useMultiFamilyLoanCalculationsAmortization = (
  formMethods,
  debounceDelay,
) => {
  const { watch, setValue } = formMethods;
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);
  const rateDeb = useDebounce(watch('loandata.rate'), debounceDelay);
  const loanAmountDeb = useDebounce(
    watch('loandata.loanAmount'),
    debounceDelay,
  );
  const termDeb = useDebounce(watch('loandata.term'), debounceDelay);
  const interestOnlyDeb = useDebounce(
    watch('loandata.interestOnly'),
    debounceDelay,
  );
  const interestOnlyMonthsDeb = useDebounce(
    watch('loandata.interestOnlyMonths'),
    debounceDelay,
  );
  const amortizationTermDeb = useDebounce(
    watch('loandata.amortizationTerm'),
    debounceDelay,
  );

  useEffect(() => {
    if (interestOnlyDeb === true || interestOnlyDeb === 'true') {
      const result = interestOnlyCalculation(
        rateDeb,
        loanAmountDeb,
        termDeb,
        interestOnlyMonthsDeb,
        amortizationTermDeb,
      );
      setValue('loandata.monthlyPayment', result?.monthlyPayment);
      setValue('loandata.afterIoMonthlyPayment', result?.afterIoMonthlyPayment);
      setValue('loandata.balloonPayment', result?.balloonPayment);
      setValue('loandata.totalInterest', result?.totalInterest);
      setValue('loandata.totalLoanPayment', result?.totalLoanPayment);
      setAmortizationSchedule(result?.schedule);
    } else {
      const result = regularAmortizationCalculation(
        rateDeb,
        loanAmountDeb,
        termDeb,
        amortizationTermDeb,
      );
      setValue('loandata.monthlyPayment', result?.monthlyPayment);
      setValue('loandata.balloonPayment', result?.balloonPayment);
      setValue('loandata.totalInterest', result?.totalInterest);
      setValue('loandata.totalLoanPayment', result?.totalLoanPayment);
      setAmortizationSchedule(result?.schedule);
    }
  }, [
    rateDeb,
    loanAmountDeb,
    termDeb,
    interestOnlyDeb,
    interestOnlyMonthsDeb,
    amortizationTermDeb,
    setValue,
  ]);
  return amortizationSchedule;
};
