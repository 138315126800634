import React from 'react';
import { Controller } from 'react-hook-form';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';

export const ConnectedInput = ({
  name,
  id,
  required,
  rules,
  Component,
  ...props
}) => {
  const { id: contextId } = useAutosavingFormContext();

  return (
    <Controller
      name={name}
      rules={{
        required,
        ...rules,
      }}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => (
        <Component
          id={id ?? contextId ? `${contextId}.${name}` : name}
          {...props}
          name={name}
          value={value || 0}
          onChange={(e) => {
            let value = e.target.value;
            onChange(value);
          }}
          onBlur={onBlur}
          error={invalid}
          helperText={error?.message}
          required={Boolean(required || rules?.required)}
        />
      )}
    />
  );
};
