export * from './axios';
export * from './format';
export * from './constant';
export * from './encompass';
export * from './immutable';
export * from './files';
export * from './featureflags';
export * from './math';

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds.
 *
 * @param {Function} func The function to be debounced
 * @param {number?} wait The delay in milliseconds
 * @param {bool?} immediate Trigger the function on the leading edge instead of trailing
 * @return {Function} The debounced function
 */
export function debounce (func, wait = 100, immediate = false) {
  let timeout;
  // let calls = 0;
  return function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      // calls++;
      // console.log('calls', calls);
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
 * Replace dots in object keys or values with underscores
 * @param {object} obj Object to snakeify
 * @param {{ keys?: boolean, values?: boolean }} config Whether to snakeify keys/values
 * @returns
 */
export const snakeifyObject = (obj, { keys = false, values = true }) => {
  const res = {};
  for (const k in obj) {
    const value = obj[k];
    const key = keys ? k.replaceAll('.', '_') : k;
    if (typeof value === 'object' && values) {
      res[key] = snakeifyObject(value);
    } else if (typeof value === 'string' && values) {
      res[key] = value.replaceAll('.', '_');
    } else {
      res[key] = value;
    }
  }
  return res;
};

/**
 * Replace underscores in object keys or values with dots
 * @param {object} obj Object to desnakeify
 * @param {{ keys?: boolean, values?: boolean }} config Whether to desnakeify keys/values
 * @returns
 */
export const desnakeifyObject = (obj, { keys = false, values = true }) => {
  const res = {};
  for (const k in obj) {
    const value = obj[k];
    const key = keys ? k.replaceAll('_', '.') : k;
    if (typeof value === 'object' && values) {
      res[key] = snakeifyObject(value);
    } else if (typeof value === 'string' && values) {
      res[key] = value.replaceAll('_', '.');
    } else {
      res[key] = value;
    }
  }
  return res;
};

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const deepFreeze = (obj) => {
  const propNames = Object.getOwnPropertyNames(obj);
  for (const name of propNames) {
    const value = obj[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }
  return Object.freeze(obj);
};

export const delay = (n) => {
  return new Promise((resolve) => setTimeout(resolve, n));
};

export const underscoreify = (str) => str.replace(/\./g, '_');

/**
 *
 * @param {{ loanId: string, fields: { [fieldId: string]: string }, locks: { id: string, userId: string }[]} data
 * @returns {{guid: string, __resourceLocks: { id: string, userId: string }[], [fieldId: string]: string }[]}
 */
export const formatPipelineDataForTable = (data) => {
  if (data == null) return data; // Replace all the dots in object keys to underscore, so react-table renders them properly
  return data.map((row, index) => {
    const fields = {};
    Object.keys(row.fields).forEach(function (key) {
      fields[underscoreify(key)] = row.fields[key];
    });
    fields.guid = data[index].loanId;
    fields.__resourceLocks = row.locks;
    return fields;
  });
};
