export enum PrepaymentPenaltyBasedOn {
  AmountPrepaid = 'Amount Prepaid',
  OriginalPrincipalAmount = 'Original Principal Amount',
}
export enum FullPrepaymentPenaltyOptionType {
  Hard = 'Hard',
  Soft = 'Soft',
}
export interface EncompassLoanProductData {
  balloonLoanMaturityTermMonthsCount?: number;
  qualifyingRatePercent?: number;
  gsePropertyType?: string;
  scheduledFirstPaymentDate?: string;
  fullPrepaymentPenaltyOptionType?: FullPrepaymentPenaltyOptionType;
  prepaymentPenaltyBasedOn?: PrepaymentPenaltyBasedOn;
  loanScheduledClosingDate?: string;
}
