import { format } from 'date-fns';
import { apiAxios } from 'src/util';

export const fetchDisclosureReport =
  (startDate, endDate) => async (dispatch) => {
    if (!startDate || !endDate) {
      throw new Error('missing startDate or endDate');
    }
    dispatch({
      type: 'set_disclosure_report',
      data: [],
    });
    const res = await apiAxios.get('reports/disclosures', {
      params: {
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      },
    });
    dispatch({
      type: 'set_disclosure_report',
      data: res.data,
    });
  };

export const downloadDisclosureReport = (startDate, endDate) => async () => {
  if (!startDate || !endDate) {
    throw new Error('missing startDate or endDate');
  }
  const res = await apiAxios.get('reports/disclosures/export', {
    params: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
  });
  const fileName = `disclosure-report_${format(startDate, 'M-d-yyyy')}_${format(
    endDate,
    'M-d-yyyy',
  )}.csv`;
  const elem = document.createElement('a');
  const blob = new File([res.data], fileName, {
    type: 'text/csv',
  });
  elem.href = URL.createObjectURL(blob);
  elem.setAttribute('download', fileName);
  elem.click();
  elem.remove();
};
