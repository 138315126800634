import {
  apiAxios,
  CINCHSETTING_EXTERNAL_LINKS,
  CINCH_EXCLUDED_FIELDS,
} from 'src/util';
import { SET_CINCH_SETTING, UPDATE_CINCH_SETTING } from './types';

export const getExcludedFields = () => async (dispatch) => {
  const res = await apiAxios.post(`settings/${CINCH_EXCLUDED_FIELDS}`);
  dispatch({
    type: SET_CINCH_SETTING,
    data: {
      key: CINCH_EXCLUDED_FIELDS,
      data: res.data,
    },
  });
};

export const setExcludedFields = (data) => async (dispatch) => {
  const res = await apiAxios.post('settings', {
    DisplayName: 'Excluded fields',
    KeyName: CINCH_EXCLUDED_FIELDS,
    Value: JSON.stringify(data),
    ValueType: 'Json',
  });
  dispatch({
    type: UPDATE_CINCH_SETTING,
    data: {
      key: CINCH_EXCLUDED_FIELDS,
      data: res.data,
    },
  });
};

export const getCinchSettingExternalLinks = () => async (dispatch) => {
  const res = await apiAxios.post(`settings/${CINCHSETTING_EXTERNAL_LINKS}`);
  dispatch({
    type: SET_CINCH_SETTING,
    data: {
      key: CINCHSETTING_EXTERNAL_LINKS,
      data: res.data,
    },
  });
};

export const setCinchSettingExternalLinks = (data) => async (dispatch) => {
  const res = await apiAxios.post('settings', {
    displayName: 'External links',
    keyName: CINCHSETTING_EXTERNAL_LINKS,
    value: JSON.stringify(data),
    valueType: 'Json',
  });
  dispatch({
    type: UPDATE_CINCH_SETTING,
    data: {
      key: CINCHSETTING_EXTERNAL_LINKS,
      data: res.data,
    },
  });
};
