import { apiAxios } from 'src/util';

export const getGeocodeFromAddress = async (address) => {
  try {
    const res = await apiAxios.get(`google/maps/geocode/address/${address}`);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.warn(err);
  }
};

export const updateGeocodeFromZip = async (zip) => {
  try {
    const res = await apiAxios.get(`google/maps/geocode/zip/${zip}`);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.warn(err);
  }
};
