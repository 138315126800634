import { AddressUnitDesignatorType } from 'src/types/encompass/loan/EncompassProperty';

export enum ResidencyBasisType {
  Own = 'Own',
  Rent = 'Rent',
  NoPrimaryHousingExpense = 'NoPrimaryHousingExpense',
}
export enum ResidencyType {
  Current = 'Current',
  Prior = 'Prior',
}

export interface EncompassResidence {
  id: string;
  residencyType: ResidencyType;
  urla2020StreetAddress?: string;
  addressCity?: string;
  addressState?: string;
  addressPostalCode?: string;
  addressCounty?: string;
  durationTermMonths?: number;
  durationTermYears?: number;
  residencyBasisType?: ResidencyBasisType;
  rent?: number;
  addressUnitDesignatorType?: AddressUnitDesignatorType;
  addressUnitIdentifier?: string;
}
