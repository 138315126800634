import { Tab } from '@material-ui/core';
import React from 'react';
import { useLoan, useSessionStorage } from 'src/hooks';
import styles from '../index.module.scss';
import { NavLink } from 'react-router-dom';

export const ActiveLoanTab = () => {
  const [lastActiveLoanGuid] = useSessionStorage('cinch-active-loan-guid');
  const loan = useLoan(lastActiveLoanGuid);

  if (!lastActiveLoanGuid) {
    return null;
  }

  const label = loan?.customFieldsMapped?.llc?.name
    ? ` (${loan.customFieldsMapped.llc.name})`
    : loan?.titleHolderName1
      ? ` (${loan.titleHolderName1})`
      : '';

  return (
    <Tab
      label={`Loan ${label}`.trim()}
      className={styles.navButton}
      activeClassName={styles.navButtonActive}
      component={NavLink}
      to={`/loans/${lastActiveLoanGuid}`}
    />
  );
};
