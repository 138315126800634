import { TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';
import { enforceHandholdCapitalizeName } from 'src/components/loanOfficer/helpers';

export const ConnectedPlainInput = React.forwardRef(
  (
    {
      min,
      max,
      name,
      rules,
      required,
      capitalize,
      lowercase,
      id,
      autoSelect,
      ...props
    },
    ref,
  ) => {
    const { id: contextId } = useAutosavingFormContext();

    return (
      <Controller
        name={name}
        rules={{
          required,
          ...rules,
        }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error },
        }) => (
          <TextField
            variant='outlined'
            size='small'
            fullWidth
            id={id ?? contextId ? `${contextId}.${name}` : name}
            {...props}
            onFocus={(e) => {
              if (autoSelect) {
                e.target.select();
              }
              if (props.onFocus) props.onFocus(e);
            }}
            name={name}
            value={value || ''}
            onChange={(e) => {
              let value = e.target.value;
              if (lowercase) {
                value = value.toLowerCase();
              }
              if (capitalize) {
                value = enforceHandholdCapitalizeName(value);
              }
              onChange(value);
            }}
            onBlur={onBlur}
            inputProps={{
              minLength: min,
              maxLength: max,
              ...props.inputProps,
            }}
            ref={ref}
            error={invalid}
            helperText={error?.message}
            required={Boolean(required || rules?.required)}
            placeholder={props.placeholder}
          />
        )}
      />
    );
  },
);

ConnectedPlainInput.displayName = 'ConnectedPlainInput';

ConnectedPlainInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  capitalize: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
};
