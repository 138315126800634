import {
  FormControl,
  FormHelperText,
  FormLabel,
  Slider,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

export const SliderInput = ({
  value,
  onChange = () => null,
  onChangeCommitted = () => null,
  disabled = false,
  marks,
  max,
  min,
  name,
  step = 1,
  valueLabelFormat,
  label,
  helperText,
  error,
  fullWidth = true,
  ...props
}) => {
  return (
    <FormControl
      component='fieldset'
      size='small'
      error={error}
      fullWidth={fullWidth}
    >
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <Slider
        style={{
          margin: '0 24px 0 16px',
        }}
        value={value}
        onChange={(e, v) =>
          onChange({ ...e, target: { ...e.target, value: v } }, v)
        }
        onChangeCommitted={(e, v) =>
          onChangeCommitted({ ...e, target: { ...e.target, value: v } }, v)
        }
        disabled={disabled}
        color='secondary'
        marks={marks}
        max={max}
        min={min}
        name={name}
        step={step}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay='auto'
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

SliderInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  disabled: PropTypes.bool,
  marks: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string,
      }),
    ),
  ]),
  step: PropTypes.number,
  valueLabelFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
