import { VOEServiceOrder } from 'src/types/VOEServiceOrder';
import { apiAxios } from 'src/util';

export const orderVOE = (serviceOrder: VOEServiceOrder) => async (dispatch) => {
  const res = await apiAxios.post('services/VOE', serviceOrder);
  return res.data;
};

export const checkVOEOrderStatus = (orderId) => async (dispatch) => {
  const res = await apiAxios.post(`services/VOE/${orderId}`);
  return res.data;
};

export const getVOEOrders = (loanGuid, provider) => async (dispatch) => {
  const res = await apiAxios.get(
    `services/VOE/${loanGuid}?provider=${provider}`,
  );
  return res.data;
};

export const searchArgyle = async (kind, searchterm) => {
  const res = await apiAxios.get(
    `services/VOE/search-argyle?kind=${kind}&search=${searchterm}`,
  );
  return res.data;
};
