import { useSelector } from 'react-redux';
import { PaginationCursor, RootStore } from 'src/types';

export const useCursors = (resourceKey: string) => {
  const all = useSelector<RootStore, Record<string, PaginationCursor[]>>(
    (store) => store.paginationCursors,
  );
  return all[resourceKey];
};

export const useNextCursor = (resourceKey: string) => {
  const cursors = useCursors(resourceKey);
  const last = cursors ? cursors[cursors.length - 1] : null;
  return last?.next ?? null;
};
