import { useSelector } from 'react-redux';
import { RootStore } from 'src/types';
import { CinchAppSetting } from 'src/types/CinchAppSetting';

export const useAppSetting = (keyName: string) => {
  const settings = useAppSettings();
  if (keyName === null || keyName === '') {
    return [];
  }

  return settings[keyName];
};

export const useAppSettings = (): Record<string, CinchAppSetting> => {
  return useSelector<RootStore, Record<string, CinchAppSetting>>(
    (store) => store.appSettings,
  );
};
