export const defaultState = {};

const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_termsheet_draft': {
      return {
        ...state,
        [data.loanGuid]: data,
      };
    }
    case 'update_termsheet_draft': {
      return {
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          ...data,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
