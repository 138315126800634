import { useContext } from 'react';
import { CinchAppConfigContext } from 'src/applicationproviders';

export const useCinchAppConfig = (): Record<
  string,
  string | number | boolean
> => {
  // @ts-ignore
  return useContext(CinchAppConfigContext);
};
