import { deepFreeze } from 'src/util';

const defaultState = {};

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'reset_cursor': {
      return deepFreeze({
        ...state,
        [data.key]: [],
      });
    }
    case 'set_next_cursor': {
      return deepFreeze({
        ...state,
        [data.key]: [
          ...(state[data.key] ?? []),
          {
            next: data.nextCursor,
            self: data.selfCursor,
            items: data.resultIds,
          },
        ],
      });
    }
    default:
      return state;
  }
};

export default reduce;
