import axios from 'axios';

const resolveDefault = (data) => {
  const res = [];
  for (const entity in data) {
    const properties = data[entity].properties;

    for (const k in properties) {
      const property = properties[k];
      if (property.field_id) {
        res.push({
          entity_name: entity,
          ...property,
        });
      } else if (property.field_instances) {
        for (const id in property.field_instances) {
          const n = {
            ...property,
            entity_name: entity,
            field_id: id,
            description: `${property.description || ''} (${
              property.field_instances[id][0]
            })`.trim(),
          };
          delete n.field_instances;
          res.push(n);
        }
      }
    }
  }
  return res;
};

const resolveCustom = (data) => {
  return data.map(({ ...x }) => {
    x.entity_name = undefined;
    x.field_id = x.id;
    delete x.id;
    x.type = x.format;
    delete x.format;
    delete x.modelPath;
    return x;
  });
};

export const fetchAllLoanFields =
  (entities = ['Loan']) =>
    async (dispatch) => {
      entities = entities.filter((x) => x !== 'VirtualFields');
      const res = await axios.get(
        'GetLoanFieldsSchema?entities=' + entities.join(','),
      );
      const resolved = [
        ...resolveDefault(res.data.default),
        ...resolveCustom(res.data.custom),
      ];
      const resObj = {};
      for (const r of resolved) {
        resObj[r.field_id] = r;
      }

      dispatch({
        type: 'set_loan_schema',
        payload: resObj,
      });
      return resObj;
    };
