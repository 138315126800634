import React from 'react';
import FMLogoAnimated from '../../images/FMLogoAnimated.svg';

export default function FMLoader ({ loadingMessage, backgroundColor }) {
  return (
    <div
      className='animated fadeIn'
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'flex',
        filter: 'alpha(opacity=80)',
        minHeight: '100%',
        width: '100%',
        backgroundColor: backgroundColor || '#000000ba',
        zIndex: '9999',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <object
          type='image/svg+xml'
          data={FMLogoAnimated}
          style={{ width: '100px' }}
        >
          FM Logo
        </object>
        {loadingMessage && (
          <div style={{ color: 'white' }}>{loadingMessage}</div>
        )}
      </div>
    </div>
  );
}
