import { InterestRates } from 'src/types/InterestRates';
import { apiAxios } from 'src/util';

export const getInterestRates = async (): Promise<InterestRates> => {
  const res = await apiAxios.get<InterestRates>('rates');
  return res.data;
};

export const updateInterestRates = async (
  data: InterestRates,
): Promise<InterestRates> => {
  const res = await apiAxios.put<InterestRates>('rates', data);
  return res.data;
};
