import { apiAxios } from 'src/util';

export const orderPreApproval = (data) => async () => {
  await apiAxios.post('preapproval/order', data);
};

export const orderPreApprovalForLoan = (loanGuid, data) => async () => {
  try {
    const res = await apiAxios.post(
      `loans/${loanGuid}/preapproval/order`,
      data,
    );
    return res;
  } catch (err) {
    return err?.response?.data || err;
  }
};

export const previewPreAppovalForLoan = (loanGuid, data) => async () => {
  try {
    const res = await apiAxios({
      method: 'post',
      url: `loans/${loanGuid}/preapproval/preview`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
      data: data,
    });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    link.href = url;
    link.setAttribute('download', 'Pre-Approval.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return res;
  } catch (err) {
    return err;
  }
};
