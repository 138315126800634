import { apiAxios } from 'src/util';

export const optimalBlueGetProfile = async () => {
  const res = await apiAxios.get('/services/optimalblue/me');
  return res.data;
};

export const optimalBlueGetLoan = async (loanGuid) => {
  const res = await apiAxios.get(`/services/optimalblue/loans/${loanGuid}`);
  return res.data;
};

export const optimalBlueUpdateLoan = async (loanGuid, patch) => {
  const res = await apiAxios.put(
    `/services/optimalblue/loans/${loanGuid}`,
    patch,
  );
  return res.data;
};

export const optimalBlueProductSearch = async (loanGuid) => {
  const res = await apiAxios.get(
    `/services/optimalblue/loans/${loanGuid}/product-search`,
  );
  return res.data;
};

export const optimalBlueRequestLock = async (loanGuid, lockRequest) => {
  const res = await apiAxios.put(
    `/services/optimalblue/loans/${loanGuid}/request-lock`,
    lockRequest,
  );
  return res.data;
};

export const optimalBlueGetProductDetail = async (searchId, productId) => {
  const res = await apiAxios.get(
    `/services/optimalblue/search/${searchId}/product/${productId}/detail`,
  );
  return res.data;
};

export const optimalBlueDownloadGuideline = async (queryParam, filename) => {
  const res = await apiAxios.get(
    `/services/optimalblue/guideline?${queryParam}`,
    {
      responseType: 'arraybuffer',
    },
  );
  const blobFile = new Blob([res.data]);
  const objectUrl = URL.createObjectURL(blobFile);
  const a = document.createElement('a');
  a.download = filename;
  a.href = objectUrl;
  a.click();
  a.remove();
  URL.revokeObjectURL(objectUrl);
};
