import { FormControl, FormGroup, FormLabel } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ConnectedCheckboxInput } from 'src/components/ConnectedInputs/ConnectedCheckboxInput';

export const ConnectedCheckboxGroupInput = React.forwardRef(
  ({ label, options, row, disabled, fullWidth = false, ...props }, ref) => {
    return (
      <FormControl
        component='fieldset'
        {...props}
        size='small'
        fullWidth={fullWidth}
        className={clsx('radio-input', disabled && 'disabled')}
      >
        {label && <FormLabel component='legend'>{label}</FormLabel>}
        <FormGroup row={row} ref={ref} disabled={disabled}>
          {options.map((x) => {
            return (
              <ConnectedCheckboxInput
                key={x.name}
                name={x.name}
                label={x.label}
                disabled={disabled || x.disabled}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  },
);

ConnectedCheckboxGroupInput.displayName = 'ConnectedCheckboxGroupInput';

ConnectedCheckboxGroupInput.propTypes = {
  label: PropTypes.string,
  row: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
};
