const config = {
  name: 'staging',
  hostnames: ['cinchstaging.azurefd.net', 'cinchstaging.fmm.com'],
  values: {
    FUNCTION_URL:
      'https://func-cinch-serverside-staging.azurewebsites.net/api/',
    API_URL: 'https://app-cinch-staging.azurewebsites.net/api/',
    AUTH_DOMAIN: 'logintest.fmm.com',
    CLIENT_ID: 'sMZyVF6fTqPwyTJqGXmLGXzsppByYifV',
    AI_INSTRUMENTATION_KEY: 'b32bdee8-9298-4de6-b5e3-093eedb5d028',
    DEPLOY_ENV: 'staging',
    BORROWER_URL: 'https://cinchborrowerstaging.fmm.com',
    LOGROCKET_APPID: 'fmm/cinch-staging',
  },
};
export default config;
