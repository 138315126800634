import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'src/util';

const defaultBreakpoints = {
  lg: 1200,
  md: 910,
  sm: 640,
} as const;

const getWindowWidth = () => Math.min(window.innerWidth, window.screen.width);

export const useViewport = () => {
  const [width, setWidth] = useState(getWindowWidth());
  useEffect(() => {
    const handleResize = debounce(() => setWidth(getWindowWidth()), 100) as (
      this: Window,
      ev: Event,
    ) => void;

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return useMemo(
    () => ({
      innerWidth: width,
    }),
    [width],
  );
};

type UseBreakpointsFunction = {
  (): keyof typeof defaultBreakpoints;
  <T extends Record<string, number>>(param: T): keyof T;
};

export const useBreakpoints: UseBreakpointsFunction = <
  T extends Record<string, number>,
>(
    breakpoints?: T,
  ) => {
  const bp = breakpoints ?? defaultBreakpoints;
  type BreakpointsKey = keyof typeof bp;
  const { innerWidth } = useViewport();

  const sortedBreakpoints = useMemo(() => {
    return Object.keys(bp)
      .reduce((a, k: BreakpointsKey) => [...a, { key: k, width: bp[k] }], [])
      .sort((a, b) => a.width - b.width);
  }, [bp]);

  return useMemo(() => {
    for (const v of sortedBreakpoints) {
      if (innerWidth <= v.width) return v.key;
    }
    return sortedBreakpoints[sortedBreakpoints.length - 1].key;
  }, [innerWidth, sortedBreakpoints]);
};
