import { apiAxios } from 'src/util';

export const getLoanHistory = async (loanGuid) => {
  const res = await apiAxios.get(`/toolbar/${loanGuid}`);
  return res.data;
};

export const getLoanSaveHistory = async (loanGuid) => {
  const res = await apiAxios.get(`/toolbar/loansavehistory/${loanGuid}`);
  return res.data;
};

export const getLoanLockHistory = async (loanGuid) => {
  const res = await apiAxios.get(`/toolbar/loanlockhistory/${loanGuid}`);
  return res.data;
};

export const getLoanDisclosuresHistory = async (loanGuid) => {
  const res = await apiAxios.get(`/toolbar/disclosure/${loanGuid}`);
  return res.data;
};

export const getLoanSignNowHistory = async (loanGuid) => {
  const res = await apiAxios.get(`/toolbar/signnow/${loanGuid}`);
  return res.data;
};

export const canProcessLoan = async (loanGuid) => {
  const res = await apiAxios.get(`/services/submitto/${loanGuid}`);
  return res.data;
};

export const getProcessingStatus = async (loanGuid, SubmitToForm) => {
  const res = await apiAxios.get(
    `/services/submitto/${loanGuid}/${SubmitToForm}`,
  );
  return res.data;
};

export const submitToProcessing = async (data) => {
  const res = await apiAxios.post('/services/submitto/Processing', data);
  return res;
};

export const submitToUnderwriting = async (data) => {
  const res = await apiAxios.post('/services/submitto/Underwriting', data);
  return res;
};

export const submitToQC = async (data) => {
  const res = await apiAxios.post('/services/submitto/qc', data);
  return res;
};

export const submitToJumboReview = async (data) => {
  const res = await apiAxios.post('/services/submitto/JumboReview', data);
  return res;
};

export const submitToClearProcessing = async (data) => {
  const res = await apiAxios.post('/services/submitto/ClearProcessing', data);
  return res;
};

export const submitToClearUnderwriting = async (data) => {
  const res = await apiAxios.post('/services/submitto/ClearUnderwriting', data);
  return res;
};

export const submitToClearJumboReview = async (data) => {
  const res = await apiAxios.post('/services/submitto/ClearJumboReview', data);
  return res;
};

export const submitToUnderwritingSuspended = async (data) => {
  const res = await apiAxios.post(
    '/services/submitto/UnderwritingSuspended',
    data,
  );
  return res;
};

export const submitToUnderwritingApproved = async (data) => {
  const res = await apiAxios.post(
    '/services/submitto/UnderwritingApproved',
    data,
  );
  return res;
};

export const saveMilestones = async (loanGuid, data) => {
  const res = await apiAxios.post(`/milestones/${loanGuid}/save`, data);
  return res;
};

export const getMilestones = async (loanGuid) => {
  const res = await apiAxios.get(`/milestones/${loanGuid}`);
  return res.data;
};
