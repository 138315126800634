export enum EncompassBusinessContactCategories {
  Appraiser = 'Appraiser',
  Attorney = 'Attorney',
  CreditCompany = 'CreditCompany',
  DocSigning = 'DocSigning',
  EscrowCompany = 'EscrowCompany',
  FloodInsurance = 'FloodInsurance',
  HazardInsurance = 'HazardInsurance',
  Lender = 'Lender',
  MortgageInsurance = 'MortgageInsurance',
  RealEstateAgent = 'RealEstateAgent',
  Servicing = 'Servicing',
  TitleInsurance = 'TitleInsurance',
  Underwriter = 'Underwriter',
  Surveyor = 'Surveyor',
  NoCategory = 'NoCategory',
  Organization = 'Organization',
  Verification = 'Verification',
  Investor = 'Investor',
  WarehouseBank = 'WarehouseBank',
  Builder = 'Builder',
  Dealer = 'Dealer',
  TradeAssignee = 'TradeAssignee',
  NewCategory = 'NewCategory',
  Accountan = 'Accountan',
}
