import {
  AppBar,
  Avatar,
  Badge,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import {
  mdiBell,
  mdiBug,
  mdiFormatListBulleted,
  mdiMenu,
  mdiPlus,
  mdiWebhook,
  mdiHome,
  mdiOfficeBuilding,
} from '@mdi/js';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Logo } from 'src/components/baseComponents/Header/Logo';
import { useBreakpoints, useCurrentLoanOfficer } from 'src/hooks';
import { useWebsocket } from 'src/hooks/signalr/use-websocket';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import styles from './index.module.scss';
import { ROLE_NAMES } from 'src/util';
import HasRole from 'src/components/baseComponents/HasRole';
import Icon from '@mdi/react';
import Notifications from 'src/components/loanOfficer/NotificationsPopover';
import { NavTabs } from 'src/components/baseComponents/Header/Tabs';
import env from 'src/env';
import UserMenu from 'src/components/baseComponents/Header/UserMenu';
import MobileNavDrawer from 'src/components/baseComponents/Header/MobileNavDrawer';

export const Header = () => {
  const loanOfficer = useCurrentLoanOfficer();
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [debugActionsRef, setDebugActionsRef] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const { status } = useWebsocket();
  const notifications = useSelector((store) => store.notifications);
  const hasUnseenNotifications = notifications.some((x) => x.unseen);
  const alertableNotifications = notifications.filter((x) => !x.dismissed);
  const newLoanPageRouteMatch = useRouteMatch('/loans/new');
  const breakpoint = useBreakpoints();

  const handleProfileMenuOpen = (event) =>
    setProfileAnchorEl(event.currentTarget);
  const handleNotificationsOpen = () => setShowNotifications(true);
  const handleProfileMenuClose = () => setProfileAnchorEl(null);
  const handleNotificationsClose = () => setShowNotifications(false);
  const showDebuggers = (e) => {
    if (debugActionsRef) {
      setDebugActionsRef(null);
    } else {
      setDebugActionsRef(e.currentTarget);
    }
  };
  const deployEnv =
    env.DEPLOY_ENV === 'production' ? undefined : env.DEPLOY_ENV;

  return (
    <AppBar position='static' className={styles.appbar}>
      <Toolbar className={clsx('flex-container', styles.toolbar)}>
        <RouterLink
          to='/'
          className={styles.logoLink}
          data-environment={deployEnv}
        >
          <span className={styles.logoText} data-environment={deployEnv}>
            Cinch
          </span>
          <Logo className={styles.logoImage} />
        </RouterLink>
        <NavTabs />
        <span className='flex' />
        <div className={styles.menuButtons}>
          <HasRole roles={[ROLE_NAMES.FMM]}>
            <Button
              color='primary'
              variant='contained'
              startIcon={
                <>
                  <Icon path={mdiPlus} />
                  <Icon path={mdiHome} />
                </>
              }
              component={RouterLink}
              to='/loans/new'
              className={styles.newLoanButton}
              disabled={Boolean(newLoanPageRouteMatch)}
            >
              NEW RESIDENTIAL
            </Button>
          </HasRole>
          <Button
            color='primary'
            variant='contained'
            startIcon={
              <>
                <Icon path={mdiPlus} />
                <Icon path={mdiOfficeBuilding} />
              </>
            }
            component={RouterLink}
            to='/loans/new?type=commercial'
            className={styles.newLoanButton}
            disabled={Boolean(newLoanPageRouteMatch)}
          >
            NEW COMMERCIAL
          </Button>
          <HasRole roles={[ROLE_NAMES.ADMINISTRATOR]}>
            <Tooltip title='Debug' arrow placement='bottom'>
              <IconButton
                color='primary'
                onClick={showDebuggers}
                className={styles.button}
              >
                <Icon path={mdiBug} />
              </IconButton>
            </Tooltip>
          </HasRole>
          <Tooltip title='Notifications' arrow placement='bottom'>
            <IconButton
              aria-label='show notifications'
              aria-haspopup='menu'
              color='primary'
              onClick={handleNotificationsOpen}
              className={styles.button}
            >
              <Badge
                badgeContent={alertableNotifications.length}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                classes={{ badge: styles.notificationsBadge }}
                overlap='circular'
              >
                <Icon
                  path={mdiBell}
                  className={clsx(hasUnseenNotifications && styles.wiggleBtn)}
                />
              </Badge>
            </IconButton>
          </Tooltip>
          {breakpoint !== 'lg' && (
            <IconButton
              color='primary'
              onClick={handleProfileMenuOpen}
              className={clsx(styles.button, styles.primary)}
            >
              <Icon path={mdiMenu} />
            </IconButton>
          )}
          {breakpoint === 'lg' && (
            <Badge
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              classes={{
                badge: clsx(
                  styles.socketStatus,
                  styles[`socketStatus${status}`],
                ),
              }}
              variant='dot'
              overlap='circular'
            >
              <Avatar
                aria-label='account of current user'
                aria-haspopup='menu'
                src={loanOfficer?.picture || null}
                onClick={handleProfileMenuOpen}
                className={clsx(styles.button, styles.avatar)}
              >
                {loanOfficer?.firstName
                  ? `${loanOfficer.firstName[0]}${
                      loanOfficer.lastName
                        ? loanOfficer.lastName[0]
                        : loanOfficer.firstName[1]
                    }`
                  : null}
              </Avatar>
            </Badge>
          )}
        </div>
      </Toolbar>

      {/* MENUS */}
      <Notifications
        open={showNotifications}
        onClose={handleNotificationsClose}
      />
      <Menu
        anchorEl={debugActionsRef}
        open={Boolean(debugActionsRef)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        onClose={() => setDebugActionsRef(null)}
        PaperProps={{
          className: styles.navMenu,
        }}
      >
        {adminDebugItems.map(({ text, link, icon }) => (
          <MenuItem
            key={link}
            component={RouterLink}
            to={link}
            onClick={() => setDebugActionsRef(null)}
          >
            <ListItemIcon>
              <Icon path={icon} />
            </ListItemIcon>
            <ListItemText>{text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      {breakpoint !== 'lg' ? (
        <MobileNavDrawer
          open={Boolean(profileAnchorEl)}
          onClose={handleProfileMenuClose}
        />
      ) : (
        <UserMenu anchorEl={profileAnchorEl} onClose={handleProfileMenuClose} />
      )}
    </AppBar>
  );
};

const adminDebugItems = [
  {
    text: 'Loan Fields',
    link: '/admin/debugger/loanfields',
    icon: mdiFormatListBulleted,
  },
  {
    text: 'Webhooks',
    link: '/admin/debugger/webhooks',
    icon: mdiWebhook,
  },
];
