import { useEffect } from 'react';

export const useMultiFamilyLoanCalculationsCosts = (formMethods) => {
  const { watch, setValue } = formMethods;
  const loanType = watch('loandata.loanType');
  const totalCosts = watch('totalCosts');
  const existingPropertyAmount = watch(
    'loandata.typeDetails.refi.existingPropertyAmount',
  );
  const purchasePriceOrEstimatedValue = watch(
    'loandata.purchasePriceEstimatedValue',
  );

  const parseNumber = (value) => {
    return Number.isFinite(parseFloat(value)) ? parseFloat(value) : 0;
  };

  useEffect(() => {
    const totalProjectCosts =
      loanType === 'Purchase'
        ? parseNumber(totalCosts) + parseNumber(purchasePriceOrEstimatedValue)
        : parseNumber(totalCosts) + parseNumber(existingPropertyAmount);
    setValue('calculated.totalProjectCosts', totalProjectCosts);
  }, [
    loanType,
    totalCosts,
    purchasePriceOrEstimatedValue,
    existingPropertyAmount,
    setValue,
  ]);
};
