const initialState = [];

const reduce = function (state = initialState, { type, data }) {
  switch (type) {
    case 'update_departments': {
      const newState = [...state];
      for (const dep of data) {
        const matchIndex = newState.findIndex(
          (item) => item.id.toString() === dep.id.toString(),
        );
        if (matchIndex > -1) {
          newState.splice(matchIndex, 1, {
            ...newState[matchIndex],
            ...dep,
            parentDepartmentId: dep.parentDepartmentId,
          });
        } else {
          newState.push(dep);
        }
      }
      return newState;
    }
    case 'remove_department': {
      const newState = [...state];
      const matchIndex = newState.findIndex(
        (item) => item.id.toString() === data.id.toString(),
      );
      if (matchIndex > -1) {
        newState.splice(matchIndex, 1);
      }
      return newState;
    }
    default:
      return state;
  }
};
export default reduce;
