// eslint-disable-next-line max-statements
export const interestOnlyCalculation = (
  rate,
  loanAmount,
  term,
  interestOnlyMonths,
  amortizationTerm,
) => {
  let result = {
    monthlyPayment: 0,
    afterIoMonthlyPayment: 0,
    balloonPayment: 0,
    totalInterest: 0,
    totalLoanPayment: 0,
    schedule: [],
  };

  if (
    !rate ||
    !loanAmount ||
    !term ||
    !interestOnlyMonths ||
    !amortizationTerm
  ) {
    return result;
  }

  const monthlyInterestRate = rate / 12 / 100;
  const newMonthlyPayment = loanAmount * monthlyInterestRate;
  let totalInterestPaid = newMonthlyPayment * interestOnlyMonths;
  let totalPayment = 0;
  let monthCount = 1;
  result.monthlyPayment = newMonthlyPayment;

  for (let i = 0; i < interestOnlyMonths; i++) {
    totalPayment += newMonthlyPayment;
    const scheduleEntry = {
      month: monthCount,
      pandi: newMonthlyPayment.toFixed(2),
      principal: 0,
      interest: newMonthlyPayment.toFixed(2),
      loanAmount: loanAmount.toFixed(2),
      totalPaid: totalPayment.toFixed(2),
    };
    monthCount++;
    result.schedule.push(scheduleEntry);
  }

  if (term > interestOnlyMonths) {
    const remainingAmortizationTerm = amortizationTerm - interestOnlyMonths;
    const discountFactor =
      (Math.pow(1 + monthlyInterestRate, remainingAmortizationTerm) - 1) /
      (monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, remainingAmortizationTerm));
    const monthlyPaymentAfter = loanAmount / discountFactor;
    result.afterIoMonthlyPayment = monthlyPaymentAfter;

    let loanAmountForCalculation = loanAmount;
    for (let i = 0; i < term - interestOnlyMonths; i++) {
      const remainingPrincipal =
        loanAmountForCalculation -
        (monthlyPaymentAfter - loanAmountForCalculation * monthlyInterestRate);
      const interestPayment = loanAmountForCalculation * monthlyInterestRate;
      totalInterestPaid += interestPayment;
      totalPayment += monthlyPaymentAfter;
      loanAmountForCalculation = remainingPrincipal;

      const scheduleEntry = {
        month: monthCount,
        pandi: monthlyPaymentAfter.toFixed(2),
        principal: (monthlyPaymentAfter - interestPayment).toFixed(2),
        interest: interestPayment.toFixed(2),
        loanAmount: remainingPrincipal.toFixed(2),
        totalPaid: totalPayment.toFixed(2),
      };
      monthCount++;
      result.schedule.push(scheduleEntry);
    }
    if (amortizationTerm > term) {
      const numberOfPayments = term - interestOnlyMonths;
      const finalPayment =
        loanAmount * Math.pow(1 + monthlyInterestRate, numberOfPayments) -
        (monthlyPaymentAfter / monthlyInterestRate) *
          (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
      result.balloonPayment = finalPayment;
      totalPayment += finalPayment;
    } else {
      result.balloonPayment = 0;
    }
  } else {
    totalPayment += loanAmount;
    result.afterIoMonthlyPayment = 0;
    result.balloonPayment = loanAmount;
  }
  result.totalInterest = totalInterestPaid;
  result.totalLoanPayment = totalPayment;
  return result;
};

// eslint-disable-next-line max-statements
export const regularAmortizationCalculation = (
  rate,
  loanAmount,
  term,
  amortizationTerm,
) => {
  let result = {
    monthlyPayment: 0,
    balloonPayment: 0,
    totalInterest: 0,
    totalLoanPayment: 0,
    schedule: [],
  };

  if (!rate || !loanAmount || !term || !amortizationTerm) {
    return result;
  }

  const monthlyInterestRate = rate / 12 / 100;
  const discountFactor =
    (Math.pow(1 + monthlyInterestRate, amortizationTerm) - 1) /
    (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, amortizationTerm));
  const monthlyPayment = loanAmount / discountFactor;

  result.monthlyPayment = monthlyPayment;

  let totalPayment = 0;
  let totalInterestPaid = 0;
  let loanAmountForCalculation = loanAmount;
  let monthCount = 1;

  for (let i = 0; i < term; i++) {
    const remainingPrincipal =
      loanAmountForCalculation -
      (monthlyPayment - loanAmountForCalculation * monthlyInterestRate);
    const interestPayment = loanAmountForCalculation * monthlyInterestRate;
    totalInterestPaid += interestPayment;
    totalPayment += monthlyPayment;
    loanAmountForCalculation = remainingPrincipal;

    const scheduleEntry = {
      month: monthCount,
      pandi: monthlyPayment.toFixed(2),
      principal: (monthlyPayment - interestPayment).toFixed(2),
      interest: interestPayment.toFixed(2),
      loanAmount: remainingPrincipal.toFixed(2),
      totalPaid: totalPayment.toFixed(2),
    };
    result.schedule.push(scheduleEntry);
    monthCount++;
  }

  if (amortizationTerm > term) {
    const numberOfPayments = term;
    const finalPayment =
      loanAmount * Math.pow(1 + monthlyInterestRate, numberOfPayments) -
      (monthlyPayment / monthlyInterestRate) *
        (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
    totalPayment += finalPayment;
    result.balloonPayment = finalPayment;

    const scheduleEntry = {
      month: monthCount - 1,
      pandi: finalPayment.toFixed(2),
      principal: 0,
      interest: 0,
      loanAmount: 0,
      totalPaid: totalPayment.toFixed(2),
    };
    result.schedule.push(scheduleEntry);
    monthCount++;
  }
  result.totalInterest = totalInterestPaid;
  result.totalLoanPayment = totalPayment;
  return result;
};
