import { apiAxios } from 'src/util';
import { compareAsc, format, parse } from 'date-fns';

const buildFormatStr = (str) => {
  const parts = str.split('/');
  const chars = ['M', 'd', 'y'];
  return [0, 1, 2].map((x) => chars[x].repeat(parts[x].length)).join('/');
};

export const fetchBlockedClosingDates = () => async () => {
  try {
    const res = await apiAxios.get('schedule-closing/blocked-dates');
    const data = res.data;

    const results = data
      .map((x) => {
        if (x.length === 10) return parse(x, 'MM/dd/yyyy', new Date());
        const formatStr = buildFormatStr(x);
        return parse(x, formatStr, new Date());
      })
      .sort((x, y) => compareAsc(x, y))
      .map((x) => format(x, 'MM/dd/yyyy'));
    return [...new Set(results)];
  } catch (e) {
    return e;
  }
};

export const sendClosingScheduledEmail = async (
  loanGuid,
  reschedule = false,
  deleted = false,
) => {
  await apiAxios.post(
    `schedule-closing/schedule-email/loan/${loanGuid}/${reschedule}/${deleted}`,
  );
};

export const sendClosingRequestEmail = async (loanGuid) => {
  await apiAxios.post(`schedule-closing/closing-email/loan/${loanGuid}`);
};

export const scheduleClosing = async ({ guid, closingDate }) => {
  await apiAxios.post(
    `schedule-closing/loan/${guid}/date/${encodeURIComponent(closingDate)}`,
  );
};

export const newTitleCompanyRequest = async (loanGuid, request) => {
  const res = await apiAxios.post(
    `schedule-closing/new-title-company/loan/${loanGuid}`,
    request,
  );
  return res.data;
};
