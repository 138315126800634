import { apiAxios } from 'src/util';

const fieldReg = /^\w+\..+/;

export const fetchLoanPipelineCanonicalFields =
  (canonicalFieldIds = [], abortController) =>
    async (dispatch) => {
      canonicalFieldIds = canonicalFieldIds.filter((field) =>
        fieldReg.test(field),
      );
      if (!canonicalFieldIds.length) return;
      try {
        const res = await apiAxios.post(
          'loanPipeline/fields',
          canonicalFieldIds,
          {
            signal: abortController?.signal,
          },
        );
        const data = res.data;
        for (const field of canonicalFieldIds) {
          if (!data[field]) {
            const alternateCasedKey = Object.keys(data).find(
              (k) => k.toLowerCase() === field.toLowerCase(),
            );
            if (alternateCasedKey) {
              data[field] = data[alternateCasedKey];
            } else {
              data[field] = {
                fieldId: field,
                error: 'Could not find field',
              };
            }
          }
        }
        dispatch({
          type: 'set_pipeline_canonicalfields',
          data,
        });
      } catch (e) {
      // if theres only 1 field and its invalid, set it as error
      // if theres more than 1, dont bother
        if (e.response?.status === 400 && canonicalFieldIds.length === 1) {
          dispatch({
            type: 'set_pipeline_canonicalfields',
            data: {
              [canonicalFieldIds[0]]: {
                fieldId: canonicalFieldIds[0],
                error: 'Could not find field',
              },
            },
          });
        } else {
          throw e;
        }
      }
    };
