import React, { useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Virtuoso } from 'react-virtuoso';
import PropTypes from 'prop-types';

export const VirtualList = ({
  data,
  hasMoreRows,
  onLoadMoreItems,
  overscan,
  renderRow: _renderRow,
  loadingMoreRows,
  onHeightChanged,
  Components,
}) => {
  const renderRow = useCallback(_renderRow, [_renderRow]);
  return (
    <div className='flex'>
      <AutoSizer defaultHeight={1}>
        {({ height, width }) => (
          <Virtuoso
            style={{ height, width }}
            data={data}
            endReached={(i) =>
              hasMoreRows && !loadingMoreRows && onLoadMoreItems(i)
            }
            overscan={overscan}
            itemContent={renderRow}
            totalCount={data.length}
            totalListHeightChanged={onHeightChanged}
            components={{
              Footer: () => Components.Footer,
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
};

VirtualList.defaultProps = {
  hasMoreRows: false,
  onLoadMoreItems: () => null,
  overscan: 100, // pixels i think
  renderRow: () => null,
  components: {},
  onHeightChanged: () => null,
};

VirtualList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMoreRows: PropTypes.bool,
  overscan: PropTypes.number,
  onLoadMoreItems: PropTypes.func,
  onHeightChanged: PropTypes.func,
  renderRow: PropTypes.func.isRequired,
  loadingMoreRows: PropTypes.bool,
  components: PropTypes.shape({
    Footer: PropTypes.any,
  }),
};
