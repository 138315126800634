import {
  NqmInvestor,
  NqmInvestorAmortization,
  NqmInvestorAmortizationRelationship,
  NqmInvestorPpp,
  NqmInvestorPppRelationship,
  NqmInvestorProgram,
} from 'src/types/nqm';
import { apiAxios } from 'src/util';

export const getAllNqmInvestors = async (): Promise<NqmInvestor[]> => {
  const res = await apiAxios.get('/nqm-investor');
  return res.data;
};

export const addNqmInvestor = async (
  investor: NqmInvestor,
): Promise<NqmInvestor> => {
  const res = await apiAxios.post('/nqm-investor', investor);
  return res.data;
};

export const updateNqmInvestor = async (
  investor: NqmInvestor,
): Promise<NqmInvestor> => {
  const res = await apiAxios.patch('/nqm-investor', investor);
  return res.data;
};

export const deleteNqmInvestor = async (investor: NqmInvestor) => {
  const res = await apiAxios.delete('/nqm-investor', { data: investor });
  return res.data;
};

export const addNqmProgram = async (
  program: NqmInvestorProgram,
): Promise<NqmInvestorProgram> => {
  const res = await apiAxios.post('/nqm-investor/program', program);
  return res.data;
};

export const updateNqmProgram = async (
  program: NqmInvestorProgram,
): Promise<NqmInvestorProgram> => {
  const res = await apiAxios.patch('/nqm-investor/program', program);
  return res.data;
};
export const deleteNqmProgram = async (program: NqmInvestorProgram) => {
  const res = await apiAxios.delete('/nqm-investor/program', { data: program });
  return res.data;
};

export const addNqmPrepaymentPenalty = async (
  ppp: NqmInvestorPpp,
): Promise<NqmInvestorPpp> => {
  const res = await apiAxios.post('/nqm-investor/ppp', ppp);
  return res.data;
};

export const updateNqmPrepaymentPenalty = async (
  ppp: NqmInvestorPpp,
): Promise<NqmInvestorPpp> => {
  const res = await apiAxios.patch('/nqm-investor/ppp', ppp);
  return res.data;
};
export const deleteNqmPrepaymentPenalty = async (ppp: NqmInvestorPpp) => {
  const res = await apiAxios.delete('/nqm-investor/ppp', { data: ppp });
  return res.data;
};

export const addNqmAmortization = async (
  amortization: NqmInvestorAmortization,
): Promise<NqmInvestorAmortization> => {
  const res = await apiAxios.post('/nqm-investor/amortization', amortization);
  return res.data;
};

export const updateNqmAmortization = async (
  amortization: NqmInvestorAmortization,
): Promise<NqmInvestorAmortization> => {
  const res = await apiAxios.patch('/nqm-investor/amortization', amortization);
  return res.data;
};
export const deleteNqmAmortization = async (amortization) => {
  const res = await apiAxios.delete('/nqm-investor/amortization', {
    data: amortization,
  });
  return res.data;
};

export const addNqmAmortizationRelationship = async (
  relationship: NqmInvestorAmortizationRelationship,
  investorId,
): Promise<NqmInvestorAmortizationRelationship> => {
  const res = await apiAxios.post(
    `/nqm-investor/relationship/amortization/${investorId}`,
    relationship,
  );
  return res.data;
};

export const addNqmPppRelationship = async (
  relationship: NqmInvestorPppRelationship,
  investorId,
): Promise<NqmInvestorPppRelationship> => {
  const res = await apiAxios.post(
    `/nqm-investor/relationship/ppp/${investorId}`,
    relationship,
  );
  return res.data;
};

export const deleteNqmAmortizationRelationship = async (
  relationship,
  investorId,
) => {
  const res = await apiAxios.delete(
    `/nqm-investor/relationship/amortization/${investorId}`,
    { data: relationship },
  );
  return res.data;
};

export const deleteNqmPppRelationship = async (relationship, investorId) => {
  const res = await apiAxios.delete(
    `/nqm-investor/relationship/ppp/${investorId}`,
    { data: relationship },
  );
  return res.data;
};
