import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

class FullPageErrorBoundary extends React.Component {
  state = {
    error: null,
    errorInfo: '',
    isChunkLoadError: false,
  };

  static getDerivedStateFromError (error) {
    return {
      error,
      isChunkLoadError: error.toString().startsWith('ChunkLoadError:'),
    };
  }

  componentDidCatch (error, info) {
    console.error(error);
    this.setState({ errorInfo: info });
  }

  clearError = () => {
    this.setState({ error: null, errorInfo: '' });
  };

  render () {
    return (
      <>
        {this.state.error && !this.state.isChunkLoadError && (
          <div
            style={{
              backgroundColor: '#dc3545',
              color: 'white',
              padding: '.5em',
            }}
            className='flex-container flex-center'
          >
            <div>
              <span>Oops! Something went wrong. Please try again. </span>
              <u className='cursor-pointer' onClick={this.clearError}>
                Close
              </u>
            </div>
            {process.env.NODE_ENV === 'development' && (
              <>
                {this.state.errorInfo && (
                  <pre style={{ color: 'white', margin: 0 }}>
                    {this.state.errorInfo?.toString()}
                  </pre>
                )}
                <pre style={{ color: 'white', margin: 0 }}>
                  {this.state.error.message}
                </pre>
              </>
            )}
          </div>
        )}
        <Dialog open={Boolean(this.state.error && this.state.isChunkLoadError)}>
          <DialogTitle>Something went wrong!</DialogTitle>
          <DialogContent style={{ paddingTop: 0 }}>
            <DialogContentText>
              Part of this page could not be loaded.
              <br />
              Cinch may have been updated - please refresh the page to use the
              latest version of the app.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.location.reload()} autoFocus>
              Refresh
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.children}
      </>
    );
  }
}

export default FullPageErrorBoundary;
