import { useMemo } from 'react';
import { useLocalStorage } from 'src/hooks/use-browser-storage';
import { CinchColorTheme } from 'src/types/ColorTheme';
import { COLOR_THEMES } from 'src/util';

export const useColorTheme = (): CinchColorTheme => {
  const [localColorTheme] = useLocalStorage('cinch-color-theme');
  return useMemo(
    () => COLOR_THEMES.find((x) => x.id === localColorTheme) ?? COLOR_THEMES[0],
    [localColorTheme],
  );
};
