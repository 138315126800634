import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useInputFocusOnNavigation = (): void => {
  const location = useLocation();
  const hash = location.hash?.substring(1);
  useEffect(() => {
    if (hash) {
      delayedFlash(hash, 500);
    }
  }, [hash]);
};

function delayedFlash (hash, delay, retry = 0) {
  setTimeout(() => {
    const elembyid = document.getElementById(hash);

    if (elembyid) {
      flash(elembyid);
    } else if (retry < 5) {
      delayedFlash(hash, delay + 500, retry + 1);
    }
  }, delay);
}

function flash (el: HTMLElement) {
  el.classList.add('input-flash');
  el.scrollIntoView({ behavior: 'smooth' });
  el.focus();
}
