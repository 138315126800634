import React, { useCallback, useEffect, useState } from 'react';
import { Button, TextField, Tooltip } from '@material-ui/core';
import styles from './index.module.scss';
import { LoadingIcon } from 'src/components/baseComponents/Loading';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const InlineTitleEditor = ({
  initialValue = '',
  onAccept = () => null,
  placeholder = 'Title',
  disabled,
  className,
  tooltip = '',
  tooltipPlacement = 'top',
}) => {
  const [value, setValue] = useState(initialValue);
  const [saving, setSaving] = useState(false);
  const canSave = Boolean(value?.trim() && value !== initialValue);

  const handleSubmit = useCallback(
    async (e) => {
      if (!canSave || saving) {
        return;
      }
      try {
        setSaving(true);
        await onAccept(value, e);
      } finally {
        setSaving(false);
      }
    },
    [canSave, saving, onAccept, value],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit],
  );

  return (
    <div className={clsx(styles.inlineTitleEditor, className)}>
      <Tooltip title={tooltip} arrow placement={tooltipPlacement}>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={disabled}
          variant='outlined'
          size='small'
          onKeyDown={handleKeyDown}
          onBlur={handleSubmit}
          placeholder={placeholder}
          error={!value?.trim()}
          InputProps={{
            endAdornment: (
              <Button
                variant='contained'
                color='primary'
                size='small'
                startIcon={saving ? <LoadingIcon /> : null}
                disabled={!canSave}
              >
                Save
              </Button>
            ),
          }}
        />
      </Tooltip>
    </div>
  );
};

InlineTitleEditor.propTypes = {
  initialValue: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};
