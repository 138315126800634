import {
  LOAN_FIELD_CHANGE,
  LOAN_KEY_VALUES_CHANGE,
  LOADING,
  LOAN_KEYS_REMOVAL,
  CLEAR_UPDATED_LOAN,
  CLEAR_LOAN,
  SET_ERROR_GETTING,
  SET_ERROR_UPDATING,
  GET_LOAN_OFFICER_PIPELINE,
  SET_PIPELINE_LOAN_FOLDER,
  SET_SELECTED_LOAN,
} from './types';
import axios from 'axios';

export const loanFieldChange = (fieldId, e) => (dispatch) => {
  if (e.target.type === 'checkbox') {
    e.target.value = e.target.checked ? 'Y' : 'N';
  }
  dispatch({
    type: LOAN_FIELD_CHANGE,
    fieldId,
    value: e.target.value,
  });
};

export const loanFieldsValuesChange = (keyValues) => (dispatch) => {
  dispatch({
    type: LOAN_KEY_VALUES_CHANGE,
    keyValues,
  });
};

export const clearUpdatedLoan = () => (dispatch) => {
  dispatch({
    type: CLEAR_UPDATED_LOAN,
    payload: '',
  });
};

export const clearLoan = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOAN,
  });
};

export const loanFieldsRemoval = (keysArray) => (dispatch) => {
  dispatch({
    type: LOAN_KEYS_REMOVAL,
    keysArray,
  });
};

export const setErrorUpdating = (error) => (dispatch) => {
  dispatch({
    type: SET_ERROR_UPDATING,
    payload: error,
  });
};

export const getLoanOfficerPipeline =
  (
    refreshing,
    loanFolder,
    { forAppraisal, forCommercial, forReports, reportType } = {},
    // eslint-disable-next-line max-statements
  ) =>
    async (dispatch, getState) => {
      const skipSet = forAppraisal || forCommercial || forReports;
      loanFolder = skipSet ? undefined : loanFolder || 'Active';

      if (refreshing && !skipSet) {
        dispatch({
          type: GET_LOAN_OFFICER_PIPELINE,
          payload: null,
        });
      }
      const state = getState();
      try {
        const response = await axios({
          method: 'post',
          url: 'GetLOPipeline',
          data: {
            loEmail: state.loanOfficer.email,
            loanFolder,
            forAppraisal,
            forCommercial,
            forReports,
            reportType,
          },
        });
        if (!response.data.error) {
          if (!skipSet) {
            dispatch({
              type: GET_LOAN_OFFICER_PIPELINE,
              payload: JSON.parse(response.data.pipelineData),
            });
            dispatch({
              type: SET_PIPELINE_LOAN_FOLDER,
              payload: response.data.loanFolder,
            });
            dispatch({
              type: SET_ERROR_GETTING,
              payload: false,
            });
          }
        }
        return response;
      } catch (error) {
        dispatch({
          type: SET_ERROR_GETTING,
          payload: true,
        });

        dispatch({
          type: LOADING,
          payload: false,
        });
        return error;
      }
    };

export const sendLoFeedback =
  ({ rating, feedbackText }) =>
    () => {
      return axios({
        method: 'post',
        url: 'LOFeedback',
        data: { rating, feedbackText },
      });
    };

/*export const scheduleClosing =
  ({ guid, closingDate }) =>
    () => {
      return axios.post('ScheduleClosing', {
        guid,
        closingDate,
      });
    };*/

export const setSelectedLoan = (loan) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_LOAN,
    payload: loan,
  });
};
