import { JsonHubProtocol, HubConnectionBuilder } from '@microsoft/signalr';

/**
 * Creates a signalr hub connection.
 * @param {string} url
 * @param {import('@microsoft/signalr').IHttpConnectionOptions} options
 * @returns {import('@microsoft/signalr').HubConnection}
 */
export const createConnection = (url, options) => {
  return new HubConnectionBuilder()
    .withUrl(url, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .build();
};
