import { useMemo } from 'react';
import {
  useCurrentLoanOfficer,
  useLoan,
  useLoanTypeFlags,
  useUserRoles,
} from 'src/hooks';
import { LoanChannel } from 'src/types';
import { CinchLoanType } from 'src/types/CinchLoanType';
import { ROLE_NAMES } from 'src/util';
import { fieldIds } from 'src/util/fieldIds';

export const overrideDisable = ({ personas, roles }) => {
  const isDisclosureDesk =
    roles.includes(ROLE_NAMES.DISCLOSUREDESK) ||
    personas?.filter((x) => x.entityName === 'Disclosure Desk').length > 0;

  if (roles.includes(ROLE_NAMES.ADMINISTRATOR) || isDisclosureDesk) return true;
  return false;
};

export const useBypassDisclosureRequirements = () => {
  const roles = useUserRoles();
  const user = useCurrentLoanOfficer();
  return overrideDisable({ personas: user.personas, roles });
};

const commonDisclosuresDisabled = ({ loan, guid, loading, locked, type }) => {
  const reasons = [];
  if (!guid) {
    reasons.push('No loan selected.');
  }

  if (loading) {
    reasons.push('Still loading borrower pairs.');
  }

  if (locked) {
    reasons.push('Loan is locked by another user.');
  }

  // noemail
  if (
    loan['1240'] === 'noemail@fmm.com' ||
    loan['1268'] === 'noemail@fmm.com'
  ) {
    reasons.push('Blocked due to email.');
  }

  if (loan[fieldIds.loanChannel] === LoanChannel.Brokered) {
    reasons.push('Cannot order disclosures for brokered loans');
  }

  if (![CinchLoanType.Commercial, CinchLoanType.FixAndFlip].includes(type)) {
    if (loan[fieldIds.applicationStatus] !== 'Achieved') {
      reasons.push(
        'Loan application must be complete before ordering disclosures.',
      );
    }
  }

  if (loan[fieldIds.sendDisclosures]?.toUpperCase() === 'NOT') {
    reasons.push(
      'Disclosures are not allowed for this loan. Please contact your administrator',
    );
  }
  return reasons;
};

const comFnfNqmDisclosuresDisabled = () => {
  return [];
};

const residentialDisclosuresDisabled = ({ loan, loanFolder }) => {
  const reasons = [];
  // if disclosures have been ordered, field 3152 will return back a date
  // '//' may be returned by Encompass rather than an empty string
  if (!['', '//'].includes(loan['3152'])) {
    reasons.push(
      'You have already ordered initial disclosures for this loan. You cannot order disclosures again.',
    );
  }

  // check for valid states
  if (!['NY', 'NJ', 'FL'].includes(loan['14'])) {
    reasons.push(
      'Non-NY/NJ/FL loans are only available to the Disclosure Desk.',
    );
  }

  if (loanFolder === 'Completed' || loanFolder === 'Denied') {
    reasons.push(`Loan is ${loanFolder.toLowerCase()}.`);
  }
  // Trans Details Loan Type is Veteran's Association
  if (loan['1172'] === 'VA') {
    reasons.push('Trans Details Loan Type is invalid.');
  }

  return reasons;
};

export const isDisclosuresDisabledObsolete = ({
  loan = {},
  personas = [],
  guid,
  loading,
  loanFolder,
  locked,
  loanFromPipeline,
  type,
  extraRules,
  roles,
}) => {
  const opts = {
    loan,
    personas,
    guid,
    loading,
    loanFolder,
    locked,
    loanFromPipeline,
    type,
    roles,
  };

  // common rules that stop disclosures from working at all
  const reasons = commonDisclosuresDisabled(opts);
  if (reasons.length >= 1) return reasons;

  // this currently takes priority over admin/disclosure desk
  if (
    type === CinchLoanType.Commercial ||
    type === CinchLoanType.FixAndFlip ||
    type === 'nqm'
  ) {
    const reasons = comFnfNqmDisclosuresDisabled(opts);
    if (reasons.length >= 1) return reasons;
  }

  // allow any admin or disclosure desk user to order disclosures
  const override = overrideDisable(opts);
  if (override) return [];

  if (type === CinchLoanType.Residential) {
    const reasons = residentialDisclosuresDisabled(opts);
    if (reasons.length >= 1) return reasons;
  }

  // handles any additional rules passed in manually
  if (typeof extraRules === 'function') {
    const reasons = extraRules(opts);
    if (reasons.length >= 1) return reasons;
  }
  return [];
};

export const extractFieldsFromPipeline = (pipelineLoan) => {
  const res = {};
  for (const k in pipelineLoan) {
    if (k.startsWith('Fields_')) {
      res[k.substr(7).replace(/_/g, '.')] = pipelineLoan[k];
    } else {
      res[k] = pipelineLoan[k];
    }
  }
  return res;
};

/**
 * @param loan Plain Loan object or Pipeline Loan object
 * @param personas List of personas from loanOfficer
 * @param guid Guid of selected loan
 * @param loading Still loading something?
 * @param loanFolder Folder of the selected loan
 * @param locked Whether the loan is locked by someone else
 * @param loanFromPipeline Whether the loan param is a Pipeline Loan object
 * @param type Loan type (com, fnf, res)
 * @param extraRules Function ({ loan, personas, guid, loading, loanFolder, locked }) => string
 */
export const useDisclosuresObsolete = ({
  loan: _loan = {},
  personas = [],
  guid,
  loading,
  loanFolder,
  locked,
  loanFromPipeline,
  type,
  extraRules,
}) => {
  const roles = useUserRoles();
  const disabledReason = useMemo(() => {
    let loan = _loan;
    if (loanFromPipeline) loan = extractFieldsFromPipeline(loan);
    return isDisclosuresDisabledObsolete({
      loan,
      personas,
      guid,
      loading,
      loanFolder,
      locked,
      loanFromPipeline,
      type,
      extraRules,
      roles,
    });
  }, [
    _loan,
    extraRules,
    guid,
    loading,
    loanFolder,
    loanFromPipeline,
    locked,
    personas,
    type,
    roles,
  ]);

  return {
    disabled: disabledReason || '',
  };
};

const getDisclosuresDisabledReasons = ({ loan, typeFlags, fields }) => {
  const reasons = [];

  for (const app of loan.applications) {
    if (!app.borrower?.emailAddressText) {
      reasons.push(
        `Missing ${app.borrower?.firstName ?? 'Borrower'}'s email address.`,
      );
    }
    if (!app.coBorrower?.emailAddressText && app.coBorrower?.firstName) {
      reasons.push(
        `Missing ${
          app.borrower?.firstName ?? 'Borrower'
        }'s co-borrower email address.`,
      );
    }
  }

  if (!typeFlags.isFnf && !typeFlags.isCommercial) {
    if (fields[fieldIds.applicationStatus] !== 'Achieved') {
      reasons.push(
        'Loan application must be complete before ordering disclosures.',
      );
    }
  }

  if (loan.channel === LoanChannel.Brokered) {
    reasons.push('Cannot order disclosures for brokered loans');
  }

  return reasons;
};

export const useInitialDisclosures = (loanGuid, fields) => {
  const loan = useLoan(loanGuid);
  const roles = useUserRoles();
  const loanOfficer = useCurrentLoanOfficer();
  const typeFlags = useLoanTypeFlags(loanGuid);
  return useMemo(() => {
    const reasons = getDisclosuresDisabledReasons({
      loan,
      typeFlags,
      fields,
    });
    const canOverride =
      roles.includes(ROLE_NAMES.ADMINISTRATOR) ||
      loanOfficer.personas?.some((x) => x.entityName === 'Disclosure Desk');
    return {
      disabled: reasons.length > 0,
      canOverride,
      reasons,
    };
  }, [loan, typeFlags, fields, roles, loanOfficer.personas]);
};
