import axios from 'axios';
import qs from 'query-string';

const apiAxios = axios.create({
  paramsSerializer: qs.stringify,
});

const anyAxios = axios.create({
  paramsSerializer: qs.stringify,
});

const elliemaeStatusCenterAxios = axios.create({
  baseURL: 'https://emstatuscenter.elliemae.com/',
});

// because iOS is bad. use this to cancel all pending axios requests if the site navigates to somewhere else (auth0, etc)
// can only be used once
const globalAxiosAbortController = new AbortController();

export {
  apiAxios,
  elliemaeStatusCenterAxios,
  anyAxios,
  globalAxiosAbortController,
};
