import React, { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const texts = {
  cancel: 'Cancel',
  submit: 'OK',
};

const PromptDialog = ({
  open,
  title,
  description,
  InputComponent,
  cancellable,
  required,
  onSubmit,
  onClose,
  buttons,
  defaultValue,
  validate,
}) => {
  const [value, setValue] = useState(defaultValue);
  const text = useMemo(
    () => ({
      ...texts,
      ...buttons,
    }),
    [buttons],
  );

  const isValid = useMemo(() => {
    return validate(value);
  }, [validate, value]);

  const handleCancel = (e) => {
    setValue(defaultValue);
    onClose(e);
  };

  const handleSubmit = () => {
    if (required && !isValid) {
      return;
    }
    onSubmit(value);
  };
  return (
    <Dialog open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {description && <div style={{ marginBottom: 8 }}>{description}</div>}
        <InputComponent
          value={value}
          setValue={setValue}
          required={required}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        {cancellable && (
          <Button variant='text' onClick={handleCancel}>
            {text.cancel}
          </Button>
        )}

        <Button
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          disabled={!isValid}
        >
          {text.submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PromptDialog.defaultProps = {
  open: false,
  onSubmit: () => null,
  onClose: () => null,
  defaultValue: '',
  validate: (v) => v !== '',
  cancellable: true,
  required: false,
  InputComponent: ({ value, setValue, ...props }) => (
    <TextField
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  ),
};

PromptDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  cancellable: PropTypes.bool,
  required: PropTypes.bool,
  InputComponent: PropTypes.func,
  validate: PropTypes.func,
  buttons: PropTypes.shape({
    submit: PropTypes.string,
    cancel: PropTypes.string,
  }),
};

export default PromptDialog;
