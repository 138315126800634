import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCanonicalFieldFetcherContext } from 'src/applicationproviders/CanonicalFieldFetcherProvider';
import { LoanPipelineCanonicalField } from 'src/types/pipeline';

const emptyArray = [];

export const useLoanPipelineCanonicalFields = (): Record<
  string,
  LoanPipelineCanonicalField
> => {
  const fields = useSelector(
    (store) =>
      // @ts-ignore
      store.pipelineFields,
  );
  return fields;
};

export const useLoanPipelineFetchCanonicalFields = (
  fields: string[],
): Record<string, LoanPipelineCanonicalField> => {
  const pipelineFields = useLoanPipelineCanonicalFields();
  const { fetchCanonicalFields } = useCanonicalFieldFetcherContext();

  useEffect(() => {
    if (fields?.length) fetchCanonicalFields(fields);
  }, [fetchCanonicalFields, fields]);

  return pipelineFields;
};

export const useLoanFolders = () => {
  const store = useSelector(
    (store) =>
      // @ts-ignore
      store.loanFolders,
  );
  return store.data ?? emptyArray;
};
