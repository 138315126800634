import { apiAxios } from 'src/util';

export const sendLoanAmountExceptionRequest = async (loanGuid, data) => {
  try {
    const res = await apiAxios.post(`loan-exception/${loanGuid}/request`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export const sendJuniorLienExceptionRequest = async (loanGuid, fromEmail) => {
  try {
    const res = await apiAxios.get(
      `loan-exception/${loanGuid}/junior-lien-submit/${fromEmail}`,
    );
    return res.data;
  } catch (err) {
    return err;
  }
};
