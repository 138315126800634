export const GET_DATA = 'GET_DATA';
export const UPDATING = 'UPDATING';
export const UPDATE_DATA = 'UPDATE_DATA';
export const LOAN_FIELD_CHANGE = 'LOAN_FIELD_CHANGE';
export const LOAN_KEY_VALUES_CHANGE = 'LOAN_KEY_VALUES_CHANGE';
export const LOADING = 'LOADING';
export const LOAN_KEYS_REMOVAL = 'LOAN_KEYS_REMOVAL';
export const CLEAR_UPDATED_LOAN = 'CLEAR_UPDATED_LOAN';
export const SET_ERROR_GETTING = 'SET_ERROR_GETTING';
export const SET_ERROR_UPDATING = 'SET_ERROR_UPDATING';
export const GET_LOAN_OFFICER_PIPELINE = 'GET_LOAN_OFFICER_PIPELINE';
export const SET_REFERRAL_TOKEN_AND_ID = 'SET_REFERRAL_TOKEN_AND_ID';
export const CLEAR_LOAN = 'CLEAR_LOAN';
export const SET_PIPELINE_LOAN_FOLDER = 'SET_PIPELINE_LOAN_FOLDER';
export const SET_SHOW_LONG_PROCESS = 'SET_SHOW_LONG_PROCESS';
export const SET_SELECTED_LOAN = 'SET_SELECTED_LOAN';
export const GET_CINCH_TASKS = 'GET_CINCH_TASKS';
export const SET_CINCH_TASKS = 'SET_CINCH_TASKS';
export const ADD_CINCH_TASKS = 'ADD_CINCH_TASKS';
export const UPDATE_CINCH_TASK = 'UPDATE_CINCH_TASK';
export const SET_CINCH_TASKS_WORKING = 'SET_CINCH_TASKS_WORKING';
export const REMOVE_CINCH_TASK = 'REMOVE_CINCH_TASK';
export const UPDATE_LOANSTORAGE = 'UPDATE_LOANSTORAGE';
export const SET_LOAN_OFFICER = 'SET_LOAN_OFFICER';
export const UPDATE_LOAN_OFFICER_PIPELINE_COLUMNS =
  'UPDATE_LOAN_OFFICER_PIPELINE_COLUMNS';
export const GET_CINCH_TASKS_RELEASE = 'GET_CINCH_TASKS_RELEASE';
export const SET_CINCH_TASKS_RELEASE = 'SET_CINCH_TASKS_RELEASE';
export const SET_CINCH_TASKS_RELEASE_ERROR = 'SET_CINCH_TASKS_RELEASE_ERROR';
export const REQUEST_LOAN_FOLDERS = 'REQUEST_LOAN_FOLDERS';
export const SET_LOAN_FOLDERS = 'SET_LOAN_FOLDERS';
export const SET_APP_SETTINGS = 'SET_APP_SETTINGS';
export const SET_CINCH_SETTING = 'SET_CINCH_SETTING';
export const UPDATE_CINCH_SETTING = 'UPDATE_CINCH_SETTING';
export const UPDATE_FLOOD_FIELDS = 'UPDATE_FLOOD_FIELDS';

export const WORKSTATE = {
  UPDATE_LOAN: 'WORKSTATE_UPDATE_LOAN',
  GENERIC_TASK: 'WORKSTATE_GENERIC_TASK',
};
