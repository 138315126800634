import React, { useEffect } from 'react';
import cinchLogo from 'src/images/logo/cinch-logo.svg';
import cinchLogoWhite from 'src/images/logo/cinch-logo-white.svg';
import mrLogo from 'src/images/logo/mrcapital-logo.svg';
import mrLogoWhite from 'src/images/logo/mrcapital-logo-white.svg';
import styles from './index.module.scss';
import clsx from 'clsx';
import { useColorTheme } from 'src/hooks';

const isMrCapital = window.location.origin.includes('mr.fmm.com');
const logo = isMrCapital ? mrLogo : cinchLogo;
const logoWhite = isMrCapital ? mrLogoWhite : cinchLogoWhite;
export const Logo = ({ className }) => {
  const theme = useColorTheme();

  useEffect(() => {
    if (isMrCapital) {
      const link = document.createElement('link');
      const oldLink = document.getElementById('dynamic-favicon');
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      link.href = window.location.origin + '/Images/mrcapitallogos.ico';
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    }
  }, []);

  return (
    <img
      src={theme.base === 'dark' ? logoWhite : logo}
      alt='Logo'
      aria-hidden={true}
      className={clsx(styles.logo, className)}
    />
  );
};
