import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { ConnectedSelectInput } from 'src/components/ConnectedInputs';
import styles from '../index.module.scss';

export const Hours = ({ disabled }) => {
  const hours = useWatch({
    name: 'hours',
  });
  const tooltip = useMemo(() => getDisplayValue(hours), [hours]);

  return (
    <Tooltip title={tooltip} arrow placement='top'>
      <ConnectedSelectInput
        multiple
        displayEmpty
        name='hours'
        options={options}
        disabled={disabled}
        fullWidth={false}
        className={clsx(styles.input, styles.hoursInput)}
        renderValue={getDisplayValue}
      />
    </Tooltip>
  );
};

const getDisplayValue = (values) =>
  values.length ? values.sort((a, b) => a - b).join(', ') : 'Every hour';

const options = new Array(24).fill(0).map((_, i) => ({
  text: `${i}`,
  value: i,
}));
