import { apiAxios } from 'src/util';
import { startLoanWork, stopLoanWork } from '.';
import { WORKSTATE } from './types';

export const fetchFlippedProperties = async (loanGuid) => {
  const res = await apiAxios.get(`flipped-properties/${loanGuid}`);
  return res.data;
};

export const updateFlippedProperties = (loanGuid, data) => async (dispatch) => {
  dispatch(startLoanWork(loanGuid), { task: WORKSTATE.UPDATE_LOAN });
  try {
    await apiAxios.put(`flipped-properties/${loanGuid}`, data);
  } catch (err) {
    console.log('Error saving RTL properties', err);
  } finally {
    dispatch(stopLoanWork(loanGuid), { task: WORKSTATE.UPDATE_LOAN });
  }
};
