import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { ChangeEvent, FC } from 'react';

interface CheckboxInputProps {
  className?: string;
  checkboxClassName?: string;
  checked: boolean;
  onChange: (e: ChangeEvent, checked: boolean) => void;
  label?: string;
  disabled?: boolean;
}
export const CheckboxInput: FC<CheckboxInputProps> = ({
  className,
  checkboxClassName,
  checked,
  onChange,
  label,
  disabled,
  ...props
}) => {
  return (
    <FormControlLabel
      className={className}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChange(e, e.target.checked)}
          disabled={disabled}
          className={checkboxClassName}
          {...props}
        />
      }
      label={label}
      disabled={disabled}
    />
  );
};
